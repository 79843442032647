var template = require("./spinner.html");


function viewModel() {
    var vm = this;


}

module.exports = {
    viewModel: viewModel,
    template:template
}