var template = require('./user-credentials.html');


function viewModel(params) {
    var self = this;
    self.ProfileId = params.ProfileId;
    self.enableDelete = ko.observable(false);
    if (!params.Credentials)
        self.Credentials = ko.observableArray();
    else
        self.Credentials = params.Credentials;
    if (params.enableDelete)
        self.enableDelete(params.enableDelete);

    self.clearCredentials = function() {
        notify.confirm("You will be deleting this person's ability to authenticate, remove their existing stored payment methods, and stopping all recurring payments. This will not delete their profile, but will allow them to reclaim their profile through registration or by sending an invite?",

               function () {
                   var model = { profileId: self.ProfileId() };
                   v2Form.POST({
                       url: '/User/Account/ClearCredentialsByProfile',
                       type: 'POST',
                       contentType: 'application/json',
                       dataType: 'json',
                       data: model,
                       success: function (data, textStatus, jqXhr) {
                           self.Credentials.removeAll();
                       },
                       error: function (data, textStatus, jqXhr) {
                           console.log('error', data);
                       }
                   });
               });
    };

    self.clearCredentialById = function (cred) {
        notify.confirm("You will be deleting this person's ability to authenticate using these authentication credentials",

            function () {
                var model = ko.toJS(cred);
                v2Form.POST({
                    url: '/User/Account/ClearCredentialsById',
                    type: 'POST',
                    contentType: 'application/json',
                    dataType: 'json',
                    data: { id: model.Id, userAccountId: model.UserAccountId},
                    success: function (data, textStatus, jqXhr) {
                        self.Credentials.remove(cred);
                        
                    },
                    error: function (data, textStatus, jqXhr) {
                        console.log('error', data);
                        
                    }
                });
            });
    };
    
    self.getCredentials = function () {
        var model = { profileId: self.ProfileId() };
        v2Form.POST({
            url: '/User/Account/GetCredentialsByProfile',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: model,
            success: function (data, textStatus, jqXhr) {
                ko.mapping.fromJS(data.Data, {}, self.Credentials);
            },
            error: function (data, textStatus, jqXhr) {
                self.Credentials.clear();
                console.log('error', data);
            }
        });
    };

    self.resendCredentials = function (cred) {
        var model = ko.toJS(cred);

        v2Form.POST({
            url: '/User/Account/ResendVerificationForCredentials',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: { id: model.Id},
            success: function (data, textStatus, jqXhr) {
                notify.alert("We have resent the verification link","Sent");
            },
            error: function (data, textStatus, jqXhr) {
                console.log('error', data);
            }
        });

    }

    if (!params.Credentials && params.ProfileId && params.ProfileId() > 0) {
        self.getCredentials();
    }


    //console.log(params, self);
}


module.exports = {
    viewModel: viewModel,
    template: template

};

