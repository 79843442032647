var template = require('./marketingsource-select.html');





function viewModel(params) {


    var self = this;

    if (params.MarketingCommunication) {
        self = params.MarketingCommunication;
    } else {
        self.Id = params.Id;
        self.MarketingCommunicationName = ko.observable();
        self.ChannelType = ko.observable();
        self.MarketingCommunicationType = ko.observable();
        self.SMSHashtag = ko.observable();
        self.Channel = ko.observable();
        if (params.AskLadderId != null)
            self.AskLadderId = params.AskLadderId;
        else
            self.AskLadderId = ko.observable();
        self.To = {
            FundableName: ko.observable(),
            FundableId: ko.observable(),
            Description: ko.observable(),
            FundableReferenceType: ko.observable()
        };
        
    }

    
    self.Sources = ko.observableArray();
    self.isNullable = ko.observable(false);
    self.FundableId = ko.observable();
    self.noSource = ko.observable(false);
    self.Label = ko.observable();
    self.NoSourceLabel = ko.observable();
    self.AddSourceLabel = ko.observable();
    self.IdSelected = ko.computed(function () {
        return ko.unwrap(self.Id) > 0;
    })
    self.Enabled = ko.observable(false);
    


    var searchArguments = {
        IsGiftSource: ko.utils.unwrapObservable(params.IsGiftSource),
        Channel: ko.utils.unwrapObservable(params.Channel),
        FundableId: ko.utils.unwrapObservable(params.FundableId),
        Type: ko.utils.unwrapObservable(params.Type),
        NotMarketingEffort: ko.utils.unwrapObservable(params.MarketingEffortId),
        IsArchived: false,
        SearchTerm: null,
        PageIndex: 0,
        PageSize : 15
    };

    console.log('marketing communication select', params, searchArguments);

    
    if (params.IsNullable) {
        self.isNullable(ko.utils.unwrapObservable(params.IsNullable));
        if (self.Id() == null)
            self.noSource(true);
    }
    else {
        
        if (!self.IdSelected())
            self.Enabled(true);
    }

    if (params.Label) {
        self.Label(ko.utils.unwrapObservable(params.Label) + " Source");
        self.NoSourceLabel("No " + ko.utils.unwrapObservable(params.Label) + " Source");
        self.AddSourceLabel("Select " + ko.utils.unwrapObservable(params.Label) + " Source");
    } else {
        self.Label("Source");
        self.NoSourceLabel("No Source");
        self.AddSourceLabel("Select Source");
    }

    console.log('marketing select params', params, ko.toJS(self));

    self.toggleEnabled = function () {
        self.Enabled(!self.Enabled())
        if (!self.Enabled()) {
            if (self.isNullable()) {
                self.Id(null);
            } else {
                self.Id(0);
            }
        }

    }
    

    
    self.enableSearch = function() {
        if (self.isNullable()) {
            self.Id(null);
        } else {
            self.Id(0);
        }
    };
    
    self.GetSources = function (options) {
        if (options.text == "") return;


        searchArguments.SearchTerm = options.text;
        searchArguments.PageIndex = options.page;
        //console.log('model', model);


        v2Form.POST({
            url: '/Marketing/MarketingSource/Search',
       
            contentType: 'application/json',
            
            data: searchArguments,
            success: function (data, textStatus, jqXhr) {
               options.callback(data.Data);
            },
            error: function (data, textStatus, jqXhr) {
                console.log('error');
                console.log(data);
            }
        });
    };




    self.Source = ko.observable();

    self.Source.subscribe(function (x) {
        //console.log(x);
        self.Id(ko.utils.unwrapObservable(x.Id));
        self.MarketingCommunicationName(ko.utils.unwrapObservable(x.MarketingCommunicationName));
        self.ChannelType(ko.utils.unwrapObservable(x.ChannelType));
        self.MarketingCommunicationType(ko.utils.unwrapObservable(x.MarketingCommunicationType));
        self.SMSHashtag(ko.utils.unwrapObservable(x.SMSHashtag));
        self.AskLadderId(ko.utils.unwrapObservable(x.AskLadderId));
        if (x.To) {
            var d = ko.mapping.toJS(x.To);
            self.To.FundableId(d.FundableId);
            self.To.FundableName(d.FundableName);
            self.To.Description(d.Description);
            self.To.FundableReferenceType(d.FundableReferenceType);
        }
            
    });

    self.getDetail = function () {
        v2Form.POST({
            url: '/Marketing/MarketingSource/GetDisplay',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: { id: self.Id() },
            success: function (data, textStatus, jqXhr) {
                self.Source(data.Data);
            },
            error: function (data, textStatus, jqXhr) {
                console.log('error');
                console.log(data);
            }
        });
    };

    console.log(ko.toJS(self));

    if (self.Id() > 0)
        self.getDetail();

    

    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};

