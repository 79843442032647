var template = require('./premium-askladder-display.html');


function viewModel(params) {

    var self = this;


    self.Id = params.Id;
    self.Amounts = params.Amounts;
    self.IsUsingImpactOrPremium = ko.observable(false);


    self.getAskLadder = function (options) {
        //self.Amounts.removeAll();
        v2Form.POST({
            url: '/Marketing/AskLadder/GetAskLadderDisplay',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data:    { Id: self.Id() },
            success: function (data, textStatus, jqXhr) {
                
                ko.mapping.fromJS(data.Data, {}, self);
               

            },
            error: function (data, textStatus, jqXhr) {
                console.log('error');
                console.log(data);
            }
        });
    };


    self.Id.subscribe(function (nv) {
        //console.log('ask ladder display id', nv);
        if (nv != null)
            self.getAskLadder();
       
            
    });
    
   


    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};

