var pagination = require("../../v2/paginationServices");
var analytics = require("../../v2/analytics.js");
require("knockout");
require("knockout-mapping");
require("../../v2/knockout.custombindings");
var radio = require("radio");
var template = require("./commitmentlineitemsearch.html");
var moment = require("moment");
var jstz = require("jstz");


function viewModel() {

    var vm = this;

    vm.Designations = new pagination.PageModel();
    vm.SearchCriteria = new PaginationServices.FundableSearchModel();


    vm.displayAnalytics = ko.observable(true);
    vm.displaySearch = ko.observable(true);
    vm.UseComplex = ko.observable(false);
    vm.loading = ko.observable(false);


    var pageSize = params.PageSize || 30;

    if (params.Type)
        vm.SearchCriteria.FundableReferenceType(ko.utils.unwrapObservable(params.Type));



    

    PaginationServices.SubscribeSearchPagination(viewModel, viewModel.Fundables, '/Marketing/Designation/Search', viewModel.Fundables.SearchCriteria);
    viewModel.Fundables.firstPage();





}