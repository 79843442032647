var template = require('./signupsetup.html');

var giftDesignations = require('../../v2/giftDesignation.js');
var extender = require('../../v2/eventCalendarExtender.js');

function viewModel(params) {
    vm = this;
    vm.ChannelId = params.ChannelId;
    vm.MarketingCommunicationId = params.MarketingCommunicationId;
    vm.Designations = params.Designations;
    vm.AskLadderId = ko.observable();
    vm.SiteId = params.SiteId;
    vm.SetupComplete = params.SetupComplete;
    vm.Schedule = params.Schedule;

    vm.addDesignation = function () {
        var designation = new giftDesignations.GiftDesignationModel();
        designation.removeDesignation = function () {
            vm.Designations.remove(designation);
        };
        vm.Designations.push(designation);

    };


    
    vm.completeSetup = function () {
        var data = ko.toJS(vm.Schedule);
        console.log('is scheduled', vm.Schedule.IsScheduled(), data);
        vm.SetupComplete(true);
    }
    
    

}

module.exports = {
    viewModel: viewModel,
    template: template
}