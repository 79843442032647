require("knockout");
require("knockout-mapping");
var radio = require("radio");
var paginationServices = require("../../v2/paginationServices.js");
var v2Form = require("../../v2/formUtilities.js");
require("../../kobindings/searchbindings.js");
var template = require('./individualsearch.html');
var eventCalendarExtender = require("../../v2/eventCalendarExtender.js");

function viewModel(params) {
    
    var self = this;
    
    self.Individuals = new paginationServices.PageModel();
    self.IndividualSearchCriteria = new paginationServices.IndividualSearchModel(0, 20);
    self.DisplayEnvelope = ko.observable(false);

    eventCalendarExtender.DatePickerEnable(self.IndividualSearchCriteria, self.IndividualSearchCriteria.FromCreatedDate);
    eventCalendarExtender.DatePickerEnable(self.IndividualSearchCriteria, self.IndividualSearchCriteria.ToCreatedDate);

    setParams(params, self.IndividualSearchCriteria);

    self.resultDownloadModalVisible = ko.observable(false); 

    if (params.DisplayEnvelope) {
        self.DisplayEnvelope(ko.utils.unwrapObservable(params.DisplayEnvelope));
    }

    self.navigateToDetailsFromSearch = function (d) {
        window.open('/People/Individual/Details/' + d.Id());
    };

    self.navigateToEditFromSearch = function (d) {
        sessionStorage.setItem("RefreshIndividualSearch", JSON.stringify(ko.toJS(self.IndividualSearchCriteria)));
        window.open('/People/Individual/Edit/' + d.Id());
    };

    self.navigateToAddGiftFromSearch = function (d) {
        sessionStorage.setItem("RefreshIndividualSearch", JSON.stringify(ko.toJS(self.IndividualSearchCriteria)));
        window.open('/Giving/Gift/CreateGiftForIndividual/' + d.Id());
    };

    self.generateDisplayName = function (d) {
        var result = "";

        if (d.PrimaryName != null) {
            var firstName = ko.toJS(d.PrimaryName.FirstName);
            var lastName = ko.toJS(d.PrimaryName.LastName);
            var middleName = ko.toJS(d.PrimaryName.MiddleName);
            var suffix = ko.toJS(d.PrimaryName.Suffix);
            var searchTag = ko.toJS(d.SearchTag);

            result = result + firstName;
            if (middleName != null && middleName.length > 0) {
                result += " " + middleName;
            }
            result += " " + lastName;
            if (suffix != null && suffix.length > 0) {
                result += ", " + suffix;
            }
            if (searchTag != null && searchTag.length > 0) {
                result += " (" + searchTag + ")";
            }
        }

        return result;
    };

    paginationServices.SubscribeSearchPagination(self, self.Individuals, '/People/Individual/SearchProfileMainPage', self.IndividualSearchCriteria);

    //must be just before first page call
    if (sessionStorage.getItem("RefreshIndividualSearch")) {
        var criteria = JSON.parse(sessionStorage.getItem("RefreshIndividualSearch"));
        ko.mapping.fromJS(criteria, {}, self.IndividualSearchCriteria);
        sessionStorage.removeItem("RefreshIndividualSearch");
    }

    self.getSpreadsheet = function () {
        v2Form.POST({
            url: '/People/Individual/Spreadsheet',
            data: ko.toJS(self.IndividualSearchCriteria),
            error: function () {
                v2Form.GET({
                    url: '/Finance/Payment/ClearArgumentsFromSession'
                });
            },
            complete: function () { $('body').unblock(); }
        });
    };

    self.ResetCriteria = function () {
        var complex = self.IndividualSearchCriteria.UseComplex();
        var model = new paginationServices.IndividualSearchModel(0, self.IndividualSearchCriteria.PageSize());
        setParams(params, model);
        ko.mapping.fromJS(ko.toJS(model), {}, self.IndividualSearchCriteria);
        self.IndividualSearchCriteria.UseComplex(complex);
        if (complex) {
            $('#indSearchFirstName').focus();
        }
    };

    function setParams(params, vm) {
        //console.log('params in set params', params);
        if (params && vm) {
           
            if (params.UseComplex) {
                vm.UseComplex(ko.utils.unwrapObservable(params.UseComplex));
            }
            if (params.IsArchived) {
                vm.IsArchived(ko.utils.unwrapObservable(params.IsArchived));
            }
        }
    }

    self.Individuals.firstPage();

    return self;
}

module.exports = {
    viewModel: viewModel,
    template: template
};