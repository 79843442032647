
$.validator.addMethod("vision2_validation_optionalIf", function (value, element) {
    //var pairedPropertyName = rule.ValidationParameters.PairedProperty;
    //var message = rule.ErrorMessage;

    //return function (value, context) {
    console.log("optionalif");
    console.log(value);
    console.log(element);
    //if we have a value, then exit because it's optional
    //if (value != null) return true;

    //var pairedProperty = $("#" + pairedPropertyName);
    //if (!pairedProperty) return true;
        
    //switch(pairedProperty.attr("type")) {
    //    case "checkbox":
    //        {
    //            if (!pairedProperty.is(":checked")) return false;
    //            break;
    //        }
    //    default:
    //        {
    //            if ($.trim(pairedProperty.val()) == "") return false;
    //        } 
    //}
        
        return true;
}, "lorem ipsum");
$.validator.unobtrusive.adapters.addBool("vision2_validation_optionalIf");