var template = require('./inventorypurchase-search.html');




function viewModel(params) {

    var self = this;

    self = params.InventoryPurchases;
    console.log(self);
    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};

