
var formatters = {
    shortDate: "MM/DD/YYYY",
    time: "hh:mm A",
    dateTime: "MM/DD/YYYY hh:mm:ss A",
    utc: "YYYY-MM-DDTHH:mm:ss Z",
    translation: ['MMM DD YYYY', 'MM/DD/YY', 'MM/DD/YYYY', 'YYYY-MM-DD', 'YYYY-MM-DDTHH:mm:ss'],
    parseDate: function (date) {
        return new Date(date);
    }

};

var deletedProperties = [
    "StartTime",
    "_start",
    "endTime",
    "endDate",
    "dayNumberHumanizedOfTheMonth",
    "dayNumberOfTheMonth",
    "dayNameOfTheMonth",
    "monthlyOpt",
    "DebugStartDate",
    "DebugStartDateUTC",
    "__ko_mapping__"
];


function eventsModel() {
    var now = moment(Date.now()).local();
    this._start = ko.observable(now);
    this.StartTime = ko.observable(now.format('hh') + ':00 ' + now.format('A'));
    this._end = ko.observable();
    var self = this;
    this.Id = ko.observable(0);
    this.ContextId = ko.observable(0);
    this.EndDate = ko.observable(null);
    this.NextDate = ko.observable(null);
    this.Subject = ko.observable(null);
    this.Location = ko.observable(null);
    this.Description = ko.observable(null);
    this.FrequencyType = ko.observable(0);
    this.Count = ko.observable(null);
    this.Interval = ko.observable(null);
    this.Day = ko.observable(0);
    this.Week = ko.observable(0);
    this.IsSunday = ko.observable(false);
    this.IsMonday = ko.observable(false);
    this.IsTuesday = ko.observable(false);
    this.IsWednesday = ko.observable(false);
    this.IsThursday = ko.observable(false);
    this.IsFriday = ko.observable(false);
    this.IsSaturday = ko.observable(false);
    this.RowStatus = ko.observable(1);
    this.TimeZone = ko.observable(jstz.determine().name());

    extendEventProperty(self);


}


//Extends the base/root view model
function extendViewModel(item) {
    item.frequencyTypeCodes = [//todo: figure out how to serialize the enumeration FrequencyTypeCodes.cs
        //{display: 'None', key: 0 },
        { display: 'Yearly', key: 1 },
        { display: 'Monthly', key: 2 },
        { display: 'Weekly', key: 3 },
        //{display: 'Daily', key: 4 },
        //{display: 'Hourly', key: 5 },
        //{display: 'WeekDayOfMonth', key: 6 },
        { display: 'Biweekly', key: 7 }
    ];

    item.dayOfWeek = [
        { display: 'Sunday', key: 0 },
        { display: 'Monday', key: 1 },
        { display: 'Tuesday', key: 2 },
        { display: 'Wednesday', key: 3 },
        { display: 'Thursday', key: 4 },
        { display: 'Friday', key: 5 },
        { display: 'Saturday', key: 6 }
    ];

}

function cleanEventsForPost(events) {
    var clone = ko.toJS(events);
    ko.utils.arrayForEach(clone, function (event) {

        console.log('start date before clean', event.StartDate);
        event.StartDate = moment(event.StartDate + ' ' + event.StartTime).utc().toISOString();
        if (event.EndDate != null)
            event.EndDate = moment(event.EndDate).utc().toISOString();

        console.log('start date after clean', event.StartDate);

        ko.utils.arrayForEach(deletedProperties, function (prop) {
            delete event[prop];
        });

    });
    return clone;
}

//Extends all events in an array
function extendEventsProperty(events) {
    ko.utils.arrayForEach(events(), function (event) {
        extendEventProperty(event);
    }); //end koforeach events
}



function extendSimpleSchedule(event, vm) {
    var st = ko.utils.unwrapObservable(event.StartDate),
        en = ko.utils.unwrapObservable(event.EndDate),
        start,
        end;
    if (st) {
        start = moment(st).local().format(formatters.shortDate);
    } else {
        start = moment(new Date()).local().format(formatters.shortDate);
    }

    if (en !== null) {
        //console.log('Initial end date' + en);
        end = moment(en).local().format(formatters.shortDate);
    } else {
        end = null;
    }
    event.initialStartDate = ko.observable(start);
    event._startDate = ko.observable(start);
    event.initialEndDate = ko.observable(end);
    event._endDate = ko.observable(end);

    //add base properties to viewModel for each event
    event.EveryWeek = ko.observable(event.EveryWeek && event.EveryWeek() ? event.EveryWeek() : 0);
    event.HasEndDate = ko.observable((event.EndDate && event.EndDate()) ? true : false);
    //(event.EndDate()) ? event.HasEndDate = ko.observable(true) : event.HasEndDate = ko.observable(false);
    event.DayOfMonth = ko.observable("2");
    event.FirstOrSixteenth = ko.observable();

    if (st) {
        var calcdate = moment(st).local(),
            month = calcdate.month(),
            day = calcdate.date();

        //moment treats month as a zero based array????
        month = month + 1;

        console.log('day of month is ', month, day, ko.utils.unwrapObservable(event.IsEndOfMonth), calcdate);
        if (event.IsEndOfMonth && event.IsEndOfMonth()) {
            //console.log('is end of month already', event.IsEndOfMonth());
            event.DayOfMonth("1");
        }
        else {
            switch (month) {
                case 4:
                case 6:
                case 8:
                case 10:
                case 12:

                    if (day === 31) {
                        event.DayOfMonth("1",month);
                    }
                    break;
                case 2:
                    //console.log('month is feb or 2',month);
                    if (day === 28 || day === 29) {
                        event.DayOfMonth("1");
                    }
                    break;
                default:
                    //console.log('month ends in 30',month);
                    if (day === 30) {
                        event.DayOfMonth("1");
                    }
                    break;

            }
        }
           
        if (day <= 16 && day != 1) {
            event.FirstOrSixteenth(1);
        } else {
            event.FirstOrSixteenth(0);
        }
        //console.log('event day of month', event.DayOfMonth());
    }



    event.dateChanged = ko.observable(false);
    event.showCalculator = ko.observable(0);
    event.StartDate = ko.computed({
        read: function () {
            if (!moment(event._startDate()) || !moment(event._startDate()).isValid()) {
                return null;
            }
            return moment(event._startDate()).format(formatters.shortDate);
        },
        write: function (value) {
            if (value === null || (moment(value) && moment(value).isValid())) {
                event._startDate(value);
            }
        },
        owner: event
    });
    event.EndDate = ko.computed({
        read: function () {
            if (!moment(event._endDate()) || !moment(event._endDate()).isValid()) {
                return null;
            }
            return moment(event._endDate()).format(formatters.shortDate);
        },
        write: function (value) {
            if (value === null || (moment(value) && moment(value).isValid())) {
                event._endDate(value);
            } else {
                event._endDate(event.initialEndDate());
            }
        },
        owner: event
    });

    event.toggleMonth = function (o) {
        console.log('toggle month called', o);
        var opt = parseInt(o, 10);
        var nextDate;
        switch (opt) {
            case 0: //1st of month                        
                var initial = moment(event._startDate());
                nextDate = initial.add(1, 'months').date(1);
                event._startDate(nextDate);
                break;
            case 1: //end of month
                var startDate = moment(event._startDate());
                nextDate = moment(startDate.add(1, 'months').subtract(startDate.date(),'days'));
                event._startDate(nextDate);
                break;
            default://same as above
                event._startDate(moment(event.initialStartDate()));
                break;
        }

    };

    event.toggleFirstAndSixteenth = function (o) {
        var opt = parseInt(o, 10);
        var initial = moment(event._startDate());
        var nextDate = initial;
        switch (opt) {
            case 0: //1st                        
                //move to the first of next month
                if (initial.date() != 1)
                    nextDate = initial.add(1, 'months').date(1);
                break;
            case 1: //16th

                //move to the 16th
                if (initial.date() != 16 && initial.date() < 16)
                    nextDate = initial.add(16 - initial.date(), 'days');
                //move to the next mont 16th
                if (initial.date() != 16 && initial.date() > 16)
                    nextDate = initial.add(1, 'months').date(16);
                break;
        }

        event._startDate(nextDate);
    };

    event._startDate.subscribe(function (nv) {
        var dt = moment(nv);
        if (parseInt(event.Frequency(), 10) === 1 || parseInt(event.Frequency(), 10) === 2) {
            event.WeekDay(dt.isoWeekday());
        }
    });



    event.toggleWeekDay = function (nv) {

        var queryDay = parseInt(nv, 10), startDate = moment(event._startDate()), startDateDay = parseInt(startDate.day(), 10);
        if (queryDay < startDateDay) {
            var dayDate = moment(event._startDate()).day(queryDay + parseInt(7, 10));
            event.StartDate(dayDate.format(formatters.shortDate));
        }
        if (queryDay > startDateDay) {
            var dayDate2 = moment(event._startDate()).day(queryDay);
            event.StartDate(dayDate2.format(formatters.shortDate));
        }
    };

    event.WeekDay.subscribe(function (nv) {
        //prevent never ending subscriptions
        if (moment(event._startDate()).isoWeekday() !== nv)
            event.toggleWeekDay(nv);
    });


    event.Frequency.subscribe(function (nv) {
        var opt = parseInt(nv, 10);
        switch (opt) {
            case 0://Monthly = 0
                event.toggleMonth(2);
                break;
            case 1:
            case 2:
                event.toggleWeekDay(event.WeekDay());
                break;
            case 6:
                var day = moment(event._startDate()).date();
                if (day <= 16 && day != 1) {
                    event.toggleFirstAndSixteenth(1);
                } else {
                    event.toggleFirstAndSixteenth(0);
                }
                break;
            default:
                break;
        }
    });

    event.DayOfMonth.subscribe(function (nv) {
        console.log('day of month changed', nv);
        event.toggleMonth(nv);
    });

    event.FirstOrSixteenth.subscribe(function (nv) {
        event.toggleFirstAndSixteenth(nv);
    });

    //add any computed observables necessary for each event
    event.Amount = ko.computed(function () {
        if(vm && vm.GiftAmount)
            return vm.GiftAmount();
        return 0;
    });

    event.startDateLabel = ko.computed(function () {
        if (parseInt(event.Frequency(), 10) !== 5) return 'Starting From';
        return 'On';
    });

    event.endDateLabel = ko.computed(function () {
        if (event.HasEndDate()) return 'Remove End Date';

        return 'Specify an End Date';
    });

    function nextFirstOrSixteenth(m) {
        if (m.date() > 16)
            return m.endOf('month').subtract(1, 'days');

        if (m.date() < 16 && m.date() != 1)
            return m.add(16 - m.date());

        return m;
    }

    event.NumberOfPayments = ko.computed(function () {
        var startDate = moment(event._startDate()),
            endDate = event.EndDate() ? moment(event._endDate()) : moment(event._startDate()).add(1, 'years'),
            range = 1;

        //Moment.Dif opts "years", "months", "weeks", "days", "hours", "minutes",  "seconds"
        //By default, moment.fn.diffwill return a rounded number. If you want the floating point number, pass true as the third argument.
        //a.diff(b, 'years')       // 1
        //a.diff(b, 'years', true) // 1.5

        //Mirrors SimpleScheduleTypeCode.cs
        //Monthly = 0,
        //Weekly =1,
        //EveryOtherWeek=2,
        //Yearly = 3,
        //Quarterly=4,
        //Once=5

        if (parseInt(event.Frequency(), 10) === 0) { //Monthly
            range = endDate.diff(startDate, 'months');
            if (startDate.add( range,'m') < endDate) {
                range = range + 1;
            }
        }
        if (parseInt(event.Frequency(), 10) === 1) { //Weekly
            range = endDate.diff(startDate, 'weeks') + 1;



        }
        if (parseInt(event.Frequency(), 10) === 2) { //BiWeekly
            range = Math.round(endDate.diff(startDate, 'weeks') / 2) + 1;

        }
        if (parseInt(event.Frequency(), 10) === 3) { //Yearly
            range = endDate.diff(startDate, 'years');
        }
        if (parseInt(event.Frequency(), 10) === 4) { //Quarterly **unused?
            range = endDate.diff(startDate, 'years') * 4;
        }
        if (parseInt(event.Frequency(), 10) === 5) { //Once
            range = 1;
        }
        if (parseInt(event.Frequency(), 10) === 6) { //First and sixteenth
            var tempEnd = nextFirstOrSixteenth(endDate);
            var tempStart = nextFirstOrSixteenth(startDate);
            range = tempEnd.diff(tempStart, 'months') * 2;

            if ((tempStart.date() < tempEnd.date())
                || (tempStart.date() == 1 && tempEnd.date() == 1)) {
                range = range + 2;
            }

            if ((tempStart.date() == 1 && tempEnd.date() == 1)
                && (tempStart.diff(tempEnd, 'days') != 0)) {
                range = range + 1;
            }

        }
        return range;
    });

    event.TotalCommitment = ko.computed(function () {
        return event.NumberOfPayments() * event.Amount();
    });

    event.AnnualCommitment = ko.computed(function () {
        var startDate = moment(event._startDate()),
            endDate = moment(new Date()).add(1, 'years'),
            range = 1;

        //Mirrors SimpleScheduleTypeCode.cs
        //Monthly = 0,
        //Weekly =1,
        //EveryOtherWeek=2,
        //Yearly = 3,
        //Quarterly=4,
        //Once=5

        if (parseInt(event.Frequency(), 10) === 0) { //Monthly
            range = endDate.diff(startDate, 'months');
        }
        if (parseInt(event.Frequency(), 10) === 1) { //Weekly
            range = endDate.diff(startDate, 'weeks');
        }
        if (parseInt(event.Frequency(), 10) === 2) { //BiWeekly
            range = Math.round(endDate.diff(startDate, 'weeks') / 2);
        }
        if (parseInt(event.Frequency(), 10) === 3) { //Yearly
            range = endDate.diff(startDate, 'years');
        }
        if (parseInt(event.Frequency(), 10) === 4) { //Quarterly **unused?
            range = endDate.diff(startDate, 'years') * 4;
        }
        if (parseInt(event.Frequency(), 10) === 5) { //Once
            range = 1;
        }
        if (parseInt(event.Frequency(), 10) == 6) { //first and sixteenth
            var tempEnd = nextFirstOrSixteenth(endDate);
            var tempStart = nextFirstOrSixteenth(startDate);
            range = tempEnd.diff(tempStart, 'months') * 2;

            if ((tempStart.date() < tempEnd.date())
                || (tempStart.date() == 1 && tempEnd.date() == 1)) {
                range = range + 2;
            }

            if ((tempStart.date() == 1 && tempEnd.date() == 1)
                && (tempStart.diff(tempEnd, 'days') != 0)) {
                range = range + 1;
            }
        }
        return range * event.Amount();
    });

    event.freqText = ko.computed(function () {
        //Mirrors SimpleScheduleTypeCode.cs
        //Monthly = 0,
        //Weekly =1,
        //EveryOtherWeek=2,
        //Yearly = 3,
        //Quarterly=4,
        //Once=5

        if (parseInt(event.Frequency(), 10) === 0) return "Monthly";
        if (parseInt(event.Frequency(), 10) === 1) return "Weekly";
        if (parseInt(event.Frequency(), 10) === 2) return "Bi-Weekly";
        if (parseInt(event.Frequency(), 10) === 6) return "First & Sixteenth";
        if (parseInt(event.Frequency(), 10) === 3) return "Yearly";
        if (parseInt(event.Frequency(), 10) === 4) return "Quarterly";
        if (parseInt(event.Frequency(), 10) === 5) return "Once";
    });

    event.WillRepeat = ko.computed(function () {
        return parseInt(event.Frequency(), 10) !== 5;
    });

    //Add any methods necessary for each event
    event.toggleEndDate = function () {
        var v = !event.HasEndDate();
        if (v && !event._endDate()) {
            var tempdate = moment(event._startDate()).add(1, 'years');
            event._endDate(!event.initialEndDate() ? tempdate.format(formatters.shortDate) : event.initialEndDate());
        }
        event.HasEndDate(v);
        if (!v)
            event._endDate(null);
    };

    //validators

    event.StartDate.extend({ required: true });

    event.StartDate.extend({
        validation: {
            validator: function (val, otherVal) {
                var startDate = moment(event._startDate());
                var todaysDate = moment(new Date());
                var endDate = moment(new Date()).startOf('day');
                return startDate >= endDate;
            },
            message: 'Start date cannot be before today\'s date',
        }
    });

    event.EndDate.extend({
        validation: {
            validator: function (val, otherVal) {
                if (event.HasEndDate()) {
                    var startDate = moment(event._startDate());
                    var endDate = moment(event._endDate());
                    return (endDate !== null && !endDate.isBefore(startDate));
                } else return true;
            },
            message: 'End Date must be greater than Start Date',
        }
    });

}

function prepareSimpleSchedule(item) {
    //console.log(ko.toJS(item));
    delete item.Schedule.initialEndDate;
    delete item.Schedule.initialStartDate;
    delete item.Schedule._startDate;
    delete item.Schedule._endDate;
    delete item.Schedule.endDateLabel;
    delete item.Schedule.freqText;
    delete item.Schedule.startDateLabel;
    delete item.FirstOrSixteenth;

    if (item.Schedule.StartDate && moment(item.Schedule.StartDate).isValid()) {
        item.Schedule.StartDate = moment(item.Schedule.StartDate).utc().toISOString();
        //console.log(item.Schedule.StartDate);
    }
    if (item.Schedule.EndDate && moment(item.Schedule.StartDate).isValid()) {
        item.Schedule.EndDate = moment(item.Schedule.EndDate).utc().toISOString();
    }
}

//Extends a single event
function extendEventProperty(event) {
    //Initialize for existing events
    var st = ko.utils.unwrapObservable(event.StartDate),
        en = ko.utils.unwrapObservable(event.EndDate),
        start,
        end;
    if (st) {
        start = moment.utc(st).local().format(formatters.shortDate);
    } else {
        start = moment(new Date()).local().format(formatters.shortDate);
    }

    if (en !== null) {
        //console.log('Initial end date' + en);
        end = moment.utc(en).local().format(formatters.shortDate);
    } else {
        end = null;
    }
    event.initialStartDate = ko.observable(start);
    event._startDate = ko.observable(start);
    event.initialEndDate = ko.observable(end);
    event._endDate = ko.observable(end);

    event.StartTime = ko.observable(moment.utc(st).local().format('hh') + ':00 ' + moment.utc(st).local().format('A'));
    //event.EndTime = ko.observable(_startDate.format('hh') + ':00 ' + _startDate.format('A'));



    event.FrequencyType.subscribe(function (v) {
        if (v == 1 || v == 4) {
            event.IsSunday(false); //TODO: Refactor this
            event.IsMonday(false);
            event.IsTuesday(false);
            event.IsWednesday(false);
            event.IsThursday(false);
            event.IsFriday(false);
            event.IsSaturday(false);
            event.Day(0);
            event.Week(0);
        }
        if (v == 3 || v == 7) {
            event.Day(0);
            event.Week(0);
        }
        if (v == 2) {
            event.monthlyOpt('number');
        }
    });

    event.DayOfWeek = ko.observable();
    if (event.IsSunday()) { event.DayOfWeek(0); }
    if (event.IsMonday()) { event.DayOfWeek(1); }
    if (event.IsTuesday()) { event.DayOfWeek(2); }
    if (event.IsWednesday()) { event.DayOfWeek(3); }
    if (event.IsThursday()) { event.DayOfWeek(4); }
    if (event.IsFriday()) { event.DayOfWeek(5); }
    if (event.IsSaturday()) { event.DayOfWeek(6); }

    event.DayOfWeek.subscribe(function (v) {
        event.IsSunday(false);
        event.IsMonday(false);
        event.IsTuesday(false);
        event.IsWednesday(false);
        event.IsThursday(false);
        event.IsFriday(false);
        event.IsSaturday(false);
        if (v == 0) { event.IsSunday(true); }
        if (v == 1) { event.IsMonday(true); }
        if (v == 2) { event.IsTuesday(true); }
        if (v == 3) { event.IsWednesday(true); }
        if (v == 4) { event.IsThursday(true); }
        if (v == 5) { event.IsFriday(true); }
        if (v == 6) { event.IsSaturday(true); }
    });

    //Support for Will Repeat property
    event.WillRepeat = ko.computed({
        read: function () { return this.FrequencyType() ? 1 : 0; },
        write: function (value) {
            if (!this.FrequencyType()) this.FrequencyType(1);
            if (value == 0) this.FrequencyType(null);
        },
        owner: event
    });



    event.StartDate = ko.computed({
        read: function () {
            if (!moment(event._startDate()) || !moment(event._startDate()).isValid()) {
                return null;
            }
            return moment(event._startDate()).format(formatters.shortDate);
        },
        write: function (value) {
            if (moment(new Date(value)) && moment(new Date(value)).isValid()) {
                event._startDate(value);
            }
        },
        owner: event
    });

    event.EndDate = ko.computed({
        read: function () {
            if (!moment(event._endDate()) || !moment(event._endDate()).isValid()) {
                return null;
            }
            return moment(event._endDate()).format(formatters.shortDate);
        },
        write: function (value) {
            if (moment(new Date(value)) && moment(new Date(value)).isValid()) {
                event._endDate(value);
            } else {
                event._endDate(event.initialEndDate());
            }
        },
        owner: event
    });




    event.DebugStartDate = ko.computed(function () {
        return event.StartDate() + ' ' + event.StartTime();
    });

    event.DebugStartDateUTC = ko.computed(function () {
        return moment(event.StartDate() + ' ' + event.StartTime()).utc().toISOString();
    });



    //Support for End Pattern property

    event.EndPattern = ko.observable(0);

    if (event.Count()) {
        event.EndPattern(1);
    } else if (event.EndDate()) {
        event.EndPattern(2);
    }

    event.EndPattern.subscribe(function (nv) {
        if (nv == 0) {
            event.Count(null);
            event.EndDate(null);
        }
        if (nv == 1) {
            event.EndDate(null);
        }
        if (nv == 2) {
            event.Count(null);
        }
    });




    event.dayNumberHumanizedOfTheMonth = ko.computed(function () {
        return moment(event.StartDate()).format('Do');
    });

    event.dayNumberOfTheMonth = ko.computed(function () {
        return moment(event.StartDate()).format('DD');
    });

    event.dayNameOfTheMonth = ko.computed(function () {
        return getHumanizedInstanceOfDay(getInstanceOfDay(event.StartDate())) + ' ' + moment(event.StartDate()).format('ddd');
    });

    event.monthlyOpt = ko.computed({
        read: function () {
            var self = this;

            if (self.Week()) {
                return 'nthday';
            }

            if (self.Day()) {
                return 'number';
            }

            return null;
        },
        write: function (value) {
            var self = this;
            if (value == 'nthday') {
                self.Day(0);
                self.Week(getInstanceOfDay(self.StartDate()));
                event.IsSunday(false); //TODO: Refactor this
                event.IsMonday(false);
                event.IsTuesday(false);
                event.IsWednesday(false);
                event.IsThursday(false);
                event.IsFriday(false);
                event.IsSaturday(false);
                self['Is' + moment(event.StartDate()).format('dddd')](true);
            }

            if (value == 'number') {
                self.Day(self.dayNumberOfTheMonth);
                self.Week(0);
                event.IsSunday(false); //TODO: Refactor this
                event.IsMonday(false);
                event.IsTuesday(false);
                event.IsWednesday(false);
                event.IsThursday(false);
                event.IsFriday(false);
                event.IsSaturday(false);
            }
        },
        owner: event
    });
}

function getHumanizedInstanceOfDay(n) {
    if (n === 1) return '1st';
    else if (n === 2) return '2nd';
    else if (n === 3) return '3rd';
    else if (n === 4) return '4th';
    else return 'last';
}

function getInstanceOfDay(d) {
    var _date = moment(new Date(d)),
        dayOfMonth = parseInt(_date.format('D')),
        dayOfWeek = parseInt(_date.format('d')),
        working = moment(new Date(d).setDate(1)),
        instance = 0;

    for (var i = parseInt(working.format('D')); i <= dayOfMonth; i++) {
        var _day = parseInt(working.format('d'));
        if (_day === dayOfWeek) instance++;
        working.add(1, 'days');
    }

    return instance;
}


  

function getPropertyName(prop, value) {
    for (var i in prop) {
        if (typeof prop[i] == 'object') {
            if (getPropertyName(prop[i], value)) {
                return res;
            }
        } else {
            if (prop[i] == value) {
                res = i;
                return res;
            }
        }
    }
    return undefined;
}

function datePickerEnable(p, prop) {
    var propertyName = getPropertyName(p, prop);
    var pVal = ko.utils.unwrapObservable(prop);
    //console.log('extending', prop, pVal);
    if (pVal) {
        prop(moment(new Date(pVal)));
    }



}
    
module.exports = {
    formatters: formatters,
    ExtendEvent: extendEventProperty,
    ExtendSimpleSchedule: extendSimpleSchedule,
    PrepareSimpleSchedule: prepareSimpleSchedule,
    ExtendEvents: extendEventsProperty,
    ExtendViewModel: extendViewModel,
    CleanEventsForPost: cleanEventsForPost,
    NewEventModel: eventsModel,
    DatePickerEnable: datePickerEnable
}
   
