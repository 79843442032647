var ko = require("knockout");
require("../../v2/enumTranslations.js");
var template = require("./channel-select.html");

function viewModel(params) {
    var vm = {};


    vm.Channels =[];
    vm.ChannelId = params.ChannelId;
   

    var j = 0;

    ko.utils.arrayForEach(enumerations.Channel, function (i, index) {
        if (params.IncludeAll && j == 0) {
            var item = {
                Id: null,
                Name: 'All Channels'
            };

        }
        else {
            var item = {
                Id: j,
                Name: i
            };

        }


        j++;

        vm.Channels.push(item);
    });

    console.log('channel select', ko.toJS(vm));
    return vm;

}

module.exports = {
    template: template,
    viewModel: viewModel

}