
var template = require("./signuphistory.html");

function viewModel(params) {
    var vm = this;
    vm.History = params.History;
}

module.exports = {
    viewModel: viewModel,
    template: template

}

