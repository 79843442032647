
var template = require('./individual-pref.html');


    function comPrefModel(c) {
        var self = this;
        self.ChannelType = ko.observable(c);
        self.CommunicationType = ko.observable(t);
        self.preference = ko.observable(0);
    }


    function setViewModel(t, d) {
        //console.log('set view Model', t, d);
        t.HouseholdId(d.HouseholdId);
        t.IsHeadOfHousehold(d.IsHeadOfHousehold);
        t.OrganizationIndividualProfileId(d.OrganizationIndividualProfileId);
        t.UseHouseholdCommunicationPreference(d.UseHouseholdCommunicationPreference);
        t.HeadOfHouseholdOrganizationIndividaulProfileId(d.HeadOfHouseholdOrganizationIndividaulProfileId);
        




        ko.mapping.fromJS(d.HouseholdPreferences, {}, t.HouseholdPreferences);
        ko.mapping.fromJS(d.IndividualPreferences, {}, t.IndividualPreferences);

        //console.log('here', t.HouseholdPreferences);
        if (t.HouseholdPreferences && t.HouseholdPreferences()) {
            ko.utils.arrayForEach(t.HouseholdPreferences(), function (h, i) {

                h.showLabel = ko.observable(i % 2 ==false);

                h.options = ko.observableArray();

                h.options.push({
                    Value: ko.observable(0),
                    Name: ko.observable('No Pref'),
                    btnClass: ko.computed(function () {
                        if (parseInt(h.Preference()) == 0)
                            return "btn-success";
                        return "btn-primary";
                    })
                });
                h.options.push({
                    Value: ko.observable(1),
                    Name: ko.observable('Opt In'),
                    btnClass: ko.computed(function () {
                        if (parseInt(h.Preference()) == 1)
                            return "btn-success";
                        return "btn-primary";
                    })
                });
                h.options.push({
                    Value: ko.observable(2),
                    Name: ko.observable('Opt Out'),
                    btnClass: ko.computed(function () {
                        if (parseInt(h.Preference()) == 2)
                            return "btn-success";
                        return "btn-primary";
                    })
                });
                //console.log('options', ko.toJS(h.options));
            })
        }
    

        ko.utils.arrayForEach(t.IndividualPreferences(), function (h,i) {
            h.options = ko.observableArray();
            h.showLabel = ko.observable(i % 2 == false);
            //console.log('label',h.showLabel(),i,i%2)

            h.options.push({
                Value: ko.observable(0),
                Name: ko.observable('No Pref'),
                btnClass: ko.computed(function () {
                    if (parseInt(h.Preference()) == 0)
                        return "btn-success";
                    return "btn-primary";
                })
            });
            h.options.push({
                Value: ko.observable(1),
                Name: ko.observable('Opt In'),
                btnClass: ko.computed(function () {
                    if (parseInt(h.Preference()) == 1)
                        return "btn-success";
                    return "btn-primary";
                })
            });
            h.options.push({
                Value: ko.observable(2),
                Name: ko.observable('Opt Out'),
                btnClass: ko.computed(function () {
                    if (parseInt(h.Preference()) == 2)
                        return "btn-success";
                    return "btn-primary";
                })
            });

            //console.log('options', ko.toJS(h.options));
        })
        t.loaded(true);
    }


    function viewModel(params) {



        var self = this;
        self.Label = ko.observable();
        self.Preferences = ko.observableArray();
        self.OganizationIndivdiaulProfile = ko.observable();
        self.loaded = ko.observable(false);
        self.HouseholdId = ko.observable();
        self.IsHeadOfHousehold = ko.observable(false);
        self.HeadOfHouseholdOrganizationIndividaulProfileId = ko.observable();
        self.UseHouseholdCommunicationPreference = ko.observable();
        self.IndividualPreferences = ko.observableArray();
        self.HouseholdPreferences = ko.observableArray();
        self.OrganizationIndividualProfileId = ko.observable(params.Id);
        self.isTyped = ko.observable(false);
        self.Type = ko.observable();
        self.loaded = ko.observable(false);
        

        if (params.Type) {

            var x = parseInt(ko.utils.unwrapObservable(params.Type));
            self.Type(ko.utils.unwrapObservable(params.Type));
            self.isTyped(true);
            switch (x) {
                case 1:
                    self.Label("Giving Statement Communication Preferences");
                    break;
            }
        }






        self.getPreferences = function () {
            self.loaded(false);
            v2Form.POST({
                url: '/People/CommunicationPreference/GetPreferencesForProfile',
                data: { Id: ko.unwrap(self.OrganizationIndividualProfileId) },
               
                success: function (data) {
                    if (data.Data) {
                        setViewModel(self, data.Data);
                        self.loaded(true);
                    } else {
                        //todo: error handling
                    }

                },
               
                error: function () {

                }
            });

        };

        self.savePreferences = function () {
           var model = ko.toJS(self);
           self.loaded(false);
           v2Form.POST({

                url: '/People/CommunicationPreference/Update',
                data: self,
                success: function (data) {
                    if (data.Data) {
                        setViewModel(self, data.Data);
                        self.loaded(true);
                    } else {
                        //todo: error handling
                    }

                },
                error: function () {

                }
            });

        };


        //console.log('com pref', ko.toJS(self));
        self.getPreferences();
    }


    module.exports = {
        viewModel: viewModel,
        template: template

    };
