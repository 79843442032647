var template = require('./site-display.html');





function viewModel(params) {
    var self = this;
    var id = ko.utils.unwrapObservable(params.Id);
    self.SiteName = ko.observable();
    self.Id= ko.observable(id);
    self.Address1 = ko.observable();
    self.Address2 = ko.observable();
    self.City = ko.observable();
    self.Region = ko.observable();
    self.PostalCode = ko.observable();
    self.loaded = ko.observable(false);


    v2Form.POST({
        url: '/Organization/Site/GetSiteDisplay',
        type: 'POST',
        contentType: 'application/json',
        dataType: 'json',
        data: { id: id },
        success: function (data, textStatus, jqXhr) {
            ko.mapping.fromJS(data.Data, {}, self);
            self.loaded(true);
        },
        error: function (data, textStatus, jqXhr) {
            console.log('error');
            console.log(data);
        }
    });




    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};

