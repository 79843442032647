


//script-loader executes the required js one time in global scope
require('jquery');
require('jquery-validation');
require('jquery-validation-unobtrusive');
require('eonasdan-bootstrap-datetimepicker');
require('jquery-expander');
require('jquery-bridget');
require('jquery-migrate');
require('jszip');
require('@progress/kendo-ui')

jQuery.migrateMute = true;
require('knockout');
require('knockout-mapping');
require('./v2/knockout.custombindings.js');

require('knockout.validation');


require('block-ui');


require('bootstrap');
require('bootstrap-timepicker');
require('spectrum-colorpicker');
require('masonry-layout');
require('mustache');
require('jasny-bootstrap');


require('bootstrap-notify');
require('./json2html.js');


window.v2Form = require('./v2/formUtilities.js');
window.TriggerConfiguration = require("./v2/triggerConfiguration.js");
window.PaginationServices = require("./v2/paginationServices.js");
window.EventCalendarExtender = require("./v2/eventCalendarExtender.js");
window.Analytics = require("./v2/analytics.js");
window.giftDesignationServices = require("./v2/giftDesignation.js");
window.GiftEntryServices = require("./v2/giftEntry.js");
window.GivingStatistics = require("./v2/givingStatistics.js");
window.fulfillmentServices = require("./v2/fulfillment.js");
window.modalService = require("./v2/knockoutModal.js");
window.MarketingTemplateServices = require("./v2/marketingTemplates.js");
window.notify = require("./v2/notify.js");
window.TemplateContexts = require("./v2/templateContexts.js");
window.VolunteerServices = require('./v2/volunteer');
window.widget = require('./v2/widgetBuild');
window.Mustache = require('mustache');
//window.JSZip = new JSZip();
window.JSZip = require('jszip');
var tippy = require('tippy.js');

window.tippy = tippy.default;


window.UserPermissions = require('./permissions/userpermissions.js');
KendoLicensing.setScriptKey('141j044b041h541j4i1d542e581e4i1i4g260i2k0f2e0d2i0c2b002f0e2c15205e47001d50224e1h502i5c2c5g2a4g1j542f5j4a59345b3257315g3d5c335h424i2h123d551g4c24511g572f562g614958315e335a345h3g5d325g414h2e4h3a523j4d424i042b0229191j3e6137524b2f014j3b21191i0i3008260827434e47554b550a4k3b4i35132d570453235c1e4c2c321b3k2f0c414j1h5j1j032a351d380b2e50405d21');



//v2/* js modules
//Convenience method to require all files in directory
function requireAll(r) { r.keys().forEach(r); }

//Second parameter in require.context is whether to include subdirectories or not
requireAll(require.context('./v2', true, /\.js$/));
//requireAll(require.context('./components', true, /\.js$/));
require('./components/v2Components.js');
require('./components/v2dynamiccomponents');


//Style
require('./../Content/less/style.less');


//console.log('getting here');


$(function () {
    //JSTZ Timezone stuff
    // If page contains a text box looking for TimeZone, set it with jstz
    if ($('#jstzTimeZone:hidden') !== undefined)
        $('#jstzTimeZone:hidden').val(jstz.determine().name());

    //bootstrap tooltip support
    //$('.hasTooltip').tooltip();

    if ('form input[required]') {
        $('form .panel-body').prepend('<div class="text-right"><small><strong>* Required</strong></small></div>');
    };

    //add validation markers to input labels
    $('[required]').each(function (i) {
        var inputID = $(this).attr('name');
        $('label[for="' + inputID + '"]').each(function (l) {
            if ($(this).text().indexOf("*") === -1) {
                var labelText = $(this).text() + "*";
                $(this).text(labelText);
            }
        });
    });
});






// If an ajax 401 error is detected (logout redirect), refresh the page forcing ASP.Net login redirect with ReturnURL
$(document).ajaxError(function (xhr, props) {
    if (props.status === 401) {
        location.reload();
    }
});



