/* File Created: July 25, 2012 */

(function () {


    function calendarViewModel(model) {
        var self = model ? model : this;
        
        self.first_day = ko.observable(moment(new Date()).day(0).toDate());
        self.last_day = ko.observable(moment(new Date()).add(12, 'months').toDate());
        self.events = ko.observableArray();
        self.events.subscribe(generateWeekDays);

        self.weeks = ko.observableArray();
        self.weeks_name = ko.observableArray(['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']);

        self.rows = ko.observable(70);
        self.cols = ko.observable(7);

        self.prev_week = function () {
            for (var j = 0; j < self.rows(); j++) {
                for (var i = 0; i < self.cols(); i++) {
                    self.weeks()[j].days()[i].minus_week();
                }
            }
        };


        //GENERATE WEEK DAYS FOR CALENDAR
        function generateWeekDays(events) {
            //list of events
            events = ko.toJS(events);

            //first day of the month?
            var beginning = new Date(self.first_day());
            
            //an index
            var evtIdx = 0;
            self.weeks.removeAll();
            
            //loop through weeks
            for (var j = 0; j < self.rows(); j++) {
                var Days = [];
                
                //loop through cols/days of week
                for (var i = 0; i < self.cols(); i++) {
                    var eventFound = null;
                    var nextDate = moment((beginning)).format('L');

                    ko.utils.arrayForEach(events, function (e) {
                        if (moment(e.St).format('L') == nextDate) eventFound = e;
                    });

                    Days[i] = new Day(moment(beginning), i, j, eventFound);
                    beginning = moment(beginning).add(1, 'days').toDate();
                }
                
                //so all I am doing is adding days to weeks and adding weeks to an array in the viewmodel
                self.weeks.push(ko.mapping.fromJS(new Week(Days)));
            }

            console.log('row count', self.rows(), ko.toJS(self.weeks));
        };



        function Week(Days) {
            var _self = this;
            _self.days = ko.observable(Days);
        }

        function Day(day, index, row, event) {
            var _self = this;
            _self.day = ko.observable(day);
            _self.index = ko.observable(index);
            _self.row = ko.observable(row);
            _self.event = event;

            // TO STRING
            _self.day_number = ko.computed(function () {
                return moment(_self.day()).format('MMM D');
            });
            _self.month_name = ko.computed(function () {
                return moment(_self.day()).format('MMM').toLowerCase();
            });

            // MODIFY VALUES
            _self.minus_month = function () {
                var t = _self.day();
                _self.day(moment(t).add(-1, 'months'));
            };

            _self.plus_month = function () {
                var t = _self.day();
                _self.day(moment(t).add(1, 'months'));
            };
            // console.log(_self.day_number()) ;
            //  console.log(day);
        }
    }

    var dataBound = false;

    function getContextEvents(model, calendarId, eventContext, eventContextId) {
        //{int calendarId, string context, int contextId}



        var data = {};
        data.context = eventContext;
        data.contextId = eventContextId();
        data.timeZone = jstz.determine().name();
        var dataurl = '/Calendar/GetContextEvents';

        $.ajax({
            url: dataurl,
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(data),
            success: function (data, textStatus, jqXhr) {
                model.events(ko.mapping.fromJS(data.Data));
                model.events.sort(function (left, right) {
                    //return a negative value if the first argument is smaller
                    //a positive value is the second is smaller
                    //or zero to treat them as equal
                    return new Date(left.St()) == new Date(right.St()) ? 0 : (new Date(left.St()) < new Date(right.St()) ? -1 : 1);
                });
                if (!dataBound) {
                    //ko.applyBindings(ko.mapping.fromJS(model), document.getElementById('calendarView'));
                    dataBound = true;
                }

            },
            error: function (jqXhr, textStatus, errorThrown) {
                //todo: deal with validators
                panel.html(errorThrown).slideDown('fast');
            }
        });
    }
    
    function getContextEventsById(model, eventContext, eventContextId, startDate, endDate) {
        //{int calendarId, string context, int contextId}
        //Other=0,
		//CommitmentLineItem = 1,
		//ChurchService =2,
		//MobileVenueAsk = 3


        if (!model.events) calendarViewModel(model);

        var data = {};
        data.context = eventContext;
        data.contextId = eventContextId();
        data.timeZone = jstz.determine().name();
        data.startDate = startDate ? startDate : null;
        data.endDate = endDate ? endDate : null;

        var dataurl = '/Calendar/GetContextEvents';

        $.ajax({
            url: dataurl,
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(data),
            success: function (data, textStatus, jqXhr) {
                
                model.events(ko.mapping.fromJS(data.Data));
                model.events.sort(function (left, right) {
                    //return a negative value if the first argument is smaller
                    //a positive value is the second is smaller
                    //or zero to treat them as equal
                    return new Date(left.St()) == new Date(right.St()) ? 0 : (new Date(left.St()) < new Date(right.St()) ? -1 : 1);
                });

                if (!dataBound) {
                    //ko.applyBindings(ko.mapping.fromJS(model), document.getElementById('calendarView'));
                    dataBound = true;
                }
            },
            error: function (jqXhr, textStatus, errorThrown) {
                //todo: deal with validators
                panel.html(errorThrown).slideDown('fast');
            }
        });
    }


    function getCalendarSiteEventsByContext(model, sourceArray, context, start, end) {
        start = start ? moment(start).format('YYYY-MM-DDTHH:mm:ss Z') : null;
        end = end ? moment(end).format('YYYY-MM-DDTHH:mm:ss Z') : null;

        var data = {};

        data.startDate = start ? start : moment(new Date()).format('YYYY-MM-DDTHH:mm:ss Z');
        data.endDate = end ? end : moment(new Date()).add(2, 'months').format('YYYY-MM-DDTHH:mm:ss Z');
        data.timeZone = jstz.determine().name();



        //this only for this page
        //{calendarIds:[], context: "ChurchService", startDate: iso, DateTime: iso}
        var calendarIds = ko.utils.arrayMap(sourceArray(), function (item) {
            return ko.toJS(item.SiteCalendarId());
        });


        if (calendarIds.length) {
            data.calendarIds = calendarIds;
            data.context = context;

            var dataurl = calendarIds.length ? '/Calendar/GetSiteEventsByContext' : '/Calendar/GetEvents';

            $.ajax({
                url: dataurl,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify(data),
                success: function (data, textStatus, jqXhr) {
                    model = new calendarViewModel();
                    model.events(ko.mapping.fromJS(data.Data));
                    model.events.sort(function (left, right) {
                        //return a negative value if the first argument is smaller
                        //a positive value is the second is smaller
                        //or zero to treat them as equal
                        return new Date(left.St()) == new Date(right.St()) ? 0 : (new Date(left.St()) < new Date(right.St()) ? -1 : 1);
                    });
                    if (!dataBound) {
                        ko.applyBindings(ko.mapping.fromJS(model), document.getElementById('calendarView'));
                        dataBound = true;
                    }

                },
                error: function (jqXhr, textStatus, errorThrown) {
                    //todo: deal with validators
                    panel.html(errorThrown).slideDown('fast');
                }
            });
        }
    }

    function getCalendarEventsByContextIds(model, contextIds, context, start, end) {
        start = start ? moment(start).format('YYYY-MM-DDTHH:mm:ss Z') : null;
        end = end ? moment(end).format('YYYY-MM-DDTHH:mm:ss Z') : null;

        var data = {};

        data.startDate = start ? start : moment(new Date()).format('YYYY-MM-DDTHH:mm:ss Z');
        data.endDate = end ? end : moment(new Date()).add(2, 'months').format('YYYY-MM-DDTHH:mm:ss Z');
        data.timeZone = jstz.determine().name();



        if (contextIds.length) {
            data.contextIds = contextIds;
            data.context = context;

            var dataurl = '/Calendar/GetMultipleContextEvents' ;

            $.ajax({
                url: dataurl,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify(data),
                success: function (data, textStatus, jqXhr) {
                    model = new calendarViewModel();
                    model.events(ko.mapping.fromJS(data.Data));
                    model.events.sort(function (left, right) {
                        //return a negative value if the first argument is smaller
                        //a positive value is the second is smaller
                        //or zero to treat them as equal
                        return new Date(left.St()) == new Date(right.St()) ? 0 : (new Date(left.St()) < new Date(right.St()) ? -1 : 1);
                    });
                    if (!dataBound) {
                        ko.applyBindings(ko.mapping.fromJS(model), document.getElementById('calendarView'));
                        dataBound = true;
                    }

                },
                error: function (jqXhr, textStatus, errorThrown) {
                    //todo: deal with validators
                    panel.html(errorThrown).slideDown('fast');
                }
            });
        }
    }
    window.calendarServices = {
        ViewModel: calendarViewModel,
        GetContextEvents: getContextEvents,
        GetCalendarSiteEventsByContext: getCalendarSiteEventsByContext,
        GetContextEventsById: getContextEventsById,
        GetCalendarEventsByContextIds: getCalendarEventsByContextIds
    };

})();