var template = require("./simpleschedule.html");

function viewModel(params) {

    var vm = this;

    vm.Schedule = params.Schedule;
    console.log('schedule params', params.Schedule);
}

module.exports = {
    viewModel: viewModel,
    template: template
}