var template = require("./statsummary.html");


function getStats(s, a) {
    var val = ko.utils.arrayFirst(a, function (i) {
        
        if (i.Name == s)
            return i;
    });
    
    return val;
};

function assignCurrencyStats(m, f) {
   
    m.Count(f.Count);
    m.Mean(currency.US.display(f.Mean));
    m.Min(currency.US.display(f.Min));
    m.Max(currency.US.display(f.Max));
    m.Total(currency.US.display(f.Total));
}

function viewModel(params) {

    this.chart = params.chart;
    this.stats = params.stats;


    this.Count = ko.observable(0);
    this.Mean = ko.observable(0);
    this.Min = ko.observable(0);
    this.Max = ko.observable(0);
    this.Total = ko.observable(0);
    this.displayYTD = ko.observable(false);
    this.YTD = ko.observable(0);
    this.PYTD = ko.observable(0);
    this.PY = ko.observable(0);
    this.TYP = ko.observable(0);
    this.MON = ko.observable(0);

    console.log('params to stats summary', params);

    if (params.agg == null) {
        console.warn('missing aggregations');
    }

    if (params.name == null)
        console.warn('missing aggregation name');

    var aggs = ko.toJS(params.agg);
    var name = ko.unwrap(params.name);
    var ytdname = ko.unwrap(params.ytd);

    if (aggs) {

        var agg = ko.utils.arrayFirst(aggs, function (i) {

            if (i.Name == name)
                return i;
        });



        if (!agg) {
            console.error('unable to find stats for', ko.unwrap(params.name));
        }
        else {
            assignCurrencyStats(this, agg);
        }

       // console.log('aggs', aggs);

        if (params.ytdname == null) {
            var ytd = ko.utils.arrayFirst(aggs, function (i) {
                if (i.Name == ytdname)
                    return i;
            });



            if (ytd) {

                this.YTD(currency.US.display(getStats('YTD', ytd.Items).Total));
                this.PYTD(currency.US.display(getStats('PYTD', ytd.Items).Total));
                this.PY(currency.US.display(getStats('PY', ytd.Items).Total));
                this.MON(currency.US.display(getStats('MON', ytd.Items).Total));
                if (getStats('2YP', ytd.Items))
                    this.TYP(currency.US.display(getStats('2YP', ytd.Items).Total));
                else
                    this.TYP(currency.US.display(getStats('2PY', ytd.Items).Total));
                this.displayYTD(true);
            }
            else {

                console.warn("unable to find ytd", ytdname, ytd);
            }
        }
    }

   

    //console.log('stat summary view model', this);

}


module.exports = {
    viewModel: viewModel,
    template: template
}