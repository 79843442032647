import(/* webpackChunkName: "formUtilities" */"../v2/formUtilities.js").then((v2forms) => {
    import(/* webpackChunkName: "componentregistrations" */"./componentregistrations.js").then(() => {
        var codeSplitLoader = {
            loadComponent: function (name, componentConfig, callback) {
                if (!componentConfig.import) {
                    ko.components.defaultLoader.loadComponent(name, componentConfig, callback)
                }
                else {
                    componentConfig.import()
                        .then((config) => {
                            ko.components.defaultLoader.loadComponent(name, config, callback);
                        });
                }
            }
        }
        ko.components.loaders.unshift(codeSplitLoader);
    });
});
