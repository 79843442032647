var template = require("./giftdesignationsearch.html");
var pagination = require("../../v2/paginationServices");
var analytics = require("../../v2/analytics.js");
require("knockout");
require("knockout-mapping");
require("../../v2/knockout.custombindings");
var radio = require("radio");
var moment = require("moment");
var jstz = require("jstz");

function viewModel(params) {

    //console.log('gift designation search ko component', params);

    this.Gifts = new pagination.PageModel();

    this.displayAnalytics = ko.observable(true);
    this.displaySearch = ko.observable(true);
    this.UseComplex = ko.observable(false);
    this.loading = ko.observable(false);

    var profileId = ko.utils.unwrapObservable(params.ProfileId) || 0;
    var pageSize = params.PageSize || 30;

    this.GiftSearchCriteria = pagination.GiftSearchModel(profileId, 0, pageSize);
    this.GiftSearchCriteria.Analytics(0);
    EventCalendarExtender.DatePickerEnable(this.GiftSearchCriteria, this.GiftSearchCriteria.FromDate);
    EventCalendarExtender.DatePickerEnable(this.GiftSearchCriteria, this.GiftSearchCriteria.ToDate);
    EventCalendarExtender.DatePickerEnable(this.GiftSearchCriteria, this.GiftSearchCriteria.FromEndDate);
    EventCalendarExtender.DatePickerEnable(this.GiftSearchCriteria, this.GiftSearchCriteria.ToEndDate);

    if (params.SiteId)
        this.GiftSearchCriteria.SiteId(ko.utils.unwrapObservable(params.SiteId));

    if (params.FundableId)
        this.GiftSearchCriteria.FundableId(ko.utils.unwrapObservable(params.FundableId));

    if (params.HouseholdId)
        this.GiftSearchCriteria.HouseholdId(ko.utils.unwrapObservable(params.HouseholdId));

    if (params.HasSchedule)
        this.GiftSearchCriteria.HasSchedule(ko.utils.unwrapObservable(params.HasSchedule));

    if (params.IsOpen)
        this.GiftSearchCriteria.IsOpen(ko.utils.unwrapObservable(params.IsOpen));

    if (params.MarketingCommunicationId)
        this.GiftSearchCriteria.MarketingCommunicationId(ko.utils.unwrapObservable(params.MarketingCommunicationId));

    if (params.displayAnalytics)
        this.displayAnalytics(ko.utils.unwrapObservable(params.displayAnalytics));

    if (params.displaySearch)
        this.displaySearch(ko.utils.unwrapObservable(params.displaySearch));

    if (params.UseComplex)
        this.GiftSearchCriteria.UseComplex(ko.utils.unwrapObservable(params.UseComplex));

    if (params.MarketingEffortId)
        this.GiftSearchCriteria.MarketingEffortId(ko.utils.unwrapObservable(params.MarketingEffortId));


    if (params.Type == 0)
        console.warn("no search type passed to commitment line item search");

    this.GiftSearchCriteria.GiftSearchType(params.Type || 0);

    if (params.analytics != false)
        analytics.Commitments.InitializeViewModel(this.Gifts, this.GiftSearchCriteria.Analytics, '/Giving/Gift/GiftDesignationSpreadsheet', '/Giving/Gift/ClearArgumentsFromSession');


    pagination.SubscribeSearchPagination(this, this.Gifts, '/Giving/Gift/SearchDesignations', this.GiftSearchCriteria);


    this.navigateToIndividualDetailsFromSearch = function (d) {
        //sessionStorage.setItem("RefreshCommitmentSearch", JSON.stringify(ko.toJS(viewModel.Payments.PaymentSearchCriteria)));
        window.open('/People/Individual/Details/' + d.Individual.Id());
    };

    this.navigateToOrganizationDetailsFromSearch = function (d) {
        // sessionStorage.setItem("RefreshPaymentSearch", JSON.stringify(ko.toJS(viewModel.Payments.PaymentSearchCriteria)));
        window.open('/Organization/Profile/Details/' + d.Organization.Id());
    };

    this.navigateToGiftFromSearch = function (d) {
        //sessionStorage.setItem("RefreshPaymentSearch", JSON.stringify(ko.toJS(viewModel.Payments.PaymentSearchCriteria)));
        window.open('/Giving/Gift/Details/' + d.CommitmentItemId());
    };

    this.Channels = ko.observableArray();
                for(var i=0;i<enumerations.Channel.length;i++) {
                    if (i != 0)
                        var y = { Name: enumerations.Channel[i], Index: i.toString() };
                    else
                        var y = { Name: 'All', Index: i.toString() };
                    this.Channels.push(y);
                }



    this.GiftSearchCriteria.TimeZone(jstz.determine().name());

    console.log('calling for initial gift designation search');
    this.Gifts.firstPage();

    this.GetSpreadSheet = function () {

        this.GiftSearchCriteria.LocalUrl = window.location.pathname;

        v2Form.POST({
            url: '/Giving/Gift/GiftDesignationSpreadsheet',
            data: ko.toJS(this.GiftSearchCriteria),
            error: function () {
                v2Form.GET({
                    url: '/Giving/Gift/ClearArgumentsFromSession'
                });
            },
            complete: function () { $('body').unblock(); }
        });
    };


    //console.log('gift designation view model', this);
}




module.exports = {
    viewModel: viewModel,
    template: template

};
