var template = require('./signup.html');
var giftDesignations = require('../../v2/giftDesignation.js');
var extender = require('../../v2/eventCalendarExtender.js');

function viewModel() {
    var vm = this;
    vm.SetupComplete = ko.observable(false);
    vm.GiftHistory = ko.observableArray();
    vm.MarketingCommunicationId = ko.observable();
    vm.SiteId = ko.observable(0);
    vm.ChannelId = ko.observable();
    vm.Designations = ko.observableArray();

    vm.Schedule = new giftDesignations.SimpleSchedule();
    extender.ExtendSimpleSchedule(vm.Schedule);
}


module.exports = {
    viewModel: viewModel,
    template: template
}