//window.console.log shiv.
//Technically we shouldn't leave window.console.logs in production code, but this is just in case, so the whole page script doesn't break.
window.console = window.console || {};
window.console.log = window.console.log ? window.console.log : function () {
    try {
        window.console.log.apply(window.console, arguments);
    }
    catch (e) {
        try {
            opera.postError.apply(opera, arguments);
        }
        catch (ee) {
            //do nothing, probably IE
        }
    }
};


function throttle(fn, threshhold, scope) {
    threshhold || (threshhold = 250);
    var last,
        deferTimer;
    return function () {
        var context = scope || this;

        var now = +new Date,
            args = arguments;
        if (last && now < last + threshhold) {
            // hold on to it
            clearTimeout(deferTimer);
            deferTimer = setTimeout(function () {
                last = now;
                fn.apply(context, args);
            }, threshhold + last - now);
        } else {
            last = now;
            fn.apply(context, args);
        }
    };
}


