
var ko = require("knockout");

ko.bindingHandlers.enterKey = {
    init: function (element, valueAccessor, allBindings, vm) {
        ko.utils.registerEventHandler(element, "keyup", function (event) {
            if (event.keyCode === 13) {
                ko.utils.triggerEvent(element, "change");
                valueAccessor().call(vm, vm); //set "this" to the data and also pass it as first arg, in case function has "this" bound
            }

            return true;
        });
    }
};


 ko.observable.fn.withPausing = function () {
            this.notifySubscribers = function () {
                if (!this.pauseNotifications) {
                    ko.subscribable.fn.notifySubscribers.apply(this, arguments);
                }
            };

            this.sneakyUpdate = function (newValue) {
                this.pauseNotifications = true;
                this(newValue);
                this.pauseNotifications = false;
            };

            return this;
        };
