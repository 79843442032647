define([], function () {
    function catalogItemOption() {
        var self = this;
        self.Quantity = ko.observable();
        self.FirstOptionId = ko.observable();
        self.SecondOptionId = ko.observable();
        self.PackageId = ko.observable();
        self.CatalogItemId = ko.observable();
        self.IsOptionsRequired = ko.observable(false);
        self.QuidProQuoAmount = ko.observable(0);

    }

    function fulfillmentRecipient() {
        var self = this;
        self.FirstName = ko.observable();
        self.LastName = ko.observable();
        self.Address1 = ko.observable();
        self.Address2 = ko.observable();
        self.City = ko.observable();
        self.Region = ko.observable();
        self.Country = ko.observable();
        self.EmailAddress = ko.observable();
        self.PhoneNumber = ko.observable();
        self.copyFrom = function(b) {
            self.FirstName(b.FirstName());
            self.LastName(b.LastName());
            self.Address1(b.Address1());
            self.Address2(b.Address2());
            self.City(b.City());
            self.Region(b.Region());
            self.CountryType(b.Country() || b.CountryType());
            self.State(b.State());
            self.EmailAddress(b.EmailAddress());
            self.PhoneNumber(b.PhoneNumber());
        }
    }

    function fulfillmentRequest() {
        var self = this;
        self.PackageId = ko.observable();
        self.PackageType = ko.observable();
        self.CatalogItemOptions = ko.observableArray();
        self.UseBillingInformation = ko.observable(true);
        self.Recipient = new fulfillmentRecipient();


    }

    function addFulfillmentRequestOptionsForPackage(p, fr) {

        ko.utils.arrayForEach(ko.utils.unwrapObservable(p.Items),
            function(i) {

                var qty = ko.utils.unwrapObservable(i.Quantity);
                for (var q = 0; q < qty; q++) {
                    var option = new catalogItemOption();
                    option.PackageId(ko.utils.unwrapObservable(p.Id));
                    option.CatalogItemId(ko.utils.unwrapObservable(i.Id));
                    option.IsOptionsRequired(ko.utils.unwrapObservable(i.IsOptionsRequired));
                    option.Quantity(ko.utils.unwrapObservable(i.Quantity));
                    option.QuidProQuoAmount(ko.utils.unwrapObservable(i.QuidkProQuoAmount));
                    subscribeOption(option, i);
                    fr.CatalogItemOptions.push(option);
                }

            });
    }

    function subscribeOption(option, item) {
        option.FirstCategory = item.FirstOptionCategory;
        option.SecondCategory = item.SecondOptionCategory;
        option.PhotoId = item.PhotoId;
        option.FirstOptions = item.FirstOptions;
        option.SecondOptions = item.SecondOptions;
        option.Name = item.Name;
        option.Description = item.Description;

        //console.log('subscribe option',ko.toJS(option), ko.toJS(item));

        option.FirstOptionPhotoId = ko.computed(function() {
            var first = ko.utils.unwrapObservable(option.FirstOptionId);
            if (option.IsOptionsRequired()) {
                var s = ko.utils.arrayFirst(ko.utils.unwrapObservable(option.FirstOptions),
               function (item) {
                   if (ko.utils.unwrapObservable(item.Id) === first)
                       return item;
               });

                if (s)
                    return ko.utils.unwrapObservable(s.PhotoId);
            }
           

            return 0;
        });

        option.SecondOptionPhotoId = ko.computed(function () {
            try {

            var options = ko.utils.unwrapObservable(option.SecondOptions);
            var second = ko.utils.unwrapObservable(option.SecondOptionId);

            if (option.IsOptionsRequired() === false || !second || !options)
                return null;
            
            var s = ko.utils.arrayFirst(options,
                    function(item) {
                        if (ko.utils.unwrapObservable(item.Id) === second)
                            return item;
                    });

                if (s)
                    return ko.utils.unwrapObservable(s.PhotoId);
            return 0;
            } catch (e) {
                console.log('exception', e)
                return null;
            }

        });

        option.FirstOptionDisplay = ko.computed(function() {
            var first = ko.utils.unwrapObservable(option.FirstOptionId);
            if (option.IsOptionsRequired()) {
                var s = ko.utils.arrayFirst(ko.utils.unwrapObservable(option.FirstOptions),
                    function(item) {
                        if (ko.utils.unwrapObservable(item.Id) === first)
                            return item;
                    });

                if (s)
                    return ko.utils.unwrapObservable(s.Name);
            }
            return null;
        });

        option.SecondOptionDisplay = ko.computed(function () {
            try {

                var options = ko.utils.unwrapObservable(option.SecondOptions);
                var second = ko.utils.unwrapObservable(option.SecondOptionId);

                if (option.IsOptionsRequired() === false || !second || !options)
                    return null;
                
                
                var s = ko.utils.arrayFirst(options,
                    function (item) {
                        if (ko.utils.unwrapObservable(item.Id) === second)
                            return item;
                    });

                if (s)
                    return ko.utils.unwrapObservable(s.Name);

                return null;
            } catch (e) {
                console.log('exception',e)
                return null;
            } 
           
        });
    }

    function subscribeFulfillmentRequestOptions(pkg, fr) {
        var p = ko.utils.unwrapObservable(pkg);
        if (p && p.Items) {
            ko.utils.arrayForEach(ko.utils.unwrapObservable(p.Items),
                function(item) {
                    ko.utils.arrayForEach(ko.utils.unwrapObservable(fr.CatalogItemOptions),
                        function(option) {
                            //console.log('looking for', option);
                            if (ko.utils.unwrapObservable(option
                                    .CatalogItemId) ===
                                ko.utils.unwrapObservable(item.Id)) {
                                subscribeOption(option, item);
                            }
                        });

                });

        } else {
            console.log('no package no items ???', p);
        }
    }

    function removeFulfillmentItemsForPackageId(i, fr) {
        if (!i)
            return;
        //console.log('removing items from request', i, ko.toJS(fr));

        var itemsToRemove = ko.utils.arrayFilter(ko.utils.unwrapObservable(fr.CatalogItemOptions),
            function (item) {
                //console.log('clearing items',item,item.PackageId());
                if (ko.utils.unwrapObservable(item.PackageId) === i)
                    return item;
            });

        //console.log('items to remove', itemsToRemove);

        ko.utils.arrayForEach(itemsToRemove,
            function (item) {
                //console.log('removing item', ko.toJS(item));
                fr.CatalogItemOptions.remove(item);
            });
    }

    function subscribePackage(pkg, li) {
        if (!li.previousPackageId) {
            li.previousPackageId = null;
            var p = ko.utils.unwrapObservable(pkg);

            if (p)
                li.previousPackageId = ko.utils.unwrapObservable(p.Id);

            //console.log(p, li.previousPackageId);
        }


        pkg.subscribe(function(nv) {
            var p = ko.utils.unwrapObservable(ko.utils.unwrapObservable(nv));
            //console.log('package change', p, li.previousPackageId);
            if (!p) {
                //find all of the fulfillment items on the line item that are from the original package id and clear them
                //console.log('removing items for packageid ', li.previousPackageId, li.FulfillmentRequest);
                removeFulfillmentItemsForPackageId(li.previousPackageId, li.FulfillmentRequest);
                
                return;
            } else {
                //console.log('package change', p, li.previousPackageId, ko.utils.unwrapObservable(p.Id));
                if (li.previousPackageId !== ko.utils.unwrapObservable(p.Id)) {
                    //.log('removing items for packageid ', li.previousPackageId, li.FulfillmentRequest);
                    removeFulfillmentItemsForPackageId(li.previousPackageId, li.FulfillmentRequest);
                    //console.log('building fulfillment request');
                    addFulfillmentRequestOptionsForPackage(p, li.FulfillmentRequest);
                    //console.log('setting package info');
                    li.previousPackageId = ko.utils.unwrapObservable(p.Id);
                    li.FulfillmentRequest.PackageId(ko.utils.unwrapObservable(p.Id));
                    li.FulfillmentRequest.PackageType(ko.utils.unwrapObservable(p.PackageType));
                }
            }
        });
    }

    function prepareFulfillmentRequest(fr) {
        ko.utils.arrayForEach(fr.CatalogItemOptions,
            function(f) {
                f.Name = null;
                f.Description = null;
                f.FirstOptions = null;
                f.SecondOptions = null;
            });

        if (fr.Recipient)
            fr.Recipient.countryTypeCodes = null;
    }

    function subscribeOverridePremium(l) {

      

   }

    return {
        SubscribePackage: subscribePackage,
        SubscribeFulfillmentRequestOptions: subscribeFulfillmentRequestOptions,
        FulfillmentRequest: fulfillmentRequest,
        SubscribeOverridePremium : subscribeOverridePremium
    };
});