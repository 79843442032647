var template = require("./valuesummary.html");


function viewModel(params) {

    

    console.log('vaulesummary',params);
    
    var vm = this;
    vm.Value = ko.observable();

    if (params.agg == null) {
        console.warn('missing aggregations');
    }

    if (params.name == null)
        console.warn('missing aggregation name');

    if (params.label == null)
        console.warn('missing aggregation label');

   
    var aggs = ko.toJS(params.agg);
    var name = ko.unwrap(params.name);
    

    if (aggs) {

        var agg = ko.utils.arrayFirst(aggs, function (i) {

            if (i.Name == name)
                return i;
        });



        if (!agg) {
            console.error('unable to find stats for', ko.unwrap(params.name));
        }
        else {
            vm.Value = ko.observable(ko.utils.unwrapObservable(agg.Value));

            if (!params.label)
                vm.Label = ko.observable(params.name);
            else
                vm.Label = ko.observable(ko.utils.unwrapObservable(params.label));
        }

    }
}


module.exports = {
    viewModel : viewModel,
    template : template
}