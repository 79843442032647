


var template = require('./detailsearch-display.html');



function viewModel(params) {

    var self = params.Profile;
    




    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};

