var template = require('./package-display.html');


function packageModel() {
    var self = this;
    self.Id = ko.observable();
    self.DisplayName = ko.observable();
    self.PhotoId = ko.observable();
    self.Description = ko.observable();
    self.PackageType = ko.observable();
    self.IsFixedAmount = ko.observable();
    self.MinimumAmount = ko.observable();
    self.MaximumAmount = ko.observable();
    self.QuidQuoProValue = ko.observable();
    self.Items = ko.observableArray();
}


function viewModel(params) {
    var self = this;
    self.loaded = ko.observable(false);
    //console.log('display',params);
    if (params.Package) {
        self.Package = params.Package;
        
    } else {

        self.Package = new packageModel();
       
        if (params.Id) {
            v2Form.POST({
                url: '/Catalog/Package/GetPackageDisplay',
                type: 'POST',
                contentType: 'application/json',
                dataType: 'json',
                data: { id: ko.utils.unwrapObservable(params.Id) },
                success: function (data, textStatus, jqXhr) {
                    ko.mapping.fromJS(data.Data, {}, self.Package);
                    self.PhotoRoot = window.v2admin.PhotoRoot;
                    self.loaded(true);
                    //console.log('package display',ko.toJS(self));
                },
                error: function (data, textStatus, jqXhr) {
                    console.log('error',data);
                }
            });

        }
    }


   

}


module.exports = {
    viewModel: viewModel,
    template: template

};
