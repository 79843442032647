var template = require('./site-select.html');


function viewModel(params) {
    var self = this;
    //console.log('site select params',params);
    self.SiteId = params.SiteId;


    //allow nulls  

    self.AllowNoSiteId = ko.observable(false);

    if (params.AllowNoSiteId) {
        self.AllowNoSiteId(params.AllowNoSiteId);
    }

    self.clearSelectedSite = function() {
        if (self.AllowNoSiteId()) {
            self.SiteId(null);
        } else {
            self.SiteId(0);
        }
    };
    self.noColFormatting = ko.observable(false);
    if(params.noCol){
        self.noColFormatting(params.noCol);
    }


    self.GetSites = function (options) {
        if (options.text == "") return;

        $.ajax({
            url: '/Organization/Site/Search',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({ searchTerm: options.text, PageIndex: options.page, PageSize: 15, IsArchived:false }),
            success: function (data, textStatus, jqXhr) {
                options.callback(data.Data);
            },
            error: function (data, textStatus, jqXhr) {
                console.log('error');
                console.log(data);
            }
        });
    };

    self.Site = ko.observable();
    self.SiteName = ko.observable();
    self.Address1 = ko.observable();
    self.Address2 = ko.observable();
    self.City = ko.observable();
    self.Region = ko.observable();
    self.PostalCode = ko.observable();


    self.Site.subscribe(function (x) {
        if (x != null) {
            self.SiteName(ko.utils.unwrapObservable(x.SiteName));
            self.SiteId(ko.utils.unwrapObservable(x.Id));
            self.Address1(ko.utils.unwrapObservable(x.Address1));
            self.Address2(ko.utils.unwrapObservable(x.Address2));
            self.City(ko.utils.unwrapObservable(x.City));
            self.Region(ko.utils.unwrapObservable(x.Region));
            self.PostalCode(ko.utils.unwrapObservable(x.PostalCode));
        }         
    });


    self.getDetail = function() {
        v2Form.POST({
            url: '/Organization/Site/GetSiteDisplay',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: { id: self.SiteId() },
            success: function (data, textStatus, jqXhr) {
                self.Site(data.Data);
            },
            error: function(data, textStatus, jqXhr) {
                console.log('error');
                console.log(data);
            }
        });
    };

    
    if (params.SiteId()  && params.SiteId() > 0)
        self.getDetail();

   // console.log(params, self);
}


module.exports = {
    viewModel: viewModel,
    template: template

};

