require("knockout");
require("knockout-mapping");
var radio = require("radio");
var paginationServices = require("../../v2/paginationServices.js");
var v2Form = require("../../v2/formUtilities.js");
require("../../kobindings/searchbindings.js");
var template = require('./duplicateindividualsearch.html');

function viewModel(params) { 
    
    var self = this;
    
    self.Individuals = new paginationServices.PageModel();
    self.IndividualSearchCriteria = new paginationServices.IndividualSearchModel(0, 20);
    self.DisplayEnvelope = ko.observable(false);
    setParams(params, self.IndividualSearchCriteria);

    self.resultDownloadModalVisible = ko.observable(false); 

    if (params.DisplayEnvelope) {
        self.DisplayEnvelope(ko.utils.unwrapObservable(params.DisplayEnvelope));
    }

    self.navigateToProfileDetailsFromSearch = function (d) {
        window.open('/People/Individual/Details/' + d.Profile.Id());
    };

    self.navigateToDuplicateDetailsFromSearch = function (d) {
        window.open('/People/Individual/Details/' + d.Duplicate.Id());
    };

    self.navigateToEditFromSearch = function (d) {
        sessionStorage.setItem("RefreshIndividualSearch", JSON.stringify(ko.toJS(self.IndividualSearchCriteria)));
        window.open('/People/Individual/Edit/' + d.Profile.Id());
    };

    self.navigateToAddGiftFromSearch = function (d) {
        sessionStorage.setItem("RefreshIndividualSearch", JSON.stringify(ko.toJS(self.IndividualSearchCriteria)));
        window.open('/Giving/Gift/CreateGiftForIndividual/' + d.Id());
    };

    self.generateProfileDisplayName = function (d) {
        var result = "";

        if (d.Profile.PrimaryName != null) {
            var firstName = ko.toJS(d.Profile.PrimaryName.FirstName);
            var lastName = ko.toJS(d.Profile.PrimaryName.LastName);
            var middleName = ko.toJS(d.Profile.PrimaryName.MiddleName);
            var suffix = ko.toJS(d.Profile.PrimaryName.Suffix);
            var searchTag = ko.toJS(d.Profile.SearchTag);

            result = result + firstName;
            if (middleName != null && middleName.length > 0) {
                result += " " + middleName;
            }
            result += " " + lastName;
            if (suffix != null && suffix.length > 0) {
                result += ", " + suffix;
            }
            if (searchTag != null && searchTag.length > 0) {
                result += " (" + searchTag + ")";
            }
        }

        return result;
    };

    self.generateDuplicateDisplayName = function (d) {
        var result = "";

        if (d.Duplicate.PrimaryName != null) {
            var firstName = ko.toJS(d.Duplicate.PrimaryName.FirstName);
            var lastName = ko.toJS(d.Duplicate.PrimaryName.LastName);
            var middleName = ko.toJS(d.Duplicate.PrimaryName.MiddleName);
            var suffix = ko.toJS(d.Duplicate.PrimaryName.Suffix);
            var searchTag = ko.toJS(d.Duplicate.SearchTag);

            result = result + firstName;
            if (middleName != null && middleName.length > 0) {
                result += " " + middleName;
            }
            result += " " + lastName;
            if (suffix != null && suffix.length > 0) {
                result += ", " + suffix;
            }
            if (searchTag != null && searchTag.length > 0) {
                result += " (" + searchTag + ")";
            }
        }

        return result;
    };

    paginationServices.SubscribeSearchPagination(self, self.Individuals, '/People/Individual/SearchDuplicates', self.IndividualSearchCriteria);

    //must be just before first page call
    if (sessionStorage.getItem("RefreshIndividualSearch")) {
        var criteria = JSON.parse(sessionStorage.getItem("RefreshIndividualSearch"));
        ko.mapping.fromJS(criteria, {}, self.IndividualSearchCriteria);
        sessionStorage.removeItem("RefreshIndividualSearch");
    }

    self.getSpreadsheet = function () {
        v2Form.POST({
            url: '/People/Individual/DuplicatesSpreadsheet',
            data: ko.toJS(self.IndividualSearchCriteria),
            error: function () {
                v2Form.GET({
                    url: '/Finance/Payment/ClearArgumentsFromSession'
                });
            },
            complete: function () { $('body').unblock(); }
        });
    };

    self.notADupe = function (data) {
        //console.log(data.Id());

        v2Form.POST({
            url: '/People/Individual/IgnoreDupe',
            data: { id: data.Id() },
            success: function (data) {
                if (data.Data === true) {
                    //d.Designation.IsArchived(true);
                    self.Individuals.firstPage();
                }
                else {
                    var panel = $('#FormNotificationPanel');

                    var modelState = data.ModelStateDictionary;

                    if (modelState) {
                        var messages = "Issues: <br />";
                        for (var key in modelState)
                            if (modelState[key].Errors)
                                for (var error in modelState[key].Errors)
                                    messages += modelState[key].Errors[error].ErrorMessage + '<br />';

                        panel.html(messages).slideDown('fast');
                        notify.alert(messages);
                    }
                }
            },
            error: function (err) {
                //v2Form.GET({
                //    url: '/Finance/Payment/ClearArgumentsFromSession'
                //});
                console.log(err);
            },
            complete: function () { $('body').unblock(); }
        });
    };

    self.ResetCriteria = function () {
        var complex = self.IndividualSearchCriteria.UseComplex();
        var model = new paginationServices.IndividualSearchModel(0, self.IndividualSearchCriteria.PageSize());
        setParams(params, model);
        ko.mapping.fromJS(ko.toJS(model), {}, self.IndividualSearchCriteria);
        self.IndividualSearchCriteria.UseComplex(complex);
        if (complex) {
            $('#indSearchFirstName').focus();
        }
    };

    function setParams(params, vm) {
        //console.log('params in set params', params);
        if (params && vm) {
           
            if (params.UseComplex) {
                vm.UseComplex(ko.utils.unwrapObservable(params.UseComplex));
            }
            if (params.IsArchived) {
                vm.IsArchived(ko.utils.unwrapObservable(params.IsArchived));
            }
        }
    }

    self.Individuals.firstPage();

    return self;
}

module.exports = {
    viewModel: viewModel,
    template: template
};