
(function () {

    
    //assumes a model with two properties: markup and sample
    function bindMarkup(section) {
        section.Json = $.parseJSON($(ko.utils.unwrapOservable(section.Sample)));
        section.Output = new ko.observable();
        section.Markup.subscribe(function(nv)
            {
                section.Output = ko.observable(Mustache.render(t, section.Json));
            });
    }

    window.mustacheServices = {
        BindMarkup : bindMarkup
    };

})();