define([],function() {

    function searchTriggers(options,source,pageSize){
        $.ajax({
            url: '/Marketing/TriggerConfiguration/SearchTriggerConfiguration',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({ QueryString: options.text, UseComplex:true, SourceType: source, PageSize: pageSize, PageIndex: options.page }),
            success: function (data, textStatus, jqXhr) {
               options.callback(data.Data);
            },
            error: function (data, textStatus, jqXhr) {
                console.log('error');
                console.log(data);
            }
        });
    };

    function getTriggerDetail(id, target) {
        $.ajax({
            url: '/Marketing/TriggerConfiguration/GetTriggerConfigurationDetail',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({
                Id: id}),
            success: function (data, textStatus, jqXhr) {
                var x = data.Data;
                assignFromJson(target, x);
                
            },
            error: function (data, textStatus, jqXhr) {
                console.log('error');
                console.log(data);
            }
        });
    }

    function assignFromJson(target, x) {
        target.Id(x.Id);
        target.Name(x.Name);
        target.Description(x.Description);
        target.StartDay(x.StartDay);
        target.LastDay(x.LastDay);
        target.Responses.removeAll();
        for (var i = 0; i < x.Responses.length; i++) {
            var tr = new triggerResponse();
            var z = x.Responses[i];
            console.log(z);
            tr.ResponseId(z.ResponseId);
            tr.Channel(z.Channel);
            tr.DaysAfter(z.DaysAfter);
            tr.MarketingTemplateId(z.MarketingTemplateId);
            target.Responses.push(tr);
        }
    }
    
    function triggerConfiguration() {
        var self = this;
        self.Id = ko.observable();
        self.Name = ko.observable();
        self.Description = ko.observable();
        self.StartDay = ko.observable();
        self.LastDay = ko.observable();
        self.Responses = ko.observableArray();
        
    }

    function triggerResponse() {
        var tr = this;
        tr.ResponseId = ko.observable();
        tr.Channel = ko.observable();
        tr.DaysAfter = ko.observable();
        tr.MarketingTemplateId = ko.observable();
    }


    function createTriggerViewModel(e,i, s) {
        var tvm = {};
        tvm.Enabled = ko.computed(function () {
            return e();
        });
        tvm.PageSize = ko.observable(15);
        tvm.PageIndex = ko.observable(0);
        tvm.Selected = ko.observable();
        tvm.Selected.subscribe(function (nv) {
            i(nv.Id);
            assignFromJson(tvm.Detail, nv);
        });
        tvm.Detail = new triggerConfiguration();
        tvm.toggleEnabled = function () {
            e(!e());
        };
        tvm.searchTriggers = function (o) {
            searchTriggers(o, s, tvm.PageSize(), tvm.PageIndex());
        };
        tvm.getDetail = function () {
            getTriggerDetail(i(), tvm.Detail);
        };
        tvm.enableSearch = function () {
            tvm.Detail.Id(null);
            tvm.Detail.Name(null);
            i(null);
        };
        
        if (i() != null) {
            tvm.getDetail();
        }
      
        return tvm;
    }

    return {
        CreateViewModelFor: createTriggerViewModel
    };
});