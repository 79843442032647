/* File Created: July 25, 2012 */
/* Copyright 2012 Vision2Systems LLC All rights reserved */
require('./fulfillment');

define([], function () {
    function giftDesignationsModel(a) {
        var self = this;
        this.GiftId = ko.observable(a || 0);
        this.BatchGiftTemplateDesignationId = ko.observable(0);
        this.GiftDesignationId = ko.observable(0);
        this.PayableId = ko.observable(0);
        this.SourceCodeId = ko.observable(0);
        this.RecurringSchedule = ko.observable(null);
        this.AskLadderAmountId = ko.observable(0);
        this.Description = ko.observable();
        this.GiftAmount = ko.observable(0);
        this.AppliedPaymentAmount = ko.observable(0);
        this.OutstandingAmount = ko.observable(0);
        this.FundableId = ko.observable(0);
        this.FundableName = ko.observable("");
        this.FundableReferenceType = ko.observable();
        this.Description = ko.observable("");
        this.SourceCode = ko.observable();
        this.AccountSegment = ko.observable();
        this.Fundables = ko.observableArray();
        this.RowStatus = ko.observable(0);
        this.Schedule = new simpleScheduleModel();
        this.IsScheduled = ko.observable(false);
        this.DisplayName = ko.observable();
        this.MarketingCommunicationId = ko.observable();
        this.IsPremium = ko.observable();
        this.NewPaymentFundingMethodType = ko.observable();
        this.IsMissionTripParticipant = ko.observable();
        this.PackageId = ko.observable();
        this.IsEditable = ko.observable(true);
        this.OverridePremium = ko.observable(false);
        setFunctionsOnDesignation(self);

    }

    function simpleScheduleModel() {
        this.Id = ko.observable(0);
        this.IsScheduled = ko.observable(false);
        this.IsEdit = ko.observable(false);
        this.Frequency = ko.observable("5"); //Once by default
        this.StartDate = ko.observable(moment(new Date()).format("MM/DD/YYYY"));
        this.originalStartDate = ko.observable(moment().format("MM/DD/YYYY"));
        this.EndDate = ko.observable(null);
        this.WeekDay = ko.observable("0");
        this.NumberOfPayments = ko.observable(0);
        this.DayOfMonth = ko.observable(0);
        this.TimeZone = ko.observable(jstz.determine().name());
        this.showCalculator = ko.observable(0);
        this.IsEndOfMonth = ko.observable(false);
    }

    function copySimpleSchedule(t, s) {
        t.Id(ko.utils.unwrapObservable(s.Id));
        t.IsScheduled(ko.utils.unwrapObservable(s.IsScheduled));
        t.IsEdit(ko.utils.unwrapObservable(s.IsEdit));
        t.Frequency(ko.utils.unwrapObservable(s.Frequency));
        t.StartDate(ko.utils.unwrapObservable(s.StartDate));
       
        t.EndDate(ko.utils.unwrapObservable(s.EndDate));
        t.WeekDay(ko.utils.unwrapObservable(s.WeekDay));
       
        t.DayOfMonth(ko.utils.unwrapObservable(s.DayOfMonth));
        t.TimeZone(ko.utils.unwrapObservable(s.TimeZone));
        t.IsEndOfMonth(ko.utils.unwrapObservable(s.IsEndOfMonth));

    }




    function setDesignationSearch(item) {
        item.Fundables = ko.observableArray();
        item.GetFundables = function (options) {
            if (options.text == "") return;

            $.ajax({
                url: '/Giving/Gift/SearchFundable',
                type: 'POST',
                contentType: 'application/json',
                dataType: 'json',
                data: JSON.stringify({ searchTerm: options.text, pageIndex: options.page }),
                success: function (data, textStatus, jqXhr) {
                    options.callback(data.Data);
                },
                error: function (data, textStatus, jqXhr) {
                    console.log('error');
                    console.log(data);
                }
            });
        };
        item.FundableItem = ko.observable();

        item.FundableItem.subscribe(function (x) {
            //console.log('designation selected',x);
            item.FundableName(ko.utils.unwrapObservable(x.Name));
            item.FundableId(ko.utils.unwrapObservable(x.FundableId));
            item.Description(ko.utils.unwrapObservable(x.Description));
            item.AccountSegment(ko.utils.unwrapObservable(x.AccountSegment));
            item.FundableReferenceType(ko.utils.unwrapObservable(x.FundableReferenceType));
            //if its a mission trip, break the relationship with the 
            //parent, if not restore the relationship


        });
    }



    function setFunctionsOnDesignation(item) {

        //uses code copied from widgets
        EventCalendarExtender.ExtendSimpleSchedule(item.Schedule, item);

        item.IsAppliedPaymentAmountEditable = ko.observable(false);

        item.Package = ko.observable();

        item.OverridePremium.subscribe(function (nv) {
            if (nv === true)
                return;
            item.PackageId(0);
            item.Package(null);
        });
        item.FulfillmentRequest = new fulfillmentServices.FulfillmentRequest();
        fulfillmentServices.SubscribePackage(item.Package, item);

        item.IsGiftAmountEditable = ko.observable(true);
        item._appliedPaymentAmount = ko.observable(0);
        item.AppliedPaymentAmount = ko.computed({
            read: function () {

                if (item.NewPaymentFundingMethodType() === 3)
                    return 0;


                if (item.IsAppliedPaymentAmountEditable() === false) {
                    return item.GiftAmount();
                }


                return item._appliedPaymentAmount();
            },
            write: function (value) {
                item._appliedPaymentAmount(value);
            },
            owner: item
        });

        item.DisplayLine = ko.computed(function () {

            var formattedStartDate = moment(item.Schedule.StartDate()).format('MM/DD/YYYY');
            var name = "TBD";
            if (item.FundableName())
                name = item.FundableName();

            var amount = ko.utils.unwrapObservable(item.GiftAmount);
            var summary = currency.US.display(amount) + ' to ' + name;
            if (item.Schedule.IsScheduled()) {
                var freq = parseInt(item.Schedule.Frequency());
                switch (freq) {
                    case 0:
                        summary = summary + ' monthly starting on ' + formattedStartDate;
                        break;
                    case 1:
                        summary = summary +
                            ' every ' +
                            enumerations.WeekDayFull[ko.utils.unwrapObservable(item.Schedule.WeekDay)] +
                            ' starting on ' +
                            formattedStartDate;
                        break;
                    case 2:
                        summary = summary +
                            ' every other ' +
                            enumerations.WeekDayFull[ko.utils.unwrapObservable(item.Schedule.WeekDay)] +
                            ' start on ' +
                            formattedStartDate;
                        break;
                    case 3:
                        summary = summary + ' on a yearly basis starting on ' + formattedStartDate;
                        break;
                    case 4:
                        summary = summary + ' on a quarterly basis starting on ' + formattedStartDate;
                        break;
                    case 5:
                        summary = summary + ' once on ' + formattedStartDate;
                        break;
                    case 6:
                        summary = summary + ' every first and sixteenth starting on ' + formattedStartDate;
                        break;
                }

                if (item.Schedule.EndDate() && item.Schedule.EndDate() != null) {
                    summary = summary + ' ending on ' + moment(item.Schedule.EndDate()).format('MM/DD/YYYY');
                }
            }

            return summary;
        });

        setDesignationSearch(item);



    }

    function updateDayOfMonth(schedule) {
        var dayOfMonth = moment(schedule.StartDate()).date();
        if (dayOfMonth == 1) {
            schedule.DayOfMonth(0);
            return;
        }
        if (dayOfMonth == moment(schedule.StartDate()).daysInMonth()) {
            schedule.DayOfMonth(1);
            return;
        }
        schedule.DayOfMonth(2);
    }

    function prepareDesignationsForPost(designations, model) {
        var len = designations.length;
        for (var s = 0; s < len; s++) {
            var eventsToPost = [];
            var item = designations[s];
            //set the applied payment amount to 0 if there is no payment being apllied
            if (model.NewFundingMethodType == 0 || model.NewFundingMethodType == 3)
                item.AppliedPaymentAmount = 0;
            EventCalendarExtender.PrepareSimpleSchedule(item);
            //handle conflict with premiums 

            //console.log('prepare designation', item.FundableReferenceType == 6 && item.MissionTripParticipantPackageId != null && item.MissionTripParticipantPackageId != 0, item);
            if (item.FundableReferenceType == 6 && item.MissionTripParticipantPackageId != null && item.MissionTripParticipantPackageId != 0) {
                item.MarketingCommunicationId = item.MissionTripParticipantPackageId;
                item.PackageId = item.MissionTripParticipantPackageId;
                item.IsMissionTripParticipant = true;
            }
            //console.log('final designation', item);
            item.DisplayLine = null;

            //console.log('designation', item, model.NewFundingMethodType);
        }
    }

    function subscribeGiftDesignations(designations, model, isTemplate) {
        designations.Add = function () {
            //add reference to payment method so that applied payment amount
            //reflects correct data for pledges

            var d = new giftDesignationsModel(0, notify);

            d.NewPaymentFundingMethodType = model.NewPaymentFundingMethodType;
            d.MarketingCommunicationId = model.MarketingCommunicationId;
            d.ParentMarketingCommunicationId = model.MarketingCommunicationId;
            d.MissionTripParticipantPackageId = ko.observable();
            d.Amounts = model.AskLadderAmounts;
            d.AskLadderId = model.AskLadderId;
            ko.utils.arrayForEach(designations(),
                function (i) {
                    i.IsEditable(false);
                });
            designations.push(d);
        };




        designations.Delete = function () {
            var self = this;
            notify.confirm('Are you sure you want to delete this designation?', function () {
                // console.log(isTemplate);
                // console.log(self);
                if (isTemplate) {
                    if (self.BatchGiftTemplateDesignationId() == 0) {
                        designations.remove(self);
                    } else {
                        self.RowStatus(2);
                    }
                }
                else {
                    if (self.GiftDesignationId() == 0) {
                        designations.remove(self);
                    }
                    else
                        self.RowStatus(2);
                }

            });
        };
        designations.ToggleSchedule = function (d) {
            if (d.IsScheduled() == false) {
                d.IsScheduled(true);
                if (d.Schedule == null) {
                    d.Schedule = new simpleScheduleModel();
                }
                d.Schedule.IsScheduled(true);
            } else {
                notify.confirm('Are you sure you want to delete this schedule?', function () {
                    d.IsScheduled(false);
                    if (d.Schedule != null) {
                        d.Schedule.IsScheduled(false);
                    }
                });
            }
        };
        designations.ToggleAppliedPaymentAmount = function (d) {
            if (d.IsAppliedPaymentAmountEditable() == true) {
                d.IsAppliedPaymentAmountEditable(false);
            } else {
                d.IsAppliedPaymentAmountEditable(true);
            }

        };


        designations.totalAppliedPayment = ko.computed(function () {
            var amt = 0;
            ko.utils.arrayForEach(designations(), function (item) {
                var parsedAmt = parseFloat(item.AppliedPaymentAmount() || 0);
                amt = parsedAmt + amt;
            });
            return amt;
        });




        //initialize all the existing gift designations
        ko.utils.arrayForEach(designations(), function (item) {
            //create a valid schedule model for designations without a schedule
            if (item.Schedule && item.Schedule.Id() == 0) {
                item.Schedule = new simpleScheduleModel();
            }
            item.MissionTripParticipantPackageId = ko.observable();
            //item.MarketingCommunicationId = model.MarketingCommunicationId;
            item.NewPaymentFundingMethodType = model.NewPaymentFundingMethodType;
            item.Amounts = model.AskLadderAmounts;
            item.AskLadderId = model.AskLadderId;
            item.IsEditable = ko.observable(false);
            setFunctionsOnDesignation(item);
        });


    }




    function selectableSource(sc) {
        //console.log(sc);
        var self = this;
        this.IsSelected = ko.observable(false);
        this.Id = ko.observable(sc.MarketingCommunicationId);
        this.SourceName = ko.observable(sc.MarketingCommunicationName);
        this.SourceDescription = ko.observable(sc.Description || sc.Name);
        this.SourceCode = ko.observable(sc.SourceCode);
        this.SelectDisplay = ko.computed(function () { return self.SourceCode() + "-" + self.SourceName(); });

    }


    function getSources(options) {
        $.ajax({
            url: '/Giving/Gift/SearchSource',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({ searchTerm: options.text, pageIndex: options.page }),
            success: function (data, textStatus, jqXhr) {
                var results = new Array();
                for (var x in data) {
                    var temp = new selectableSource(data[x]);
                    results[x] = temp;
                }
                options.callback({ data: results });
            }
        });
    }

    function subscribeGift(vm, t) {
        //console.log(vm);
        vm.GetSources = getSources;
        vm.SourceItem = ko.observable();
        vm.SourceItem.subscribe(function (x) {
            vm.SourceName(x.SourceName());
            vm.MarketingCommunicationId(x.Id());
            vm.SourceDescription(x.SourceDescription());
            vm.SourceCode(x.SourceCode());
        });

    }


    return {
        SubscribeGiftDesignations: subscribeGiftDesignations,
        PrepareDesignationsForPost: prepareDesignationsForPost,
        SubscribeGift: subscribeGift,
        SetDesignationSearch: setDesignationSearch,
        GiftDesignationModel: giftDesignationsModel,
        SimpleSchedule: simpleScheduleModel,
        CopySimpleSchedule: copySimpleSchedule
    };
});