var template = require('./catalogitem-select.html');




function viewModel(params) {

    var self = this;
    


    self.Id = params.Id;
    self.Name = ko.observable('*');
    self.PhotoId = ko.observable(0);
    self.Description = ko.observable();
    self.IsOptionsRequired = params.IsOptionsRequired || ko.observable();
    self.FirstOptionCategory = ko.observable();
    self.SecondOptionCategory = ko.observable();
   
    
    //used to support packages which need the amount from the selected item
    if (!params.Item)
        params.Item = {};

    
    self.IsFixedAmount = params.Item.IsFixedAmount || ko.observable();
    self.MinimumAmount = params.Item.MinimumAmount || ko.observable();
    self.MaximumAmount = params.Item.MaximumAmount || ko.observable();
    self.QuidProQuoValue = params.Item.QuidProQuoValue || ko.observable();
    self.IsQuidProQuo = params.Item.IsQuidProQuo || ko.observable();
    

    self.SearchCriteria = new PaginationServices.CatalogItemSearchModel;
   // console.log(self);
    self.SearchCriteria.PageIndex(0);
    self.SearchCriteria.PageSize(15);
    self.SearchCriteria.ItemType(ko.utils.unwrapObservable(params.CatalogItemType));


    
    self.CatalogItem = ko.observable();
  
    self.CatalogItem.subscribe(function (x) {
        //console.log('selected', x);
        self.Id(ko.utils.unwrapObservable(x.Id));
        self.Name(ko.utils.unwrapObservable(x.Name));
        self.PhotoId(ko.utils.unwrapObservable(x.PhotoId));
        self.Description(ko.utils.unwrapObservable(x.Description));
        self.IsFixedAmount(ko.utils.unwrapObservable(x.IsFixedAmount));
        self.MinimumAmount(ko.utils.unwrapObservable(x.MinimumAmount));
        self.MaximumAmount(ko.utils.unwrapObservable(x.MaximumAmount));
        self.QuidProQuoValue(ko.utils.unwrapObservable(x.QuidProQuoValue));
        self.IsQuidProQuo(ko.utils.unwrapObservable(x.IsQuidProQuo));

        //added to support inventory options
        self.IsOptionsRequired(ko.utils.unwrapObservable(x.IsOptionsRequired));
        self.FirstOptions = ko.utils.unwrapObservable(x.FirstOptions);
        self.FirstOptionCategory(ko.utils.unwrapObservable(x.FirstOptionCategory));
        self.SecondOptions = ko.utils.unwrapObservable(x.SecondOptions);
        self.SecondOptionCategory(ko.utils.unwrapObservable(x.SecondOptionCategory));

        
       

        if (params.Options) {
            if (self.IsOptionsRequired()) {
                params.Options.FirstOptions.removeAll();
                params.Options.FirstOptionCategory(self.FirstOptionCategory());
                console.log(self.FirstOptions);
                ko.utils.arrayForEach(self.FirstOptions, function(i) { params.Options.FirstOptions.push(i) });

                if (self.SecondOptionCategory() != null) {
                    params.Options.SecondOptionCategory(self.SecondOptionCategory());
                    params.Options.SecondOptions.removeAll();
                    ko.utils.arrayForEach(self.SecondOptions, function (i) { params.Options.SecondOptions.push(i) });
                }
            } else {
                
            }
            
            
        }
    });

    self.GetItems = function (options) {
        if (options.text == "") return;

        self.SearchCriteria.QueryTerm(options.text);
        self.SearchCriteria.PageIndex(options.page);
        
        v2Form.POST({
            url: '/Catalog/Item/Search',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: self.SearchCriteria,
            success: function (data, textStatus, jqXhr) {
               options.callback(data.Data);
            },
            error: function (data, textStatus, jqXhr) {
                console.log('error');
                console.log(data);
            }
        });
    };


    self.clearSelectedItem = function() {
        self.Id(null);
    }


    self.GetItemForDisplay = function() {

        v2Form.POST({
            url: '/Catalog/Item/GetItemDisplay',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data : {Id : self.Id()},
            success: function(data, textStatus, jqXhr) {
                self.CatalogItem(data.Data);
            },
            error: function(data, textStatus, jqXhr) {
                console.log('error');
                console.log(data);
            }
        });
    }

    if (params.Id() > 0)
        self.GetItemForDisplay();

    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};

