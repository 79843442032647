var template = require('./catalogoption-display.html');


function viewModel(params) {

    var self = this;


    self.Id = params.Id;
    self.Name = ko.observable();
    self.PhotoId = ko.observable();
    self.Description = ko.observable();
    self.Category = ko.observable();

    self.CatalogOption = ko.observable();

    self.CatalogOption.subscribe(function (x) {
        self.Id(ko.utils.unwrapObservable(x.Id));
        self.Name(ko.utils.unwrapObservable(x.Name));
        self.PhotoId(ko.utils.unwrapObservable(x.PhotoId));
        self.Description(ko.utils.unwrapObservable(x.Description));
        self.Category(ko.utils.unwrapObservable(x.Category));
        
    });


    self.GetOptionForDisplay = function () {

        v2Form.POST({
            url: '/Catalog/Item/GetCatalogOptionDisplay',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: { Id: ko.utils.unwrapObservable(self.Id) },
            success: function (data, textStatus, jqXhr) {
                if(data.Data)
                    self.CatalogOption(data.Data);
            },
            error: function (data, textStatus, jqXhr) {
                console.log('error', data);
                
            }
        });
    }
    var id = ko.utils.unwrapObservable(self.Id);
    if (id != null && id > 0)
        self.GetOptionForDisplay();

    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};

