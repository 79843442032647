var jstz = require('jstz');
import { $, jquery } from 'jquery';

import handleSessionTimeout  from './sessionTimeoutHandler.js';


	/// <summary>Handler for all ajax (and post) form actions.</summary>
    var post = function (opts) {
       // console.info('Post method called.', opts)
		/// <summary>
		///     POST action: Configuration object accepts: 
		///     dataConfiguration: custom function you pass in to transform data
		///         Example:: function (o) { 
		///             o.Events = eventScheduler.ToListVEvents(o.Events);
		///             return o; //*!! IMPORTANT TO RETURN THE DATA MODEL 
		///         }
		///     data to send to the server (and transform)
		///     block: true|false - if you want to blockui - default = false
		///     element: jquery object dom element to block - default jquery('body');
		///     success: success function, 
		///     error: error function, 
		///     beforeSend: before ajax run (don't use for data transform) 
		///     complete: after ajax run regardless of success or fail
		///</summary>
		/// <param name="options" type="object">Configuration Object(optional)</param>
	    /// <returns type="Number">The area.</returns>

        opts.type = 'POST';
		opts.contentType = 'application/json';
	    opts.cleanData = typeof opts.cleanData === 'undefined' ? true : false;
		doFormAction(opts);
	};

	var get = function (opts) { 
		/// <summary>
		///     POST action: Configuration object accepts: 
		///     dataConfiguration: custom function you pass in to transform data
		///         Example:: function (o) {  
		///             o.Events = eventScheduler.ToListVEvents(o.Events);
		///             return o; //*!! IMPORTANT TO RETURN THE DATA MODEL 
		///         } 
		///     data to send to the server (and transform)
		///     block: true|false - if you want to blockui - default = false
		///     element: jquery object dom element to block - default jquery('body');
		///     success: success function, 
		///     error: error function, 
		///     beforeSend: before ajax run (don't use for data transform)
		///     complete: after ajax run regardless of success or fail
		///</summary>
		/// <param name="options" type="object">Configuration Object(optional)</param>
		/// <returns type="Number">The area.</returns>
		opts.type = 'GET';
		opts.cleanData = typeof opts.cleanData === 'undefined' ? true : false;
		doFormAction(opts);
	};

	var del = function (opts) {
		/// <summary>
		///     POST action: Configuration object accepts: 
		///     dataConfiguration: custom function you pass in to transform data
		///         Example:: function (o) { 
		///             o.Events = eventScheduler.ToListVEvents(o.Events);
		///             return o; //*!! IMPORTANT TO RETURN THE DATA MODEL 
		///         }
		///     data to send to the server (and transform)
		///     block: true|false - if you want to blockui - default = false
		///     element: jquery object dom element to block - default jquery('body');
		///     success: success function, 
		///     error: error function, 
		///     beforeSend: before ajax run (don't use for data transform)
		///     complete: after ajax run regardless of success or fail
		///</summary>
		/// <param name="options" type="object">Configuration Object(optional)</param>
		/// <returns type="Number">The area.</returns>
		opts.type = 'DELETE';
		opts.cleanData = typeof opts.cleanData === 'undefined' ? true : false;
		doFormAction(opts);
	};

	var timeout;

    function doFormAction(opts) {

        if (!jstz)
            console.log('missing time zone library');

	    if (jstz && opts.data)
            opts.data.jstzTimeZone = jstz.determine().name();
        
        

		
		if (opts.dataConfiguration)
			opts.data = opts.dataConfiguration(opts.data);
		
		opts.success = opts.success || generalSuccess;
		opts.error = opts.error || generalFail;
        opts.before = opts.before || generalBeforeSend;
        opts.complete = opts.complete || generalComplete;


        //console.log('form utilities opts', opts, opts.complete);
		
		var data = opts.data ? (typeof opts.cleanData === 'undefined' || opts.cleanData) ? cleanViewModel(opts.data) : opts.data : {};
	    var token = {};
	    if (opts.data && opts.data.RequestVerificationToken) {
		   token = opts.data.RequestVerificationToken();
		}
	        
       
		
       // console.log('form action data',data);
	    if (opts.element) {
	        if (typeof opts.element === typeof "") {
	            if (opts.element.substring(0, 1) === "#")
	                opts.element = jquery(opts.element);
	            else
	                opts.element = jquery('#' + opts.element);
	        }
	    }
        jquery.ajax({
			url: opts.url,
			type: opts.type,
			contentType: 'application/json',
			data: data,
			beforeSend: function () {
			    if (opts.element) opts.element.block();
			    if (opts.beforeSend) opts.beforeSend();
                
			},
            success: function (data, textStatus, jqXhr) {
                if (opts.success)
                    opts.success(data, textStatus, jqXhr, opts);
                if (opts.complete)
                    opts.complete();
            },
            error: function (jqXhr, props, errorThrown) {

                handleSessionTimeout(props);
                
                if (opts.element) opts.element.unblock();
                if (window.appInsights) {

                    window.appInsights.trackException(errorThrown, "form utilities ajax for " + opts.url);
                }

                if (opts.error) opts.error(jqXhr, textStatus, errorThrown, opts);

			},
			complete: function () {
                if (opts.element) opts.element.unblock();
                if (opts.complete) opts.complete();
                
			},
			headers: { 'RequestVerificationToken': token }
	    });
	}

	function generalSuccess(data, textStatus, jqXhr, opts) {

	    var panel = {};

	    if (opts.panel)
	        panel = jquery(opts.panel);
	    else
	        panel = jquery('#FormNotificationPanel');
		
		var retData = data.Data,
            message = data.Message,
			error = data.Error,
			redirect = data.Redirect,
			modelState = data.ModelStateDictionary;

		if (modelState) {
            var messages = "";
			for (key in modelState)
				if (modelState[key].Errors)
					for (error in modelState[key].Errors)
						messages += modelState[key].Errors[error].ErrorMessage + " ";
            console.log('general success modelstate', modelState, messages);
            panel.html(messages).slideDown('fast');
            import('./notify.js').then((notify) => {
                notify.alert(messages, "Sorry...",);
            })
            
		}

		//if (error || messages) {
		//    notify.growl(error, "Error", { mode: 'error', timeout: 10000 });
		//    if (opts.error) opts.error(jqXhr, textStatus, messages||error, opts);
		//}
		//else if (message) notify.growl(message, "Vision2", { timeout: 10000 });

		if (redirect) {
			jquery('body').block();
			if (error || message) timeout = window.setTimeout("window.location='" + redirect + "';", 5000);
			else window.location = redirect;
		}
	}

	function generalFail(jqXhr, textStatus, errorThrown, opts) {

	    var panel = {};

	    if (opts.panel)
	        panel = jquery(opts.panel);
	    else
	        panel = jquery('#FormNotificationPanel');

		var temp = jqXhr;
		var error = errorThrown;
		if (jqXhr.status == 500) {
			errorThrown = 'Oops, we messed up, tell Vision2 support about it.';
		}

		
		if (jqXhr.status == 404) {
			errorThrown = 'Not found, Kevin may have moved tell Vision2 support about it';
		}

		if (jqXhr.status == 403) {
			notify.top("You are trying to access things you aren't supposed to be or your session timed out.");
		}
		
        import('./notify.js').then((notify) => {
            notify.alert("Error", errorThrown);
        });
		panel.html(errorThrown).slideDown('fast');

	};

	function handleErrors(d,p) {
	    var panel = null;
	    if (p)
	        panel = jquery(p);
	    else
	        panel = jquery('#FormNotificationPanel');

	    var modelState = d.ModelStateDictionary;
	    console.log(d);

        if (modelState) {
            var count = 0;
            var messages = ""; 
            for (key in modelState)
                if (modelState[key].Errors)
                    for (error in modelState[key].Errors) {
                        count = count + 1;
                        messages += '<span>'+ modelState[key].Errors[error].ErrorMessage + '</span><br />';
                    }

            
             messages = messages;


            if(panel!=null)
                panel.html(messages).slideDown('fast');
            import('./notify.js').then((notify) => {
                notify.alert(messages, "Oops");
            });
            return true;
        } else {
            if (d.HttpStatusCode === 400) {
                if(panel!=null)
                    panel.html("An error occurred, we are unable to complete your request").slideDown('fast');
                
                import('./notify.js').then((notify) => {
                    notify.alert('An error occurred, we are unable to complete your request');
                });
                return true;
            }

        }

	    return false;


	}

function generalBeforeSend(element) {
    //use main element to block now
    if (element) {
        $(element).addClass("lds-dual-ring");
    }
    else {
        $('#main_content').addClass("lds-dual-ring");
    }
	//if (element) element.block();
}


function generalComplete(element) {
    if (element) {
        $(element).removeClass("lds-dual-ring");
    }
    else {
        $('#main_content').removeClass("lds-dual-ring");
    }
}

function IsArray(obj) {
    //console.log('is array',obj, Object.prototype.toString.call(obj));
    return Object.prototype.toString.call(obj) === '[object Array]';
}


function cleanViewModel(viewModel) {




    //Make a copy of deleted properties
    var deletedProperties = ko.utils.unwrapObservable(viewModel.deletedProperties) || [];
    //console.log('deleted properties', deletedProperties);
    //console.log("deletedProperties from viewModel", deletedProperties);

    
    //clone viewmodel into a different object
    var converted = ko.toJS(viewModel);
      

    //console.log("converted from ko", converted);

    
    //check for command on viewModel and added to cleaned viewModel
    if (viewModel.command) {
        converted.command = ko.utils.unwrapObservable(viewModel.command);
    }
    
    //make sure there is a deletedProperties array to delete stuff we definitely want out
    //foreach deletedproperty call deepdelete
    for (var i = 0, ii = deletedProperties.length; i < ii; i++) {
        //console.log('deep delete', deletedProperties[i]);
        converted = deepDelete(deletedProperties[i], converted);
    }
    //console.log("after deep delete", converted);

  
    converted = deepDelete("__ko_mapping__", converted);
    converted = deepDelete("enumerables", converted);
    converted = deepDelete("deletedProperties", converted);
    converted = deepDelete("formData", converted);
    
    
    //recursive function to delete properties
    

    var test = JSON.stringify(converted, null, 2);
    //console.log('returning stringify',test);

    return test;
}

function deepDelete(target, context) {
    // Assume global scope if none provided.
    var prop = target;

    if (!context)
        return;

    //split a string 'Prop.Prop...' into an array [Prop, Prop...]
    if (target.includes(".")) {
        prop = target.split('.');
    }

    if (!context) {
        //console.log('missing context', context, target);
        return;
    }

    //console.log('targets', prop);
    //if we still have levels to traverse
    if (IsArray(prop)) {

        var c = prop[0];


        prop.shift();
      

        var t = prop.join('.')
        if (context && context[c] && IsArray(context[c])) {
            for (var i = 0, ii = context[c].length; i < ii; i++) {
                //console.log('recursing array', t, context[c]);
                deepDelete(t, context[c])
            }
        }
        else {
            //console.log('recursing', t, context[c]);
                deepDelete(t, context[c])
        }
        
    } else { //if this is the last level of properties just delete the target
        //console.log('deleting property', prop, context);
        context = safeDelete(context, prop);
    }

    return context;
}

function safeDelete(context, target) {
    try {
        //console.log('safe delete', context[target]);

        if (context[target]) {
            //console.log('safe delete access', context[target]);
            delete context[target];
            //console.log('after deleted', context[target]);
        }

    } catch (e) {
        try {
            var test = context[target];
            


            context[target] = null;
            context[target] = undefined;
            delete context[target];
        }
        catch (e) {
        }
    }

    return context;
}








//var queryString = (function () {
//    var urlParams = {}, e,
//        a = /\+/g,  // Regex for replacing addition symbol with a space
//        r = /([^&=]+)=?([^&]*)/g,
//        d = function (s) { return decodeURIComponent(s.replace(a, " ")); },
//        q = window.location.search.substring(1);

//    while (e = r.exec(q))
//        urlParams[d(e[1]).toLowerCase()] = d(e[2]);

//    return urlParams;
//})();

////tooltips
//jquery(function () {
//    jquery('*[rel="tooltip"]').tooltip();
//    jquery('*[rel="popover"]').popover({trigger: 'hover'});
//});



export default {
    POST: post,
    GET: get,
    DELETE: del,
    ERRORS: handleErrors,
    CleanViewModel: cleanViewModel,
    SafeDelete: safeDelete,
    DeepDelete: deepDelete

}
