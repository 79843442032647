var template = require('./push-entity.html');

function viewModel(params) {
    var self = this;

    self.baseUrl = params.baseUrl;
    self.ParentId = params.Id;
    self.entityType = params.entityType;
    self.uiElementToBlock = params.uiElementToBlock;
    self.ExternalSystems = ko.observableArray();
    self.hasPreventions = params.hasPreventions != undefined && params.hasPreventions();

    function GetExternalSystems() {
        v2Form.POST({
            url: self.baseUrl + '/GetExternalSystems',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            success: function (data, textStatus, jqXhr) {
                //console.log('data' + data);

                if (data.Data) {
                    ko.mapping.fromJS(data.Data, {}, self.ExternalSystems);

                    if (self.ExternalSystems().length > 1) {
                        const allExternalSystemsOption = { SubscriptionId: ko.observable(null), ExternalSystemId: ko.observable(0), ExternalSystemName: ko.observable('All')};
                        self.ExternalSystems.splice(0, 0, allExternalSystemsOption);
                    }
                }
            },
            complete: function (data) {
                
            }
        });
    }

    self.push = function (externalSystem) {
        if (self.hasPreventions)
            notify.alert('This record has been blocked from pushing to your ChMS. Please contact Vision2 if you would like this block removed.', "Vision2", { timeout: 30000 });
        else {
            v2Form.POST({
                block: true,
                element: $("#" + self.uiElementToBlock),
                url: self.baseUrl + '/PushEntity',
                type: 'POST',
                contentType: 'application/json',
                dataType: 'json',
                data: { entityId: self.ParentId, externalSystemId: externalSystem.ExternalSystemId },
                success: function (data, textStatus, jqXhr) {
                    //console.log('data' + data);

                    if (data.Data) {
                        notify.alert(self.entityType + ' pushed', "Vision2", { timeout: 30000 });
                    }
                },
                complete: function (data) {

                }
            });
        }
    }

    GetExternalSystems();
}

module.exports = {
    viewModel: viewModel,
    template: template
};