var template = require("./appliedpaymentdetailrow.html");


require("knockout");
require("knockout-mapping");
require("../../v2/knockout.custombindings");
var radio = require("radio");
var moment = require("moment");
var currency = require("../../v2/currencyServices.js");



function viewModel(params) {
    var vm = this;
    vm.Detail = params.Detail;

    //console.log('params', params);

    this.navigateToDesignation = function (d) {
        //sessionStorage.setItem("RefreshPaymentSearch", JSON.stringify(ko.toJS(viewModel.Payments.PaymentSearchCriteria)));
        window.open('/Marketing/Designation/Details/' + d.Detail.Designation.FundableId());
    };

    vm.navigateToSource = function (d) {

    }

    vm.navigatetoFund = function (d) {

    }

}


module.exports = {
    viewModel: viewModel,
    template: template
}