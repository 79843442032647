



export default function checkForSessionTimeout(result) {
    if (result.status === 401) {
        import('./notify.js').then((notify) => {
            notify.alert('Warning', 'We have logged you out of Vision2 after 30 minutes of activity to protect your account. Please log in again.').then(location.reload());
        });
    }
}

    