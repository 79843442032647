/*
 * Passed in Parameters
 * display - the title next to the radio button for the data option
 * opt - the name of the data option to include in the div tag
 * type - the type of the data option to use (bool, text, area, search-projects, etc)
 * initial - the default value of the widget if nothing is specified
 * description - the long description of the option shown to the user for them to select
 * required - indicates if the user is required to select a value for this item.
 * 
 * Internal Parameters
 * selected - indicates if the radio button for this option should show selected 
 * value - stores the value provided by the user
 * included - indicates if the user has changed the value from the default and drives whether an option is added to the div tag
 */
function dataOpt(display, opt, type, initial, description, required) {
    //grab handle to the current opt for callbacks
    var self = this;

    //set the initial values that don't have behavior
    self.display = ko.observable(display);
    self.type = ko.observable(type);
    self.initial = ko.observable(initial);
    self.description = ko.observable(description);
    self.opt = ko.observable(opt);
    self.value = ko.observable(null);

    self.configured = ko.computed(function () {
        if (self.value() !== null && self.initial() != self.value())
            return true;

        return false;
    })

    //create placeholder for search result selected object and update value with id by subscriber
    self.valueObj = ko.observable(null);
    self.valueObj.subscribe(function (selected) {
        //console.log(selected);
        //console.log(ko.utils.unwrapObservable(selected.FundableReferenceType) == 2);
        //console.log(ko.utils.unwrapObservable(selected.FundableId));
        if (selected.Id != null)
            self.value(ko.utils.unwrapObservable(selected.Id));
        else
            self.value(ko.utils.unwrapObservable(selected));

    });

    //check to see if required was specified as true and set the initial value
    //if the option is required, force it to be included, else included is false
    //showing that the user has not changed the value from the default
    if (required === true) {
        self.required = ko.observable(true);
        self.included = ko.observable(true);
    } else {
        self.required = ko.observable(false);
        self.included = ko.observable(false);
    }

    //if the type is boolean and the default is true, select the radio button
    if (type === 'bool' && initial === true) {
        self.selected = ko.observable(true);
    } else {
        self.selected = ko.observable(false);
    }

    //create the function to handle switching the radio button
    self.toggleSelected = function () {
        if (this.selected()==true) {
            this.selected(false);
        } else {
            this.selected(true);
        }
        if (this.included()==true) {
            this.included(false);
        } else {
            this.included(true);
        }
        
        if (this.type() == 'bool') {
            this.value(!this.initial());
        } else {
           this.value(this.initial());
        }
        
        
    };
};


function widgetModel() {
    var self = this;

    self.dataOpts = ko.observableArray();
    self.scriptPath = ko.observable();
    self.webRoot = ko.observable();
    self.dataModule = ko.observable();
    self.title = ko.observable()
   
    
   
   
  
};


module.exports = {
    WidgetModel: widgetModel,
    DataOpt: dataOpt
}
