





ko.components.register('archive-status', require('./archive/archive-status.js'));
 
ko.components.register('appliedpayment-search', require('./search/appliedpaymentsearch.js'));
ko.components.register('appliedpaymentdetailrow', require('./payment/appliedpaymentdetailrow.js'));



ko.components.register('catalogitem-select', require('./catalogitem/catalogitem-select.js'));
ko.components.register('catalogitem-edit', require('./catalogitem/catalogitem-edit.js'));
ko.components.register('catalogitem-search', require('./catalogitem/catalogitem-search.js'));
ko.components.register('catalogitem-display', require('./catalogitem/catalogitem-display.js'));
ko.components.register('catalogoption-display', require('./catalogitem/catalogoption-display.js'));

ko.components.register('channel-select', require('./channel/channel-select.js'));
ko.components.register('channeltype-picker', require('./chartofaccounts/channeltype-picker.js'));

ko.components.register('commitmentlineitem-search', require('./search/commitmentlineitemsearch.js'));

ko.components.register('department-select', require('./department/departmentselect.js'));
ko.components.register('designation-search', require('./search/designationsearch'));


ko.components.register('push-entity', require('./integration/push-entity.js'));



ko.components.register('fundable-picker', require('./fundable/fundable-picker.js'));
ko.components.register('fundable-display', require('./fundable/fundable-display.js'));
ko.components.register('fundable-search', require('./fundable/fundable-search.js'));
ko.components.register('fundable-status', require('./fundable/fundable-status.js'));



ko.components.register('giftdesignation-search', require('./search/giftdesignationsearch.js'));
ko.components.register('gift-search', require('./search/giftsearch.js'));

ko.components.register('giftnotes', require('./giving/giftnotes.js'));
ko.components.register('givinggoal-search', require('./search/givinggoalsearch.js'));


ko.components.register('inventorypurchase-search', require('./catalogitem/inventorypurchase-search.js'));

ko.components.register('ind-comm-pref', require('./communicationpref/individual-pref.js'));

ko.components.register('individualdetailsearch-display', require('./individual/detailsearch-display.js'));
ko.components.register('individualprofile-display', require('./individual/individualprofile-display.js'));
ko.components.register('individualsearch', require('./search/individualsearch.js'));
ko.components.register('individual', require('./individual/individual.js'));
ko.components.register('duplicateindividualsearch', require('./search/duplicateindividualsearch.js'));

ko.components.register('marketingsource-select', require('./marketingsource/marketingsource-select.js'));

ko.components.register('marketingsource-status', require('./marketingsource/marketingsource-status.js'));


ko.components.register('package-search', require('./package/package-search.js'));
ko.components.register('package-select', require('./package/package-select.js'));
ko.components.register('package-display', require('./package/package-display.js'));



ko.components.register('pagination', require('./pagination/pagination.js'));

ko.components.register('paymenttype-picker', require('./chartofaccounts/paymenttype-picker.js'));


ko.components.register('picklist-display', require('./fulfillment/picklist-display.js'));
ko.components.register('promotionlist-select', require('./promotionlist/promotionlist-select'));
ko.components.register('premium-askladder-display', require('./premium/premium-askladder-display.js'));
ko.components.register('premium-askladder-result', require('./premium/premium-askladder-result.js'));
ko.components.register('restrictiontype-picker', require('./chartofaccounts/restrictiontype-picker.js'));


ko.components.register('signup', require('./giving/signup.js'));
ko.components.register('signupsetup', require('./giving/signupsetup.js'));
ko.components.register('signupgift', require('./giving/signupgift.js'));
ko.components.register('signuphistory', require('./giving/signuphistory.js'));

ko.components.register('simpleschedule', require('./schedule/simpleschedule.js'));

ko.components.register('site-select', require('./site/site-select.js'));
ko.components.register('site-display', require('./site/site-display.js'));

ko.components.register('spinner', require('./spinner/spinner.js'));

//ko.components.register('nav-search', require('./navigation/nav-search.js'));
//ko.components.register('nav-search-container', require('./navigation/nav-search-container.js'));
ko.components.register('stat-summary', require("./graphs/statsummary.js"));



ko.components.register('user-credentials', require('./user/user-credentials.js'));

window.Masonry = require('masonry-layout');

ko.components.register('valuesummary', require('./graphs/valuesummary.js'));






