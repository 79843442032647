
var template = require("./signupgift.html");

var giftDesignations = require('../../v2/giftDesignation.js');
var extender = require('../../v2/eventCalendarExtender.js');

var years = (function () {
    var dates = [];
    for (var i = 0; i <= 20; i++)
        dates.push(i + parseInt(new Date().getFullYear()));
    return dates;
}());

var months = [
    { name: "01 - January", value: '01' },
    { name: "02 - February", value: '02' },
    { name: "03 - March", value: '03' },
    { name: "04 - April", value: '04' },
    { name: "05 - May", value: '05' },
    { name: "06 - June", value: '06' },
    { name: "07 - July", value: '07' },
    { name: "08 - August", value: '08' },
    { name: "09 - September", value: '09' },
    { name: "10 - October", value: '10' },
    { name: "11 - November", value: '11' },
    { name: "12 - December", value: '12' }
];

function basicProfileModel() {

    this.IndividualProfileId = ko.observable(0).withPausing();
    this.PhoneOrEmailAddress = ko.observable().withPausing();
    this.FirstName = ko.observable().withPausing();
    this.MiddleName = ko.observable().withPausing();
    this.Suffix = ko.observable().withPausing();
    this.LastName = ko.observable().withPausing();
    this.AddressId = ko.observable().withPausing();
    this.Address1 = ko.observable().withPausing();
    this.Address2 = ko.observable().withPausing();
    this.City = ko.observable().withPausing();
    this.Region = ko.observable().withPausing();
    this.PostalCode = ko.observable().withPausing();
    this.CountryType = ko.observable().withPausing();
    this.PhoneId = ko.observable().withPausing();
    this.PhoneNumber = ko.observable().withPausing();
    this.EmailAddress = ko.observable().withPausing();
    this.EmailAddressId = ko.observable().withPausing();
    this.SiteId = ko.observable().withPausing();
    this.SearchTerm = ko.observable().withPausing();
    this.Envelope = ko.observable().withPausing();
    this.IsDeceased = ko.observable(false).withPausing();
    this.clear = function () {
        this.PhoneOrEmailAddress(null);
        this.FirstName(null);
        this.MiddleName(null);
        this.Suffix(null);
        this.LastName(null);
        this.AddressId(null);
        this.Address1(null);
        this.Address2(null);
        this.City(null);
        this.Region(null);
        this.PostalCode(null);
        this.CountryType(null);
        this.PhoneId(null);
        this.PhoneNumber(null);
        this.EmailAddress(null);
        this.EmailAddressId(null);
        this.SiteId(null)
        this.SearchTerm(null);
        this.Envelope(null);
        this.IsDeceased(null);
        this.IndividualProfileId(0);

    }
}

function paymentMethod(i, n, f) {
    var self = this;
    self.Id = ko.observable(i);
    self.MethodName = ko.observable(n);
    self.FundingMethod = ko.observable(f);
}

function giftModel() {
    var vm = {};
    vm.GiftId = ko.observable();
    vm.MarketingCommunicationId = ko.observable();
    vm.IndividualProfile = new basicProfileModel();
    vm.Designations = ko.observableArray();
    vm.GiftDate = ko.observable(moment(new Date()));
    vm.SiteId = ko.observable();
    vm.Channel = ko.observable();
    vm.SendEmailAcknolwedgement = ko.observable(true);
    vm.PaymentMethods = ko.observableArray();
    vm.SelectedPaymentMethod = ko.observable();

    var creditcard = new paymentMethod(1, 'Credit Card', 1);
    creditcard.btnClass = ko.computed(function () {
        if (creditcard.FundingMethod() == vm.SelectedPaymentMethod())
            return "btn-success";

        return "btn-default"
    });
    vm.PaymentMethods.push(creditcard);

    var bankaccount = new paymentMethod(2, 'Bank Account', 2);
    bankaccount.btnClass = ko.computed(function () {
        if (bankaccount.FundingMethod() == vm.SelectedPaymentMethod())
            return "btn-success";

        return "btn-default"
    });
    vm.PaymentMethods.push(bankaccount);
    vm.SelectedPaymentMethod(1);

    vm.selectMethod = function (data) {
        vm.SelectedPaymentMethod(data.FundingMethod());
    }

    vm.CreditCard = {
        EncryptedCardNumber: ko.observable(),
        Month: ko.observable(),
        Year: ko.observable(),
        CCV: ko.observable(),
        months: months,
        years: years,
        KeyId: ko.observable(),
        Display: ko.observable()
    }

    vm.BankAccount = {
        EncryptedCardNumber: ko.observable(),
        Bank: ko.observable(),
        RoutingNumber: ko.observable(),
        AccountType: ko.observable(),
        KeyId: ko.observable(),
        Display: ko.observable()

    }

    vm.Schedule = new giftDesignations.SimpleSchedule();

    return vm;
}


function viewModel(params) {
    var vm = {};
    console.log('setup info', ko.toJS(params.Schedule), params.ChannelId());
    
    vm.CurrentGift = {
        
    };


    vm.addGift = function () {
        vm.CurrentGift = giftModel();
        vm.CurrentGift.SiteId(params.SiteId());
        vm.CurrentGift.Channel(params.ChannelId());
        vm.CurrentGift.MarketingCommunicationId(params.MarketingCommunicationId());
        ko.mapping.fromJS(ko.toJS(params.Designations), {}, vm.CurrentGift.Designations);
        giftDesignations.CopySimpleSchedule(vm.CurrentGift.Schedule, params.Schedule);
        console.log('after copy', ko.toJS(params.Schedule), ko.toJS(vm.CurrentGift.Schedule));
        extender.ExtendSimpleSchedule(vm.CurrentGift.Schedule);
        console.log('after extension', ko.toJS(params.Schedule), ko.toJS(vm.CurrentGift.Schedule));
        console.log('current gift', ko.toJS(vm.CurrentGift));
        ko.utils.arrayForEach(vm.CurrentGift.Designations, function (d) {
            d.removeDesignation = function () {
                vm.CurrentGift.Designations.remove(d);
            }
            d.Schedule = vm.CurrentGift.Schedule;
        });

    }

    vm.saveGift = function () {
        params.History.push(vm.CurrentGift);
        vm.CurrentGift = new giftModel();
    }

    vm.addGift();
    return vm;
}

module.exports = {  
    viewModel: viewModel,
    template: template
}


