var template = require('./marketingsource-status.html');





function viewModel(params) {

    var self = this;
    self.Source = params.Source;
    

    self.archive = function (d) {
        notify.confirm('Are you sure you want to archive this source?', function () {
            var id = d.Source.Id();
            v2Form.POST({
                block: true,
                element: $("#koMainIndex"),
                url: '/Marketing/MarketingSource/Archive',
                data: { id: id },
                success: function (data) {
                    if (!v2Form.ERRORS(data) && data.Data === true) {
                        d.Source.IsArchived(true);
                    }
                }
            });
        });
    };

    self.unarchive = function (d) {
        notify.confirm('Are you sure you want to unarchive this source?', function () {
            var id = d.Source.Id();
            v2Form.POST({
                block: true,
                element: $("#koMainIndex"),
                url: '/Marketing/MarketingSource/Unarchive',
                data: { id: id },
                success: function (data) {
                    
                    if (!v2Form.ERRORS(data) && data.Data === true) {
                        d.Source.IsArchived(false);
                    }
                }
            
            });
        });
    };

  

    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};

