require("moment");
require("knockout");


//var reactComponents = require("../reactcomponents/index");

var marketingTemplateServices = require("./marketingTemplates.js");

var Inputmask  = require("inputmask");


var existing = ko.bindingProvider.instance;
console.log()


ko.bindingProvider.instance = {
    nodeHasBindings: existing.nodeHasBindings,
    getBindings: function (node, bindingContext) {
        var bindings;
        
        try {
            //console.log('KNOCKOUT attempting binding for', node, bindingContext);
            bindings = existing.getBindings(node, bindingContext);
        }
        catch (ex) {
            
             console.error("KNOCKOUT: binding error", ex.message, node, bindingContext);
            
        }

        return bindings;
    }
};



function getCardMask(nv) {
    if (nv.length < 1)
        return false;
    //console.log('test', nv.substring(0,1));

    if (nv.substring(0, 1) == "3") {
        return "9999 999999 99999";
    }
    else {
        return "9999 9999 9999 9999";
    }
}


//ko.bindingHandlers.react = {
//    update: reactComponents.render
//}


ko.bindingHandlers.masked = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var mask = allBindingsAccessor().mask || {};
        var e = $(element);
        Inputmask(mask).mask(e);

        $(element).bind('focusout change', function () {
            //console.log('here')
            var observable = valueAccessor();
            var temp = $(element).val();
            temp = temp.replace(/ /g, '').replace(/_/g, '');
            observable($(element).val());
        });
    },
    update: function (element, valueAccessor) {
        var value = ko.utils.unwrapObservable(valueAccessor());
        $(element).val(value);
    }
};



ko.bindingHandlers.ccMask = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var mask = "9999 9999 9999 9999";
        try {
            //console.log('getting element for mask', $(element));
            Inputmask({ mask: mask, placeholder: '*' }).mask($(element));
            //console.log('masking field setup, subscribing to events')

            //updates value
            $(element).bind('focusout change keyup', function () {
                var observable = valueAccessor();
                var temp = $(element).val();
                temp = temp.replace(/ /g, '').replace(/\*/g, '');
                //console.log('val', temp, $(element));
                if (temp.length > 1) {
                    var nm = getCardMask(temp);
                    var im = new Inputmask({ mask: nm, placeholder: '*' });
                    im.mask($(element));
                    //console.log('upd', nm, element, im)

                    //if ($element).inputmask('isComplete')) {
                    //observable($(element).val());
                    //} else {
                    //    observable(null);
                    //}
                }
                observable(temp);
            });

            //ko.utils.registerEventHandler(element, 'focusout change keyup', 'focusout change keyup', function () {
            //var observable = valueAccessor();
            //var temp = $(element).val();
            //temp = temp.replace(/ /g, '').replace(/\*/g, '');
            //console.log('val', temp, $(element));
            //if (temp.length > 1) {
            //    var nm = billing.GetCardMask(temp);
            //    var im = new Inputmask({ mask: nm, placeholder: '*' });
            //    im.mask($(element));
            //    console.log('upd', nm, element, im)

            //    //if ($element).inputmask('isComplete')) {
            //    //observable($(element).val());
            //    //} else {
            //    //    observable(null);
            //    //}
            //}
            //observable(temp);
            //});
        }
        catch (e) {
            console.error('mask failed', e)
        }

    },
    update: function (element, valueAccessor) {
        try {

            var value = ko.utils.unwrapObservable(valueAccessor());
            $(element).val(value);
        }
        catch (e) {
            console.error('mask failed', e);
        }
    }



}





        ko.bindingHandlers.stopBindings = {
            init: function () {
                return { 'controlsDescendantBindings': true };
            }
        };

        ko.extenders.css = function (target, value) {
            var beforeChange;
            var onChange;

            //add sub-observables to our observable
            target.show = ko.observable(true);

            beforeChange = function (oldValue) {
                target.show(false);
            }
            onChange = function (newValue) {
                target.show(true);
            }
            target.subscribe(beforeChange, null, "beforeChange");
            target.subscribe(onChange);
            return target;
        };

        ko.observable.fn.withPausing = function () {
            this.notifySubscribers = function () {
                if (!this.pauseNotifications) {
                    ko.subscribable.fn.notifySubscribers.apply(this, arguments);
                }
            };

            this.sneakyUpdate = function (newValue) {
                this.pauseNotifications = true;
                this(newValue);
                this.pauseNotifications = false;
            };

            return this;
        };


        ko.bindingHandlers.log = {
            init: function (element, valueAccessor, allBindingsAccessor) {
                var val = ko.toJS(ko.utils.unwrapObservable(allBindingsAccessor().log));
                for (var i = 0, ii = val.length; i < ii; i++) {
                    console.log(val[i]);
                }
            }
        };

        ko.bindingHandlers.enterKey = {
            init: function (element, valueAccessor, allBindings, vm) {
                ko.utils.registerEventHandler(element, "keyup", function (event) {
                    if (event.keyCode === 13) {
                        ko.utils.triggerEvent(element, "change");
                        valueAccessor().call(vm, vm); //set "this" to the data and also pass it as first arg, in case function has "this" bound
                    }

                    return true;
                });
            }
        };


        //ko.bindingHandlers.ckeditor = {
        //    init: function (element, valueAccessor) {

        //        var modelValue = valueAccessor();
        //        var value = ko.utils.unwrapObservable(valueAccessor());
        //        var element$ = $(element);



        //        var editor = element$.ckeditor(function () { }, { customConfig: '/Scripts/ckeditor/config.js' }).editor;
        //        console.log('editor', editor, element$);
        //        if (editor != null) {
        //            console.log('found editor',editor);
        //        }


        //        // bind to change events and link it to the observable
        //        //editor.on('change', function (e) {
        //        //    var self = this;
        //        //    if (ko.isWriteableObservable(self)) {
        //        //        self($(e.listenerData).val());
        //        //    }
        //        //}, modelValue, element);

        //        // bind to change events and link it to the observable
        //        editor.on('change', function (e) {
        //            if (ko.isWriteableObservable(modelValue)) {
        //                modelValue(element$.val());
        //            }
        //        }, modelValue, element);


        //        /* Handle disposal if KO removes an editor
        //         * through template binding */
        //        ko.utils.domNodeDisposal.addDisposeCallback(element,
        //            function () {
        //                editor.updateElement();
        //                editor.destroy();
        //            });
        //    },

        //    /* Hook and handle the binding updating so we write
        //     * back to the observable */
        //    update: function (element, valueAccessor) {
        //        var element$ = $(element);
        //        var newValue = ko.utils.unwrapObservable(valueAccessor());
        //        if (element$.ckeditorGet().getData() != newValue) {
        //            element$.ckeditorGet().setData(newValue);
        //        }
        //    }

        //};

        ko.bindingHandlers.displayJsonModel = {
            update: function (element, valueaccessor, allbindings, viewModel, bindingContext) {
                var value = ko.utils.unwrapObservable(valueaccessor());
                var result = marketingTemplateServices.TemplateContextTransform('Template Data', value);
                element.innerHTML = result;

            }
        };

        ko.bindingHandlers.datepicker = {


            init: function (element, valueAccessor, allBindingsAccessor) {
                //initialize datepicker with some optional options
                var options = allBindingsAccessor().dateTimePickerOptions || { format: "MM/DD/YYYY" };
                var initialValue = ko.utils.unwrapObservable(valueAccessor());

                if (!options.showClear)
                    options.showClear = true;

                //initial date has not been initialized as a moment
                if (initialValue && !moment.isMoment(initialValue)) {
                    var value = valueAccessor();
                    if (ko.isObservable(value)) {
                        value(moment(new Date(initialValue)));
                    }
                }


                //console.log('init date picker value', initialValue);
                if (!options.useCurrent && initialValue) {
                    options.defaultDate = new Date(initialValue);
                }




                $(element).datetimepicker(options);

                //when a user changes the date, update the view model
                ko.utils.registerEventHandler(element, "dp.change", function (event) {
                    var value = valueAccessor();
                    if (ko.isObservable(value)) {
                        var val = $(element).val();
                        var m = value();
                        // console.log('update on dp change called', m,val,event.date);
                        if (event.date && val != null && !(val instanceof Date)) {
                            //console.log('date picker change set to not null', val, value);
                            value(moment(new Date(val)));
                        } else {
                            //console.log('set to null');
                            value(null);
                        }
                    }
                });

                ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                    var picker = $(element).data("DateTimePicker");
                    if (picker) {
                        picker.destroy();
                    }
                });
            }
            ,
            update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                var picker = $(element).data("DateTimePicker");
                //when the view model is updated, update the widget
                if (picker) {

                    var koDate = ko.utils.unwrapObservable(valueAccessor());

                    //console.log('update on binding called', koDate);
                    if (koDate && typeof (koDate) !== 'object') {
                        var date = new Date(koDate);
                        picker.date(koDate);
                    }

                }

            }


        };





        //Datepicker
        ko.bindingHandlers.timepicker = {
            init: function (element, valueAccessor, allBindingsAccessor) {
                //set defaults
                var settings = {
                    template: 'dropdown',       //string Specify the template to use. [modal, dropdown]
                    minuteStep: 1,              //ifnteger		Specify a step for the minute field.
                    defaultTime: 'current',     //string Set the initial time value. Setting it to 'current' sets it to the current time.
                    disableFocus: false	        //boolean Disables the input from focusing. This is useful for touch screen devices that display a keyboard on input focus.
                };

                //merge opts with defaults
                $.fn.extend(settings, allBindingsAccessor().datepickerOptions);

                //initialize datepicker with options
                $(element).timepicker(settings);

                $(element).on('change', function (ev) {
                    valueAccessor()($(element).val());
                });




                ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                    $(element).remove();
                });
            },
            update: function (element, valueAccessor) {
                var value = ko.utils.unwrapObservable(valueAccessor());
                $(element).val(value);
            }
        };


        // note: requires moment.js, bootstrap.datepicker.js, ko, observer
        //Datepicker
        ko.bindingHandlers.recurring = {
            //create-initialize
            init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
                //set defaults
                var settings = {
                    occurancePatternSelected: 'weekday',
                    startDate: new Date(),
                    endsPattern: 'on',
                    occurs: 'once'
                };

                //merge opts with defaults
                $.fn.extend(settings, allBindingsAccessor().datepickerOptions);

                //initialize datepicker with options
                $(element).scheduler(settings);

                //handle disposal (if KO removes by the template binding)
                ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                    $(element).remove();
                });
            },
            //update values
            update: function (element, valueAccessor) {
                var value = ko.utils.unwrapObservable(valueAccessor());
                $(element).val(value);
            }
        };


        ko.bindingHandlers.fulfillmentOptionDisplay = {
            init: function (element, valueAccessor, allBindingsAccessor, model, bindingContext) {
                var val = ko.utils.unwrapObservable(valueAccessor());
                var options = ko.utils.unwrapObservable(allBindingsAccessor.get('categoryOptions'));
                var display = "";
                if (val && options) {
                    var x = ko.utils.arrayFirst(options,
                        function (z) {
                            if (ko.utils.unwrapObservable(z.Id) == val)
                                return z;
                        });
                    display = ko.utils.unwrapObservable(x.Name);
                }


                $(element)
                    .text(display);
            },
            update: function (element, valueAccessor, allBindingsAccessor, model, bindingContext) {
                var val = ko.utils.unwrapObservable(valueAccessor());
                var options = ko.utils.unwrapObservable(allBindingsAccessor.get('categoryOptions'));
                var display = "";
                if (val && options) {
                    var x = ko.utils.arrayFirst(options,
                        function (z) {
                            if (ko.utils.unwrapObservable(z.Id) == val)
                                return z;
                        });
                    display = ko.utils.unwrapObservable(x.Name);
                }


                $(element)
                    .text(display);
            }
        };



        //Modal for Gift Create and Ind Profile Household Members Search Results
        var individualSearchResultPopoverIdStore = 0;
        ko.bindingHandlers.IndividualSearchResultPopover = {
            init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                // First get the latest data that we're bound to
                var value = valueAccessor(), allBindings = allBindingsAccessor();

                // Next, whether or not the supplied model property is observable, get its current value
                var valueUnwrapped = ko.utils.unwrapObservable(value);

                //Set the ID of this instance
                var id = 'PopOverCloseButton' + individualSearchResultPopoverIdStore++;
                var url = ko.toJS(bindingContext.$root.profileMatchSearchUrl);

                //ajax setup
                var profile = {}, loaded = false;
                function getData() {
                    var data = {}; data.id = valueUnwrapped.Id;
                    v2Form.POST({
                        url: url,
                        data: data,
                        success: function (data) {
                            profile = data.Data;
                            loaded = true;
                        },
                        error: function (a, b, c, errorThrown) { profile.error = errorThrown; }
                    });
                }

                //html support
                var $element = $(element);

                //check to see if I havee been passed a full profile search document with a profile diplay object or the profile display object.
                var display;
                if (valueUnwrapped.Display) {
                    display = valueUnwrapped.Display;
                } else {
                    display = valueUnwrapped;
                }

                //values
                var loading = $("<div><strong>" + 'loading extra info...' + "</strong></div>");
                var closeBtn = $("<button id=" + id + ">").addClass('close').text('x');
                var titleName = $("<strong>" + display.Name + "</strong>");
                var popTitle = $("<div>");
                popTitle.append(titleName).append(closeBtn);
                var emails = [], phones = [], names = [], addresses = [], notes = [], html = '';

                //modal html
                var mWrap = $('<div class="modal fade">'),
                    mDialog = $('<div class="modal-dialog">'),
                    mContent = $('<div class="modal-content">'),
                    mHead = $('<div class="modal-header">'),
                    mBody = $('<div class="modal-body">'),
                    mClose = $('<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>'),
                    mTitle = $('<h3>Modal header</h3>'),
                    mFoot = $('<div class="modal-footer">'),
                    mFootClose = $('<a data-dismiss="modal" class="btn btn-default">Close</a>'),
                    mSelect = $('<a class="btn btn-primary">Select</a>');
                mDialog.append(mContent);
                mContent.append(mHead).append(mBody).append(mFoot);
                mWrap.append(mDialog);
                mFoot.append(mSelect).append(mFootClose);
                mHead.append(mClose).append(mTitle);
                mSelect.on('click', function (e) {
                    bindingContext.$parent.ProfileSelected(valueUnwrapped);
                    mWrap.modal('hide');
                });

                var timer = setInterval(function () {
                    if (loaded) {
                        clearInterval(timer);
                        console.log(profile);
                        console.log(addresses);

                        ko.utils.arrayForEach(profile.Names, function (name) {
                            var f = name.FirstName ? name.FirstName : null,
                                m = name.MiddleName ? name.MiddleName : null,
                                l = name.LastName ? name.LastName : null;
                            names.push((f ? f + ' ' : '') + (m ? m + ' ' : '') + (l ? l : ''));
                        });
                        ko.utils.arrayForEach(profile.Emails, function (email) {
                            emails.push(email.EmailAddress);
                        });
                        ko.utils.arrayForEach(profile.Phones, function (phone) {
                            phones.push(phone.Number);
                        });
                        ko.utils.arrayForEach(profile.Notes, function (note) {
                            notes.push('<p><strong>Note:</strong><br />' + note.Note + "</p>");
                        });
                        ko.utils.arrayForEach(profile.PotentialSites, function (site) {
                            if (site.SiteId == profile.SiteId) {
                                profile.SiteName = site.Name;
                                return;
                            }
                        });
                        ko.utils.arrayForEach(profile.Addresses, function (address) {
                            var a1 = address.Address1 ? address.Address1 : null,
                                a2 = address.Address2 ? address.Address2 : null,
                                c = address.City ? address.City : null,
                                s = address.Region ? address.Region : null,
                                z = address.PostalCode ? address.PostalCode : null;
                            var addressStr = (a1 ? a1 + ' ' : '') + (a2 ? a2 + ' ' : '') + '<br>' + (c ? c + ' ,' : '') + (s ? s + ' ' : '') + (z ? z : '');
                            addresses.push(addressStr);
                        });
                        if (profile.BirthDate) { html += ('Date of Birth: ' + moment(profile.BirthDate).format('MMM. DD, YYYY') + '<br>'); }
                        //if (profile.BirthDate) { html += ('Date of Birth: ' + moment(profile.BirthDate).format('MMM. DD, YYYY') + '<br>'); }
                        if (names.length) { html += '<h6>Names</h6>'; html += names.join('<br>'); }
                        if (emails.length) { html += '<h6>Emails</h6>'; html += emails.join('<br>'); }
                        if (phones.length) { html += '<h6>Phones</h6>'; html += phones.join('<br>'); }
                        if (addresses.length) { html += '<h6>Addresses</h6>'; html += addresses.join('<br>'); }
                        if (profile.SiteName) { html += '<h6>Site</h6>'; html += profile.SiteName + '<br>'; }
                        if (notes.length) { html += '<h6>Notes</h6>'; html += notes.join('<br>'); }

                        mBody.html(html);
                        mTitle.html(titleName.html());
                        $('body').append(mWrap);
                        mWrap.modal('show');
                    }
                }, 100);

                //register events
                $element
                    .on('click', function (e) {
                        e.preventDefault();
                        if (!loaded) {
                            getData();
                        } else {
                            $('body').append(mWrap);
                            mWrap.modal('show');
                        }
                    });
            }
        };

        //Modal for Gift Create Designation Search Results
        var DesignationSearchResultPopoverIdStore = 0;
        ko.bindingHandlers.DesignationSearchResultPopover = {
            init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                // First get the latest data that we're bound to
                var value = valueAccessor(), allBindings = allBindingsAccessor();

                // Next, whether or not the supplied model property is observable, get its current value
                var valueUnwrapped = ko.utils.unwrapObservable(value);

                //Set the ID of this instance
                var id = 'PopOverCloseButton' + DesignationSearchResultPopoverIdStore++;

                //html support
                var $element = $(element);

                //values
                var loading = $("<div><strong>" + 'loading extra info...' + "</strong></div>");
                var closeBtn = $("<button id=" + id + ">").addClass('close').text('x');
                var titleName = $("<strong>" + valueUnwrapped.Name + "</strong>");
                var popTitle = $("<div>");
                popTitle.append(titleName).append(closeBtn);
                var html = '';

                //modal html
                var mWrap = $('<div class="modal fade">'),
                    mDialog = $('<div class="modal-dialog">'),
                    mContent = $('<div class="modal-content">'),
                    mHead = $('<div class="modal-header">'),
                    mBody = $('<div class="modal-body">'),
                    mClose = $('<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>'),
                    mTitle = $('<h3>Modal header</h3>'),
                    mFoot = $('<div class="modal-footer">'),
                    mFootClose = $('<a data-dismiss="modal" class="btn btn-default">Close</a>'),
                    mSelect = $('<a class="btn btn-primary">Select</a>');
                mDialog.append(mContent);
                mContent.append(mHead).append(mBody).append(mFoot);
                mWrap.append(mDialog);
                mFoot.append(mSelect).append(mFootClose);
                mHead.append(mClose).append(mTitle);
                mSelect.on('click', function (e) {
                    bindingContext.$parent.FundableItem(valueUnwrapped);
                    mWrap.modal('hide');
                });


                if (valueUnwrapped.Name) { html += '<h6>Designation Name</h6>'; html += valueUnwrapped.Name; }
                if (valueUnwrapped.FundableId) { html += '<h6>Fundable Id</h6>'; html += valueUnwrapped.FundableId; }
                if (valueUnwrapped.AccountSegment) { html += '<h6>Account Segment</h6>'; html += valueUnwrapped.AccountSegment; }
                if (valueUnwrapped.DesignationCode) { html += '<h6>Designation Code</h6>'; html += valueUnwrapped.DesignationCode; }
                if (valueUnwrapped.ShortDescription) { html += '<h6>Short Description</h6>'; html += valueUnwrapped.ShortDescription; }
                if (valueUnwrapped.StartDate) { html += '<h6>Start Date</h6>'; html += valueUnwrapped.StartDate.substring(0, 10); }
                if (valueUnwrapped.EndDate) { html += '<h6>End Date</h6>'; html += valueUnwrapped.EndDate.substring(0, 10); }

                mBody.html(html);
                mTitle.html(titleName.html());
                $('body').append(mWrap);

                //register events
                $element
                    .on('click', function (e) {
                        e.preventDefault();
                        $('body').append(mWrap);
                        mWrap.modal('show');

                    });
            }
        };



        // Modal for Payment index "Details" link
        var FundableSearchResultPopoverIdStore = 0;
        ko.bindingHandlers.FundableSearchResultPopover = {
            init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                // First get the latest data that we're bound to
                var value = valueAccessor(), allBindings = allBindingsAccessor();
                var vm = bindingContext.$root;

                // Next, whether or not the supplied model property is observable, get its current value
                var valueUnwrapped = ko.utils.unwrapObservable(value);
                $(element).click(function (e) {
                    e.preventDefault();
                    if (vm.Payments.PaymentSearchCriteria.UseComplex()) {
                        //Set the ID of this instance
                        var id = 'PopOverCloseButton' + FundableSearchResultPopoverIdStore++;
                        var url = ko.toJS(bindingContext.$root.detailMatchSearchUrl);

                        //ajax setup
                        var profile = {}, loaded = false;

                        function getData() {
                            var data = { id: valueUnwrapped.Id };
                            v2Form.POST({
                                url: url,
                                data: data,
                                success: function (data) {
                                    profile = data;
                                    loaded = true;
                                },
                                error: function (a, b, c, errorThrown) { profile.error = errorThrown; }
                            });
                        }

                        //html support
                        var $element = $(element);


                        //values
                        var loading = $("<div><strong>" + 'loading extra info...' + "</strong></div>");
                        var closeBtn = $("<button id=" + id + ">").addClass('close').text('x');
                        var titleName = $("<strong>" + 'Payment Details' + "</strong>");
                        var popTitle = $("<div>");
                        popTitle.append(titleName).append(closeBtn);
                        var appliedTo = [], names = [], appliedAmount = [], commitmentType = [], html = '';

                        //modal html
                        var mWrap = $('<div class="modal fade">'),
                            mDialog = $('<div class="modal-dialog">'),
                            mContent = $('<div class="modal-content">'),
                            mHead = $('<div class="modal-header">'),
                            mBody = $('<div class="modal-body">'),
                            mClose = $('<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>'),
                            mTitle = $('<h3>Payment Details</h3>'),
                            mFoot = $('<div class="modal-footer">'),
                            mVoid = $('<a class="btn btn-danger">Void Payment <span class="icon-remove"></span></a> '),
                            mRefund = $('<a class="btn btn-danger">Refund Payment <span class="icon-remove"></span></a>'),
                            mFootClose = $('<a data-dismiss="modal" class="btn btn-default">Close</a>');
                        mDialog.append(mContent);
                        mContent.append(mHead).append(mBody).append(mFoot);
                        mWrap.append(mDialog);
                        mFoot.append(mFootClose);
                        mHead.append(mClose).append(mTitle);

                        mVoid.on('click', function (e) {
                            e.preventDefault();
                            bindingContext.$root.VoidPayment(valueUnwrapped.Id, mClose, viewModel);
                        });
                        mRefund.on('click', function (e) {
                            e.preventDefault();
                            bindingContext.$root.RefundPayment(valueUnwrapped.Id, mClose, viewModel);
                        });

                        var timer = setInterval(function () {
                            if (loaded) {
                                clearInterval(timer);
                                if (profile.Individual) {
                                    ko.utils.arrayForEach(profile.Individual.Names, function (name) {
                                        var f = name.FirstName ? name.FirstName : null,
                                            m = name.MiddleName ? name.MiddleName : null,
                                            l = name.LastName ? name.LastName : null;
                                        names.push((f ? f + ' ' : '') + (m ? m + ' ' : '') + (l ? l : ''));
                                    });
                                } else if (profile.Individual) {
                                    names.push(profile.Organization.Name);
                                } else {
                                    names.push("Anonymous");
                                }

                                //This will need updating when mutliple designations is set up
                                ko.utils.arrayForEach(profile.AppliedTo, function (applied) {
                                    commitmentType.push(enumerations.CommitmentLineItem[applied.Details[0].CommitmentLineItemType]);
                                    appliedAmount.push(applied.AppliedAmountDouble);
                                    ko.utils.arrayForEach(applied.Details, function (details) {
                                        appliedTo.push(details.Designation.FundableName);
                                    });
                                });

                                if (names.length) {
                                    html += '<strong>From: </strong>';
                                    html += names.join('<br>');
                                }
                                html += '<br><br><strong>Amount:</strong> ' + '$' + profile.AmountDouble.toFixed(2) + '<br>';
                                html += '<strong>Payment Method:</strong> ' + profile.PaymentMethodTypeName + '<br>';
                                html += '<strong>Payment Date:</strong> ' + profile.PaymentDate + '<br>';
                                html += '<strong>Payment Status:</strong> ' + enumerations.PaymentStatus[profile.PaymentStatusType] + '<br>';


                                html += '<h4>Designations</h4>';
                                html += '<strong>Applied Amount:</strong> ' + '$' + appliedAmount[0].toFixed(2) + '<br>';

                                if (commitmentType.length) {
                                    html += '<strong>Commitment Type: </strong>';
                                    html += commitmentType.join('<br>');
                                }

                                if (appliedTo.length) {
                                    html += '<br><strong>Applied To: </strong>';
                                    html += appliedTo.join('<br>');
                                }

                                if (profile.PaymentStatusType == 2) {
                                    if (profile.PaymentMethodTypeName == 'Cash' || profile.PaymentMethodTypeName == 'Check' || profile.PaymentMethodTypeName == 'Stock') {
                                        mFoot.prepend(mVoid);
                                    }
                                    mFoot.prepend(mRefund);
                                }

                                mBody.html(html);
                                mTitle.html(titleName.html());
                                $('body').append(mWrap);
                                mWrap.modal('show');
                            }
                        }, 100);

                        if (!loaded) {
                            getData();
                        } else {
                            $('body').append(mWrap);
                            mWrap.modal('show');
                        }

                    } else {
                        window.location = $(element).attr('href');
                    }
                });




            }
        };


        //Modal for Payment Index Individual link
        var individualPaymentSearchResultPopoverIdStore = 0;
        ko.bindingHandlers.IndividualPaymentSearchResultPopover = {
            init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                // First get the latest data that we're bound to
                var value = valueAccessor(), allBindings = allBindingsAccessor();
                var vm = bindingContext.$root;

                // Next, whether or not the supplied model property is observable, get its current value
                var valueUnwrapped = ko.utils.unwrapObservable(value);
                $(element).click(function (e) {
                    e.preventDefault();
                    if (vm.Payments.PaymentSearchCriteria.UseComplex()) {
                        //Set the ID of this instance
                        var id = 'PopOverCloseButton' + individualPaymentSearchResultPopoverIdStore++;

                        var url = ko.toJS(bindingContext.$root.profileMatchSearchUrl);



                        //ajax setup
                        var profile = {}, loaded = false;

                        function getData() {
                            var data = { id: valueUnwrapped.Individual.Id };
                            v2Form.POST({
                                url: url,
                                data: data,
                                success: function (data) {
                                    profile = data.Data;
                                    loaded = true;
                                },
                                error: function (a, b, c, errorThrown) { profile.error = errorThrown; }
                            });
                        }

                        //html support
                        var $element = $(element);


                        //values
                        var loading = $("<div><strong>" + 'loading extra info...' + "</strong></div>");
                        var closeBtn = $("<button id=" + id + ">").addClass('close').text('x');
                        var titleName = $("<strong>Profile Details</strong>");
                        var popTitle = $("<div>");
                        popTitle.append(titleName).append(closeBtn);
                        var addresses = [], names = [], emails = [], html = '';

                        //modal html
                        var mWrap = $('<div class="modal fade">'),
                            mDialog = $('<div class="modal-dialog">'),
                            mContent = $('<div class="modal-content">'),
                            mHead = $('<div class="modal-header">'),
                            mBody = $('<div class="modal-body">'),
                            mClose = $('<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>'),
                            mTitle = $('<h3>Payment Details</h3>'),
                            mFoot = $('<div class="modal-footer">'),
                            mVoid = $('<a class="btn btn-danger">Void Payment <span class="icon-remove"></span></a> '),
                            mRefund = $('<a class="btn btn-danger">Refund Payment <span class="icon-remove"></span></a>'),
                            mFootClose = $('<a data-dismiss="modal" class="btn btn-default">Close</a>');
                        mDialog.append(mContent);
                        mContent.append(mHead).append(mBody).append(mFoot);
                        mWrap.append(mDialog);
                        mFoot.append(mFootClose);
                        mHead.append(mClose).append(mTitle);


                        mVoid.on('click', function (e) {
                            e.preventDefault();
                            bindingContext.$root.VoidPayment(valueUnwrapped.Id, mClose, valueAccessor);
                        });
                        mRefund.on('click', function (e) {
                            e.preventDefault();
                            bindingContext.$root.RefundPayment(valueUnwrapped.Id, mClose, valueAccessor);
                        });

                        var timer = setInterval(function () {
                            if (loaded) {
                                clearInterval(timer);
                                console.log(profile);

                                ko.utils.arrayForEach(profile.Names, function (name) {
                                    var f = name.FirstName ? name.FirstName : null,
                                        m = name.MiddleName ? name.MiddleName : null,
                                        l = name.LastName ? name.LastName : null;
                                    names.push((f ? f + ' ' : '') + (m ? m + ' ' : '') + (l ? l : ''));
                                });
                                ko.utils.arrayForEach(profile.Addresses, function (address) {
                                    var a = address.Address1 ? address.Address1 : null,
                                        b = address.Address2 ? address.Address2 : null,
                                        c = address.City ? address.City : null,
                                        d = address.Region ? address.Region : null,
                                        e = address.PostalCode ? address.PostalCode : null;
                                    addresses.push((a ? a + ' ' : '') + (b ? b + ' ' : '') + (c ? c + " " : '') + (d ? d + " " : '') + (e ? e : ''));
                                });
                                ko.utils.arrayForEach(profile.Emails, function (email) {
                                    var e = email.EmailAddress ? email.EmailAddress : null;
                                    emails.push(e);
                                });
                                ko.utils.arrayForEach(profile.PotentialSites, function (site) {
                                    if (site.SiteId == profile.SiteId) {
                                        profile.SiteName = site.Name;
                                        return;
                                    }
                                });

                                if (names.length) {
                                    html += '<strong>Names: </strong><br>';
                                    html += names.join('<br>') + "<br><br>";
                                }
                                if (addresses.length) {
                                    html += '<strong>Address: </strong><br>';
                                    html += addresses.join('<br>') + '<br><br>';
                                }
                                if (emails.length) {
                                    html += '<strong>Email:</strong><br> ' + emails.join('<br>') + '<br><br>';
                                }
                                if (profile.SiteName) { html += '<strong>Site:</strong><br>'; html += profile.SiteName + '<br>'; }

                                mBody.html(html);
                                mTitle.html(titleName.html());
                                $('body').append(mWrap);
                                mWrap.modal('show');
                            }
                        }, 100);

                        if (!loaded) {
                            getData();
                        } else {
                            $('body').append(mWrap);
                            mWrap.modal('show');
                        }

                    } else {
                        window.location = $(element).attr('href');
                    }
                });

            }
        };

        //Modal for Payment Index Organization link
        var orgPaymentSearchResultPopoverIdStore = 0;
        ko.bindingHandlers.orgPaymentSearchResultPopover = {
            init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                // First get the latest data that we're bound to
                var value = valueAccessor(), allBindings = allBindingsAccessor();
                var vm = bindingContext.$root;

                // Next, whether or not the supplied model property is observable, get its current value
                var valueUnwrapped = ko.utils.unwrapObservable(value);
                $(element).click(function (e) {
                    e.preventDefault();
                    if (vm.Payments.PaymentSearchCriteria.UseComplex()) {
                        //Set the ID of this instance
                        var id = 'PopOverCloseButton' + orgPaymentSearchResultPopoverIdStore++;
                        var url = ko.toJS(bindingContext.$root.orgMatchSearchUrl);


                        //ajax setup
                        var profile = {}, loaded = false;

                        function getData() {
                            var data = { id: valueUnwrapped.Organization.Id };
                            v2Form.POST({
                                url: url,
                                data: data,
                                success: function (data) {
                                    profile = data.Data;
                                    loaded = true;
                                },
                                error: function (a, b, c, errorThrown) { profile.error = errorThrown; }
                            });
                        }

                        //html support
                        var $element = $(element);


                        //values
                        var loading = $("<div><strong>" + 'loading extra info...' + "</strong></div>");
                        var closeBtn = $("<button id=" + id + ">").addClass('close').text('x');
                        var titleName = $("<strong>" + 'Organization Details' + "</strong>");
                        var popTitle = $("<div>");
                        popTitle.append(titleName).append(closeBtn);
                        var appliedTo = [], names = [], appliedAmount = [], commitmentType = [], html = '';

                        //modal html
                        var mWrap = $('<div class="modal fade">'),
                            mDialog = $('<div class="modal-dialog">'),
                            mContent = $('<div class="modal-content">'),
                            mHead = $('<div class="modal-header">'),
                            mBody = $('<div class="modal-body">'),
                            mClose = $('<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>'),
                            mTitle = $('<h3>Payment Details</h3>'),
                            mFoot = $('<div class="modal-footer">'),
                            mVoid = $('<a class="btn btn-danger">Void Payment <span class="icon-remove"></span></a> '),
                            mRefund = $('<a class="btn btn-danger">Refund Payment <span class="icon-remove"></span></a>'),
                            mFootClose = $('<a data-dismiss="modal" class="btn btn-default">Close</a>');
                        mDialog.append(mContent);
                        mContent.append(mHead).append(mBody).append(mFoot);
                        mWrap.append(mDialog);
                        mFoot.append(mFootClose);
                        mHead.append(mClose).append(mTitle);


                        mVoid.on('click', function (e) {
                            e.preventDefault();
                            console.log(mClose);
                            bindingContext.$root.VoidPayment(valueUnwrapped.Id, mClose, valueAccessor);
                        });
                        mRefund.on('click', function (e) {
                            e.preventDefault();
                            console.log(mClose);
                            bindingContext.$root.RefundPayment(valueUnwrapped.Id, mClose, valueAccessor);
                        });

                        var timer = setInterval(function () {
                            if (loaded) {
                                clearInterval(timer);
                                console.log(profile);

                                html += '<strong>Name: </strong><br>' + profile.Name + '<br><br>';
                                html += '<strong>Address: </strong><br>' + profile.Address1 + " " + profile.City + " " + profile.Region + " " + profile.PostalCode;

                                mBody.html(html);
                                mTitle.html(titleName.html());
                                $('body').append(mWrap);
                                mWrap.modal('show');
                            }
                        }, 100);

                        if (!loaded) {
                            getData();
                        } else {
                            $('body').append(mWrap);
                            mWrap.modal('show');
                        }

                    } else {
                        window.location = $(element).attr('href');
                    }
                });

            }
        };




        ko.bindingHandlers.indexforattribute = {
            init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
                $(element).attr(valueAccessor().attr, valueAccessor().key.replace('$index', valueAccessor().index));
                if (valueAccessor().attr === 'id')
                    $(element).attr('name', valueAccessor().key.replace('$index', valueAccessor().index));
            },
            update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
                $(element).attr(valueAccessor().attr, valueAccessor().key.replace('$index', valueAccessor().index));
                if (valueAccessor().attr === 'id')
                    $(element).attr('name', valueAccessor().key.replace('$index', valueAccessor().index));
            }
        };


        var updateCount = 0;
        ko.bindingHandlers.colorPicker = {
            init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                // This will be called when the binding is first applied to an element
                // Set up any initial state, event handlers, etc. here
                var $element = $(element),
                    $input = $('<input class="colorPickerUserInput">');

                //Color picker documentation:
                //http://bgrins.github.io/spectrum/
                $element.spectrum({
                    color: ko.unwrap(valueAccessor()),
                    change: handlePickerChange
                });

                //inject a text element for user input after the color picker in the dom
                $element.after($input);

                $input.on('change', function (e) {
                    e.stopPropagation();
                    valueAccessor()($input.val());
                });

                //todo can we make this a shared function instead of making a new copy each time we invoke the colorpicker
                function handlePickerChange(color) {
                    valueAccessor()(color.toHexString());
                };
            },
            update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
                // This will be called once when the binding is first applied to an element,
                // and again whenever any observables/computeds that are accessed change
                // Update the DOM element based on the supplied values here.
                var $element = $(element),
                    valueUnwrapped = ko.unwrap(valueAccessor()),
                    $input = $element.next('.colorPickerUserInput');

                //subscribe the valueaccessor to update the input
                $input.val(valueUnwrapped);

                //subscribe the valueaccessor to update the color picker
                $element.spectrum('set', valueUnwrapped);
            }



        };


        ko.bindingHandlers.DesignationLink = {
            init: function (element, valueAccessor, a, b) {
                var value = valueAccessor(), valueUnwrapped = ko.utils.unwrapObservable(value.FundableId),
                    type = ko.utils.unwrapObservable(value.FundableReferenceType);

                var url;
                switch (type) {
                    case 1:
                        url = '/Organization/Admin/Details/' + valueUnwrapped;
                        break;
                    case 2:
                        url = '/Project/Admin/Details/' + valueUnwrapped;
                        break;
                    case 3:
                        url = '/Marketing/Campaign/Details/' + valueUnwrapped;
                        break;
                    case 4:
                        url = '/Finance/OrganizationFund/Details/' + valueUnwrapped;
                        break;
                    case 6:
                        url = '/Project/MissionTrip/Details/' + valueUnwrapped;
                        break;
                    case 7:
                        url = '/Finance/GeneralDesignation/Details/' + valueUnwrapped;
                        break;
                    default:
                    //console.error('invalid information for detail link');
                };
                if (valueUnwrapped > 0)
                    $(element).attr('href', url || '#');
            },
            update: function (element, valueAccessor, a, b) {
                var value = valueAccessor(), valueUnwrapped = ko.utils.unwrapObservable(value.FundableId),
                    type = ko.utils.unwrapObservable(value.FundableReferenceType);

                var url;
                switch (type) {
                    case 1:
                        url = '/Organization/Admin/Details/' + valueUnwrapped;
                        break;
                    case 2:
                        url = '/Project/Admin/Details/' + valueUnwrapped;
                        break;
                    case 3:
                        url = '/Marketing/Campaign/Details/' + valueUnwrapped;
                        break;
                    case 4:
                        url = '/Finance/OrganizationFund/Details/' + valueUnwrapped;
                        break;
                    case 6:
                        url = '/Project/MissionTrip/Details/' + valueUnwrapped;
                        break;
                    case 7:
                        url = '/Finance/GeneralDesignation/Details/' + valueUnwrapped;
                        break;
                    default:
                    //console.error('invalid information for detail link');
                };
                if (valueUnwrapped > 0)
                    $(element).attr('href', url || '#');
            }
        };


        ko.bindingHandlers.DesignationAmountAndScheduleSummary = {
            init: function (element, valueAccessor, allBindingsAccessor) {
                var value = ko.toJS(valueAccessor());
                var schedule = value.SimpleSchedule || value.Schedule;
                var localStartDate = moment.utc(schedule.StartDate).local();
                //console.log('here',localStartDate.format('LLL'), schedule.StartDate);
                var formattedStartDate = moment.utc(schedule.StartDate).local().format('LL');
                var name = "";
                if (value.Designation) {
                    name = value.Designation.To.FundableName;
                }

                if (value.Name) {
                    name = value.Name;
                }

                if (value.FundableName) {
                    name = value.FundableName;
                }


                var amount = ko.utils.unwrapObservable(value.Amount) || ko.utils.unwrapObservable(value.AmountDouble) || ko.utils.unwrapObservable(value.GiftAmount);
                console.log('value', ko.toJS(value), amount);
                var summary = currency.US.display(amount) + ' to ' + name;
                if (schedule.IsScheduled) {
                    var freq = schedule.Frequency;
                    switch (freq) {
                        case 0:
                            summary = summary + ' monthly starting on ' + formattedStartDate;
                            break;
                        case 1:
                            summary = summary + ' every ' + localStartDate.format('ddd') + ' starting on ' + formattedStartDate;
                            break;
                        case 2:
                            summary = summary + ' every other ' + localStartDate.format('ddd') + ' start on ' + formattedStartDate;
                            break;
                        case 3:
                            summary = summary + ' on a yearly basis starting on ' + formattedStartDate;
                            break;
                        case 4:
                            summary = summary + ' on a quarterly basis starting on ' + formattedStartDate;
                            break;
                        case 5:
                            summary = summary + ' once on ' + formattedStartDate;
                            break;
                        case 6:
                            summary = summary + ' every first and sixteenth starting on ' + formattedStartDate;
                            break;
                    }

                    if (schedule.EndDate && schedule.EndDate != null) {
                        summary = summary + ' ending on ' + moment.utc(schedule.EndDate).local().format('MM/DD/YYYY');
                    }



                } else {

                    summary = currency.US.display(amount) + ' to ' + name;
                }
                $(element).text(summary);
            }
        };



        ko.bindingHandlers.fileUpload = {
            init: function (element, valueAccessor) {
                $(element).change(function () {
                    valueAccessor()(element.files[0]);
                });
            },
            update: function (element, valueAccessor) {
                if (ko.unwrap(valueAccessor()) === null) {
                    $(element).wrap('<form>').closest('form').get(0).reset();
                    $(element).unwrap();
                }
            }
        };



ko.bindingHandlers.deleteconfirm = {
    init: function (element, valueAccessor) {
        var value = valueAccessor();
        var message = "Are you sure you want to delete this?";
        
        if (value.message)
            message = value.message;

        var fullUrl = value.url + ko.utils.unwrapObservable(value.Id);

        function confirm() {
          
        }


        $(element).click(function () {
            import("./notify.js").then((notify) => {
                notify.confirm(message).then((deleteOk) => {
                    if (deleteOk)
                        confirm();
                });
            });
        });
           
    }


}



