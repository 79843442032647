/* ========================================================
* knockout.custombindings.js 
* ========================================================
* Copyright 2012 Vision2Systems.
*  */

(function () {

    function viewModel(opts) {
        var EventsModel = function () {
            var self = this;
            
            this.End = null;
            this.startDate = ko.observable(moment(new Date()).toDate());
            this.startTime = ko.observable(moment(new Date()).format('hh:mm A'));
            this.Start = ko.computed(function () {
                var date = moment(self.startDate()).format("YYYY-MM-DD"),
                    time = self.startTime().format('HH:mm:ss z'),
                    converted = moment(date + 'T' + time);
                //console.log(converted.format('YYYY-MM-DDTHH:mm:ss Z'));
                return converted.format('YYYY-MM-DDTHH:mm:ss Z');
            }, this);
            this.dayNameOfTheMonth = ko.computed(function () {
                //console.log(getHumanizedInstanceOfDay(getInstanceOfDay(self.startDate())) + ' ' + moment(self.startDate()).format('ddd'));
                return getHumanizedInstanceOfDay(getInstanceOfDay(self.startDate())) + ' ' + moment(self.startDate()).format('ddd');
            }, this);
            this.UID = null;
            this.Ocurrs = 0;
            this.RecurrenceRules = [];
            this.Location = null;
            this.Class = null;
            this.Categories = null;
            this.Description = null;
            this.EventContextId = null;
            this.EventContext = null;
        };

        var RecurrenceRuleModel = function () {
            this.endPattern = null;
            this.FrequencyType = null;
            this.Count = null;  //occurances
            this.Interval = null;
            this.untilDate = ko.observable(moment(new Date()).toDate());
            this.untilTime = ko.observable(moment(new Date()).format('hh:mm A'));
            this.Until = ko.computed(function () {
                var date = moment(this.untilDate()).format("YYYY-MM-DD"),
                    time = this.untilTime().format('HH:mm:ss z'),
                    converted = moment(date + 'T' + time);
                //console.log(converted.format('YYYY-MM-DDTHH:mm:ss Z'));
                return converted.format('YYYY-MM-DDTHH:mm:ss Z');
            }, this);
            this.DaysOfWeek = []; //days in week pattern
            this.WeekStart = 1; //always monday on client
            this.DaysOfYear = null;
            this.DaysOfMonth = null;
        };

        var FrequencyTypeCodes = [
            { key: 1, display: "Yearly" },
            { key: 2, display: "Monthly" },
            { key: 3, display: "Weekly" },
            { key: 4, display: "Daily" }
        ];

        var DaysOfWeek = [
            { key: 0, display: 'Sunday', small: 'SU', abbr: 'Sun' },
            { key: 1, display: 'Monday', small: 'MO', abbr: 'Mon' },
            { key: 2, display: 'Tuesday', small: 'TU', abbr: 'Tue' },
            { key: 3, display: 'Wednesday', small: 'WE', abbr: 'Wed' },
            { key: 4, display: 'Thursday', small: 'TH', abbr: 'Thu' },
            { key: 5, display: 'Friday', small: 'FR', abbr: 'Fri' },
            { key: 6, display: 'Saturday', small: 'SA', abbr: 'Sat' }
        ];

        var newModel = new EventsModel();
        newModel.RecurrenceRules.push(new RecurrenceRuleModel());
        newModel.frequencyTypeCodes = FrequencyTypeCodes;
        newModel.daysOfWeek = DaysOfWeek;
        return { Calendar: { Events: [newModel]} };
    }


    //todo: rewrite to new model
    function toICalendar(viewModel) {

        //first create an iCal model if we dont have one
        var iCal = {};
        iCal.EVENTS = [];

        //return null value if there is no recurrence
        if (viewModel.occurs === 'once') { return null; }

        //figure out the selected pattern
        var pattern = viewModel.occurancePattern.occurancePatternSelected;

        if (pattern === 'weekly') {
            //declare new event
            var event = {};
            event.RRULE = {};
            event.RRULE.WKST = "MO";

            //set properties
            event.DTSTART = viewModel.startDate;
            event.RRULE.FREQ = pattern.toUpperCase();

            //figure out which specific days and set the property
            var days = viewModel.occurancePattern.weekly.days;
            var dayStr = [];
            for (var i = 0, ii = days.length; i < ii; i++) {
                dayStr.push(abbrevDayName(days[i]));
            }
            event.RRULE.BYDAY = dayStr.join(',');

            //figure out the end of occurance pattern and set it
            var ends = viewModel.ends;
            if (ends.pattern == 'after') {
                event.RRULE.COUNT = ends.after;
            }
            if (ends.pattern == 'on') {
                event.RRULE.UNTIL = ends.date;
            }

            //push the event into the list
            iCal.EVENTS.push(event);
        }
        else if (pattern === 'monthly') {
            //declare new event
            var event = {};
            event.RRULE = {};
            event.RRULE.WKST = "MO";

            //set properties
            event.DTSTART = viewModel.startDate;
            event.RRULE.FREQ = pattern.toUpperCase();

            //figure out which specific days and set the property
            if (viewModel.occurancePattern.monthly.recurrDay === 'daynumber') {
                event.RRULE.BYMONTHDAY = moment(viewModel.startDate).format('DD');
            }
            else if (viewModel.occurancePattern.monthly.recurrDay === 'dayname') {
                var num = getInstanceOfDay(viewModel.startDate);
                num = num === 5 ? -1 : num;
                var abbrev = abbrevDayName(moment(viewModel.startDate).format('dddd'));
                event.RRULE.BYDAY = num + abbrev;
            }

            //figure out the end of occurance pattern and set it
            var ends = viewModel.ends;
            if (ends.pattern == 'after') {
                event.RRULE.COUNT = ends.after;
            }
            if (ends.pattern == 'on') {
                event.RRULE.UNTIL = ends.date;
            }

            //push the event into the list
            iCal.EVENTS.push(event);
        }
        else if (pattern === 'specifically') {
            var dates = viewModel.occurancePattern.specifically.dates;
            for (var i = 0, ii = dates.length; i < ii; i++) {
                var event = {};
                event.RRULE = {};
                event.RRULE.WKST = "MO";
                //set properties
                event.DTSTART = dates[i];

                //figure out the end of occurance pattern and set it
                var ends = viewModel.ends;
                if (ends.pattern == 'after') {
                    event.RRULE.COUNT = ends.after;
                }
                if (ends.pattern == 'on') {
                    event.RRULE.UNTIL = ends.date;
                }

                iCal.EVENTS.push(event);
            }
        }
        else { //daily weekdays yearly
            var event = {};
            event.RRULE = {};
            event.RRULE.WKST = "MO";
            event.RRULE.FREQ = pattern.toUpperCase();
            //set properties
            event.DTSTART = viewModel.startDate;

            //figure out the end of occurance pattern and set it
            var ends = viewModel.ends;
            if (ends.pattern == 'after') {
                event.RRULE.COUNT = ends.after;
            }
            if (ends.pattern == 'on') {
                event.RRULE.UNTIL = ends.date;
            }

            iCal.EVENTS.push(event);
        }

        return iCal;
    }


    function getInstanceOfDay(d) {
        var _date = moment(new Date(d)),
                dayOfMonth = parseInt(_date.format('D')),
                dayOfWeek = parseInt(_date.format('d')),
                working = moment(new Date(d).setDate(1)),
                instance = 0;

        for (var i = parseInt(working.format('D')); i <= dayOfMonth; i++) {
            var _day = parseInt(working.format('d'));
            if (_day === dayOfWeek) instance++;
            working.add(1, 'days');
        }

        return instance;
    }

    function getHumanizedInstanceOfDay(n) {
        if (n === 1) return '1st';
        else if (n === 2) return '2nd';
        else if (n === 3) return '3rd';
        else if (n === 4) return '4th';
        else return 'last';
    }

    function abbrevDayName(d) {
        d = d.toLowerCase();
        if (d === 'Monday'.toLowerCase()) return "MO";
        if (d === 'Tuesday'.toLowerCase()) return "TU";
        if (d === 'Wednesday'.toLowerCase()) return "WE";
        if (d === 'Thursday'.toLowerCase()) return "TH";
        if (d === 'Friday'.toLowerCase()) return "FR";
        if (d === 'Saturday'.toLowerCase()) return "SA";
        if (d === 'Sunday'.toLowerCase()) return "SU";
    }


    window.scheduler = {
        ViewModel: viewModel,
        ToICalendar: toICalendar
    };
})();


