var template = require('./catalogitem-display.html');


function viewModel(params) {

    var self = this;

    //console.log(params);
    self.Id = params.Id;
    self.Name = ko.observable('*');
    self.PhotoId = ko.observable(0);
    self.Description = ko.observable();

    self.IsFixedAmount =  ko.observable();
    self.MinimumAmount =  ko.observable();
    self.MaximumAmount =  ko.observable();
    self.QuidQuoProValue = ko.observable();
    self.IsQuidProQuo = ko.observable();
    self.FirstCategory = ko.observable();
    self.SecondCategory = ko.observable();
    self.FirstCategoryOptionId = params.FirstCategoryOptionId || ko.observable();
    self.SecondCategoryOptionId = params.SecondCategoryOptionId || ko.observable();
    self.IsOptionsRequired = ko.observable(false);
    self.FirstOptions = ko.observableArray();
    self.SecondOptions = ko.observableArray();
    self.FirstOptionPhotoId = ko.computed(function () {
        var first = ko.utils.unwrapObservable(self.FirstCategoryOptionId);
        if (self.IsOptionsRequired()) {
            var s = ko.utils.arrayFirst(ko.utils.unwrapObservable(self.FirstOptions),
           function (item) {
               if (ko.utils.unwrapObservable(item.Id) === first)
                   return item;
           });

            if (s)
                return ko.utils.unwrapObservable(s.PhotoId);
        }


        return 0;
    });

    self.SecondOptionPhotoId = ko.computed(function () {
        var second = ko.utils.unwrapObservable(self.SecondCategoryOptionId);
        if (self.IsOptionsRequired()) {
            var s = ko.utils.arrayFirst(ko.utils.unwrapObservable(self.SecondOptions),
                function (item) {
                    if (ko.utils.unwrapObservable(item.Id) === second)
                        return item;
                });

            if (s)
                return ko.utils.unwrapObservable(s.PhotoId);
        }
        return 0;
    });

    self.FirstOptionDisplay = ko.computed(function () {
        var first = ko.utils.unwrapObservable(self.FirstCategoryOptionId)
        if (self.IsOptionsRequired()) {
            var s = ko.utils.arrayFirst(ko.utils.unwrapObservable(self.FirstOptions),
                function (item) {
                    if (ko.utils.unwrapObservable(item.Id) === first)
                        return item;
                });

            if (s)
                return ko.utils.unwrapObservable(s.Name);
        }
        return null;
    });

    self.SecondOptionDisplay = ko.computed(function () {
        var second = ko.utils.unwrapObservable(self.SecondCategoryOptionId);
        if (self.IsOptionsRequired()) {
            var s = ko.utils.arrayFirst(ko.utils.unwrapObservable(self.SecondOptions),
                function (item) {
                    if (ko.utils.unwrapObservable(item.Id) === second)
                        return item;
                });

            if (s)
                return ko.utils.unwrapObservable(s.Name);
        }
        return null;
    });

    self.CatalogItem = ko.observable();

    self.CatalogItem.subscribe(function (x) {
        self.Id(ko.utils.unwrapObservable(x.Id));
        self.Name(ko.utils.unwrapObservable(x.Name));
        self.PhotoId(ko.utils.unwrapObservable(x.PhotoId));
        self.Description(ko.utils.unwrapObservable(x.Description));
        self.IsFixedAmount(ko.utils.unwrapObservable(x.IsFixedAmount));
        self.MinimumAmount(ko.utils.unwrapObservable(x.MinimumAmount));
        self.MaximumAmount(ko.utils.unwrapObservable(x.MaximumAmount));
        self.QuidQuoProValue(ko.utils.unwrapObservable(x.QuidQuoProValue));
        self.IsOptionsRequired(ko.utils.unwrapObservable(x.IsOptionsRequired));
        self.IsQuidProQuo(ko.utils.unwrapObservable(x.IsQuidProQuo));
        self.FirstCategory(ko.utils.unwrapObservable(x.FirstOptionCategory));
        self.SecondCategory(ko.utils.unwrapObservable(x.SecondOptionCategory));
        if (x.FirstOptions) {
            ko.utils.arrayForEach(x.FirstOptions, function (i) {
                self.FirstOptions.push(i);
            });
        }

        if (x.SecondOptions) {
            ko.utils.arrayForEach(x.SecondOptions, function (i) {
                self.SecondOptions.push(i);
            });
        }
        
        
    });

  
    self.GetItemForDisplay = function () {

        v2Form.POST({
            url: '/Catalog/Item/GetItemDisplay',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: { Id: self.Id() },
            success: function (data, textStatus, jqXhr) {
                self.CatalogItem(data.Data);
            },
            error: function (data, textStatus, jqXhr) {
                console.log('error');
                console.log(data);
            }
        });
    }

    self.GetItemForDisplay();

    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};

