var template = require('./restrictiontype-picker.html');



function restrictionDetail() {
    this.Id = ko.observable();
    this.Name = ko.observable();
}

function viewModel(params) {
    var self = this;
    self.RestrictionTypeId = params.Id;
    self.RestrictionTypes = ko.observableArray();

    $.ajax({
        url: '/Finance/RestrictionType/GetAllRestrictions',
        type: 'POST',
        contentType: 'application/json',
        dataType: 'json',
        data: {},
        success: function (data, textStatus, jqXhr) {
            ko.mapping.fromJS(data.Data.PageData, {}, self.RestrictionTypes);
            var item = new restrictionDetail();
            item.Name('None');
            self.RestrictionTypes.unshift(item);
            self.RestrictionTypeId(null);
        },
        error: function (data, textStatus, jqXhr) {
            console.log('error');
            console.log(data);
        }
    });

    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};

