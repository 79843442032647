var template = require('./package-select.html');


function viewModel(params) {

    var self = this;

   // console.log('here package select');
    self.Id = params.Id;
    self.DisplayName = ko.observable('*');
    self.PhotoId = ko.observable(0);
    self.Description = ko.observable();
    self.PackageType = ko.observable(ko.utils.unwrapObservable(params.PackageType));
    

    

    //used to support packages which need the amount from the selected item
    //if (!params.Item)
    //    params.Item = {};
    
    self.placeHolderItem = ko.observable(params.placeHolder || 'Search for Package');
    
    self.IsFixedAmount = ko.observable();
    self.MinimumAmount = ko.observable();
    self.MaximumAmount =  ko.observable();
    self.QuidQuoProValue = ko.observable();
    self.Items = ko.observableArray();

    self.SearchCriteria = new PaginationServices.PackageSearchModel;
    self.SearchCriteria.PageIndex(0);
    self.SearchCriteria.PageSize(15);
    self.SearchCriteria.PackageType(ko.utils.unwrapObservable(params.PackageType));
    if (params.FundableId)
        self.SearchCriteria.FundableId = params.FundableId;
    if (params.IsActive)
        self.SearchCriteria.IsActive = params.IsActive;
    self.Individual = {
        Id: ko.observable()
    };

    self.Package = ko.observable();
  
    self.Package.subscribe(function (x) {
        //console.log('package subscribe', x);
        //NOTE: don't subscribe package type
        //console.log('package', x);
        if (x == null)
            return;
        self.Id(ko.utils.unwrapObservable(x.Id));
        self.DisplayName(ko.utils.unwrapObservable(x.Name));
        self.PhotoId(ko.utils.unwrapObservable(x.PhotoId));
        self.Description(ko.utils.unwrapObservable(x.Description));
        self.IsFixedAmount(ko.utils.unwrapObservable(x.IsFixedAmount));
        self.Items.removeAll();
        var amt = 0;
        ko.utils.arrayForEach(x.Items, function (i) {
            amt = amt = i.MinimumAmount * i.Quantity;
            var catalogItem = {};
            ko.mapping.fromJS(i, {}, catalogItem);
            self.Items.push(catalogItem);
        });
        self.MinimumAmount(amt);
         amt = 0;
        ko.utils.arrayForEach(x.Items, function (i) {
            amt = amt = i.MaximumAmount * i.Quantity;
        });
        self.MaximumAmount(amt);
        amt = 0;
        ko.utils.arrayForEach(x.Items, function (i) {
            amt = amt = i.QuidProQuoValue * i.Quantity;
        });
        //console.log('self', x, ko.toJS(x));
        self.QuidQuoProValue(amt);
        //self.Items(x.Items);
      
        //console.log('here is the package', self.PackageType() === 1, x.Items != null);

        if (ko.utils.unwrapObservable(x.PackageType) === 1 && x.Items != null) {
            //console.log(x.Items[0].Individual);
            ko.mapping.fromJS(x.Items[0].Individual, {}, self.Individual);
        }
        //has to occur after the edit
        self.PackageType(ko.utils.unwrapObservable(x.PackageType));

        //console.log('package complete', ko.toJS(self));
        //self.MinimumAmount(ko.utils.unwrapObservable(x.MinimumAmount));
        //self.MaximumAmount(ko.utils.unwrapObservable(x.MaximumAmount));
        //self.QuidQuoProValue(ko.utils.unwrapObservable(x.QuidQuoProValue));
        //self.IsQuidProQuo(ko.utils.unwrapObservable(x.IsQuidProQuo));
        if (params.Package) {
            params.Package(self);
        }
    });

    self.GetPackages = function (options) {
        if (options.text == "") return;

        self.SearchCriteria.SearchTerm(options.text);
        self.SearchCriteria.PageIndex(options.page);

        v2Form.POST({
            url: '/Catalog/Package/Search',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: self.SearchCriteria,
            success: function (data, textStatus, jqXhr) {
               options.callback(data.Data);
            },
            error: function (data, textStatus, jqXhr) {
                console.log('error');
                console.log(data);
            }
        });
    };


    self.GetPackageDisplay = function() {
        v2Form.POST({
            url: '/Catalog/Package/GetPackageDisplay',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: { Id: self.Id() },
            success: function (data, textStatus, jqXhr) {
               // console.log('result of get package display', data);
                if(data.Data !== null)
                    self.Package(data.Data);
            },
            error: function (data, textStatus, jqXhr) {
                console.log('error');
                console.log(data);
            }
        });
    }

    self.clearSelectedItem = function() {
        self.Id(null);
    }

    if (params.Id() > 0)
        self.GetPackageDisplay();
   

    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};

