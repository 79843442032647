/* File Created: July 26, 2012 */


ko.utils.stringStartsWith = function (string, startsWith) {
    string = string || "";
    if (startsWith.length > string.length) return false;
    return string.substring(0, startsWith.length) === startsWith;
};


ko.utils.stringStartsWith = function (string, startsWith) {
    string = string || "";
    if (startsWith.length > string.length) return false;
    return string.substring(0, startsWith.length) === startsWith;
};


ko.bindingHandlers.bindElement = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var value = valueAccessor();
        value(element);
    }
};


ko.bindingHandlers.currency = {
    'init': function (element, valueAccessor, allBindingsAccessor) {
        var eventName = allBindingsAccessor()["valueUpdate"] || "change";
        var formatter = ko.utils.unwrapObservable(allBindingsAccessor().formatter);
        var handleEventAsynchronously = false;
        if ((ko.utils.Xa || ko.utils.stringStartsWith)(eventName, "after")) {
            handleEventAsynchronously = true;
            eventName = eventName.substring("after".length);
        }
        var runEventHandler = handleEventAsynchronously ?
        function (handler) {
            setTimeout(handler, 0);
        } : function (handler) {
            handler();
        };

        ko.utils.registerEventHandler(element, eventName, function () {
            runEventHandler(function () {
                var modelValue = valueAccessor();
                var elementValue = ko.selectExtensions.readValue(element);
                if (formatter) elementValue = formatter.value(elementValue.toString());
                if (!formatter) console.log("formatter not found");

                if (ko.isWriteableObservable(modelValue)) {
                    modelValue(elementValue);
                } else {
                    var allBindings = allBindingsAccessor();
                    if (allBindings['_ko_property_writers'] && allBindings['_ko_property_writers']['value']) allBindings['_ko_property_writers']['value'](elementValue);
                }
            });
        });
    },
    'update': function (element, valueAccessor, allBindingsAccessor) {
        var newValue = ko.utils.unwrapObservable(valueAccessor());
        var elementValue = ko.selectExtensions.readValue(element);
        var valueHasChanged = (newValue !== elementValue);
        var formatter = ko.utils.unwrapObservable(allBindingsAccessor().formatter);
        if ((newValue === 0) && (elementValue !== 0) && (elementValue !== "0")) valueHasChanged = true;

        if (valueHasChanged) {
            if (formatter) newValue = formatter.display(newValue);
            if (!formatter) console.log("formatter not found");
            var applyValueAction = function () {
                ko.selectExtensions.writeValue(element, newValue);
            };
            applyValueAction();

            var alsoApplyAsynchronously = element.tagName == "SELECT";
            if (alsoApplyAsynchronously) setTimeout(applyValueAction, 0);
        }


        if (element.tagName == "SELECT") {
            elementValue = ko.selectExtensions.readValue(element);
            if (elementValue !== newValue) ko.utils.triggerEvent(element, "change");
        }
    }
};

ko.bindingHandlers.money = {
    update: function (element, valueAccessor, allBindingsAccessor) {
        var value = valueAccessor(), allBindings = allBindingsAccessor();
        var valueUnwrapped = ko.utils.unwrapObservable(value);

        var m = "";
        if (valueUnwrapped) {
            m = parseInt(valueUnwrapped);
            if (m) {
                var parts = m.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                m = parts.join(".");
            }
        }
        $(element).text(m);
    }
};

ko.bindingHandlers.currencyTextInput = {    
    init: function (element, valueAccessor) {
        var el = $(element);
        el.change(function (event) {
            if (!el.val()) {
                return;
            }

            if (el.val().indexOf('.') === -1) {
                el.val(el.val() + '.00');
            }

            while (el.val().indexOf('.') > el.val().length - 3) {
                el.val(el.val() + '0');
            }
        });
    },
    update: function (element, valueAccessor) {
        $(element).change();
    },
    //make it parameterless
    preprocess: function (value) {
        if (!value)
            value = 'true';
        return value;
    }
};