/* ========================================================
* knockout.custombindings.js 
* ========================================================
* Copyright 2012 Vision2Systems.
*  */

(function () {

    function viewModel() {
        var EventsModel = function() {
            var self = this;
            this.Id = 0;
            this.End = null;
            //this date format must be kept in synch with the date format in the date picker
            var initialDate = moment(new Date()).format("MM/DD/YYYY");
            var initialTime = moment(new Date()).minutes(0).seconds(0).milliseconds(0).format('hh:mm A');
            this.startDate = ko.observable(initialDate);
            this.startTime = ko.observable(initialTime);
            this.Start = ko.computed(function() {
                var date = moment(self.startDate()).format("YYYY-MM-DD");
                var converted = moment(date + " " + self.startTime().toString(), "YYYY-MM-DD hh:mm A");
                //console.log(converted.format('YYYY-MM-DDTHH:mm:ss Z'));
                return converted.format('YYYY-MM-DDTHH:mm:ss Z');
            }, this);
            this.dayNameOfTheMonth = ko.computed(function() {
                //console.log(getHumanizedInstanceOfDay(getInstanceOfDay(self.startDate())) + ' ' + moment(self.startDate()).format('ddd'));
                return getHumanizedInstanceOfDay(getInstanceOfDay(self.startDate())) + ' ' + moment(self.startDate()).format('ddd');
            }, this);
            this.dayNumberOfTheMonth = ko.computed(function() {
                return moment(self.startDate()).format('Do');
            });
            this.UID = ko.observable();
            this.WillRepeat = ko.observable(0);
            this.RecurrenceRules = ko.observableArray();
            this.Location = null;
            this.Class = null;
            this.Categories = null;
            this.Description = ko.observable();
            this.EventContextId = ko.observable();
            this.EventContext = ko.observable();
            this.RowStatus = ko.observable(1);

        };

        var DaysOfWeek = [
            { Id: 0, key: 0, display: 'Sunday', small: 'SU', abbr: 'Sun', IsSelected: false},
            { Id: 0, key: 1, display: 'Monday', small: 'MO', abbr: 'Mon', IsSelected: false },
            { Id: 0, key: 2, display: 'Tuesday', small: 'TU', abbr: 'Tue', IsSelected: false },
            { Id: 0, key: 3, display: 'Wednesday', small: 'WE', abbr: 'Wed', IsSelected: false },
            { Id: 0, key: 4, display: 'Thursday', small: 'TH', abbr: 'Thu', IsSelected: false },
            { Id: 0, key: 5, display: 'Friday', small: 'FR', abbr: 'Fri', IsSelected: false },
            { Id: 0, key: 6, display: 'Saturday', small: 'SA', abbr: 'Sat', IsSelected: false }
        ];

        var RecurrenceRuleModel = function() {
            var self = this;
            this.Id = ko.observable(0);
            this.endPattern = null;
            this.FrequencyType = null;
            this.Count = null; //occurances
            this.Interval = null;
            //this date format must be kept in synch with the date format in the date picker
            this.untilDate = ko.observable(moment(new Date()).format("MM/DD/YYYY"));
            this.untilTime = ko.observable(moment(new Date()).minutes(0).seconds(0).milliseconds(0).add('hours', 1).format('hh:mm A'));
            this.Until = ko.computed(function() {
                var date = moment(self.untilDate()).format("YYYY-MM-DD");
                var converted = moment(date + " " + self.untilTime().toString(), "YYYY-MM-DD hh:mm A");
                //console.log(converted.format('YYYY-MM-DDTHH:mm:ss Z'));
                return converted.format('YYYY-MM-DDTHH:mm:ss Z');
            }, this);
            this.DaysOfWeek = DaysOfWeek;
            this.WeekStart = 1; //always monday on client
            this.DaysOfYear = [];
            this.IsDayOfMonthNumber = false;
            this.DaysOfMonth = ko.observable('daynumber');
            this.RowStatus = ko.observable(1);
        };

        var FrequencyTypeCodes = [
            { key: 1, display: "Yearly" },
            { key: 2, display: "Monthly" },
            { key: 3, display: "Weekly" }
        ];


        var newModel = new EventsModel();
        newModel.RecurrenceRules.push(new RecurrenceRuleModel());
        newModel.frequencyTypeCodes = FrequencyTypeCodes;
        newModel.daysOfWeek = DaysOfWeek;


        return newModel;
    }

    function clientSideRecurrenceRule() {
        var self = this;
        this.Id = ko.observable(0);
        this.endPattern = null;
        this.FrequencyType = null;
        this.Count = null; //occurances
        this.Interval = null;
        //this date format must be kept in synch with the date format in the date picker
        this.untilDate = ko.observable(moment(new Date()).format("MM/DD/YYYY"));
        this.untilTime = ko.observable(moment(new Date()).minutes(0).seconds(0).milliseconds(0).add('hours', 1).format('hh:mm A'));
        this.Until = ko.computed(function() {
            var date = moment(self.untilDate()).format("YYYY-MM-DD");
            var converted = moment(date + " " + self.untilTime().toString(), "YYYY-MM-DD hh:mm A");
            //console.log(converted.format('YYYY-MM-DDTHH:mm:ss Z'));
            return converted.format('YYYY-MM-DDTHH:mm:ss Z');
        }, this);
        this.DaysOfWeek = getDaysOfWeek();
        this.WeekStart = 1; //always monday on client
        this.DaysOfYear = [];
        this.IsDayOfMonthNumber = false;
        this.DaysOfMonth = null;
        this.Days = [];
        this.RowStatus = ko.observable(0);

    }

    function getDaysOfWeek() {
        var results = [
            { Id: 0, key: 0, display: 'Sunday', small: 'SU', abbr: 'Sun', IsSelected: false },
            { Id: 0, key: 1, display: 'Monday', small: 'MO', abbr: 'Mon', IsSelected: false },
            { Id: 0, key: 2, display: 'Tuesday', small: 'TU', abbr: 'Tue', IsSelected: false },
            { Id: 0, key: 3, display: 'Wednesday', small: 'WE', abbr: 'Wed', IsSelected: false },
            { Id: 0, key: 4, display: 'Thursday', small: 'TH', abbr: 'Thu', IsSelected: false },
            { Id: 0, key: 5, display: 'Friday', small: 'FR', abbr: 'Fri', IsSelected: false },
            { Id: 0, key: 6, display: 'Saturday', small: 'SA', abbr: 'Sat', IsSelected: false }
        ];
        return results;
    }

    //server side representative of VEvent
    function vEvent(opts) {
        this.Id = opts.Id;
        this.StartDate = opts.StartDate;
        this.RecurrenceRules = opts.RecurrenceRules || [];
        this.EndDate = opts.EndDate || null;
        this.UtcOffset = opts.UtcOffset || 0;
        this.RowStatus = opts.RowStatus;
        return this;
    }

    function vRule(ropts) {
        this.Id = ropts.Id;
        this.FrequencyType = ropts.FrequencyType || 0;
        this.DaysOfWeek = ropts.DaysOfWeek || [];
        this.Until = ropts.Until;
        this.Count = ropts.Count;
        this.Days = ropts.Days || [];
        this.RowStatus = ropts.RowStatus;
        return this;
    }

    function vDay(dopts) {
        this.Id = dopts.Id;
        this.Day = dopts.Day;
        this.Week = dopts.Week || 0;
        this.RowStatus = dopts.RowStatus;
        return this;
    }
    

    function toListVEvents(el) {
        var evtList = el;
        //console.log(el);
        //first create an iCal model if we dont have one
        var newModel = [];

        if (!el)
            return newModel;

        for (var i = 0; i < evtList.length; i++) {
            var currentEvent = evtList[i];
            var opts = {};
            opts.Id = currentEvent.Id;
            opts.RowStatus = currentEvent.RowStatus;
            opts.StartDate = currentEvent.Start;
            opts.UtcOffset = moment(new Date()).format('Z');
            
            //no recurring pattern
            if (currentEvent.endPattern === 'once') {
                newModel.push(new vEvent(opts));
                continue;
            }

            opts.RecurrenceRules = [];
            //console.log(currentEvent.RecurrenceRules);
            var ruleCount = currentEvent.RecurrenceRules.length;

            console.log("Recurrency Rule Count:" + ruleCount);
            for (var k = 0; k < ruleCount; k++) {
                var currentRule = currentEvent.RecurrenceRules[k];
                var ropts = {};
                ropts.Id = currentRule.Id;
                ropts.RowStatus = currentRule.RowStatus;
                ropts.FrequencyType = currentRule.FrequencyType;
                ropts.Days = [];
                //figure out the selected pattern
                //None=0, Yearly = 1, Monthly = 2, Weekly = 3, Daily = 4, Hourly = 5
                var pattern = currentRule.FrequencyType;
                
                //remove the row if it is not repeating
                if (currentEvent.WillRepeat == 0)
                    ropts.RowStatus = 2;

                
                if (pattern === 3) {
                    var tempDays = [];
                    var l = 0;
                    var maxL = (currentRule.Days) ? currentRule.Days.length : 0;
                    for (var j = 0, je = currentRule.DaysOfWeek.length; j < je; j++) {
                        var dopts = { Id: 0, Day: currentRule.DaysOfWeek[j].key, Week: 0 };
                        //update existing rows
                        if (l < maxL && currentRule.DaysOfWeek[j].IsSelected) {
                            dopts.RowStatus = 0;
                            dopts.Id = currentRule.Days[l].Id;
                            tempDays.push(new vDay(dopts));
                            l++;
                        }
                        else {
                            //add rows as neccesary
                            if (l >= maxL && currentRule.DaysOfWeek[j].IsSelected) {
                                dopts.RowStatus = 1;
                                tempDays.push(new vDay(dopts));

                            }

                        }
                    }

                    //delete remaining rows
                    
                    for (var m = l; m < maxL; m++) {
                        dopts = { Id: currentRule.Days[m].Id, Day: currentRule.Days[m].Day, Week: currentRule.Days[m].Week, RowStatus: 2 };
                        tempDays.push(new vDay(dopts));
                    }
                    ropts.Days = tempDays;
                    ropts.FrequencyType = 3;
                 
                    
                    
                }


                if (pattern === 2) {
                    ropts.FrequencyType = 2;
                    var weekNum = 0;
                    var temp = moment(currentEvent.startDate).format('DD');
                    //named day of month
                    if (currentRule.DaysOfMonth === 'dayname') {
                        temp = moment(currentEvent.startDate).day();
                        weekNum = getInstanceOfDay(currentEvent.startDate);
                        weekNum = weekNum === 5 ? -1 : weekNum;
                        ropts.FrequencyType = 6;
                    }
                   
                    //add a day
                    if (!currentRule.Days || currentRule.Days.length == 0) {
                        dopts = { Id: 0, Day: temp, Week: weekNum, RowStatus: 1 };
                        ropts.Days.push(new vDay(dopts));
                    }
                    else
                    {
                        //update
                        if (currentRule.Days && currentRule.Days.length >= 1) {
                            dopts = { Id: currentRule.Days[0].Id, Day: temp, Week: weekNum };
                            ropts.Days.push(new vDay(dopts));
                        }
                        //flip row status on remaining days
                        if (currentRule.Days && currentRule.Days.length > 1)
                            for (var z = 1; z < currentRule.Days.length; z++) {
                                dopts = { Id: currentRule.Days[z].Id, Day: temp, Week: weekNum, RowStatus: 2 };
                                ropts.Days.push(new vDay(dopts));
                            }
                    }
                    

                 
                   
                }
                
                if (pattern == 1) {
                    var weekNum = 0;
                    var temp = moment(currentEvent.startDate).format('DD');
                   
                    //add a day
                    if (!currentRule.Days || currentRule.Days.length==0) {
                        dopts = { Id: 0, Day: temp, Week: weekNum, RowStatus: 1 };
                        ropts.Days.push(new vDay(dopts));
                    }
                    else
                    {
                        //update
                        if (currentRule.Days && currentRule.Days.length >= 1) {
                            dopts = { Id: currentRule.Days[0].Id, Day: temp, Week: weekNum };
                            ropts.Days.push(new vDay(dopts));
                        }
                        //flip row status on remaining days
                        if (currentRule.Days && currentRule.Days.length > 1)
                            for (var w = 1; w < currentRule.Days.length; w++) {
                                dopts = { Id: currentRule.Days[w].Id, Day: temp, Week: weekNum, RowStatus: 2 };
                                ropts.Days.push(new vDay(dopts));
                            }
                    }
                   

                }
                
                //ends
                var ends = currentRule.endPattern;
            
                if (ends == 'after') {
                    ropts.Count = currentRule.Count;
                }
                if (ends == 'on') {
                    ropts.Until = currentRule.Until;
            
                }
                console.log(ropts.RowStatus);

                var rr = new vRule(ropts);
                
                opts.RecurrenceRules.push(rr);
            
            }

            //console.log(opts);
            newModel.push(new vEvent(opts));
        }

        return newModel;
    }

    //converts serverside to clientside list

    function fromListVEvents(vl) {

        var newModel = ko.observableArray();

        if (!vl)
            return newModel;

        for (var i = 0; i < vl.length; i++) {
            var currentEvent = vl[i];
            var clientEvent = new viewModel();
            clientEvent.RecurrenceRules = [];

            //intitial dates
            clientEvent.Id = ko.observable(currentEvent.Id);
            clientEvent.RowStatus = ko.observable(0);
            clientEvent.startDate = ko.observable(moment.utc(currentEvent.StartDate() + "Z0:00").local());
            clientEvent.startTime = ko.observable(moment.utc(currentEvent.StartDate() + "Z0:00").local().format('hh:mm A'));
            clientEvent.Start = ko.computed(function () {
                var date = moment(clientEvent.startDate()).format("YYYY-MM-DD");
                var converted = moment(date + " " + clientEvent.startTime().toString(), "YYYY-MM-DD hh:mm A");
                //console.log(converted.format('YYYY-MM-DDTHH:mm:ss Z'));
                return converted.format('YYYY-MM-DDTHH:mm:ss Z');
            }, this);
            clientEvent.WillRepeat = ko.observable(0);
            //console.log(currentEvent.RecurrenceRules());
            //we have recurring patterns
            if (currentEvent.RecurrenceRules() && currentEvent.RecurrenceRules().length > 0) {

                var currentEventRules = ko.utils.unwrapObservable(currentEvent.RecurrenceRules);

                for (var j = 0; j < currentEventRules.length; j++) {
                    var rule = ko.utils.unwrapObservable(currentEventRules[j]);
                    var freq = rule.FrequencyType();
                    if (freq != 0) {
                        clientEvent.WillRepeat = ko.observable(1);
                    }
                    
                    var cRule = new clientSideRecurrenceRule();
                    cRule.Id = ko.observable(rule.Id);
                    cRule.RowStatus = ko.observable(0);
                    cRule.FrequencyType = ko.observable(freq);
                    cRule.Count = ko.observable(rule.Count);  //occurances
                    cRule.Interval = ko.observable(rule.Interval);
                    cRule.Days = ko.observableArray(rule.Days);
                    
                

                    
                    if (rule.Until()) {
                        cRule.untilDate = ko.observable(moment(rule.Until()).format("MM/DD/YYYY"));
                        cRule.untilTime = ko.observable(moment(rule.Until()).format('hh:mm A'));
                        cRule.Until = ko.computed(function () {
                            var date = moment(cRule.untilDate()).format("YYYY-MM-DD");
                            var converted = moment(date + " " + cRule.untilTime().toString(), "YYYY-MM-DD hh:mm A");
                            //console.log(converted.format('YYYY-MM-DDTHH:mm:ss Z'));
                            return converted.format('YYYY-MM-DDTHH:mm:ss Z');
                        }, this);
                    } else {
                        cRule.untilDate = ko.observable(moment(new Date()).format("MM/DD/YYYY"));
                        cRule.untilTime = ko.observable(moment(new Date()).format('hh:mm A'));
                        cRule.Until = ko.computed(function () {
                            var date = moment(cRule.untilDate()).format("YYYY-MM-DD");
                            var converted = moment(date + " " + cRule.untilTime().toString(), "YYYY-MM-DD hh:mm A");
                            //console.log(converted.format('YYYY-MM-DDTHH:mm:ss Z'));
                            return converted.format('YYYY-MM-DDTHH:mm:ss Z');
                        }, this);
                    }
                    //this date format must be kept in synch with the date format in the date picker

                    //add the days to the recurrance pattern
                    
                    cRule.DaysOfWeek = getDaysOfWeek();
                    
                    //days of week server side
                    if (freq == 3) {
                       
                        for (var x = 0; x < rule.Days().length; x++) {
                            var day = rule.Days()[x].Day();
                            cRule.DaysOfWeek[day].IsSelected = true;
                            cRule.DaysOfWeek[day].Id = day.Id;
                        }

                    }

                    cRule.DaysOfMonth = ko.observable();

                    //days of month server side
                    if (freq == 2) {
                        cRule.DaysOfMonth = ko.observable('daynumber');
                        
                    }


                    if (freq == 6) {
                            //days of month 
                        cRule.DaysOfMonth = ko.observable('dayname');
                        cRule.FrequencyType(2);
                        }

                    clientEvent.dayNameOfTheMonth = ko.computed(function () {
                        return getHumanizedInstanceOfDay(getInstanceOfDay(clientEvent.startDate())) + ' ' + moment(clientEvent.startDate()).format('ddd');
                    }, this);

                    clientEvent.dayNumberOfTheMonth = ko.computed(function () {
                        return moment(clientEvent.startDate()).format('Do');
                    });

                    cRule.endPattern = ko.observable('never');

                    if (cRule.Count() > 0)
                        cRule.endPattern = ko.observable('after');

                    if (rule.Until())
                        cRule.endPattern = ko.observable('on');

                    clientEvent.RecurrenceRules.push(cRule);
                   
                }
            } else {
                clientEvent.WillRepeat = ko.observable(0);
            }

            newModel.push(clientEvent);

        }

        return newModel;

    }



    function getInstanceOfDay(d) {
        var _date = moment(new Date(d)),
                dayOfMonth = parseInt(_date.format('D')),
                dayOfWeek = parseInt(_date.format('d')),
                working = moment(new Date(d).setDate(1)),
                instance = 0;

        for (var i = parseInt(working.format('D')); i <= dayOfMonth; i++) {
            var _day = parseInt(working.format('d'));
            if (_day === dayOfWeek) instance++;
            working.add(1, 'days');
        }

        return instance;
    }

    function getHumanizedInstanceOfDay(n) {
        if (n === 1) return '1st';
        else if (n === 2) return '2nd';
        else if (n === 3) return '3rd';
        else if (n === 4) return '4th';
        else return 'last';
    }

    function abbrevDayName(d) {
        d = d.toLowerCase();
        if (d === 'Monday'.toLowerCase()) return "MO";
        if (d === 'Tuesday'.toLowerCase()) return "TU";
        if (d === 'Wednesday'.toLowerCase()) return "WE";
        if (d === 'Thursday'.toLowerCase()) return "TH";
        if (d === 'Friday'.toLowerCase()) return "FR";
        if (d === 'Saturday'.toLowerCase()) return "SA";
        if (d === 'Sunday'.toLowerCase()) return "SU";
    }

    function dayNumber(d) {
        d = d.toLowerCase();
        if (d === 'Monday'.toLowerCase()) return 1;
        if (d === 'Tuesday'.toLowerCase()) return 2;
        if (d === 'Wednesday'.toLowerCase()) return 3;
        if (d === 'Thursday'.toLowerCase()) return 4;
        if (d === 'Friday'.toLowerCase()) return 5;
        if (d === 'Saturday'.toLowerCase()) return 6;
        if (d === 'Sunday'.toLowerCase()) return 0;
    }


    window.eventScheduler = {
        ViewModel: viewModel,
        ToListVEvents: toListVEvents,
        FromListVEvents: fromListVEvents
    };
})();


