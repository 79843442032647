var template = require("./appliedpaymentsearch.html");

var pagination = require("../../v2/paginationServices.js");
var analytics = require("../../v2/analytics.js");
require("knockout");
require("knockout-mapping");
require("../../v2/knockout.custombindings");
var radio = require("radio");
var moment = require("moment");
var jstz = require("jstz");



function viewModel(params) {


    this.Payments = new pagination.PageModel();

    this.displayAnalytics = ko.observable(true);
    this.displaySearch = ko.observable(true);
    this.UseComplex = ko.observable(false);
    this.loading = ko.observable(false);

    this.resultDownloadModalVisible = ko.observable(false);

    var profileId = ko.utils.unwrapObservable(params.ProfileId) || 0;
    var pageSize = params.PageSize || 30;

    this.PaymentSearchCriteria = new pagination.PaymentSearchModel(profileId, 0, pageSize);
    this.PaymentSearchCriteria.Analytics(0);    

    EventCalendarExtender.DatePickerEnable(this.PaymentSearchCriteria, this.PaymentSearchCriteria.FromDate);
    EventCalendarExtender.DatePickerEnable(this.PaymentSearchCriteria, this.PaymentSearchCriteria.ToDate);
    EventCalendarExtender.DatePickerEnable(this.PaymentSearchCriteria, this.PaymentSearchCriteria.FromDepositDate);
    EventCalendarExtender.DatePickerEnable(this.PaymentSearchCriteria, this.PaymentSearchCriteria.ToDepositDate);

    

    this.Channels = ko.observableArray();
    for (var i = 0; i < enumerations.Channel.length; i++) {
        if (i != 0)
            var y = { Name: enumerations.Channel[i], Index: i.toString() };
        else
            var y = { Name: 'All', Index: i.toString() };
        this.Channels.push(y);
    }
    console.log('enumerations and channels', enumerations, this.Channels);


    if (params.SiteId)
        this.PaymentSearchCriteria.PaymentSiteId(ko.utils.unwrapObservable(params.SiteId));

    if (params.FundableId)
        this.PaymentSearchCriteria.FundableId(ko.utils.unwrapObservable(params.FundableId));

    if (params.HouseholdId)
        this.PaymentSearchCriteria.HouseholdId(ko.utils.unwrapObservable(params.HouseholdId));

    if (params.MarketingCommunicationId)
        this.PaymentSearchCriteria.MarketingCommunicationId(ko.utils.unwrapObservable(params.MarketingCommunicationId));

    if (params.MarketingEffortId)
        this.PaymentSearchCriteria.MarketingEffortId(ko.utils.unwrapObservable(params.MarketingEffortId));

    if (params.displayAnalytics)
        this.displayAnalytics(ko.utils.unwrapObservable(params.displayAnalytics));

    if (params.displaySearch)
        this.displaySearch(ko.utils.unwrapObservable(params.displaySearch));

    if (params.UseComplex)
        this.PaymentSearchCriteria.UseComplex(ko.utils.unwrapObservable(params.UseComplex));

    if (params.CommitmentItemId)
        this.PaymentSearchCriteria.CommitmentItemId(ko.utils.unwrapObservable(params.CommitmentItemId));

    if (params.BankAccountId)
        this.PaymentSearchCriteria.BankAccountId(ko.utils.unwrapObservable(params.BankAccountId));

    if (params.IsStatement) 
        this.PaymentSearchCriteria.IsStatement(ko.utils.unwrapObservable(params.IsStatement));

    if (params.IsGivingPermissionBased)
        this.PaymentSearchCriteria.IsGivingPermissionBased(ko.utils.unwrapObservable(params.IsGivingPermissionBased));

    if (params.IsCampaignParticipationBased)
        this.PaymentSearchCriteria.IsCampaignParticipationBased(ko.utils.unwrapObservable(params.IsCampaignParticipationBased));

    if (params.PageSize)
        this.PaymentSearchCriteria.PageSize(ko.utils.unwrapObservable(params.PageSize));



    if (params.Type == 0)
        console.warn("no search type passed to applied payment search");

    this.PaymentSearchCriteria.PaymentSearchType(params.Type || 0);

    this.PaymentSearchCriteria.TimeZone(jstz.determine().name());

    pagination.SubscribeSearchPagination(this, this.Payments, '/Finance/AppliedPayment/Search', this.PaymentSearchCriteria);

    this.navigateToEdit = function (d) {
        window.open('/Finance/Payment/Edit/' + d.PaymentId());
    }

    this.navigateToIndividual = function (d) {
        //sessionStorage.setItem("RefreshCommitmentSearch", JSON.stringify(ko.toJS(viewModel.Payments.PaymentSearchCriteria)));
        window.open('/People/Individual/Details/' + d.Individual.Id());
    };

    this.navigateToOrganization = function (d) {
        // sessionStorage.setItem("RefreshPaymentSearch", JSON.stringify(ko.toJS(viewModel.Payments.PaymentSearchCriteria)));
        window.open('/Organization/Profile/Details/' + d.Organization.Id());
    };

    this.navigateToDetails = function (d) {
        //sessionStorage.setItem("RefreshPaymentSearch", JSON.stringify(ko.toJS(viewModel.Payments.PaymentSearchCriteria)));
        window.open('/Finance/Payment/Details/' + d.PaymentId());
    };

    this.Payments.firstPage();

     this.PaymentSearchCriteria.TimeZone(jstz.determine().name());


    this.GetSpreadSheet = function () {
        console.log('get spreadsheet');
        this.PaymentSearchCriteria.LocalUrl = window.location.pathname;

        v2Form.POST({
            url: '/Finance/AppliedPayment/AppliedPaymentAnalyticsSpreadsheet',

            data: ko.toJS(this.PaymentSearchCriteria),
            error: function () {
                v2Form.GET({
                    url: '/Finance/Payment/GetAppliedPaymentSearchSpreadsheet'
                });
            },
            complete: function () { $('body').unblock(); }
        });
    };

    this.fundablesLoaded = ko.observable(false);
    this.FundableList = ko.observableArray([]);
    this.PaymentSearchCriteria.SelectedFundables = ko.observableArray([]);
    this.IncludeArchivedFundables = ko.observable(false);
    this.FundableSearchType = ko.observable('name');

   
}



module.exports = {
    viewModel: viewModel,
    template: template
}