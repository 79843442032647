var template = require('./fundable-display.html');





function viewModel(params) {
    var self = this;
    var id = ko.utils.unwrapObservable(params.Id);
    self.FundableId = ko.observable();
    self.Name = ko.observable();
    self.ShortDescription = ko.observable();
    self.FundableReferenceType = ko.observable();
   

    v2Form.POST({
        url: '/Finance/Designation/GetDisplay',
        type: 'POST',
        contentType: 'application/json',
        dataType: 'json',
        data: {id: id},
        success: function (data, textStatus, jqXhr) {
            ko.mapping.fromJS(data.Data, {}, self);
        },
        error: function (data, textStatus, jqXhr) {
            console.log('error');
            console.log(data);
        }
    });
   

   

    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};

