require("knockout");
var jq = require("jquery");

function pageModel(i, s) {
    if (!i) {
        i = 0;
    }
    if (!s) {
        s = 10;
    }

    this.PageData = ko.observableArray();
    this.PageIndex = ko.observable(i);
    this.PageSize = ko.observable(s);
    this.IsFirstPage = ko.observable();
    this.IsLastPage = ko.observable();
    this.HasNextPage = ko.observable();
    this.HasPreviousPage = ko.observable();
    this.TotalCount = ko.observable(0);
    this.TotalPages = ko.observable(0);
    this.Facets = ko.observableArray();
    this.LocalUrl = ko.observable();
    this.NoClear = ko.observable(false); //Set to true in order to prevent clearing of local pagination model while call is in progress to server

    return this;
}

function subscribeBrowserOnlyPagination(vm, p) {
    if (p.DataSourceObservable === null || p.DataSourceObservable === undefined) {
        console.error('view model [' + JSON.stringify(p) + '].DataSourceObservable, is null', vm);
    }

    if (p.BrowserOnlyFilters === null || p.BrowserOnlyFilters === undefined || Object.keys(p.BrowserOnlyFilters).length === 0) {
        console.error('p.BrowserOnlyFilters is null, undefined, or there aren\'t any', vm);
    }

    p.firstPage = function () {
        p.PageIndex(0);
        getBrowserOnlyPage(p);
    };

    p.lastPage = function () {
        p.PageIndex(p.TotalPages() - 1);
        getBrowserOnlyPage(p);
    };

    p.nextPage = function () {
      
        p.PageIndex(p.PageIndex() + 1);
        getBrowserOnlyPage(p);
    };

    p.previousPage = function () {
        p.PageIndex(p.PageIndex() - 1);
        getBrowserOnlyPage(p);
    };

    p.refreshPage = function () {
        getBrowserOnlyPage(p);
    };
}

//view model, page model, search path, search criteria
function subscribePagination(vm, p, path, id) {
    if (vm === null)
        console.error('view model is null');

    if (vm[p] === null)
        console.error('view model [' + JSON.stringify(p) + '], is null', vm);

    vm[p].firstPage = function () {

        var param = {
            Id: id,
            PageIndex: 0,
            PageSize: vm[p].PageSize()
        };
        getPage(vm, vm[p], path, param);
    };

    vm[p].lastPage = function () {
        var param = {
            Id: id,
            PageIndex: vm[p].TotalPages() - 1,
            PageSize: vm[p].PageSize()
        };
        getPage(vm, vm[p], path, param);
    };

    vm[p].nextPage = function () {
        var param = {
            Id: id,
            PageIndex: vm[p].PageIndex() + 1,
            PageSize: vm[p].PageSize()
        };
        getPage(vm, vm[p], path, param);
    };

    vm[p].previousPage = function () {
        var param = {
            Id: id,
            PageIndex: vm[p].PageIndex() - 1,
            PageSize: vm[p].PageSize()
        };
        getPage(vm, vm[p], path, param);
    };
}

function subscribeSearchPagination(vm, p, path, query, eventName, subscribe) {

    p.firstPage = function () {
        query.PageIndex(0);
        getPage(vm, p, path, query, subscribe);
        return true;
    };

    p.search = function () {
        if (eventName) {
            //appAnalytics.TrackEvent(eventName + 'Search', query);
        }
        p.firstPage();
        return true;
    }

    p.lastPage = function () {
        query.PageIndex(p.TotalPages() - 1);
        getPage(vm, p, path, query, subscribe);
        return true;
    };

    p.nextPage = function () {
        query.PageIndex(p.PageIndex() + 1);
        if (eventName) {
            //appAnalytics.TrackEvent(eventName + 'Pagination', query);
        }
        getPage(vm, p, path, query, subscribe);
        return true;
    };

    p.previousPage = function () {
        query.PageIndex(p.PageIndex() - 1);
        if (eventName) {
            //  (eventName + 'Pagination', query);
        }
        getPage(vm, p, path, query, subscribe);
        return true;
    };
}

function getBrowserOnlyPage(p, param) {
    if (!p.PageData) {
        console.log('Invalid model provided to pagination');
    }

    var browserOnlyFilters = p.BrowserOnlyFilters;

    //filter data by provided filters. Provided filter keys are property names on the element.
    //Provided filter values are filter functions that return true if the property on the element passes the filter, otherwise they return false.
    var filteredDataSource = p.DataSourceObservable().filter(function (elem) {

        //Get the property names of the filters provided
        for (var propName in browserOnlyFilters) {
            //If the filter has a value:
            var filterValue = browserOnlyFilters[propName]();
            var filterFunc = browserOnlyFilters[propName].filterFunc;

            if (filterValue !== undefined && filterValue !== null && filterValue !== "") {
                var elemValue = elem[propName]();

                //convert the element value to string form (lower case) and see if it contains the filter value (lower case) - this causes the filter to pass - otherwise the filter fails
                if (!filterFunc(elemValue, filterValue)) {
                    return false;
                }
            }
        }

        //The element passed all of the provided filters. Return true so that it is included in the data set.
        return true;
    });

    //use param to filter the page
    var itemCount = filteredDataSource.length;
    p.TotalCount(itemCount);
    var pageSize = p.PageSize();

    p.TotalPages(Math.ceil(itemCount / pageSize));

    var maxPageIndex = p.TotalPages() - 1;
    var pageIndex = Math.min(maxPageIndex, p.PageIndex());

    p.IsFirstPage(pageIndex == 0);
    p.IsLastPage(pageIndex == maxPageIndex);
    p.HasNextPage(!p.IsLastPage());
    p.HasPreviousPage(!p.IsFirstPage());

    var startIndex = pageIndex * pageSize;
    var newPageData = filteredDataSource.slice(startIndex, Math.min(startIndex + pageSize - 1, p.TotalCount() - 1) + 1);//get a page from the data source based on the page index and page size

    p.PageData(newPageData);
}

function getPage(vm, p, path, param, subscribe) {

    


    if (!p.PageData)
        console.log('Invalid model provided to pagination');

    if (!p.NoClear || !p.NoClear()) {
        p.PageData.removeAll();
        p.PageIndex(0);
        p.TotalCount(0);
        p.TotalPages(0);

        p.IsFirstPage(false);
        p.IsLastPage(false);
        p.HasNextPage(false);
        p.HasPreviousPage(false);
    }

    if (typeof p.ClearAll !== 'undefined')
        p.ClearAll();

    if (typeof param.toPostModel !== 'undefined')
        param = param.toPostModel();

    param.jstzTimeZone = jstz.determine().name();
    param.TimeZone = param.jstzTimeZone;
    var searchArgs = JSON.stringify(ko.toJS(param));
    jq.ajax({
        url: path,
        type: 'POST',
        beforeSend: function () {
            if (vm.loading)
                vm.loading(true);
        },
        contentType: 'application/json',
        dataType: 'json',
        data: searchArgs,
        error: function (xhr, ajaxOptions, thrownError) {
            console.log(xhr.statusText);
            console.log(xhr.responseText);
            console.log(xhr.status);
            console.log(thrownError);
        },
        success: function (data, textStatus, jqXhr) {

            if (data.Redirect) {
                window.location = data.Redirect + "?returnUrl=" + encodeURIComponent(window.location.pathname);
            }

            if (data.Data == null) {
                console.log('missing data? for ' + path, searchArgs, data);
                return;
            }

            ko.mapping.fromJS(data.Data.PageData, {}, p.PageData);
            if (subscribe != null) {
                ko.utils.arrayForEach(p.PageData(), function (item) {
                    subscribe(item);
                });
            }
            p.PageIndex(data.Data.PageIndex);
            p.PageSize(data.Data.PageSize);
            p.TotalCount(data.Data.TotalCount);
            p.IsFirstPage(data.Data.IsFirstPage);
            p.IsLastPage(data.Data.IsLastPage);
            p.HasNextPage(data.Data.HasNextPage);
            p.HasPreviousPage(data.Data.HasPreviousPage);
            p.TotalPages(data.Data.TotalPages);
            p.Facets(data.Data.Aggregations);
            ko.mapping.fromJS(data.Data, null, p);

            if (typeof p.ClearAll !== 'undefined') {
                Analytics.UpdateViewModel(p);
            }
        },
        complete: function () {
            if (vm.loading)
                vm.loading(false);
        }
    });
}

function glSearchModel(pageIndex, pageSize) {

    if (!pageIndex) {
        pageIndex = 0;
    }

    if (!pageSize) {
        pageSize = 10;
    }

    var self = this;
    this.ExternalSystemId = ko.observable();
    this._toDebitAmount = ko.observable();
    this._fromDebitAmount = ko.observable();
    this.c = ko.observable();
    this._toCreditAmount = ko.observable();
    this._fromCreditAmount = ko.observable();
    this.PageSize = ko.observable(pageSize);
    this.PageIndex = ko.observable(pageIndex);
    this.UseComplex = ko.observable(false);
    this.QueryString = ko.observable("");
    this.PostId = ko.observable();
    this.FromTransactionDate = ko.observable();
    this.ToTransactionDate = ko.observable();
    this.FromPostDate = ko.observable();
    this.ToPostDate = ko.observable();

    this.FromDebitAmount = ko.computed({
        read: function () {
            if (isNaN(parseFloat(self._fromDebitAmount())))
                return null;
            else
                return parseFloat(self._fromDebitAmount());
        },
        write: function (value) {
            self._fromDebitAmount(value);
        },
        owner: self
    });

    this.ToDebitAmount = ko.computed({
        read: function () {

            if (isNaN(parseFloat(self._toDebitAmount())))
                return null;
            else
                return parseFloat(self._toDebitAmount());
        },
        write: function (value) {
            self._toDebitAmount(value);
        },
        owner: self
    });

    this.FromCreditAmount = ko.computed({
        read: function () {
            if (isNaN(parseFloat(self._fromCreditAmount())))
                return null;
            else
                return parseFloat(self._fromCreditAmount());
        },
        write: function (value) {
            self._fromCreditAmount(value);
        },
        owner: self
    });

    this.ToCreditAmount = ko.computed({
        read: function () {

            if (isNaN(parseFloat(self._toCreditAmount())))
                return null;
            else
                return parseFloat(self._toCreditAmount());
        },
        write: function (value) {
            self._toCreditAmount(value);
        },
        owner: self
    });
}

function individualSearchModel(pageIndex, pageSize) {
    var self = this;
    self.FirstName = ko.observable();
    self.LastName = ko.observable();
    self.Address1 = ko.observable();
    self.Address2 = ko.observable();
    self.City = ko.observable();
    self.Region = ko.observable();
    self.PostalCode = ko.observable();
    self.EmailAddress = ko.observable();
    self.PhoneNumber = ko.observable();
    self.PageIndex = ko.observable(pageIndex);
    self.PageSize = ko.observable(pageSize);
    self.UseComplex = ko.observable(false);
    self.QueryString = ko.observable("");
    self.SiteId = ko.observable();
    self.Envelope = ko.observable();
    self.IsStaff = ko.observable();
    self.Tags = ko.observable();
    self.IsArchived = ko.observable("false");
    self.DisplayEnvelope = ko.observable(false);
    self.FromCreatedDate = ko.observable();
    self.ToCreatedDate = ko.observable();
}

function individualRelationshipSearchModel(profileId, pageIndex, pageSize) {
    var self = this;
    self.ProfileId = ko.observable(profileId);
    self.RelationshipType = ko.observable();
    self.FromStartDate = ko.observable();
    self.ToStartDate = ko.observable();
    self.FromEndDate = ko.observable();
    self.ToEndDate = ko.observable();
    self.FirstName = ko.observable();
    self.LastName = ko.observable();
    self.Address1 = ko.observable();
    self.Address2 = ko.observable();
    self.City = ko.observable();
    self.Region = ko.observable();
    self.PostalCode = ko.observable();
    self.EmailAddress = ko.observable();
    self.PhoneNumber = ko.observable();
    self.PageIndex = ko.observable(pageIndex);
    self.PageSize = ko.observable(pageSize);
    self.UseComplex = ko.observable(false);
    self.QueryString = ko.observable("");
    self.SiteId = ko.observable();
    self.IsStaff = ko.observable();
    self.Tags = ko.observable();
    self.IsArchived = ko.observable();
}

function batchSearchModel(pageIndex, pageSize) {
    var self = this;
    self.PageIndex = ko.observable(pageIndex);
    self.PageSize = ko.observable(pageSize);
    self.BatchStartDate = ko.observable();
    self.BatchEndDate = ko.observable();
    self.PostStartDate = ko.observable();
    self.PostEndDate = ko.observable();
    self.BatchStatus = ko.observable();
    self.IsPostedToLedger = ko.observable();
    self.UseComplex = ko.observable(false);
    self.QueryString = ko.observable("");
    self.SiteId = ko.observable();
    self.IsStaff = ko.observable();
    self.Tags = ko.observable();
}

//This giftSearchModel is paired with GiftSearchArguments on the server
function baseSearchModel(profileId, pageIndex, pageSize, siteType) {

    if (!pageIndex) {
        pageIndex = 0;
    }

    if (!pageSize) {
        pageSize = 10;
    }

    var self = this;

    //fields to support computed properties
    this._fromAmount = ko.observable();
    this._toAmount = ko.observable();
    this._sourceCode = ko.observable();

    //base properties
    this.UseComplex = ko.observable(false);
    this.QueryString = ko.observable("");
    this.ProfileId = ko.observable(profileId);
    this.MarketingCommunicationId = ko.observable();
    this.MarketingEffortId = ko.observable();
    this.CommitmentStatus = ko.observable();
    this.FundableId = ko.observable();
    this.HouseholdId = ko.observable();
    this.PostalCode = ko.observable("");
    this.City = ko.observable("");
    this.Region = ko.observable("");
    this.BatchNumber = ko.observable("");
    this.BatchFromDate = ko.observable();
    this.BatchToDate = ko.observable();
    this.FromDate = ko.observable();
    this.ToDate = ko.observable();
    this.FromDepositDate = ko.observable();
    this.ToDepositDate = ko.observable();
    this.Amount = ko.observable("");
    this.TimeZone = ko.observable(jstz.determine().name());
    this.SelectedDesignations = ko.observable([]);

    this.FromAmount = ko.computed({
        read: function () {
            if (isNaN(parseFloat(self._fromAmount())))
                return null;
            else
                return parseFloat(self._fromAmount());
        },
        write: function (value) {
            self._fromAmount(value);
        },
        owner: this
    });

    this.ToAmount = ko.computed({
        read: function () {

            if (isNaN(parseFloat(self._toAmount())))
                return null;
            else
                return parseFloat(self._toAmount());
        },
        write: function (value) {
            self._toAmount(value);
        },
        owner: this
    });

    this.FundableName = ko.observable();
    this.Notes = ko.observable();
    this.IsGivingPermissionBased = ko.observable(false);
    this.IsCampaignParticipationBased = ko.observable(false);

    this.SourceCode = ko.computed({
        read: function () {
            return self._sourceCode();
        },
        write: function (value) {
            self._sourceCode(value);
        },
        owner: this
    });

    this.PageIndex = ko.observable(pageIndex);
    this.PageSize = ko.observable(pageSize);
    this.Analytics = ko.observable(0);
    this.FundableReferenceType = ko.observable(0);
    this.LocalUrl = ko.observable("");
    this.SiteId = ko.observable();
    this.IsHistorical = ko.observable();
    this.IsAcknowledged = ko.observable();
    this[siteType + "SiteId"] = ko.observable();

    //client use only properties
    this.ShowFundable = ko.observable(true);
    this.SelectedDesignations = ko.observableArray([]);

    this.toPostModel = function () {
        var clone = ko.toJS(self);
        clone.BatchFromDate = clone.BatchFromDate ? moment(clone.BatchFromDate).utc().toISOString() : null;
        clone.BatchToDate = clone.BatchToDate ? moment(clone.BatchToDate).utc().toISOString() : null;
        clone.FromDate = clone.FromDate ? moment(clone.FromDate).utc().toISOString() : null;
        clone.ToDate = clone.ToDate ? moment(clone.ToDate).utc().toISOString() : null;
        clone.FromDepositDate = clone.FromDepositDate ? moment(clone.FromDepositDate).utc().toISOString() : null;
        clone.ToDepositDate = clone.ToDepositDate ? moment(clone.ToDepositDate).utc().toISOString() : null;
        return clone;
    };
}

//This giftSearchModel is paired with GiftSearchArguments on the server
function giftSearchModel(profileId, pageIndex, pageSize) {
    var result = new baseSearchModel(profileId, pageIndex, pageSize, "Gift");
    result.GiftSearchType = ko.observable();
    result.ChannelTypeSelected = ko.observableArray();
    result.GiftTypes = ko.observableArray();
    return result;
}

//This paymentSearchModel is paired with PaymentSearchArguments on the server
function paymentSearchModel(profileId, pageIndex, pageSize) {
    var result = new baseSearchModel(profileId, pageIndex, pageSize, "Payment");
    result.BankAccountId = ko.observable();
    result.PaymentStatus = ko.observableArray();
    result.PaymentSearchType = ko.observable();
    result.PaymentMethodSelected = ko.observableArray();
    result.CommitmentItemId = ko.observable();
    result.ChannelTypeSelected = ko.observableArray();
    result.DonorCoveredFee = ko.observableArray();
    return result;
}

function acknowledgementSearchModel(pageIndex, pageSize) {
    var result = new baseSearchModel(0, pageIndex, pageSize,"Acknowledgement");
    result.ChannelTypeSelected = ko.observableArray();
    result.SelectedFundables = ko.observableArray();
    result.AcknowledgementTypeSelected = ko.observableArray();
    return result;
}


//This commitmentSearchModel is paired with CommitmentLineItemSearchArguments on the server
function commitmentSearchModel(profileId, pageIndex, pageSize) {
    var result = new baseSearchModel(profileId, pageIndex, pageSize, "Commitment");
    result.CommitmentSearchType = ko.observable();

    result.FromEndDate = ko.observable();
    result.ToEndDate = ko.observable();
    result.HasActiveSchedule = ko.observable();
    result.HasSchedule = ko.observable();
    return result;
}

function templateSearchModel(pageIndex, pageSize) {
    var self = this;
    self.PageIndex = ko.observable(pageIndex);
    self.PageSize = ko.observable(pageSize);
    self.Name = ko.observable();
    self.UseComplex = ko.observable(false);
    self.Description = ko.observable();
    self.ChannelType = ko.observable();
    self.Markup = ko.observable();
    self.MarketingTemplateType = ko.observable();
    self.TemplateSearchType = ko.observable();
    self.QueryString = ko.observable();
    return self;
}

function triggerConfigurationSearchModel(pageIndex, pageSize) {
    var self = this;
    self.PageIndex = ko.observable(pageIndex);
    self.PageSize = ko.observable(pageSize);
    self.Name = ko.observable();
    self.UseComplex = ko.observable(false);
    self.Description = ko.observable();
    self.SourceType = ko.observable();
    self.QueryString = ko.observable();
    return self;
}

function volunteerParticipantSearchModel() {
    var self = this;
    self.SearchTerm = ko.observable();
    self.PageIndex = ko.observable(0);
    self.PageSize = ko.observable(10);
    self.VolunteerOpportunityId = ko.observable();
    self.VolunteerRoleId = ko.observable();
    self.Status = ko.observable();
    self.FundableId = ko.observable();
    self.Analytics = ko.observable(0);
    self.IsGivingPermissionBased = ko.observable();
    return self;
}


function simpleSearchModel() {
    var self = this;
    self.SearchTerm = ko.observable('*');
    self.PageIndex = ko.observable(0);
    self.PageSize = ko.observable(15);
    return self;
}

function projectSearchModel() {
    this.SearchTerm = ko.observable('*');
    this.PageIndex = ko.observable(0);
    this.PageSize = ko.observable(15);
    this.ProjectStatus = ko.observable();
}

function communicationSeriesSearchModel() {
    this.SearchTerm = ko.observable('*');
    this.PageIndex = ko.observable(0);
    this.PageSize = ko.observable(15);
    this.IsDynamicSchedule = ko.observable();
    this.ApealType = ko.observable();
    this.CommunicationSeriesType = ko.observable();
    this.UseComplex = ko.observable(false);
}

function appealSearchModel() {
    this.SearchTerm = ko.observable('*');
    this.PageIndex = ko.observable(0);
    this.PageSize = ko.observable(15);
    this.IsDynamicSchedule = ko.observable();
    this.AppealType = ko.observable();
    this.UseComplex = ko.observable(false);
}

function scheduledPaymentSearchModel() {
    this.PageIndex = ko.observable(0);
    this.PageSize = ko.observable(10);
    this.StartDate = ko.observable();
    this.EndDate = ko.observable();
    this.CommtimentLinetItemId = ko.observable();
    this.EventStatus = ko.observable();
}

function paymentLogErrorSearchModel() {
    this.PageIndex = ko.observable(0);
    this.PageSize = ko.observable(10);
    this.CommitmentLineItemId = ko.observable();
    this.LogId = ko.observable();
}

function paymentEventSearchModel() {
    this.PageIndex = ko.observable(0);
    this.PageSize = ko.observable(10);
    this.StartDate = ko.observable();
    this.EndDate = ko.observable();
}

function alertSubscriptionSearchModel() {
    this.PageIndex = ko.observable(0);
    this.PageSize = ko.observable(10);
    this.FundableId = ko.observable();
    this.SubscribedOrganizationProfileId = ko.observable();
    this.SubscribedOrganizationIndividualProfileId = ko.observable();
    this.AlertOrganizationIndividualProfileId = ko.observable();
    this.PackageId = ko.observable();
    this.QueryTerm = ko.observable('*');
}

function fundableSearchModel() {
    this.PageIndex = ko.observable(0);
    this.PageSize = ko.observable(10);
    this.FundableReferenceType = ko.observable();
    this.QueryTerm = ko.observable('*');
    this.FromStartDate = ko.observable();
    this.ToStartDate = ko.observable();
    this.FromEndDate = ko.observable();
    this.ToEndDate = ko.observable();
    this.IsArchived = ko.observable();
    this.IsHiddenFromDonors = ko.observable();
    this.IsActive = ko.observable();
    this.ProjectStatusType = ko.observable();
    this.EnableTypeSearch = ko.observable(false);
    this.UseComplex = ko.observable(false);
}

    function givingGoalSearchModel() {
        this.PageIndex = ko.observable(0);
        this.PageSize = ko.observable(10);
        this.OrganizationIndividualProfileId = ko.observable();
        this.OrganizationProfileId = ko.observable();
        this.GivingGoalType = ko.observable();
        this.MarketingCampaignId = ko.observable();
        this.GoalAmount = ko.observable();
        this.FromAmount = ko.observable();
        this.ToAmount = ko.observable();
        this.RemainingFromAmount = ko.observable();
        this.RemainingToAmount = ko.observable();
        this.FundedFromAmount = ko.observable();
        this.FundedToAmount = ko.observable();
        this.FromRemainingFundedByRecurring = ko.observable();
        this.ToRemainingFundedByRecurring = ko.observable();
        this.FirstName = ko.observable();
        this.LastName = ko.observable();
        //this.OrganizationName = ko.observable();
        this.QueryString = ko.observable('*');
        this.StartDate = ko.observable();
        this.EndDate = ko.observable();
        this.SiteId = ko.observable();
        this.FromDateCreated = ko.observable();
        this.ToDateCreated = ko.observable();
        this.GoalSearchType = ko.observable();
        this.TimeZone = ko.observable();
        this.FirstName = ko.observable();
        this.LastName = ko.observable();
        this.IsGivingPermissionBased = ko.observable(false);
        this.UseComplex = ko.observable(false);
    }

function designationMapSearchModel() {
    this.SelectedFundId = ko.observable(null);
    this.SelectedSubFundId = ko.observable(null);
    this.SelectedPledgeDriveId = ko.observable(null);
    this.IsOutbound = ko.observable(false);
    this.IncompleteOnly = ko.observable(false);
    this.Query = ko.observable(null);
    this.PageIndex = ko.observable(0);
    this.PageSize = ko.observable(20);
}

function marketingCommunicationSearchModel(pageIndex, pageSize) {
    this.FundableId = ko.observable();
    this.Channel = ko.observable();
    this.Type = ko.observable();
    this.IncompleteOnly = ko.observable(false);
    this.SearchTerm = ko.observable(null);
    this.PageIndex = ko.observable(pageIndex);
    this.PageSize = ko.observable(pageSize);
    this.MarketingEffortId = ko.observable();
    this.IsArchived = ko.observable(false);
}

function workplaceCampaignSearchModel() {
    this.PageIndex = ko.observable(0);
    this.PageSize = ko.observable(10);
    this.OrganizationProfileId = ko.observable();
    this.IsActive = ko.observable();
    this.SearchTerm = ko.observable('*');
}

function workplaceCampaignInviteSearchModel() {
    this.PageIndex = ko.observable(0);
    this.PageSize = ko.observable(10);
    this.Status = ko.observable();
    this.SearchTerm = ko.observable('*');
}

function expenseSearchModel() {
    this.PageIndex = ko.observable(0);
    this.PageSize = ko.observable(20);
    this.Status = ko.observable();
    this.SearchTerm = ko.observable('*');
    this.FromDate = ko.observable();
    this.ToDate = ko.observable();
    this.FromAmount = ko.observable();
    this.ToAmount = ko.observable();
    this.ExpenseTypes = ko.observableArray();
    this.DisbursementId = ko.observableArray();
}


function organizationWorkplaceDesignationSearchModel() {
    this.PageIndex = ko.observable();
    this.PageSize = ko.observable(10);
    this.SearchTerm = ko.observable('*');
    this.OrganizationProfileId = ko.observable();
}

function organizationExpenseSearchModel() {
    this.PageIndex = ko.observable();
    this.PageSize = ko.observable(10);
    this.SearchTerm = ko.observable('*');
    this.BankAccountId = ko.observable();
}

function catalogItemSearchModel() {
    this.PageIndex = ko.observable();
    this.PageSize = ko.observable();
    this.QueryTerm = ko.observable();
    this.ItemType = ko.observable();
    this.IsQuidProQuo = ko.observable();
    this.UseComplex = ko.observable(false);
    this.IsArchived = ko.observable(false);
}

function inventoryPurchaseSearchModel() {
    this.PageIndex = ko.observable();
    this.PageSize = ko.observable();
    this.QueryTerm = ko.observable();
    this.UseComplex = ko.observable(false);
    this.FromPurchaseDate = ko.observable();
    this.ToPurchaseDate = ko.observable();
    this.PurchaseOrder = ko.observable();
    this.CatalogItemId = ko.observable();
    this.TransactionType = ko.observable();
}

function chartOfAccountsSearchModel() {
    this.PageIndex = ko.observable();
    this.PageSize = ko.observable();
    this.QueryTerm = ko.observable();
    this.UseComplex = ko.observable(false);
    this.AccountType = ko.observable();
    this.FundableId = ko.observable();
    this.OrganizationFundId = ko.observable();
    this.BankAccountId = ko.observable();
    this.SiteId = ko.observable();
    this.ChannelType = ko.observable();
    this.PaymentType = ko.observable();
    this.RestrictionType = ko.observable();
    this.IsUserEdited = ko.observable();
}

function packageSearchModel() {
    this.PageIndex = ko.observable();
    this.PageSize = ko.observable();
    this.SearchTerm = ko.observable();
    this.UseComplex = ko.observable(false);
    this.PackageType = ko.observable();
    this.CatalogItemId = ko.observable();
    this.FundableId = ko.observable();
    this.AvailableFrom = ko.observable();
    this.AvailableTo = ko.observable();
    this.IsActive = ko.observable();
    this.TimeZone = ko.observable();
    this.IsArchived = ko.observable(false);
}

function ditSearchModel() {
    this.PageIndex = ko.observable(0);
    this.AsOfDate = ko.observable();
    this.PageSize = ko.observable(20);
}


function fulfillmentSearchModel() {
    this.PageIndex = ko.observable();
    this.PageSize = ko.observable();
    this.SearchTerm = ko.observable();
    this.CatalogItemId = ko.observable();
    this.ToDateCreated = ko.observable();
    this.FromDateCreated = ko.observable();
    this.ToDateShipped = ko.observable();
    this.FromDateShipped = ko.observable();
    this.IsActive = ko.observable();
    this.PaymentId = ko.observable();
    this.OrganizationProfileId = ko.observable();
    this.PackageId = ko.observable();
    this.OrganizationIndividualProfileId = ko.observable();
    this.Status = ko.observable();
    this.CommitmentLineItemId = ko.observable();
    this.CommitmentItemId = ko.observable();
    this.PicklistId = ko.observable();
    this.FirstName = ko.observable();
    this.LastName = ko.observable();
}

function picklistSearchModel() {
    this.QueryTerm = ko.observable();
    this.PackageId = ko.observable();
    this.StartPickDate = ko.observable();
    this.EndPickDate = ko.observable();
    this.PageIndex = ko.observable(0);
    this.PageSize = ko.observable(30);
    this.Status = ko.observable();
    this.IsArchived = ko.observable(false);
    this.StartFulfillmentAsOfDate = ko.observable();
    this.EndFulfillmentAsOfDate = ko.observable();
}

function shipperSearchModel() {
    this.QueryTerm = ko.observable();
    this.PageIndex = ko.observable(0);
    this.PageSize = ko.observable(30);
}

function volunteerFundraiserSearchModel() {
    this.PageIndex = ko.observable();
    this.PageSize = ko.observable();
    this.QueryString = ko.observable();
    this.OrganizationIndividualProfileId = ko.observable();
    this.FundableId = ko.observable();
    this.VolunteerOpportunityId = ko.observable();
    this.IsActive = ko.observable(true);
    this.IsGivingPermissionBased = ko.observable(false);
    this.PhotoApproval = ko.observable();
    this.StatementApproval = ko.observable();
    this.FirstName = ko.observable();
    this.LastName = ko.observable();
}

function newBaseSearchModel(pageIndex, pageSize) {

    if (!pageIndex) {
        pageIndex = 0;
    }

    if (!pageSize) {
        pageSize = 10;
    }

    var self = this;

    //fields to support computed properties
    this._fromAmount = ko.observable();
    this._toAmount = ko.observable();
    this._sourceCode = ko.observable();

    //base properties
    this.UseComplex = ko.observable(false);
    this.QueryString = ko.observable();
    this.IndividualProfileId = ko.observable();
    this.OrganizationProfileId = ko.observable();
    this.MarketingCommunicationId = ko.observable();
    this.MarketingEffortId = ko.observable();
    this.FundableId = ko.observable();
    this.HouseholdId = ko.observable();
    this.PostalCode = ko.observable();
    this.City = ko.observable();
    this.Region = ko.observable("");
    this.BatchNumber = ko.observable("");
    this.BatchFromDate = ko.observable();
    this.BatchToDate = ko.observable();
    this.FromDate = ko.observable();
    this.ToDate = ko.observable();
    this.FromDepositDate = ko.observable();
    this.ToDepositDate = ko.observable();
    this.Amount = ko.observable();
    this.TimeZone = ko.observable(jstz.determine().name());

    this.FromAmount = ko.computed({
        read: function () {
            if (isNaN(parseFloat(self._fromAmount())))
                return null;
            else
                return parseFloat(self._fromAmount());
        },
        write: function (value) {
            self._fromAmount(value);
        },
        owner: this
    });

    this.ToAmount = ko.computed({
        read: function () {

            if (isNaN(parseFloat(self._toAmount())))
                return null;
            else
                return parseFloat(self._toAmount());
        },
        write: function (value) {
            self._toAmount(value);
        },
        owner: this
    });

    this.PageIndex = ko.observable(pageIndex);
    this.PageSize = ko.observable(pageSize);
    this.Analytics = ko.observable(0);
    this.FundableId = ko.observable();
    this.SiteId = ko.observable();
    this.Channel = ko.observableArray();
    this.Status = ko.observableArray();

    //client use only properties
    this.ShowFundable = ko.observable(true);

    this.toPostModel = function () {
        var clone = ko.toJS(self);
        clone.BatchFromDate = clone.BatchFromDate ? moment(clone.BatchFromDate).utc().toISOString() : null;
        clone.BatchToDate = clone.BatchToDate ? moment(clone.BatchToDate).utc().toISOString() : null;
        clone.FromDate = clone.FromDate ? moment(clone.FromDate).utc().toISOString() : null;
        clone.ToDate = clone.ToDate ? moment(clone.ToDate).utc().toISOString() : null;
        clone.FromDepositDate = clone.FromDepositDate ? moment(clone.FromDepositDate).utc().toISOString() : null;
        clone.ToDepositDate = clone.ToDepositDate ? moment(clone.ToDepositDate).utc().toISOString() : null;
        return clone;
    };
}

function generalRevenueSearchModel(pageIndex, pageSize) {
    var result = new newBaseSearchModel(pageIndex, pageSize);
    return result;
}

function ledgerPostSearchModel(pageIndex, pageSize) {
    var self = this;
    self.PageIndex = ko.observable(pageIndex);
    self.PageSize = ko.observable(pageSize);
    self.FromPostDate = ko.observable();
    self.ToPostDate = ko.observable();
    self.BatchId = ko.observable();
    self.LedgerPostTypes = ko.observableArray();
    self.HasEntries = ko.observable(true);
    self.IsReconciled = ko.observable(null);
    return self;
}


function organizationProfileSearchModel(pageIndex, pageSize) {
    var self = this;
    self.PageIndex = ko.observable(pageIndex);
    self.PageSize = ko.observable(pageSize);
    self.SearchTerm = ko.observable();
    self.OrganizationProfileType = ko.observable(0);
    return self;
}

module.exports = {
    SubscribeBrowserOnlyPagination: subscribeBrowserOnlyPagination,
    SubscribePagination: subscribePagination,
    SubscribeSearchPagination: subscribeSearchPagination,
    PageModel: pageModel,
    GiftSearchModel: giftSearchModel,
    PaymentSearchModel: paymentSearchModel,
    CommitmentSearchModel: commitmentSearchModel,
    GLSearchModel: glSearchModel,
    LedgerPostSearchModel: ledgerPostSearchModel,
    IndividualRelationshipSearchModel: individualRelationshipSearchModel,
    IndividualSearchModel: individualSearchModel,
    BatchSearchModel: batchSearchModel,
    MarketingTemplateSearchModel: templateSearchModel,
    TriggerConfigurationSearchModel: triggerConfigurationSearchModel,
    SimpleSearchModel: simpleSearchModel,
    ProjectSearchModel: projectSearchModel,
    VolunteerParticipantSearchModel: volunteerParticipantSearchModel,
    CommunicationSeriesSearchModel: communicationSeriesSearchModel,
    AppealSearchModel: appealSearchModel,
    ScheduledPaymentSearchModel: scheduledPaymentSearchModel,
    AlertSubscriptionSearchModel: alertSubscriptionSearchModel,
    FundableSearchModel: fundableSearchModel,
    GivingGoalSearchModel: givingGoalSearchModel,
    WorkplaceCampaignSearchModel: workplaceCampaignSearchModel,
    WorkplaceCampaignInviteSearchModel: workplaceCampaignInviteSearchModel,
    OrganizationWorkplaceDesignationSearchModel: organizationWorkplaceDesignationSearchModel,
    DesignationMapSearchModel: designationMapSearchModel,
    MarketingCommunicationSearchModel: marketingCommunicationSearchModel,
    OrganizationExpenseSearchModel: organizationExpenseSearchModel,
    CatalogItemSearchModel: catalogItemSearchModel,
    InventoryPurchaseSearchModel: inventoryPurchaseSearchModel,
    ChartOfAccountsSearchModel: chartOfAccountsSearchModel,
    PackageSearchModel: packageSearchModel,
    FulfillmentSearchModel: fulfillmentSearchModel,
    PicklistSearchModel: picklistSearchModel,
    ShipperSearchModel: shipperSearchModel,
    VolunteerFundraiserSearchModel: volunteerFundraiserSearchModel,
    GeneralRevenueSearchModel: generalRevenueSearchModel,
    OrganizationProfileSearchModel: organizationProfileSearchModel,
    AcknowledgementSearchModel: acknowledgementSearchModel,
    DITSearchModel: ditSearchModel,
    ExpenseSearchModel: expenseSearchModel
};