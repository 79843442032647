define([],function() {

    function getFundablePhoto(p, path) {
        console.log(p);
        console.log(path);
        $.ajax({
            url: path,
            data: { PageIndex: p.PageIndex(), pageSize: p.PageSize() },
            type: 'POST',
            error: function (xhr, ajaxOptions, thrownError) {
                console.log(xhr.statusText);
                console.log(xhr.responseText);
                console.log(xhr.status);
                console.log(thrownError);
            },
            success: function(data, textStatus, jqXhr) {
                p.PageData(ko.mapping.fromJS(data.Data.PageData, vm.PotentialPhotos.PageData));
                p.PageIndex(data.Data.PageIndex);
                p.PageSize(data.Data.PageSize);
                p.TotalCount(data.Data.TotalCount);
                p.IsFirstPage(data.Data.IsFirstPage);
                p.IsLastPage(data.Data.IsLastPage);
                p.HasNextPage(data.Data.HasNextPage);
                p.HasPreviousPage(data.Data.HasPreviousPage);
                p.TotalPages(data.Data.TotalPages);
            }
        });
    }

    function photoPageModel() {
        this.PageData = ko.observableArray();
        this.PageIndex = ko.observable(0);
        this.PageSize = ko.observable(9);
        this.TotalCount = ko.observable(0); 
        this.IsFirstPage =ko.observable(); 
        this.IsLastPage=ko.observable(); 
        this.HasNextPage=ko.observable(); 
        this.HasPreviousPage=ko.observable(); 
        this.TotalPages = ko.observable(0);
        return this;
    }

    return {
        GetFundablePhoto: getFundablePhoto,
        PhotoPageModel : photoPageModel
    };
});