var template = require('./package-search.html');




function viewModel(params) {

    var self = this;

    self.SearchCriteria = params.SearchCriteria;
    self.Packages = params.Packages;
    
    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};

