var template = require('./paymenttype-picker.html');



function paymentMethodDetail() {
    this.Id = ko.observable();
    this.Name = ko.observable();
}

function viewModel(params) {
    var self = this;
    self.PaymentMethodId = params.Id;
    self.PaymentMethods = ko.observableArray();
    
    $.ajax({
        url: '/Finance/PaymentMethod/GetPaymentMethods',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: {},
        success: function (data, textStatus, jqXhr) {
                ko.mapping.fromJS(data.Data.PageData, {}, self.PaymentMethods);
                var item = new paymentMethodDetail();
                item.Name('None');
                self.PaymentMethods.unshift(item);
                self.PaymentMethodId(null);
        },
            error: function (data, textStatus, jqXhr) {
                console.log('error');
                console.log(data);
            }
        });
   
    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};

