var template = require('./fundable-status.html');





function viewModel(params) {

    var self = this;
    self.Designation = params.Designation;
    self.Fundables = params.Fundables;

    self.archive = function (d) {
        notify.confirm('Are you sure you want to archive this designation?', function () {
            var id = d.Designation.FundableId();
            v2Form.POST({
                block: true,
                element: $("#koMainIndex"),
                url: '/Finance/Designation/Archive',
                data: { id: id },
                success: function (data) {
                    if (data.Data === true) {
                        d.Designation.IsArchived(true);
                    }
                }
            });
        });
    };

    self.unarchive = function (d) {
        notify.confirm('Are you sure you want to unarchive this designation?', function () {
            var id = d.Designation.FundableId();
            v2Form.POST({
                block: true,
                element: $("#koMainIndex"),
                url: '/Finance/Designation/Unarchive',
                data: { id: id },
                success: function (data) {
                    if (data.Data === true) {
                        d.Designation.IsArchived(false);
                    }
                }
            });
        });
    };


    self.hide = function (d) {
        notify.confirm('Are you sure you want to hide this designation from donor search?', function () {
            var id = d.Designation.FundableId();
            v2Form.POST({
                block: true,
                element: $("#koMainIndex"),
                url: '/Finance/Designation/Hide',
                data: { id: id },
                success: function (data) {
                    if (data.Data === true) {
                        d.Designation.IsHiddenFromDonorSearch(true);
                    }
                }
            });
        });
    };

    self.unhide = function (d) {
        notify.confirm('Are you sure you want to make this designation visible for donor search?', function () {
            var id = d.Designation.FundableId();
            v2Form.POST({
                block: true,
                element: $("#koMainIndex"),
                url: '/Finance/Designation/Unhide',
                data: { id: id },
                success: function (data) {
                    if (data.Data === true) {
                        d.Designation.IsHiddenFromDonorSearch(false);
                    }
                }
            });
        });
    };


    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};

