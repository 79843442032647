var template = require("./departmentselect.html");

function viewModel(params) {
    var vm = this;

    vm.DepartmentId = params.DepartmentId;
    vm.Items = ko.observableArray();
    vm.Name = ko.observable();
    vm.loaded = ko.observable(false);
    vm.SearchText = ko.observable();
    vm.selected = ko.computed(function () {
        if (vm.DepartmentId() > 0)
            return true;
        return false;
    });
    vm.add = function () {
        alert("add clicked");
    }
    vm.addbuttonlabel = ko.observable("Add Department");
    vm.addItem = ko.observable(true);
    vm.label = ko.observable("Department:");
    vm.dropdownVisible = ko.observable(true);
    vm.select = function (data) {
        vm.DepartmentId(data.Id);
    };

   
    $.ajax({
        url: '/Finance/Department/GetList',
        type: 'POST',
        contentType: 'application/json',
        dataType: 'json',
        
        success: function (data, textStatus, jqXhr) {
            console.log('success', data);
            if (data && data.Data) {
                var none = {
                    Id: ko.observable(null),
                    Name: "No Department Selected",
                    AccountSegment: null
                };
                vm.Items.push(none);
                ko.utils.arrayForEach(data.Data.PageData, function (item) {
                    var dept = {
                        Id: ko.observable(item.Id),
                        Name: ko.observable(item.Name),
                        AccountSegment: ko.observable(item.AccountSegment)
                    };
                    vm.Items.push(dept);
                });
                //console.log('vm', ko.toJS(vm));
            }

            vm.loaded(true);
        },
        error: function (data, textStatus, jqXhr) {
            console.log('error',data);
           
        }
    });

}

module.exports = {
    viewModel: viewModel,
    template:template

}