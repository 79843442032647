var template = require('./promotionlist-select.html');


function viewModel(params) {
    var self = this;
    //console.log('promo',params);
    self.PromotionListId = params.PromotionListId;
    self.AllowNoPromotionListId = ko.observable(false);
    if (params.AllowNoPromotionListId) {
        self.AllowNoPromotionListId(ko.utils.unwrapObservable(params.AllowNoPromotionListId));
    }

    self.clearSelectedPromotionList = function () {
        if (self.AllowNoPromotionListId()) {
            self.PromotionListId(null);
        } else {
            self.PromotionListId(0);
        }
    };
    self.noColFormatting = ko.observable(false);
    if (params.noCol) {
        self.noColFormatting(params.noCol);
    }


    self.GetPromotionLists = function (options) {
        if (options.text == "") return;

        $.ajax({
            url: '/Marketing/PromotionList/Search',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({ searchTerm: options.text, PageIndex: options.page, PageSize: 15, IsArchived: false }),
            success: function (data, textStatus, jqXhr) {
               options.callback(data.Data);
            },
            error: function (data, textStatus, jqXhr) {
                console.log('error');
                console.log(data);
            }
        });
    };

    self.PromotionList = ko.observable();
    self.PromotionListName = ko.observable();
   


    self.PromotionList.subscribe(function (x) {
        if (x != null) {
            self.PromotionListName(ko.utils.unwrapObservable(x.Name));
            self.PromotionListId(ko.utils.unwrapObservable(x.Id));
        }
    });


    self.getDetail = function () {
        v2Form.POST({
            url: '/Marketing/PromotionList/GetDisplay',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: { id: self.PromotionListId() },
            success: function (data, textStatus, jqXhr) {
                self.PromotionList(data.Data);
            },
            error: function (data, textStatus, jqXhr) {
                console.log('error');
                console.log(data);
            }
        });
    };


    if (params.PromotionListId() != null && params.PromotionListId() > 0)
        self.getDetail();

    // console.log(params, self);
}


module.exports = {
    viewModel: viewModel,
    template: template

};

