var pagination = require("../../v2/paginationServices");
var analytics = require("../../v2/analytics.js");
require("knockout");
require("knockout-mapping");
require("../../v2/knockout.custombindings");
var radio = require("radio");
var template = require("./commitmentlineitemsearch.html");
var moment = require("moment");
var jstz = require("jstz");
//var enumerations = require("../../v2/enumTranslations");

function viewModel(params) {
    this.Commitments = new pagination.PageModel();
    this.HasPermissionToEdit = ko.computed(function () {
        return UserPermissions.HasPermission('GiftPermissions.Edit')
    });
    
    this.displayAnalytics = ko.observable(true);
    this.displaySearch = ko.observable(true);
    this.UseComplex = ko.observable(false);
    this.loading = ko.observable(false);
    //this.enumerations = enumerations;

    this.resultDownloadModalVisible = ko.observable(false);

    var profileId = ko.utils.unwrapObservable(params.ProfileId) || 0;
    var pageSize = params.PageSize || 30;

    this.CommitmentSearchCriteria = new pagination.CommitmentSearchModel(profileId, 0, pageSize);
    this.CommitmentSearchCriteria.Analytics(0);
    EventCalendarExtender.DatePickerEnable(this.CommitmentSearchCriteria, this.CommitmentSearchCriteria.FromDate);
    EventCalendarExtender.DatePickerEnable(this.CommitmentSearchCriteria, this.CommitmentSearchCriteria.ToDate);
    EventCalendarExtender.DatePickerEnable(this.CommitmentSearchCriteria, this.CommitmentSearchCriteria.FromEndDate);
    EventCalendarExtender.DatePickerEnable(this.CommitmentSearchCriteria, this.CommitmentSearchCriteria.ToEndDate);

    if (params.SiteId)
        this.CommitmentSearchCriteria.SiteId(ko.utils.unwrapObservable(params.SiteId));

    if (params.FundableId)
        this.CommitmentSearchCriteria.FundableId(ko.utils.unwrapObservable(params.FundableId));

    if (params.HouseholdId)
        this.CommitmentSearchCriteria.HouseholdId(ko.utils.unwrapObservable(params.HouseholdId));

    if (params.HasSchedule)
        this.CommitmentSearchCriteria.HasSchedule(ko.utils.unwrapObservable(params.HasSchedule));

    if (params.MarketingEffortId)
        this.CommitmentSearchCriteria.MarketingEffortId(ko.utils.unwrapObservable(params.MarketingEffortId));

    if (params.IsOpen) {
        var value = ko.utils.unwrapObservable(params.IsOpen);
        if (value === true) {
            this.CommitmentSearchCriteria.CommitmentStatus(0);
        }
    }
       

    if (params.MarketingCommunicationId)
        this.CommitmentSearchCriteria.MarketingCommunicationId(ko.utils.unwrapObservable(params.MarketingCommunicationId));

    if (params.displayAnalytics)
        this.displayAnalytics(ko.utils.unwrapObservable(params.displayAnalytics));

    if (params.displaySearch)
        this.displaySearch(ko.utils.unwrapObservable(params.displaySearch));

    if (params.UseComplex)
        this.CommitmentSearchCriteria.UseComplex(ko.utils.unwrapObservable(params.UseComplex));

    if (params.IsActiveWithFundingMethod)
        this.CommitmentSearchCriteria.UseComplex(ko.utils.unwrapObservable(params.IsActiveWithFundingMethod));

    if (params.IsGivingPermissionBased)
        this.CommitmentSearchCriteria.IsGivingPermissionBased(ko.utils.unwrapObservable(params.IsGivingPermissionBased));

    if (params.IsCampaignParticipationBased)
        this.CommitmentSearchCriteria.IsCampaignParticipationBased(ko.utils.unwrapObservable(params.IsCampaignParticipationBased));

    if (params.Type == 0)
        console.warn("no search type passed to commitment line item search");

    this.CommitmentSearchCriteria.CommitmentSearchType(params.Type || 0);

   

    pagination.SubscribeSearchPagination(this, this.Commitments, '/Giving/Commitment/Search', this.CommitmentSearchCriteria);


    this.navigateToIndividualDetailsFromSearch = function (d) {
        //sessionStorage.setItem("RefreshCommitmentSearch", JSON.stringify(ko.toJS(viewModel.Payments.PaymentSearchCriteria)));
        window.open('/People/Individual/Details/' + d.Individual.Id());
    };

    this.navigateToOrganizationDetailsFromSearch = function (d) {
       // sessionStorage.setItem("RefreshPaymentSearch", JSON.stringify(ko.toJS(viewModel.Payments.PaymentSearchCriteria)));
        window.open('/Organization/Profile/Details/' + d.Organization.Id());
    };

    this.navigateToDetails = function (d) {
        //sessionStorage.setItem("RefreshPaymentSearch", JSON.stringify(ko.toJS(viewModel.Payments.PaymentSearchCriteria)));
        window.open('/Giving/Gift/Details/' + d.CommitmentItemId());
    };

    this.navigateToEdit = function (d) {
        //sessionStorage.setItem("RefreshPaymentSearch", JSON.stringify(ko.toJS(viewModel.Payments.PaymentSearchCriteria)));
        window.open('/Giving/Gift/Details/' + d.CommitmentItemId());
    };

    this.CommitmentSearchCriteria.TimeZone(jstz.determine().name());
    
    this.Commitments.firstPage();

    this.GetSpreadSheet = function () {
        //console.log('get spreadsheet');
        this.CommitmentSearchCriteria.LocalUrl = window.location.pathname;

        v2Form.POST({
            url: '/Giving/Commitment/CommitmentAnalyticsSpreadsheet',
            data: ko.toJS(this.CommitmentSearchCriteria),
            error: function () {
                v2Form.GET({
                    url: '/Giving/Commitment/GetCommitmentSearchSpreadsheet'
                });
            },
            complete: function () { $('body').unblock(); }
        });
    };
    
}


module.exports = {
    viewModel: viewModel,
    template: template
}