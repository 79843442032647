/* File Created: April 9, 2013 */
/* Copyright 2013 Vision2Systems LLC All rights reserved */
(function () {
    function findFundableMatches(profile, resultsArray) {
        if (profile.FundableName() != "") {
            $.ajax({
                url: '/Marketing/PromotionList/SearchDesignation',
                type: 'POST',
                contentType: 'application/json',
                dataType: 'json',
                data: JSON.stringify(
                {
                    searchDesignation: profile.FundableName()
                }),
                success: function (data, textStatus, jqXhr) {
                    profile.FundableMatches(data);
                }
            });
        }
        else {
            profile.FundableMatches(0);
        }
    }

    function subscribeFundableChanges(profile) {
        profile.FundableName.subscribe(function (nv) {
            if (!profile.AvoidSecondCall) {
                profile.AvoidSecondCall = true;
                properCase(profile.FundableName);
                findFundableMatches(profile);
                profile.AvoidSecondCall = false;
            }
        });

        profile.FundableSelected = function (d, e) {
            newFundable = new Object();
            newFundable.FundableId = ko.observable(d.FundableId);
            newFundable.FundableName = ko.observable(d.Name);
            newFundable.Id = ko.observable(0);
            newFundable.RowStatus = ko.observable(0);
            newFundable.Sequence = ko.observable(0);
            newFundable.ShortDescription = ko.observable(d.ShortDescription);

            profile.ListItems.push(newFundable);

            profile.FundableName("");
        };

        profile.ListItems.Delete = function (d, e) {
            for (var i = 0; i < profile.ListItems().length; i++)
            {
                if (profile.ListItems()[i].Id() == d.Id() && profile.ListItems()[i].FundableName() == d.FundableName() && profile.ListItems()[i].ShortDescription() == d.ShortDescription()) {
                    profile.ListItems()[i].RowStatus = ko.observable(2);
                    var listItem = profile.ListItems()[i];
                    profile.ListItems.splice(i, 1);
                    profile.ListItems.push(listItem);
                    break;
                }
            }
        };

        profile.AddCategory = function (d, e) {
            newCategory = new Object();
            newCategory.Id = ko.observable(0);
            newCategory.FriendlyName = ko.observable("");
            newCategory.Tags = ko.observable("");
            newCategory.RowStatus = ko.observable(0);

            profile.Categories.push(newCategory);
        }

        profile.Categories.Delete = function (d, e) {
            for (var i = 0; i < profile.Categories().length; i++) {
                if (profile.Categories()[i].Id() == d.Id() && profile.Categories()[i].FriendlyName() == d.FriendlyName() && profile.Categories()[i].Tags() == d.Tags()) {
                    profile.Categories()[i].RowStatus = ko.observable(2);
                    var category = profile.Categories()[i];
                    profile.Categories.splice(i, 1);
                    profile.Categories.push(category);
                    break;
                }
            }
        }
    }

    function properCase(o) {
        var temp = ko.utils.unwrapObservable(o);
        console.log(temp);
        if (temp != null && temp === temp.toLowerCase()) {
            temp = temp.substring(0, 1).toUpperCase() + temp.substring(1);
            o(temp);
        }
    }

    window.fundableServices = {
        SubscribeFundableChanges: subscribeFundableChanges
    };
})();