var template = require("./giftnotes.html");


function GiftNotesModel() {
            this.GiftId = ko.observable(0);
            this.GiftNoteId = ko.observable(0);
            this.GiftNote = ko.observable(null);
            this.UserAccountId = ko.observable(0);
            this.RowStatus = ko.observable(1);
        }




function viewModel(params){
    var vm = this;

  
    vm.Notes = params.Notes || ko.observableArray();
    
    vm.Notes.Add = function() {
            vm.Notes.push(new GiftNotesModel());
            
        };

    vm.Notes.Delete = function(row) {
        notify.confirm('Are you sure you want to delete this item?').then(result=>{
            if(result.value){ 
              
                if (row.RowStatus() == 1) {
                    vm.Notes.remove(row);
                } else {
                    row.RowStatus(2);
                } /*end all cases if*/
            }
          });
    };


}

module.exports = {
    viewModel : viewModel,
    template: template
}