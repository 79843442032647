var template = require('./fundable-search.html');

function viewModel(params) {

    

    var self= params.Fundables;
    
    
    self.resultDownloadModalVisible = ko.observable(false);
    
    EventCalendarExtender.DatePickerEnable(self.SearchCriteria, self.SearchCriteria.FromStartDate);
    EventCalendarExtender.DatePickerEnable(self.SearchCriteria, self.SearchCriteria.ToStartDate);
    EventCalendarExtender.DatePickerEnable(self.SearchCriteria, self.SearchCriteria.FromEndDate);
    EventCalendarExtender.DatePickerEnable(self.SearchCriteria, self.SearchCriteria.ToEndDate);

    self.GetSpreadSheet = function () {
        //console.log('get spreadsheet');
        self.SearchCriteria.LocalUrl = window.location.pathname;

        v2Form.POST({
            url: '/Finance/Designation/DesignationsSpreadsheet',
            data: ko.toJS(self.SearchCriteria),
            error: function () {
                v2Form.GET({
                    url: '/Finance/Designation/GetDesignationSearchSpreadsheet'
                });
            },
            complete: function () { $('body').unblock(); }
        });
    };

   // console.log(self);
    return self;
    
}

module.exports = {
    viewModel: viewModel,
    template: template
};

