/// <reference path="../../Areas/Giving/Views/Shared/giftCommitmentSearchTemplate.cshtml" />
/* File Created: December 7, 2012 */
/* Copyright 2012 Vision2Systems LLC All rights reserved */



    function assignCurrencyStats(m, f) {

        m.Count(f.Count);
        m.Mean(currency.US.display(f.Mean));
        m.Min(currency.US.display(f.Min));
        m.Max(currency.US.display(f.Max));
        m.Total(currency.US.display(f.Total));
    }

    function statsModel(m, f) {
       
        this.Name = ko.observable(m);
        this.Count = ko.observable(f.Count);
        this.Mean = ko.observable(currency.US.display(f.Mean));
        this.Min = ko.observable(currency.US.display(f.Min));
        this.Max = ko.observable(currency.US.display(f.Max));
        this.Total = ko.observable(currency.US.display(f.Total));
    }

    function assignZeroStats(m) {

        m.Count(0);
        m.Mean(currency.US.display(0));
        m.Min(currency.US.display(0));
        m.Max(currency.US.display(0));
        m.Total(currency.US.display(0));
    }

    //handlers
    var handler = {};
    handler.giftAmount = function (d, f) {

        if (d.GivingSummary == null)
            return;

        (f.count !== 0) ? assignCurrencyStats(d.GivingSummary, f) : assignZeroStats(d.GivingSummary);
    };

    handler.genderGiving = function (d, f) {
        if (d.GenderGiving == null)
            return;
        d.GenderGiving.Data = [];
        for (var j = 0; j < f.Items.length; j++) {
            var item = f.Items[j];
            d.GenderGiving.Data.push([item.Display, item.Total]);
        }
       
        if (!ko.isObservable(d.GenderGiving.chartOptions))
            d.GenderGiving.chartOptions = ko.observable();

        d.GenderGiving.chartOptions({
            chart: {
                type: 'pie'
            },
            title: {
                text: 'Demographics - Giving by Gender',
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage}%</b>',
                percentageDecimals: 1
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        formatter: function () {
                            var pointName = this.point.name;
                            var percent = Math.round(this.percentage);
                            if (pointName.length > 25) {
                                return '<b>' + pointName.substring(0, 21) + "...</b>" + percent + '%';
                            } else {
                                return '<b>' + pointName + '</b>:' + percent + '%';
                            }
                        }
                    }
                }
            },
            series: [{
                type: 'pie',
                name: 'GenderGiving',
                data: d.GenderGiving.Data
            }]
        });

    };

    handler.giftYTD = function (d, f) {
        if (d.GivingYTD === null)
            return;

        d.GivingYTD.Data = [];


        var ytd = handler.getStatsFor('YTD', f.Items);
        var pytd = handler.getStatsFor('PYTD', f.Items);
        var py = handler.getStatsFor('PY', f.Items);
        var tpy = handler.getStatsFor('2PY', f.Items);

        if (ytd)
            d.PaymentYOY.Data.push(Math.round(100 * ytd.Total / 100));
        if (pytd)
            d.PaymentYOY.Data.push(Math.round(100 * pytd.Total / 100));
        if (py)
            d.PaymentYOY.Data.push(Math.round(100 * py.Total / 100));
        if (tpy)
            d.PaymentYOY.Data.push(Math.round(100 * tpy.Total / 100));

        if (!ko.isObservable(d.GivingYTD.chartOptions))
            d.GivingYTD.chartOptions = ko.observable();


        d.GivingYTD.chartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 110, 80]
            },
            title: {
                text: 'Giving YTD Year over Year'
            },
            xAxis: {
                categories: d.GivingYTD.Categories,
                labels: {
                    title: 'Year',
                    rotation: -60,
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    }
                }
            },
            yAxis: {
                title: {
                    text: 'Total Given'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Giving',
                data: d.GivingYTD.Data
            }]
        });


    };

    handler.top10postalGiving = function (d, f) {
        if (d.Top10PostalCodeGiving == null || f.Items.length == 0)
            return;

        d.Top10PostalCodeGiving.Categories = [];
        d.Top10PostalCodeGiving.TotalData = [];
        d.Top10PostalCodeGiving.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var item = f.Items[j];
            d.Top10PostalCodeGiving.Categories.push(item.Disiplay);
            d.Top10PostalCodeGiving.TotalData.push(Math.round(item.Total * 100) / 100);
            d.Top10PostalCodeGiving.AverageData.push(Math.round(item.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.Top10PostalCodeGiving.totalChartOptions))
            d.Top10PostalCodeGiving.totalChartOptions = ko.observable();

        d.Top10PostalCodeGiving.totalChartOptions({
            chart: {
                renderTo: 'top10TotalPostalGivingContainer',
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Total Giving For Top 10 Postal Codes'
            },
            xAxis: {
                categories: d.Top10PostalCodeGiving.Categories,
                labels: {
                    rotation: -45,
                    align: 'right',
                    title: 'Postal Code',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Giving'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        'Giving Total ' + highcharts.numberFormat(this.y, 2);
                }
            },
            series: [{
                name: 'Top 10 Postal Codes',
                data: d.Top10PostalCodeGiving.TotalData
            }]
        });

        if (!ko.isObservable(d.Top10PostalCodeGiving.averageChartOptions))
            d.Top10PostalCodeGiving.averageChartOptions = ko.observable();

        d.Top10PostalCodeGiving.averageChartOptions({
            chart: {
                renderTo: 'top10AvgPostalGivingContainer',
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Average Giving For Top 10 Postal Codes'
            },
            xAxis: {
                categories: d.Top10PostalCodeGiving.Categories,
                labels: {
                    rotation: -45,
                    align: 'right',
                    title: 'Postal Code',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Gift'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        'Average Gift ' + highcharts.numberFormat(this.y, 2);
                }
            },
            series: [{
                name: 'Top 10 Postal Codes - Average Gift',
                data: d.Top10PostalCodeGiving.AverageData
            }]
        });

    };

    handler.ageGiving = function (d, f) {
        if (d.AgeGiving == null || f.Items.length === 0)
            return;

        d.AgeGiving.TotalData = [];
        d.AgeGiving.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var rng = f.Items[j];
            d.AgeGiving.TotalData.push(Math.round(rng.Total * 100) / 100);
            d.AgeGiving.AverageData.push(Math.round(rng.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.AgeGiving.totalChartOptions))
            d.AgeGiving.totalChartOptions = ko.observable();

        d.AgeGiving.totalChartOptions({
            chart: {
                renderTo: 'ageGivingTotalContainer',
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Demographics - Total Giving by Age'
            },
            xAxis: {
                categories: d.AgeGiving.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Age Range',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Giving'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        'Giving Total ' + highcharts.numberFormat(this.y, 2);
                }
            },
            series: [{
                name: 'Age Ranges',
                data: d.AgeGiving.TotalData
            }]
        });


        if (!ko.isObservable(d.AgeGiving.averageChartOptions))
            d.AgeGiving.averageChartOptions = ko.observable();

        d.AgeGiving.averageChartOptions({
            chart: {
                renderTo: 'ageGivingAvgContainer',
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Demographics - Average Gift by Age'
            },
            xAxis: {
                categories: d.AgeGiving.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Age Range',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Gift'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        'Average Gift ' + highcharts.numberFormat(this.y, 2);
                }
            },
            series: [{
                name: 'Age Ranges',
                data: d.AgeGiving.AverageData
            }]
        });

    };

    handler.channelGiving = function (d, f) {
        if (d.ChannelGiving == null || f.Items.length == 0)
            return;

        d.ChannelGiving.Categories = [];
        d.ChannelGiving.TotalData = [];
        d.ChannelGiving.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var r = f.Items[j];
            d.ChannelGiving.Categories.push(r.Display);


            d.ChannelGiving.TotalData.push(Math.round(r.Total * 100) / 100);
            d.ChannelGiving.AverageData.push(Math.round(r.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.ChannelGiving.totalChartOptions))
            d.ChannelGiving.totalChartOptions = ko.observable();

        d.ChannelGiving.totalChartOptions({
            chart: {
                renderTo: 'channelGivingTotalContainer',
                type: 'column',
                margin: [50, 50, 110, 80]
            },
            title: {
                text: 'Marketing - Total Given by Channel'
            },
            xAxis: {
                categories: d.ChannelGiving.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Channel',
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Given'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        'Giving Total $' + highcharts.numberFormat(this.y, 2);
                }
            },
            series: [{
                name: 'Age Ranges',
                data: d.ChannelGiving.TotalData
            }]
        });

        if (!ko.isObservable(d.ChannelGiving.averageChartOptions))
            d.ChannelGiving.averageChartOptions = ko.observable();

        d.ChannelGiving.averageChartOptions({
            chart: {
                renderTo: 'channelGivingAvgContainer',
                type: 'column',
                margin: [50, 50, 110, 80]
            },
            title: {
                text: 'Marketing - Average Gift by Channel'
            },
            xAxis: {
                categories: d.ChannelGiving.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Channel',
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 13) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Gift'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Age Ranges',
                data: d.ChannelGiving.AverageData
            }]
        });

    };

    handler.lastYearGivingInMonths = function (d, f) {

    };

    handler.sourcecodeGiving = function (d, f) {
        if (d.SourceCodeGiving == null || f.Items.length == 0)
            return;

        d.SourceCodeGiving.Categories = [];
        d.SourceCodeGiving.TotalData = [];
        d.SourceCodeGiving.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var r = f.Items[j];
            d.SourceCodeGiving.Categories.push(r.Display);
            d.SourceCodeGiving.TotalData.push(Math.round(r.Total * 100) / 100);
            d.SourceCodeGiving.AverageData.push(Math.round(r.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.SourceCodeGiving.totalChartOptions))
            d.SourceCodeGiving.totalChartOptions = ko.observable();

        d.SourceCodeGiving.totalChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Marketing - Total 10 Source Codes'
            },
            xAxis: {
                categories: d.SourceCodeGiving.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Channel',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Given'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Age Ranges',
                data: d.SourceCodeGiving.TotalData
            }]
        });

        if (!ko.isObservable(d.SourceCodeGiving.averageChartOptions))
            d.SourceCodeGiving.averageChartOptions = ko.observable();

        d.SourceCodeGiving.averageChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Marketing - Top 10 Source Codes'
            },
            xAxis: {
                categories: d.SourceCodeGiving.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Channel',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Gift'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Age Ranges',
                data: d.SourceCodeGiving.AverageData
            }]
        });

    };

    //TODO: Review this and make sure it is right (its basically hard copied from the designatedGift handler below)
    handler.designatedCommitments = function (d, f) {
        if (d.FundableCommitments == null || f.Items.length == 0)
            return;
        d.FundableCommitments.Categories = [];
        d.FundableCommitments.TotalData = [];
        d.FundableCommitments.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var item = f.Items[j];
            d.FundableCommitments.Categories.push(item.Display);
            d.FundableCommitments.TotalData.push(Math.round(item.Total * 100) / 100);
            d.FundableCommitments.AverageData.push(Math.round(item.Mean * 100) / 100);
        }

        
        if (!ko.isObservable(d.FundableCommitments.totalChartOptions))
            d.FundableCommitments.totalChartOptions = ko.observable();

        d.FundableCommitments.totalChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Designated - Commitments by Designation'
            },
            xAxis: {
                categories: d.FundableCommitments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Designation',
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Commitment'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Total Designated Commitment',
                data: d.FundableCommitments.TotalData
            }]
        });

        if (!ko.isObservable(d.FundableCommitments.averageChartOptions))
            d.FundableCommitments.averageChartOptions = ko.observable();

        d.FundableCommitments.averageChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Designated - Avg Commitment by Designation'
            },
            xAxis: {
                categories: d.FundableCommitments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Designation',
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Commitment'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Designated - Average Commitment',
                data: d.FundableCommitments.AverageData
            }]
        });
    };

    handler.designatedCommitmentsByType = function (d, f) {
        if (d.FundableTypeCommitments == null || f.Items.length == 0)
            return;
        d.FundableTypeCommitments.Categories = [];
        d.FundableTypeCommitments.TotalData = [];
        d.FundableTypeCommitments.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var item = f.Items[j];
            d.FundableTypeCommitments.Categories.push(item.Display);
            d.FundableTypeCommitments.TotalData.push(Math.round(item.Total * 100) / 100);
            d.FundableTypeCommitments.AverageData.push(Math.round(item.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.FundableTypeCommitments.totalChartOptions))
            d.FundableTypeCommitments.totalChartOptions = ko.observable();

        d.FundableTypeCommitments.totalChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Designated - Commitments by Designation Type'
            },
            xAxis: {
                categories: d.FundableTypeCommitments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Designation Type',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Commitments'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Total Designated Commitments',
                data: d.FundableTypeCommitments.TotalData
            }]
        });

        if (!ko.isObservable(d.FundableTypeCommitments.averageChartOptions))
            d.FundableTypeCommitments.averageChartOptions = ko.observable();

        d.FundableTypeCommitments.averageChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Designated - Avg Commitment by Designation Type'
            },
            xAxis: {
                categories: d.FundableTypeCommitments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Designation Type',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Commitment'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Designated - Average Commitment',
                data: d.FundableTypeCommitments.AverageData
            }]
        });

    };

    handler.getStatsFor = function (s, a) {
        console.log('get stats for', s, a);
        var val = ko.utils.arrayFirst(a, function (i) {
            console.log('item', i);
            if (i.Name == s)
                return i;
        });
        return val;
    };


    
    handler.designatedGift = function (d, f) {
        if (d.FundableGiving == null || f.Items.length == 0)
            return;
        d.FundableGiving.Categories = [];
        d.FundableGiving.TotalData = [];
        d.FundableGiving.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var item = f.Items[j];
            d.FundableGiving.Categories.push(item.Display);
            d.FundableGiving.TotalData.push(Math.round(item.Total * 100) / 100);
            d.FundableGiving.AverageData.push(Math.round(item.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.FundableGiving.totalChartOptions))
            d.FundableGiving.totalChartOptions = ko.observable();

        d.FundableGiving.totalChartOptions({
            chart: {
               
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Designated - Giving by Designation'
            },
            xAxis: {
                categories: d.FundableGiving.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Designation',
                    formatter: function () {
                        if (this.value.length > 13) {
                        return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Giving'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                style: {
                    width: '150px'
                },
            },
            series: [{
                name: 'Total Designated Giving',
                data: d.FundableGiving.TotalData
            }]
        });
        
        if (!ko.isObservable(d.FundableGiving.averageChartOptions))
            d.FundableGiving.averageChartOptions = ko.observable();

        d.FundableGiving.averageChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Designated - Avg Gift by Designation'
            },
            xAxis: {
                categories: d.FundableGiving.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Designation',
                    formatter: function () {
                        if (this.value.length > 13) {
                        return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Gift'
                }
            },
            legend: {
                enabled: false
            },
            
            series: [{
                name: 'Designated - Average Gift',
                data: d.FundableGiving.AverageData
            }]
        });

    };
    
    handler.fundableTypeGiving = function (d, f) {
        if (d.FundableTypeGiving == null || f.Items.length == 0)
            return;
        d.FundableTypeGiving.Categories = [];
        d.FundableTypeGiving.TotalData = [];
        d.FundableTypeGiving.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var item = f.Items[j];
            d.FudableType.Categories.push(item.Display);
            d.FundableTypeGiving.TotalData.push(Math.round(item.Total*100)/100);
            d.FundableTypeGiving.AverageData.push(Math.round(item.Mean*100)/100);
        }

        if (!ko.isObservable(d.FundableTypeGiving.totalChartOptions))
            d.FundableTypeGiving.totalChartOptions = ko.observable();

        d.FundableTypeGiving.totalChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Designated - Giving by Designation Type'
            },
            xAxis: {
                categories: d.FundableTypeGiving.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Designation Type',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Giving'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Total Designated Giving',
                data: d.FundableTypeGiving.TotalData
            }]
        });

        if (!ko.isObservable(d.FundableTypeGiving.averageChartOptions))
            d.FundableTypeGiving.averageChartOptions = ko.observable();

        d.FundableTypeGiving.averageChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Designated - Avg Gift by Designation Type'
            },
            xAxis: {
                categories: d.FundableTypeGiving.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Designation Type',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Gift'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        'Average Gift ' + highcharts.numberFormat(this.y, 2);
                }
            },
            series: [{
                name: 'Designated - Average Gift',
                data: d.FundableTypeGiving.AverageData
            }]
        });

    };

    //start payments analytics
    handler.paymentYTD = function (d, f) {
        //console.log('here', f.Items[0], f.Items[1]);
        if (d.PaymentYOY == null)
            return;
        

        d.PaymentYOY.Stats.removeAll();
        d.PaymentYOY.Stats.push(new statsModel('YTD', f.Items[0]));
        d.PaymentYOY.Stats.push(new statsModel('PYTD', f.Items[0]));
        d.PaymentYOY.Stats.push(new statsModel('Last Year Total', f.Items[0]));
        d.PaymentYOY.Stats.push(new statsModel('2 Years Previous Total', f.Items[0]));

        d.PaymentYOY.Data = [];

        var ytd = handler.getStatsFor('YTD', f.Items);
        var pytd = handler.getStatsFor('PYTD', f.Items);
        var py = handler.getStatsFor('PY', f.Items);
        var tpy = handler.getStatsFor('2PY', f.Items);

        if(ytd)
            d.PaymentYOY.Data.push(Math.round(100 * ytd.Total / 100));
        if(pytd)
            d.PaymentYOY.Data.push(Math.round(100 * pytd.Total / 100));
        if(py)
            d.PaymentYOY.Data.push(Math.round(100 * py.Total / 100));
        if(tpy)
            d.PaymentYOY.Data.push(Math.round(100 * tpy.Total / 100));
        
        //for (var j = 0; j < f.Items.length; j++) {
        //    //console.log('data point', j, f.Items[j].Total, f.Items[j],f.Items)
        //    d.PaymentYOY.Data.push(Math.round(100 * f.Items[j].Total) / 100);
        //}

        if (!ko.isObservable(d.PaymentYOY.cartOptions))
            d.PaymentYOY.chartOptions = ko.observable();

        d.PaymentYOY.chartOptions({
            chart: {
                
                type: 'column',
                margin: [50, 50, 110, 80]
            },
            title: {
                text: 'Payments Year over Year'
            },
            xAxis: {
                categories: d.PaymentYOY.Categories,
                labels: {
                    title: 'Year',
                    rotation: -60,
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    }
                }
            },
            yAxis: {
                title: {
                    text: 'Total Paid'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        'Total paid $' + highcharts.numberFormat(this.y, 2);
                }
            },
            series: [{
                name: 'Payments',
                data: d.PaymentYOY.Data
            }]
        });


    };
    
    handler.paymentsByYear = function (d, f) {
        if (d.PaymentsByYear == null)
            return;

        d.PaymentsByYear.Stats.removeAll();
        d.PaymentsByYear.Stats.push(new statsModel('This Month', f.Items[0]));
        d.PaymentsByYear.Stats.push(new statsModel('YTD', f.Items[0]));
        d.PaymentsByYear.Stats.push(new statsModel('Previous YTD', f.Items[0]));
        d.PaymentsByYear.Stats.push(new statsModel('Last Year Total', f.Items[0]));
        d.PaymentsByYear.Stats.push(new statsModel('2 Years Previous Total', f.Items[0]));

        d.PaymentsByYear.Data = [];

        for (var j = 0; j < f.Items.length; j++) {

            d.PaymentsByYear.Data.push(Math.round(100 * f.Items[j].Total) / 100);
        }

        if (!ko.isObservable(d.PaymentsByYear.chartOptions))
            d.PaymentsByYear.chartOptions = ko.observable();
        

        d.PaymentsByYear.chartOptions({
            chart: {
                
                type: 'column',
                margin: [50, 50, 110, 80]
            },
            title: {
                text: 'Payments By Year'
            },
            xAxis: {
                categories: d.PaymentsByYear.Categories,
                labels: {
                    title: 'Period',
                    rotation: -60,
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    }
                }
            },
            yAxis: {
                title: {
                    text: 'Total Paid'
                }
            },
            legend: {
                enabled: false
            },
           
            series: [{
                name: 'Payments',
                data: d.PaymentsByYear.Data
            }]
        });


    };

    handler.paymentAmount = function (d, f) {
        if (d.PaymentSummary == null)
            return;
        (f.count != 0) ? assignCurrencyStats(d.PaymentSummary, f) : assignZeroStats(d.PaymentSummary);
    };
    
    handler.settledCardPayments = function (d, f) {
        if (d.SettledCardPayments == null)
            return;
        (f.count != 0) ? assignCurrencyStats(d.SettledCardPayments, f) : assignZeroStats(d.SettledCardPayments);
    };
    
    handler.settledBankPayments = function (d, f) {
        if (d.SettledBankPayments == null)
            return;
        (f.count != 0) ? assignCurrencyStats(d.SettledBankPayments, f) : assignZeroStats(d.SettledBankPayments);
    };
    
    handler.settledCardPaymentsGraph = function (d, f) {
        if (d.SettledCardPaymentsGraph == null)
            return;
        (f.count != 0) ? assignCurrencyStats(d.SettledCardPaymentsGraph, f) : assignZeroStats(d.SettledCardPaymentsGraph);
    };

    handler.settledBankPaymentsGraph = function (d, f) {
        if (d.SettledBankPaymentsGraph == null)
            return;
        (f.count != 0) ? assignCurrencyStats(d.SettledBankPaymentsGraph, f) : assignZeroStats(d.SettledBankPaymentsGraph);
    };

    handler.genderPayments = function (d, f) {
        if (d.GenderPayments == null)
            return;
        d.GenderPayments.Data = [];
        for (var j = 0; j < f.Items.length; j++) {
            var item = f.Items[j];
            d.GenderPayments.Data.push([item.Display, item.Total]);
           
        }

        if (!ko.isObservable(d.GenderPayments.chartOptions))
            d.GenderPayments.chartOptions = ko.observable();

        d.GenderPayments.chartOptions({
            chart: {
                type: 'pie'
            },
            title: {
                text: 'Demographics - Payments by Gender',
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage}%</b>',
                percentageDecimals: 1
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        formatter: function () {
                            var pointName = this.point.name;
                            var percent = Math.round(this.percentage);
                            if (pointName.length > 25) {
                                return '<b>' + pointName.substring(0, 21) + "...</b>" + percent + '%';
                            } else {
                                return '<b>' + pointName + '</b>:' + percent + '%';
                            }
                        }
                    }
                }
            },
            series: [{
                type: 'pie',
                name: 'GenderPayments',
                data: d.GenderPayments.Data
            }]
        });

    };

    handler.top10postalPayments = function (d, f) {
        if (d.Top10PostalCodePayments == null || f.Items.length == 0)
            return;


        d.Top10PostalCodePayments.Categories = [];
        d.Top10PostalCodePayments.TotalData = [];
        d.Top10PostalCodePayments.AverageData = [];

        for (var j = 0; j < f.Items.length; j++) {
            var item = f.Items[j];
            d.Top10PostalCodePayments.Categories.push(item.Display);
            d.Top10PostalCodePayments.TotalData.push(Math.round(item.Total * 100) / 100);
            d.Top10PostalCodePayments.AverageData.push(Math.round(item.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.Top10PostalCodeCommitments.totalChartOptions))
            d.Top10PostalCodeCommitments.totalChartOptions = ko.observable();


        d.Top10PostalCodePayments.totalChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Total Payments For Top 10 Postal Codes'
            },
            xAxis: {
                categories: d.Top10PostalCodePayments.Categories,
                labels: {
                    rotation: -45,
                    align: 'right',
                    title: 'Postal Code',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Payments'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Top 10 Postal Codes',
                data: d.Top10PostalCodePayments.TotalData
            }]
        });

        if (!ko.isObservable(d.Top10PostalCodeCommitments.averageChartOptions))
            d.Top10PostalCodeCommitments.averageChartOptions = ko.observable();

        d.Top10PostalCodePayments.averageChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Average Payments For Top 10 Postal Codes'
            },
            xAxis: {
                categories: d.Top10PostalCodePayments.Categories,
                labels: {
                    rotation: -45,
                    align: 'right',
                    title: 'Postal Code',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Payment'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Top 10 Postal Codes - Average Payment',
                data: d.Top10PostalCodePayments.AverageData
            }]
        });

    };

    handler.agePayments = function (d, f) {
        if (d.AgePayments == null || f.Items.length === 0)
            return;
        
        d.AgePayments.TotalData = [];
        d.AgePayments.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var rng = f.Items[j];
            d.AgePayments.TotalData.push(Math.round(rng.Total * 100) / 100);
            d.AgePayments.AverageData.push(Math.round(rng.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.AgePayments.totalChartOptions))
            d.AgePayments.totalChartOptions = ko.observable();

        d.AgePayments.totalChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Demographics - Total Payments by Age'
            },
            xAxis: {
                categories: d.AgePayments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Age Range',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Payments'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Age Ranges',
                data: d.AgePayments.TotalData
            }]
        });


        if (!ko.isObservable(d.AgePayments.averageChartOptions))
            d.AgePayments.averageChartOptions = ko.observable();

        d.AgePayments.averageChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Demographics - Average Payment by Age'
            },
            xAxis: {
                categories: d.AgePayments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Age Range',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Payment'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Age Ranges',
                data: d.AgePayments.AverageData
            }]
        });

    };

    handler.channelPayments = function (d, f) {
        if (d.ChannelPayments == null || f.Items.length == 0)
            return;

        d.ChannelPayments.Categories = [];
        d.ChannelPayments.TotalData = [];
        d.ChannelPayments.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var r = f.Items[j];
            d.ChannelPayments.Categories.push(r.Display);
           
            d.ChannelPayments.TotalData.push(Math.round(r.Total * 100) / 100);
            d.ChannelPayments.AverageData.push(Math.round(r.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.ChannelPayments.totalChartOptions))
            d.ChannelPayments.totalChartOptions = ko.observable();


        d.ChannelPayments.totalChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 110, 80]
            },
            title: {
                text: 'Marketing - Total Paid by Channel'
            },
            xAxis: {
                categories: d.ChannelPayments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Channel',
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Paid'
                }
            },
            legend: {
                enabled: false
            },
           
            series: [{
                name: 'Age Ranges',
                data: d.ChannelPayments.TotalData
            }]
        });

        if (!ko.isObservable(d.ChannelPayments.averageChartOptions))
            d.ChannelPayments.averageChartOptions = ko.observable();

        d.ChannelPayments.averageChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 110, 80]
            },
            title: {
                text: 'Marketing - Average Payment by Channel'
            },
            xAxis: {
                categories: d.ChannelPayments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Channel',
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 13) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Payment'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Age Ranges',
                data: d.ChannelPayments.AverageData
            }]
        });

    };
  
    handler.paymentmethod = function (d, f) {
        if (d.PaymentMethodSummary == null || f.Items.length == 0)
            return;

        d.PaymentMethodSummary.Categories = [];
        d.PaymentMethodSummary.TotalData = [];
        d.PaymentMethodSummary.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var r = f.Items[j];
            d.PaymentMethodSummary.Categories.push(r.Display);
             d.PaymentMethodSummary.TotalData.push(Math.round(r.Total * 100) / 100);
            d.PaymentMethodSummary.AverageData.push(Math.round(r.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.PaymentMethodSummary.totalChartOptions))
            d.PaymentMethodSummary.totalChartOptions = ko.observable();

        d.PaymentMethodSummary.totalChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 110, 80]
            },
            title: {
                text: 'Total Paid Per Payment Method'
            },
            xAxis: {
                categories: d.PaymentMethodSummary.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Method',
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Paid'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Age Ranges',
                data: d.PaymentMethodSummary.TotalData
            }]
        });

        if (!ko.isObservable(d.PaymentMethodSummary.averageChartOptions))
            d.PaymentMethodSummary.averageChartOptions = ko.observable();

        d.PaymentMethodSummary.averageChartOptions({
            chart: {     
                type: 'column',
                margin: [50, 50, 110, 80]
            },
            title: {
                text: 'Average Paid Per Payment Method'
            },
            xAxis: {
                categories: d.PaymentMethodSummary.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Method',
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 13) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Payment'
                }
            },
            legend: {
                enabled: false
            },
           
            series: [{
                name: 'Age Ranges',
                data: d.PaymentMethodSummary.AverageData
            }]
        });

    };

    handler.sourcecodePayments = function (d, f) {
        if (d.SourceCodePayments == null || f.Items.length == 0)
            return;

        d.SourceCodePayments.Categories = [];
        d.SourceCodePayments.TotalData = [];
        d.SourceCodePayments.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var r = f.Items[j];
            d.SourceCodePayments.Categories.push(r.Display);
            d.SourceCodePayments.TotalData.push(Math.round(r.Total * 100) / 100);
            d.SourceCodePayments.AverageData.push(Math.round(r.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.SourceCodePayments.totalChartOptions))
            d.SourceCodePayments.totalChartOptions = ko.observable();

        d.SourceCodePayments.totalChartOptions ({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Marketing - Total 10 Source Codes'
            },
            xAxis: {
                categories: d.SourceCodePayments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Channel',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Paid'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Age Ranges',
                data: d.SourceCodePayments.TotalData
            }]
        });

        if (!ko.isObservable(d.SourceCodePayments.averageChartOptions))
            d.SourceCodePayments.averageChartOptions = ko.observable();

        d.SourceCodePayments.averageChartOptions ({
            chart: {
             
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Marketing - Top 10 Source Codes'
            },
            xAxis: {
                categories: d.SourceCodePayments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Channel',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Payment'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Age Ranges',
                data: d.SourceCodePayments.AverageData
            }]
        });

    };

    handler.designatedPayment = function (d, f) {
        if (d.FundablePayments == null || f.Items.length == 0)
            return;
        d.FundablePayments.Categories = [];
        d.FundablePayments.TotalData = [];
        d.FundablePayments.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var item = f.Items[j];
            d.FundablePayments.Categories.push(item.Display);
            d.FundablePayments.TotalData.push(Math.round(item.Total * 100) / 100);
            d.FundablePayments.AverageData.push(Math.round(item.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.FundablePayments.totalChartOptions))
            d.FundablePayments.totalChartOptions = ko.observable();

        d.FundablePayments.totalChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Designated - Payments by Designation'
            },
            xAxis: {
                categories: d.FundablePayments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Designation',
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Payments'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Total Designated Payments',
                data: d.FundablePayments.TotalData
            }]
        });

        if (!ko.isObservable(d.FundablePayments.averageChartOptions))
            d.FundablePayments.averageChartOptions = ko.observable();

        d.FundablePayments.averageChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Designated - Avg Payment by Designation'
            },
            xAxis: {
                categories: d.FundablePayments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Designation',
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Payment'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Designated - Average Payment',
                data: d.FundablePayments.AverageData
            }]
        });

    };

    handler.fundableTypePayments = function (d, f) {
        if (d.FundableTypePayments == null || f.Items.length == 0)
            return;
        d.FundableTypePayments.Categories = [];
        d.FundableTypePayments.TotalData = [];
        d.FundableTypePayments.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var item = f.Items[j];
            d.FundableTypePayments.Categories.push(item.Display);
            d.FundableTypePayments.TotalData.push(Math.round(item.Total * 100) / 100);
            d.FundableTypePayments.AverageData.push(Math.round(item.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.FundablePayments.totalChartOptions))
            d.FundablePayments.TotalChart = ko.observable();

        d.FundableTypePayments.totalChartOptions({
            chart: {
                
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Designated - Payments by Designation Type'
            },
            xAxis: {
                categories: d.FundableTypePayments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Designation Type',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Payments'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Total Designated Payments',
                data: d.FundableTypePayments.TotalData
            }]
        });

        if (!ko.isObservable(d.FundablePayments.averageChartOptions))
            d.FundablePayments.averageChartOptions = ko.observable();

        d.FundableTypePayments.averageChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Designated - Avg Payment by Designation Type'
            },
            xAxis: {
                categories: d.FundableTypePayments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Designation Type',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Payment'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        'Average Payment ' + highcharts.numberFormat(this.y, 2);
                }
            },
            series: [{
                name: 'Designated - Average Payment',
                data: d.FundableTypePayments.AverageData
            }]
        });

    };
    
    handler.individualPaymentRange = function (d, f) {
        if (d.IndividualPaymentRange == null)
            return;
        
        d.IndividualPaymentRange.TotalData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var r = f.Items[j];
            d.IndividualPaymentRange.TotalData.push(r.count);
        }

        if (!ko.isObservable(d.FundablePayments.totalChartOptions))
            d.FundablePayments.totalChartOptions = ko.observable();

        d.IndividualPaymentRange.totalChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 110, 80]
            },
            title: {
                text: 'Donors per'
            },
            xAxis: {
                categories: d.IndividualPaymentRange.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Channel',
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Paid'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Age Ranges',
                data: d.IndividualPaymentRange.TotalData
            }]
        });

    };

    handler.organizationPaymentRange = function (d, f) {
        if (d.OrganizationPaymentRange == null)
            return;
        
        d.OrganizationPaymentRange.TotalData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var r = f.Items[j];

            d.OrganizationPaymentRange.TotalData.push(r.count);
        }

        if (!ko.isObservable(d.OrganizationPaymentRange.totalChartOptions))
            d.OrganizationPaymentRange.totalChartOptions = ko.observable();

        d.OrganizationPaymentRange.totalChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 110, 80]
            },
            title: {
                text: 'Organization donors per monetary range'
            },
            xAxis: {
                categories: d.OrganizationPaymentRange.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Channel',
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Paid'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Age Ranges',
                data: d.OrganizationPaymentRange.TotalData
            }]
        });
    };




    handler.individualDistancePayments = function (d, f) {
        if (d.IndividualDistancePayments == null)
            return;


        d.IndividualDistancePayments.TotalData = [];
        d.IndividualDistancePayments.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var r = f.Items[j];

            d.IndividualDistancePayments.TotalData.push(Math.round(r.Total * 100) / 100);
            d.IndividualDistancePayments.AverageData.push(Math.round(r.Total * 100) / 100);
        }

        if (!ko.isObservable(d.IndividualDistancePayments.totalChartOptions))
            d.IndividualDistancePayments.totalChartOptions = ko.observable();

        d.IndividualDistancePayments.totalChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 110, 80]
            },
            title: {
                text: 'Individual Total Donations Per Distance'
            },
            xAxis: {
                categories: d.IndividualDistancePayments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Channel',
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Paid'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        'Total Amount Donated ' + highcharts.numberFormat(this.y, 2);
                }
            },
            series: [{
                name: 'Age Ranges',
                data: d.IndividualDistancePayments.TotalData
            }]
        });

        if (!ko.isObservable(d.IndividualDistancePayments.averageChartOptions))
            d.IndividualDistancePayments.averageChartOptions = ko.observable();
        
        d.IndividualDistancePayments.averageChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 110, 80]
            },
            title: {
                text: 'Individual Average Donation Per Distance'
            },
            xAxis: {
                categories: d.IndividualDistancePayments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Channel',
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Paid'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Age Ranges',
                data: d.IndividualDistancePayments.AverageData
            }]
        });


    };


    handler.orgDistancePayments = function (d, f) {
        return;
    };

    //end payments
    
    //start commitment analytics
    handler.commitmentAmount = function (d, f) {

        if (d.CommitmentSummary == null)
            return;

        (f.count != 0) ? assignCurrencyStats(d.CommitmentSummary, f) : assignZeroStats(d.CommitmentSummary);
    };

    handler.genderCommitment = function (d, f) {
    
        if (d.GenderCommitments == null)
            return;
        d.GenderCommitments.Data = [];
        for (var j = 0; j < f.Items.length; j++) {
            var item = f.Items[j];
            d.GenderCommitments.Data.push([item.Display, item.Total]);
            
        }

        if (!ko.isObservable(d.GenderCommitments.chartOptions))
            d.GenderCommitments.chartOptions = ko.observable();

        d.GenderCommitments.chartOptions({
            chart: {
                type:'pie'
            },
            title: {
                text: 'Demographics - Commitments by Gender',
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage}%</b>',
                percentageDecimals: 1
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        formatter: function () {
                            var pointName = this.point.name;
                            var percent = Math.round(this.percentage);
                            if (pointName.length > 25) {
                                return '<b>' + pointName.substring(0, 21) + "...</b>" + percent + '%';
                            } else {
                                return '<b>' + pointName + '</b>:' + percent + '%';
                            }
                        }
                    }
                }
            },
            series: [{
                type: 'pie',
                name: 'GenderCommitments',
                data: d.GenderCommitments.Data
            }]
        });

    };

    handler.commitmentYTD = function (d, f) {
        if (d.CommitmentYTD == null)
            return;

        d.CommitmentYTD.Data = [];
        for (var j = 0; j < f.Items.length; j++) {
            d.CommitmentYTD.Data.push(Math.round(100 * f.Items[j].Total) / 100);
        }

        if (!ko.isObservable(d.CommitmentYTD.chartOptions))
            d.CommitmentYTD.chartOptions = ko.observable();

        d.CommitmentYTD.chartOptions({
            chart: {               
                type: 'column',
                margin: [50, 50, 110, 80]
            },
            title: {
                text: 'Commitment YTD Year over Year'
            },
            xAxis: {
                categories: d.CommitmentYTD.Categories,
                labels: {
                    title: 'Year',
                    rotation: -60,
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    }
                }
            },
            yAxis: {
                title: {
                    text: 'Total Committed'
                }
            },
            legend: {
                enabled: false
            },            
            series: [{
                name: 'Commitments',
                data: d.CommitmentYTD.Data
            }]
        });


    };

    handler.top10postalCommitment = function (d, f) {
        if (d.Top10PostalCodeCommitments == null || f.Items.length == 0)
            return;

        d.Top10PostalCodeCommitments.Categories = [];
        d.Top10PostalCodeCommitments.TotalData = [];
        d.Top10PostalCodeCommitments.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var item = f.Items[j];
            d.Top10PostalCodeCommitments.Categories.push(item.Display);
            d.Top10PostalCodeCommitments.TotalData.push(Math.round(item.Total * 100) / 100);
            d.Top10PostalCodeCommitments.AverageData.push(Math.round(item.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.Top10PostalCodeCommitments.totalChartOptions))
            d.Top10PostalCodeCommitments.totalChartOptions = ko.observable();

        d.Top10PostalCodeCommitments.totalChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Total Commitment For Top 10 Postal Codes'
            },
            xAxis: {
                categories: d.Top10PostalCodeCommitments.Categories,
                labels: {
                    rotation: -45,
                    align: 'right',
                    title: 'Postal Code',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Commitments'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Top 10 Postal Codes',
                data: d.Top10PostalCodeCommitments.TotalData
            }]
        });

        if (!ko.isObservable(d.Top10PostalCodeCommitments.averageChartOptions))
            d.Top10PostalCodeCommitments.averageChartOptions = ko.observable();


        d.Top10PostalCodeCommitments.averageChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Average Commitment For Top 10 Postal Codes'
            },
            xAxis: {
                categories: d.Top10PostalCodeCommitments.Categories,
                labels: {
                    rotation: -45,
                    align: 'right',
                    title: 'Postal Code',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Commitment'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Average Commitment For Top 10 Postal Codes',
                data: d.Top10PostalCodeCommitments.AverageData
            }]
        });

    };

    handler.ageCommitment = function (d, f) {
        if (d.AgeCommitments == null || f.Items.length == 0)
            return;

        d.AgeCommitments.TotalData = [];
        d.AgeCommitments.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var rng = f.Items[j];
            d.AgeCommitments.TotalData.push(Math.round(rng.Total * 100) / 100);
            d.AgeCommitments.AverageData.push(Math.round(rng.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.AgeCommitments.totalChartOptions))
            d.AgeCommitments.totalChartOptions = ko.observable();


        d.AgeCommitments.totalChartOptions({
            chart: {                
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Demographics - Total Commitments by Age'
            },
            xAxis: {
                categories: d.AgeCommitments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Age Range',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Commitments'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        'Commitments Total ' + highcharts.numberFormat(this.y, 2);
                }
            },
            series: [{
                name: 'Age Ranges',
                data: d.AgeCommitments.TotalData
            }]
        });


        if (!ko.isObservable(d.AgeCommitments.averageChartOptions))
            d.AgeCommitments.averageChartOptions = ko.observable();

        d.AgeCommitments.averageChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Demographics - Average Commitment by Age'
            },
            xAxis: {
                categories: d.AgeCommitments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Age Range',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Commitment'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        'Average Commitment ' + highcharts.numberFormat(this.y, 2);
                }
            },
            series: [{
                name: 'Age Ranges',
                data: d.AgeCommitments.AverageData
            }]
        });

    };


    handler.channelCommitment = function (d, f) {
        if (d.ChannelCommitments == null || f.Items.length == 0)
            return;

        d.ChannelCommitments.Categories = [];
        d.ChannelCommitments.TotalData = [];
        d.ChannelCommitments.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var r = f.Items[j];
            d.ChannelCommitments.Categories.push(r.Display);
 

            d.ChannelCommitments.TotalData.push(Math.round(r.Total * 100) / 100);
            d.ChannelCommitments.AverageData.push(Math.round(r.Mean * 100) / 100);
        }


        if (!ko.isObservable(d.ChannelCommitments.totalChartOptions))
            d.ChannelCommitments.totalChartOptions = ko.observable();

        d.ChannelCommitments.TotalChart({
            chart: {
                type: 'column',
                margin: [50, 50, 110, 80]
            },
            title: {
                text: 'Marketing - Total Committed by Channel'
            },
            xAxis: {
                categories: d.ChannelCommitments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Channel',
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Committed'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        'Commitments Total $' + highcharts.numberFormat(this.y, 2);
                }
            },
            series: [{
                name: 'Age Ranges',
                data: d.ChannelCommitments.TotalData
            }]
        });

        if (!ko.isObservable(d.ChannelCommitments.averageChartOptions))
            d.ChannelCommitments.averageChartOptions = ko.observable();

        d.ChannelCommitments.averageChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 110, 80]
            },
            title: {
                text: 'Marketing - Average Commitment by Channel'
            },
            xAxis: {
                categories: d.ChannelCommitments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Channel',
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 13) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Commitment'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Age Ranges',
                data: d.ChannelCommitments.AverageData
            }]
        });

    };


    handler.sourcecodeCommitment = function (d, f) {
        if (d.SourceCodeCommitments == null || f.Items.length == 0)
            return;

        d.SourceCodeCommitments.Categories = [];
        d.SourceCodeCommitments.TotalData = [];
        d.SourceCodeCommitments.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var r = f.Items[j];
            d.SourceCodeCommitments.Categories.push(r.Display);
            d.SourceCodeCommitments.TotalData.push(Math.round(r.Total * 100) / 100);
            d.SourceCodeCommitments.AverageData.push(Math.round(r.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.SourceCodeCommitments.totalChartOptions))
            d.SourceCodeCommitments.totalChartOptions = ko.observable();

        d.SourceCodeCommitments.totalChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Marketing - Total 10 Source Codes'
            },
            xAxis: {
                categories: d.SourceCodeCommitments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Channel',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Given'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Age Ranges',
                data: d.SourceCodeCommitments.TotalData
            }]
        });

        if (!ko.isObservable(d.SourceCodeCommitments.averageChartOptions))
            d.SourceCodeCommitments.averageChartOptions = ko.observable();

        d.SourceCodeCommitments.averageChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Marketing - Top 10 Source Codes'
            },
            xAxis: {
                categories: d.SourceCodeCommitments.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Channel',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Commitment'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Age Ranges',
                data: d.SourceCodeCommitments.AverageData
            }]
        });

    };

    

    //end commitments analytics

    //start recurring gift analytics
    handler.recurringGiftAmount = function (d, f) {

        if (d.CommitmentSummary == null)
            return;

        (f.count != 0) ? assignCurrencyStats(d.CommitmentSummary, f) : assignZeroStats(d.CommitmentSummary);
    };

    handler.genderRecurringGifts = function (d, f) {

        if (d.GenderRecurringGifts == null)
            return;
        d.GenderRecurringGifts.Data = [];
        for (var j = 0; j < f.Items.length; j++) {
            var item = f.Items[j];
            d.GenderRecurringGifts.Data.push(item.Display, item.Total);
         
        }


        if (!ko.isObservable(d.GenderRecurringGifts.chartOptions))
            d.GenderRecurringGifts.chartOptions = ko.observable();

        d.GenderRecurringGifts.chartOptions({
            chart: {
                type:'pie'
            },
            title: {
                text: 'Demographics - Recurring Gifts by Gender',
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage}%</b>',
                percentageDecimals: 1
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        formatter: function () {
                            var pointName = this.point.name;
                            var percent = Math.round(this.percentage);
                            if (pointName.length > 25) {
                                return '<b>' + pointName.substring(0, 21) + "...</b>" + percent + '%';
                            } else {
                                return '<b>' + pointName + '</b>:' + percent + '%';
                            }
                        }
                    }
                }
            },
            series: [{
                type: 'pie',
                name: 'GenderRecurringGifts',
                data: d.GenderRecurringGifts.Data
            }]
        });

    };

    handler.recurringGiftYTD = function (d, f) {
        if (d.RecurringGiftYTD == null)
            return;

        d.RecurringGiftYTD.Data = [];
        for (var j = 0; j < f.Items.length; j++) {
            d.RecurringGiftYTD.Data.push(Math.round(100 * f.Items[j].Total) / 100);
        }

        if (!ko.isObservable(d.RecurringGiftYTD.chartOptions))
            d.RecurringGiftYTD.chartOptions = ko.observable();


        d.RecurringGiftYTD.chartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 110, 80]
            },
            title: {
                text: 'Recurring Gifts YTD Year over Year'
            },
            xAxis: {
                categories: d.RecurringGiftYTD.Categories,
                labels: {
                    title: 'Year',
                    rotation: -60,
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    }
                }
            },
            yAxis: {
                title: {
                    text: 'Total Given'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        'Total given $' + highcharts.numberFormat(this.y, 2);
                }
            },
            series: [{
                name: 'Recurring Gifts',
                data: d.RecurringGiftYTD.Data
            }]
        });


    };

    handler.top10PostalCodeRecurringGifts = function (d, f) {
        if (d.Top10PostalCodeRecurringGifts == null || f.Items.length == 0)
            return;

        d.Top10PostalCodeRecurringGifts.Categories = [];
        d.Top10PostalCodeRecurringGifts.TotalData = [];
        d.Top10PostalCodeRecurringGifts.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var item = f.Items[j];
            d.Top10PostalCodeRecurringGifts.Categories.push(item.Display);
            d.Top10PostalCodeRecurringGifts.TotalData.push(Math.round(item.Total * 100) / 100);
            d.Top10PostalCodeRecurringGifts.AverageData.push(Math.round(item.Mean * 100) / 100);
        }


        if (!ko.isObservable(d.Top10PostalCodeRecurringGifts.totalChartOptions))
            d.Top10PostalCodeRecurringGifts.totalChartOptions = ko.observable();

        d.Top10PostalCodeRecurringGifts.totalChartOptions({
            chart: {
                
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Total Recurring Gifts For Top 10 Postal Codes'
            },
            xAxis: {
                categories: d.Top10PostalCodeRecurringGifts.Categories,
                labels: {
                    rotation: -45,
                    align: 'right',
                    title: 'Postal Code',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Recurring Gifts'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        'Recurring Gifts Total ' + highcharts.numberFormat(this.y, 2);
                }
            },
            series: [{
                name: 'Top 10 Postal Codes',
                data: d.Top10PostalCodeRecurringGifts.TotalData
            }]
        });

        if (!ko.isObservable(d.Top10PostalCodeRecurringGifts.averageChartOptions))
            d.Top10PostalCodeRecurringGifts.averageChartOptions = ko.observable();


        d.Top10PostalCodeRecurringGifts.averageChartOptions({
            chart: {                
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Average Recurring Gifts For Top 10 Postal Codes'
            },
            xAxis: {
                categories: d.Top10PostalCodeRecurringGifts.Categories,
                labels: {
                    rotation: -45,
                    align: 'right',
                    title: 'Postal Code',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Recurring Gifts'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        'Average Recurring Gift ' + highcharts.numberFormat(this.y, 2);
                }
            },
            series: [{
                name: 'Average Recurring Gifts For Top 10 Postal Codes',
                data: d.Top10PostalCodeRecurringGifts.AverageData
            }]
        });

    };

    handler.ageRecurringGifts = function (d, f) {
        if (d.AgeRecurringGifts == null || f.Items.length == 0)
            return;

        d.AgeRecurringGifts.TotalData = [];
        d.AgeRecurringGifts.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var rng = f.Items[j];
            d.AgeRecurringGifts.TotalData.push(Math.round(rng.Total * 100) / 100);
            d.AgeRecurringGifts.AverageData.push(Math.round(rng.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.AgeRecurringGifts.totalChartOptions))
            d.AgeRecurringGifts.totalChartOptions = ko.observable();

        d.AgeRecurringGifts.totalChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Demographics - Total Recurring Gifts by Age'
            },
            xAxis: {
                categories: d.AgeRecurringGifts.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Age Range',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total RecurringGifts'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Age Ranges',
                data: d.AgeRecurringGifts.TotalData
            }]
        });

        if (!ko.isObservable(d.AgeRecurringGifts.averageChartOptions))
            d.AgeRecurringGifts.averageChartOptions = ko.observable();

        d.AgeRecurringGifts.averageChartOptions({
            chart: {
                renderTo: 'ageCommitmentsAvgContainer',
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Demographics - Average Recurring Gifts by Age'
            },
            xAxis: {
                categories: d.AgeRecurringGifts.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Age Range',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Recurring Gift'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Age Ranges',
                data: d.AgeRecurringGifts.AverageData
            }]
        });

    };


    handler.channelRecurringGifts = function (d, f) {
        if (d.ChannelRecurringGifts == null || f.Items.length == 0)
            return;

        d.ChannelRecurringGifts.Categories = [];
        d.ChannelRecurringGifts.TotalData = [];
        d.ChannelRecurringGifts.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var r = f.Items[j];
            d.ChannelRecurringGifts.Categories.push(r.Display);
           

            d.ChannelRecurringGifts.TotalData.push(Math.round(r.Total * 100) / 100);
            d.ChannelRecurringGifts.AverageData.push(Math.round(r.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.ChannelRecurringGifts.totalChartOptions))
            d.ChannelRecurringGifts.totalChartOptions = ko.observable();

        d.ChannelRecurringGifts.totalChartOptions({
            chart: {
                renderTo: 'channelCommitmentsTotalContainer',
                type: 'column',
                margin: [50, 50, 110, 80]
            },
            title: {
                text: 'Marketing - Total Given by Channel'
            },
            xAxis: {
                categories: d.ChannelRecurringGifts.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Channel',
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Given'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Age Ranges',
                data: d.ChannelRecurringGifts.TotalData
            }]
        });

        if (!ko.isObservable(d.ChannelRecurringGifts.averageChartOptions))
            d.ChannelRecurringGifts.averageChartOptions = ko.observable();

        d.ChannelRecurringGifts.averageChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 110, 80]
            },
            title: {
                text: 'Marketing - Average Recurring Gift by Channel'
            },
            xAxis: {
                categories: d.ChannelRecurringGifts.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Channel',
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 13) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Recurring Gift'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Age Ranges',
                data: d.ChannelRecurringGifts.AverageData
            }]
        });

    };


    handler.sourceCodeRecurringGifts = function (d, f) {
        if (d.SourceCodeRecurringGifts == null || f.Items.length == 0)
            return;

        d.SourceCodeRecurringGifts.Categories = [];
        d.SourceCodeRecurringGifts.TotalData = [];
        d.SourceCodeRecurringGifts.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var r = f.Items[j];
            d.SourceCodeRecurringGifts.Categories.push(r.Display);
            d.SourceCodeRecurringGifts.TotalData.push(Math.round(r.Total * 100) / 100);
            d.SourceCodeRecurringGifts.AverageData.push(Math.round(r.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.SourceCodeRecurringGifts.totalChartOptions))
            d.SourceCodeRecurringGifts.totalChartOptions = ko.observable();

        d.SourceCodeRecurringGifts.totalChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Marketing - Total 10 Source Codes'
            },
            xAxis: {
                categories: d.SourceCodeRecurringGifts.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Channel',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Given'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        'Recurring Gifts Total ' + highcharts.numberFormat(this.y, 2);
                }
            },
            series: [{
                name: 'Age Ranges',
                data: d.SourceCodeRecurringGifts.TotalData
            }]
        });

        if (!ko.isObservable(d.SourceCodeRecurringGifts.averageChartOptions))
            d.SourceCodeRecurringGifts.averageChartOptions = ko.observable();

        d.SourceCodeRecurringGifts.averageChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Marketing - Top Avg 10 Source Codes'
            },
            xAxis: {
                categories: d.SourceCodeRecurringGifts.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Channel',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Recurring Gift'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Age Ranges',
                data: d.SourceCodeRecurringGifts.AverageData
            }]
        });

    };


    handler.fundableRecurringGifts = function (d, f) {
        if (d.FundableRecurringGifts == null || f.Items.length == 0)
            return;
        d.FundableRecurringGifts.Categories = [];
        d.FundableRecurringGifts.TotalData = [];
        d.FundableRecurringGifts.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var item = f.Items[j];
            d.FundableRecurringGifts.Categories.push(item.Display);
            d.FundableRecurringGifts.TotalData.push(Math.round(item.Total * 100) / 100);
            d.FundableRecurringGifts.AverageData.push(Math.round(item.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.FundableRecurringGifts.totalChartOptions))
            d.FundableRecurringGifts.totalChartOptions = ko.observable();

        d.FundableRecurringGifts.totalChartOptions({
            chart: {
                
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Designated - Recurring Gifts by Designation'
            },
            xAxis: {
                categories: d.FundableRecurringGifts.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Designation',
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Recurring Gifts'
                }
            },
            legend: {
                enabled: false
            },
            series: [{
                name: 'Total Designated RecurringGifts',
                data: d.FundableRecurringGifts.TotalData
            }]
        });

        if (!ko.isObservable(d.FundableRecurringGifts.averageChartOptions))
            d.FundableRecurringGifts.averageChartOptions = ko.observable();

        d.FundableRecurringGifts.averageChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Designated - Avg Recurring Gift by Designation'
            },
            xAxis: {
                categories: d.FundableRecurringGifts.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Designation',
                    formatter: function () {
                        if (this.value.length > 13) {
                            return this.value.substring(0, 12) + '...';
                        }
                        else {
                            return this.value;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Recurring Gift'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                style: {
                    width: '150px'
                },
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        'Average Recurring Gift ' + highcharts.numberFormat(this.y, 2);
                }
            },
            series: [{
                name: 'Designated - Average Recurring Gift',
                data: d.FundableRecurringGifts.AverageData
            }]
        });

    };


    handler.fundableTypeRecurringGifts = function (d, f) {
        if (d.FundableTypeRecurringGifts == null || f.Items.length == 0)
            return;
        d.FundableTypeRecurringGifts.Categories = [];
        d.FundableTypeRecurringGifts.TotalData = [];
        d.FundableTypeRecurringGifts.AverageData = [];
        for (var j = 0; j < f.Items.length; j++) {
            var item = f.Items[j];
            d.FundableTypeRecurringGifts.Categories.push(item.Display);
            d.FundableTypeRecurringGifts.TotalData.push(Math.round(item.Total * 100) / 100);
            d.FundableTypeRecurringGifts.AverageData.push(Math.round(item.Mean * 100) / 100);
        }

        if (!ko.isObservable(d.FundableRecurringGifts.totalChartOptions))
            d.FundableRecurringGifts.totalChartOptions = ko.observable();

        d.FundableTypeRecurringGifts.totalChartOptions({
            chart: {
               
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Designated - Recurring Gifts by Designation Type'
            },
            xAxis: {
                categories: d.FundableTypeRecurringGifts.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Designation Type',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Total Recurring Gifts'
                }
            },
            legend: {
                enabled: false
            },
           
            series: [{
                name: 'Total Designated RecurringGifts',
                data: d.FundableTypeRecurringGifts.TotalData
            }]
        });

        if (!ko.isObservable(d.FundableTypeRecurringGifts.averageChartOptions))
            d.FundableTypeRecurringGifts.averageChartOptions = ko.observable();


        d.FundableTypeRecurringGifts.averageChartOptions({
            chart: {
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: 'Designated - Avg Recurring Gift by Designation Type'
            },
            xAxis: {
                categories: d.FundableTypeRecurringGifts.Categories,
                labels: {
                    rotation: -60,
                    align: 'right',
                    title: 'Designation Type',
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Average Recurring Gift'
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        'Average Recurring Gift ' + highcharts.numberFormat(this.y, 2);
                }
            },
            series: [{
                name: 'Designated - Average Recurring Gift',
                data: d.FundableTypeRecurringGifts.AverageData
            }]
        });

    };
    //end recurring gift analytics

module.exports = {
    handler:handler
}