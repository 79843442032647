var template = require('./channeltype-picker.html');



function channelDetail() {
    this.Id = ko.observable();
    this.Name = ko.observable();
}

function viewModel(params) {
    var self = this;
    self.ChannelTypeId = params.Id;
    self.ChannelTypes = ko.observableArray();

    $.ajax({
        url: '/Finance/ChannelType/GetAllChannels',
        type: 'POST',
        contentType: 'application/json',
        dataType: 'json',
        data: {},
        success: function (data, textStatus, jqXhr) {
            
            
            ko.mapping.fromJS(data.Data.PageData, {}, self.ChannelTypes);
            var item = new channelDetail();
            item.Name('None');
            self.ChannelTypes.unshift(item);
            self.ChannelTypeId(null);

        },
        error: function (data, textStatus, jqXhr) {
            console.log('error');
            console.log(data);
        }
    });

    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};

