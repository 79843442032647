

    var countryCodes = [
       { name: "Afghanistan", value: 235 },
       { name: "Åland Islands", value: 144 },
       { name: "Albania", value: 40 },
       { name: "Algeria", value: 4 },
       { name: "American Samoa", value: 5 },
       { name: "Andorra", value: 6 },
       { name: "Angola", value: 7 },
       { name: "Anguilla", value: 8 },
       { name: "Antarctica", value: 9 },
       { name: "Antigua and Barbuda", value: 10 },
       { name: "Argentina", value: 11 },
       { name: "Armenia", value: 12 },
       { name: "Aruba", value: 13 },
       { name: "Australia", value: 14 },
       { name: "Austria", value: 15 },
       { name: "Azerbaijan", value: 16 },
       { name: "Bahamas", value: 17 },
       { name: "Bahrain", value: 18 },
       { name: "Bangladesh", value: 19 },
       { name: "Barbados", value: 20 },
       { name: "Belarus", value: 21 },
       { name: "Belgium", value: 22 },
       { name: "Belize", value: 23 },
       { name: "Benin", value: 24 },
       { name: "Bermuda", value: 25 },
       { name: "Bhutan", value: 26 },
       { name: "Bolivia, Plurinational State Of", value: 27 },
       { name: "Bonaire, Sint Eustatius And Saba", value: 28 },
       { name: "Bosnia And Herzegovina", value: 29 },
       { name: "Botswana", value: 30 },
       { name: "Bouvet Island", value: 31 },
       { name: "Brazil", value: 32 },
       { name: "British Indian Ocean Territory", value: 33 },
       { name: "Brunei Darussalam", value: 34 },
       { name: "Bulgaria", value: 35 },
       { name: "Burkina Faso", value: 36 },
       { name: "Burundi", value: 37 },
       { name: "Cambodia", value: 38 },
       { name: "Cameroon", value: 39 },
       { name: "Canada", value: 3 },
       { name: "Cape Verde", value: 41 },
       { name: "Cayman Islands", value: 42 },
       { name: "Central African Republic", value: 43 },
       { name: "Chad", value: 44 },
       { name: "Chile", value: 45 },
       { name: "China", value: 46 },
       { name: "Christmas Island", value: 47 },
       { name: "Cocos (Keeling) Islands", value: 48 },
       { name: "Colombia", value: 49 },
       { name: "Comoros", value: 50 },
       { name: "Congo", value: 51 },
       { name: "Congo, The Democratic Republic Of", value: 52 },
       { name: "Cook Islands", value: 53 },
       { name: "Costa Rica", value: 54 },
       { name: "Côte D'Ivoire", value: 55 },
       { name: "Croatia", value: 56 },
       { name: "Cuba", value: 57 },
       { name: "Curaçao", value: 58 },
       { name: "Cyprus", value: 59 },
       { name: "Czech Republic", value: 60 },
       { name: "Denmark", value: 61 },
       { name: "Djibouti", value: 62 },
       { name: "Dominica", value: 63 },
       { name: "Dominican Republic", value: 64 },
       { name: "Ecuador", value: 65 },
       { name: "Egypt", value: 66 },
       { name: "El Salvador", value: 67 },
       { name: "Equatorial Guinea", value: 68 },
       { name: "Eritrea", value: 69 },
       { name: "Estonia", value: 70 },
       { name: "Ethiopia", value: 71 },
       { name: "Falkland Islands (Malvinas)", value: 72 },
       { name: "Faroe Islands", value: 73 },
       { name: "Fiji", value: 74 },
       { name: "Finland", value: 75 },
       { name: "France", value: 76 },
       { name: "French Guiana", value: 77 },
       { name: "French Polynesia", value: 78 },
       { name: "French Southern Territories", value: 79 },
       { name: "Gabon", value: 80 },
       { name: "Gambia", value: 81 },
       { name: "Georgia", value: 82 },
       { name: "Germany", value: 83 },
       { name: "Ghana", value: 84 },
       { name: "Gibraltar", value: 85 },
       { name: "Greece", value: 86 },
       { name: "Greenland", value: 87 },
       { name: "Grenada", value: 88 },
       { name: "Guadeloupe", value: 89 },
       { name: "Guam", value: 90 },
       { name: "Guatemala", value: 91 },
       { name: "Guernsey", value: 92 },
       { name: "Guinea", value: 93 },
       { name: "Guinea Bissau", value: 94 },
       { name: "Guyana", value: 95 },
       { name: "Haiti", value: 96 },
       { name: "Heard Island And Mcdonald Islands", value: 97 },
       { name: "Holy See (Vatican City)", value: 98 },
       { name: "Honduras", value: 99 },
       { name: "Hong Kong", value: 100 },
       { name: "Hungary", value: 101 },
       { name: "Iceland", value: 102 },
       { name: "India", value: 103 },
       { name: "Indonesia", value: 104 },
       { name: "Iran, Islamic Republic Of", value: 105 },
       { name: "Iraq", value: 106 },
       { name: "Ireland", value: 107 },
       { name: "Isle of Man", value: 108 },
       { name: "Israel", value: 109 },
       { name: "Italy", value: 110 },
       { name: "Jamaica", value: 111 },
       { name: "Japan", value: 112 },
       { name: "Jersey", value: 113 },
       { name: "Jordan", value: 114 },
       { name: "Kazakhstan", value: 115 },
       { name: "Kenya", value: 116 },
       { name: "Kiribati", value: 117 },
       { name: "Korea, Democratic People's Republic Of", value: 118 },
       { name: "Korea, Republic Of", value: 119 },
       { name: "Kuwait", value: 120 },
       { name: "Kyrgyzstan", value: 121 },
       { name: "Lao People's Democratic Republic", value: 122 },
       { name: "Latvia", value: 123 },
       { name: "Lebanon", value: 124 },
       { name: "Lesotho", value: 125 },
       { name: "Liberia", value: 126 },
       { name: "Libya", value: 127 },
       { name: "Liechtenstein", value: 128 },
       { name: "Lithuania", value: 129 },
       { name: "Luxembourg", value: 130 },
       { name: "Macao", value: 131 },
       { name: "Macedonia, The Former Yugoslav Republic Of", value: 132 },
       { name: "Madagascar", value: 133 },
       { name: "Malawi", value: 134 },
       { name: "Malaysia", value: 135 },
       { name: "Maldives", value: 136 },
       { name: "Mali", value: 137 },
       { name: "Malta", value: 138 },
       { name: "Marshall Islands", value: 139 },
       { name: "Martinique", value: 140 },
       { name: "Mauritania", value: 141 },
       { name: "Mauritius", value: 142 },
       { name: "Mayotte", value: 143 },
       { name: "Mexico", value: 2 },
       { name: "Micronesia, Federated States Of", value: 145 },
       { name: "Moldova, Republic Of", value: 146 },
       { name: "Monaco", value: 147 },
       { name: "Mongolia", value: 148 },
       { name: "Montenegro", value: 149 },
       { name: "Montserrat", value: 150 },
       { name: "Morocco", value: 151 },
       { name: "Mozambique", value: 152 },
       { name: "Myanmar", value: 153 },
       { name: "Namibia", value: 154 },
       { name: "Nauru", value: 155 },
       { name: "Nepal", value: 156 },
       { name: "Netherlands", value: 157 },
       { name: "New Caledonia", value: 158 },
       { name: "New Zealand", value: 159 },
       { name: "Nicaragua", value: 160 },
       { name: "Niger", value: 161 },
       { name: "Nigeria", value: 162 },
       { name: "Niue", value: 163 },
       { name: "Norfolk Island", value: 164 },
       { name: "Northern Mariana Islands", value: 165 },
       { name: "Norway", value: 166 },
       { name: "Oman", value: 167 },
       { name: "Pakistan", value: 168 },
       { name: "Palau", value: 169 },
       { name: "Palestine, State Of", value: 170 },
       { name: "Panama", value: 171 },
       { name: "Papua New Guinea", value: 172 },
       { name: "Paraguay", value: 173 },
       { name: "Peru", value: 174 },
       { name: "Philippines", value: 175 },
       { name: "Pitcairn", value: 176 },
       { name: "Poland", value: 177 },
       { name: "Portugal", value: 178 },
       { name: "Puerto Rico", value: 179 },
       { name: "Qatar", value: 180 },
       { name: "Réunion", value: 181 },
       { name: "Romania", value: 182 },
       { name: "Russian Federation", value: 183 },
       { name: "Rwanda", value: 184 },
       { name: "Saint Barthélemy", value: 185 },
       { name: "Saint Helena, Ascension And Tristan Da Cunha", value: 186 },
       { name: "Saint Kitts and Nevis", value: 187 },
       { name: "Saint Lucia", value: 188 },
       { name: "Saint Martin (French Part)", value: 189 },
       { name: "SaintPierreAndMiquelon", value: 190 },
       { name: "Saint Vincent and The Grenadines", value: 191 },
       { name: "Samoa", value: 192 },
       { name: "San Marino", value: 193 },
       { name: "Sao Tome and Principe", value: 194 },
       { name: "Saudi Arabia", value: 195 },
       { name: "Senegal", value: 196 },
       { name: "Serbia", value: 197 },
       { name: "Seychelles", value: 198 },
       { name: "Sierra Leone", value: 199 },
       { name: "Singapore", value: 200 },
       { name: "Sint Maarten (Dutch Part)", value: 201 },
       { name: "Slovakia", value: 202 },
       { name: "Slovenia", value: 203 },
       { name: "Solomon Islands", value: 204 },
       { name: "Somalia", value: 205 },
       { name: "South Africa", value: 206 },
       { name: "South Georgia and the South Sandwich Islands", value: 207 },
       { name: "SouthSudan", value: 208 },
       { name: "Spain", value: 209 },
       { name: "Sri Lanka", value: 210 },
       { name: "Sudan", value: 211 },
       { name: "Suriname", value: 212 },
       { name: "Svalbard and Jan Mayen", value: 213 },
       { name: "Swaziland", value: 214 },
       { name: "Sweden", value: 215 },
       { name: "Switzerland", value: 216 },
       { name: "Syrian Arab Republic", value: 217 },
       { name: "Taiwan, Province of China", value: 218 },
       { name: "Tajikistan", value: 219 },
       { name: "Tanzania, United Republic Of", value: 220 },
       { name: "Thailand", value: 221 },
       { name: "Timor-Leste", value: 222 },
       { name: "Togo", value: 223 },
       { name: "Tokelau", value: 224 },
       { name: "Tonga", value: 225 },
       { name: "Trinidad and Tobago", value: 226 },
       { name: "Tunisia", value: 227 },
       { name: "Turkey", value: 228 },
       { name: "Turkmenistan", value: 229 },
       { name: "Turks and Caicos Islands", value: 230 },
       { name: "Tuvalu", value: 231 },
       { name: "Uganda", value: 232 },
       { name: "Ukraine", value: 233 },
       { name: "United Arab Emirates", value: 234 },
       { name: "United Kingdom", value: 1 },
       { name: "United States", value: 0 },
       { name: "United States Minor Outlying Islands", value: 237 },
       { name: "Uruguay", value: 238 },
       { name: "Uzbekistan", value: 239 },
       { name: "Vanuatu", value: 240 },
       { name: "Venezuela, Bolivarian Republic Of", value: 241 },
       { name: "Viet Nam", value: 242 },
       { name: "Virgin Islands, British", value: 243 },
       { name: "Virgin Islands, U.S.", value: 244 },
       { name: "Wallis and Futuna", value: 245 },
       { name: "Western Sahara", value: 246 },
       { name: "Yemen", value: 247 },
       { name: "Zambia", value: 248 },
       { name: "Zimbabwe", value: 249 },
       { name: "Netherlands Antilles", value: 250 }
    ];
    var fundedBy = ['None', 'Check', 'Cash'];
    var fundingMethod = ['None', 'Credit Card', 'Bank Account', 'Pledge', 'Conduit', 'Stock', 'Cash', 'Check','Existing','Electronic Deposit', 'Payroll Deduction'];
    var giftType = ['None', 'Individual', 'Organization', , 'Anonymous', , 'IndividualConduit'];
    var paymentStatus = ['None', 'ToBeSettled', 'ToBeDeposited', 'Deposited', 'Refunded', 'Voided', 'Chargeback','ToBeRefunded','Manual Refund','Resubmitted Check Scan','Error'];
    var individualRelationship = ['None', 'Associate', 'Marriage', 'Parent', 'Child', 'Family'];
    var orgMemberType = ['None', 'Employee', 'Member', 'Board Member', 'Volunteer','Owner','Other'];
    var fundableReferenceType = ['None', 'Organization', 'Project', 'Campaign', 'Fund', 'Event', 'MisionTrip' , 'General'];
    var projectStatusType = ['None', 'Pending', 'Active', 'Paused', 'Closed', 'Canceled'];
    var dateInterval = ['Second', 'Minute', 'Hour', 'Day', 'Week', 'Month', 'Quarter', 'Year'];
    var commitmentLineItem = ['none', 'Gift Designation', 'Event Package', 'Direct Support', 'General Revenue'];
    var channel = ['None', 'Telephone', 'SMS', 'Email', 'Web', 'Mail', 'White Mail', 'Social Network', 'Face to Face', 'Mobile', 'Multi', 'Brokerage', 'Donor Advised Fund', 'IRA Direct Contribution'];
    var accountType = ['None', 'Cash', 'Asset', 'Gift Payable', 'Gift Revenue', 'Gift Receivable', 'Expense', 'Deposit in Transit', 'Inventory Asset', 'Cost of Goods Sold', 'General Revenue', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'Vision2 Payment Processing Expense'];
    var restriction = ['None', 'Unrestricted', 'Temporarily Restricted', 'Restricted'];
var ledgerPostStatus = ['Pending', 'InProgress', 'Completed', 'Error', 'Reversal Pending', 'Reversal In Progress', 'Reversal Error', 'Reversed', 'Pending Account Reassignment', 'Error Account Reassignment', 'In Validation'];
    var country = ['United States', 'United Kingdom', 'Mexico', 'Canada'];
    var frequency = ['Monthly', 'Weekly', 'EveryOtherWeek', 'Yearly', 'Quarterly',  'Once', 'on the First and 15th'];
    var vaultReturn = ['Success', 'Failed', 'Invalid Funding Source', 'Could Not Resolve Merchant Account', 'Could Not Resolve Funding Source', 'Could Not Resolve Original Transaction', 'Gateway Communication Failed', 'General Decline', 'Missing Data', 'Invalid Data', 'RFI Decline', 'Expired Card', 'Lost Or Stolen Card', 'Invalid CVN', 'Invalid Address', 'Invalid Account Number', 'Authorization Already Reversed', 'Authorization Already Captured', 'Mismatched Transaction Amount', 'Invalid Request Id', 'Closed Account', 'Failed Vault Validation', 'Invalid Card Type', 'Inactive Or Refused For Card Not Present Transaction'];
    var mergeProfileStatus = ['None', 'In Process', 'Error', 'Complete'];
    var paymentMethod = ['None', 'Credit Card', 'Bank Account', '', '', 'Stock', 'Cash', 'Check', '', 'Electronic Deposit','Payroll Deduction'];
    var batchStatus = ['Not Posted', 'Post Started', 'Errors', 'Posted', 'Error: Contact Vision2 Support '];
    var commitmentItemType = ['None', 'Gift', 'Event', 'Goods', 'Services'];
    var depositTicketStatus = ['Not Deposited', 'Deposit In Process', 'Deposit Error', 'Deposited', 'Voided'];
    var triggerSourceType = ['', 'Credit Card Expiration', 'Recurring Payment Failure', 'Recurring Gift Expiration', 'Payment Return', 'Scheduled Payment', 'First Gift', 'Last Gift'];
    var marketingTemplateType = ['', 'Connection Acknowledgement', 'Single Designated Gift Acknowledgement', 'Multi Designation Gift Acknowledgement', 'Commitment Source Acknowledgement', 'Outbound Appeal', 'Newsletter', 'Returned Payment', 'Recurring Gift Payment Failure', 'Expiring Credit Card', 'Commitment Payment Reminder', 'Recurring Gift Schedule Ending', 'Last Gift Appeal', 'First Gift Welcome',  'Volunteer Fundraiser', 'Campaign Goal Acknowledgement','Church Service Acknowledgement'];
    var volunteerRequirementType = ['', 'Background Check', 'Minimum Age', 'Maximum Age', 'Gender', 'Yes or No', 'Yes or No with an expiration date', 'Yes or No with a value'];
    var volunteerParticipantStatus = ['Pending Review', 'Waiting List', 'Accepted', 'Removed', 'Declined'];
    var gender = ['Unspecified', 'Male', 'Female'];
    var appealType = ['', 'Fundraising', 'Volunteer Fundraiser'];
    var communicationSeriesType = ['', 'Fundraising Dynamic Start', 'Fundraising Dyanmic End', 'Fundraising Fixed Schedule', 'Volunteer Fundraiser Dynamic Start', 'Volunteer Fundraiser Dynamic End'];
    var commitmentLineItemType = ['', 'Gift Designation', 'Event', 'Direct Support', 'General Revenue'];
    var alertEventType = ['','New pledge','New gift with funding','Scheduled payment processing', 'Recurring payment batch', 'New donor', 'New profile', 'Missed recurring payment', 'Recurring payment', 'Failed merchant authorization', 'Fulfillment Request', 'Manual Escalation', 'Recurring Gift Stopped'];
    var alertFrequencyType = ['', 'Weekly', 'Daily', 'Immediately'];
    var authenticationProviderType = ['', 'Email Address and Password', 'Facebook', '','', 'OAuth', 'JWT', 'Twitter', 'Google', 'Yahoo', '','', '', 'Fellowship One', 'APIKey', 'OAuthToken', 'SMS', 'Chat', 'Fellowship One Capture','Single Use Token', 'Ministy Platform Token', 'Ministry Platform JWT', '', '', 'PIN'];
    var irsSubsection = ['', 'Government', 'Title Holding', 'Charity', 'Association', 'Union', 'Business Association', 'Social', 'Fraternal Beneficiary', 'Voluntary Employee Association', 'Domestic Association', 'Teacher Retirement Fund', 'Cooperative', 'Cemetary Association', 'Credit Union', 'Mutual Insurance', 'Crop Finance', 'Unemployment Trust', 'Pension Trust', 'War Veteran Organization', 'Legal Services', 'Black Lung', 'Multi Employer Pension', 'Prior to 1800 War Veteran Association', 'ERISA 4049 Trust', 'Pension Title Holding', 'State High Risk Health', 'State Workers Compensation', '', 'ACA Qualified Health Insurance', '', '', '', '', '', '', '', '', '', '', 'Religious', '', '', '', '', '', '', '', '', '', '', 'Cooperative Hospital',, '', '', '', '', '', '', '', '', '', '', 'Cooperative Education', '', '', '', '', '', '', '', '', '', '', 'Child Care', 'Charitable Risk', '', '', '', '', '', '', '', '', '', '', 'State Sponsored Tuition Program', 'Private Foundation'];
    var irsExemption = ['', 'Unconditional', 'Conditional', '', '', '', '', '', '', '', '', '', '', 'Trust', '', '', '', '', '', '', '', '', '', '', '', '', 'No Longer Private Foundation'];
    var irsOrganization = ['', 'Corporation', 'Trust', 'Cooperative', 'Partnership', 'Association'];
    var irsDeductible = ['', 'Deductible', 'Not Deductible', 'Deductible by Treaty'];
    var irsCharitableClassification = ['', 'Charitable', 'Educational', 'Literary', 'Cruelty to Animals', 'Cruelty to Children', 'Public Safety', 'Religous', 'Scientific'];
    var workplaceCampaignInviteStatus = ['', 'Invited', 'Pending Vision2 Approval', 'Pending rganization Add', 'Pending Non Profit Response', 'Pending Corporate Response', 'Accepted', 'Rejected'];
    var catalogItemType = ['', 'Sponsorship', 'Mission Trip Participant', 'Event', 'Product'];
    var massMergeStatus = ['Pending', 'Started', 'Completed', 'Error'];
    var fulfillmentStatus = ['Not Picked', 'Picking process started', 'Picked', 'Shipped', 'Returned'];
    var packageType = ['None', 'Mission Trip Participant', 'Missionary', 'Premium'];
    var accountSegmentType = ['Constant', 'Site', 'Channel', 'Designation', 'Payment Method', 'Fund', 'BankAccount', 'Restriction', 'Source', 'Provider','Department'];
    var communicationType = ['None', 'Donor Statement', 'Gift Acknowledgement', 'Recurring Gift Acknowledgement', 'Campaign Goal Acknowledgement'];
    var picklistStatusType = ['', 'Not Started', 'Started', 'Picking Complete', 'Unpick', 'Unpick Started', 'Unpick Complete', 'Error'];
    var marketingCommunicationType = ['', 'General', 'Online', 'Connect', 'Pledge', 'Website', 'Solicitation', 'Mailing', 'Package'];
    var weekDayDDD = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    var weekDayFull = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var donorStatementExecutionStatus = ['Not Started', 'Statistics Generating', 'Statistics Finished', 'Statements Generating', 'Householding Completed', 'Individual No Address Completed', 'Individual Address Completed', 'Organization Completed', 'Run In Progress', 'Run Complete', 'Errors'];
    var importTaskStatusType = ['','Created','Validating file format','Ready for mapping','Submitted for mapping validation','Failed mapping validation', 'Passed mapping validation', 'Submitted for processing','Importing','Data mapping failed','Completed Processing With Errors','Completed Processing', 'User Accepted Errors', 'Closed', 'System Error', 'User Does Not Have Permissions to Complete Import', 'No Rows Found In Import File'];
    var importTaskType = ['', 'Individual', 'Individual Gift','', 'Individual Commitment', 'Individual Payment', 'Individual Payment', 'Organization Gift', 'Organization Commitment', 'Organization Payment', 'Prospect', 'Employee', 'Designation', 'Scheduled Giving', 'Fulfillment Shipment', 'Organization', 'Anonymous Gifts'];
   // var triggerSourceType = ['', 'Credit Card Expiration', 'Recurring Payment Failure', 'Recurring Gift Expiration', 'Payment Returned', 'Pledge Reminder', 'First Gift', 'Last Gift'];
    var triggerEventStatusType = ['', 'Active', 'Aborted', 'Error', 'Successful Response', 'Completed'];
    var commitmentItemStatusType = ['Open', 'Invalid Funding Method', 'Paused', '', '', '', '', '', '', '', 'Has Funding Method', 'Paid in Full', 'Closed By Donor', 'Closed by Org', 'Voided', 'Refunded','Card Updated But Not Reactivated'] ;
    var scheduledPaymentEventStatusType = ['To Be Processed', 'Successful', 'Failed', 'Invalid Funding Method', 'Archived Funding Method', 'Manually Funded','Closed'];
    var authenticationCredentialsStatusType = ['None', 'Pending', 'Active', 'Inactive'];
    var organizationProfileType = [' None', 'Church', 'Non Profit', 'Corporation', 'Foundation', 'Trust', 'Other'];
    var departmentDefaultAccountType = accountType;
    var integrationAPIKeyType = ['None', 'OAuth', 'External API','Ministry Platfrom Payment'];
    var settlementPaymentMethodType = ['None', 'Credit Card', 'ACH', 'High Value Amex', 'Electronic Deposit'];
    var transactionType = ['','Payment', 'Void Payment', 'Refund Payment', 'Payment Services Expense', 'Chargeback', '', 'Refund In Transit', 'Payment Settlement', 'Settlement Rounding', 'Payment Adjustment', 'Mannual Refund', 'Error Adjustment', 'Inventory Purchase', 'Premium Fulfillment', 'Premium Return', 'Inventory Writeoff', 'Service Fee for Chargeback Refund', 'Reversal of Over Settlement', 'Settlement of Fee Only'];
    var acknowledgementType = ['','Giving Link Acknowledgement','','','Multi Designation Acknowledgement', 'Giving Goal Acknowledgement', 'Recurring Gift Acknowledgement', 'Church Service Acknowledgement', 'General Source Acknowledgement', '']
        var externalAuthenticationProviderType = ['OAuth2', 'JWT', 'Office365/AzureAD OAuth2', 'Google'];
var internalClaim = ['Account Unique Identifier', 'First Name', 'Last Name', 'Email', 'Roles', 'Nonce', 'External System Key', 'Account Status']; 
  
var expenseType = ['Failed Transactions','Auth Only', 'Authorization Only', 'Rounding', 'Fee Credit', 'Negative Balance Transfer', 'Wire Transfer Fee Adjustment', 'Negative Settlement/Balance Due']
var creditCardType = ['', 'Visa', 'Master Card', 'Discover', 'Amex']
var lederPostType = ['Manual', 'New Revenue', 'Changes', 'Offline', 'Card Settlement', 'Bank Account Settlement', 'Check Settlement', 'High Val Amex Settlement']
var customFieldEntityType = ['', 'Individual', 'Organization', 'Engagement Household'];
var customFieldDataType = ['Text', 'LookupValue', 'True/False', 'Date','Date Range', 'Integer', 'Decimal', 'Currency']
    
    var temp = {
   
        CountryCodes: countryCodes,
        FundedBy: fundedBy,
        FundingMethod: fundingMethod,
        GiftType: giftType,
        PaymentStatus: paymentStatus,
        IndividualRelationship: individualRelationship,
        OrgMemberType: orgMemberType,
        FundableReferenceType: fundableReferenceType,
        ProjectStatusType: projectStatusType,
        DateInterval: dateInterval,
        CommitmentLineItem: commitmentLineItem,
        Channel: channel,
        AccountType: accountType,
        Restriction: restriction,
        LedgerPostStatus: ledgerPostStatus,
        Country: country,
        Frequency: frequency,
        ValueReturn: vaultReturn,
        MergeProfileStatus: mergeProfileStatus,
        PaymentMethod: paymentMethod,
        BatchStatus: batchStatus,
        CommitmentItemType: commitmentItemType,
        DepositTicketStatus: depositTicketStatus,
        TriggerSourceType: triggerSourceType,
        MarketingTemplateType: marketingTemplateType,
        VolunteerRequirementType: volunteerRequirementType,
        Gender: gender,
        VolunteerParticipantStatus: volunteerParticipantStatus,
        AppealType: appealType,
        CommitmentLineItemType: commitmentLineItemType,
        CommunicationSeriesType: communicationSeriesType,
        AlertEventType: alertEventType,
        AlertFrequencyType: alertFrequencyType,
        AuthenticationProviderType: authenticationProviderType,
        IrsSubsection: irsSubsection,
        IrsExemption: irsExemption,
        IrsOrganization: irsOrganization,
        IrsDeductible: irsDeductible,
        IrsCharitableClassification: irsCharitableClassification,
        WorkplaceCampaignInviteStatus: workplaceCampaignInviteStatus,
        CatalogItemType: catalogItemType,
        MassMergeStatus: massMergeStatus,
        FulfillmentStatus: fulfillmentStatus,
        PackageType: packageType,
        AccountSegmentType: accountSegmentType,
        CommunicationType: communicationType,
        PicklistStatusType: picklistStatusType,
        MarketingCommunicationType: marketingCommunicationType,
        WeekDayDDD: weekDayDDD,
        WeekDayFull: weekDayFull,
        DonorStatementExecutionStatus : donorStatementExecutionStatus,
        ImportTaskStatusType: importTaskStatusType,
       
        TriggerEventStatusType: triggerEventStatusType,
        CommitmentItemStatusType: commitmentItemStatusType,
        ScheduledPaymentEventStatusType: scheduledPaymentEventStatusType,
        AuthenticationCredentialsStatusType: authenticationCredentialsStatusType,
        OrganizationProfileType: organizationProfileType,
        DepartmentDefaultAccountType: departmentDefaultAccountType,
        IntegrationAPIKeyType: integrationAPIKeyType,
        SettlementPaymentMethodType: settlementPaymentMethodType,
        TransactionType: transactionType,
        ExternalAuthenticationProviderType: externalAuthenticationProviderType,
        InternalClaim: internalClaim,
        AcknowledgementType: acknowledgementType,
        ExpenseType: expenseType,
        CreditCardType: creditCardType,
        ImportTaskType: importTaskType,
        LedgerPostType: lederPostType,
        CustomFieldEntityType: customFieldEntityType,
        CustomFieldDataType: customFieldDataType
};

//until we complete cleanup of UI
window.enumerations = temp;


if (typeof window === 'undefined') {
    module.exports = temp;
}

