
    function generateChart(current, last, path) {
        return new Highcharts.Chart({
            chart: {
                renderTo: path,
                type: 'column',
                margin: [50, 50, 50, 80]
            },
            title: {
                text: null
            },
            xAxis: {
                categories: ['Trailing 12 Months', 'Trailing 13-24 Months'],
                labels: {
                    rotation: 0,
                    align: 'center',
                    overflow: "justify"
                }
            },
            yAxis: {
                title: {
                    text: null
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function() {
                    return '<b>' + this.x + '</b><br/>' +
                        '$' + Highcharts.numberFormat(this.y, 2);
                }
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        formatter: function() {
                            return Highcharts.numberFormat(this.y, 2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                    }
                }
            },
            series: [
                {
                    name: 'Data',
                    data: [last, current],
                    dataLabels: {
                        enabled: false,
                        rotation: -90,
                        color: '#FFFFFF',
                        align: 'right',
                        x: 4,
                        y: 10,
                        style: {
                            fontSize: '13px',
                            fontFamily: 'Verdana, sans-serif',
                            fontWeight: 'bold'
                        }
                    }
                }
            ]
        });
    }
    

    function measureChange(last, current) {
        if (last != 0)
            return Math.round((current - last) / last * 100);
        return null;
    }

  

    

    function updateData(vm) {
      
        vm.GiftFrequencyData = ko.observableArray();
        vm.PaymentFrequencyData = ko.observableArray();

       if (vm.Statistics.GiftBuckets())
            vm.Statistics.GiftBuckets().reverse();

        if(vm.Statistics.PaymentBuckets())
            vm.Statistics.PaymentBuckets().reverse();

        vm.Categories = ko.observableArray();
        
        vm.Statistics.TotalGiftChange = ko.computed(function () {
            return measureChange(vm.Statistics.TotalGiftLastYear(), vm.Statistics.TotalGiftThisYear());
        });

        vm.Statistics.TotalPaymentChange = ko.computed(function () {
            return measureChange(vm.Statistics.TotalPaymentLastYear(), vm.Statistics.TotalPaymentThisYear());
        });
        
        vm.Statistics.MaxPaymentChange = ko.computed(function () {
            return measureChange(vm.Statistics.MaxPaymentLastYear(), vm.Statistics.MaxPaymentThisYear());
        });

        vm.Statistics.MaxGiftChange = ko.computed(function () {
            return measureChange(vm.Statistics.MaxGiftLastYear(), vm.Statistics.MaxGiftThisYear());
        });

        vm.Statistics.AverageGiftChange = ko.computed(function () {
                        return measureChange(vm.Statistics.AverageGiftLastYear(), vm.Statistics.AverageGiftLastYear());
        });

        vm.Statistics.AveragePaymentChange = ko.computed(function () {
            return measureChange(vm.Statistics.AveragePaymentLastYear(), vm.Statistics.AveragePaymentLastYear());
        });
        
        

        var length = 8;
        if (vm.Statistics.GiftBuckets() && vm.Statistics.GiftBuckets().length < length)
            length = vm.Statistics.GiftBuckets().length;
        if (vm.Statistics.PaymentBuckets() && vm.Statistics.PaymentBuckets().length < length)
            length = vm.Statistics.PaymentBuckets().length;
        for (var i = 0; i < length; i++) {
            if(vm.Statistics.GiftBuckets())
                vm.GiftFrequencyData.push(vm.Statistics.GiftBuckets()[i].TotalCount());
            if (vm.Statistics.PaymentBuckets())
                vm.PaymentFrequencyData.push(vm.Statistics.PaymentBuckets()[i].TotalCount());
            vm.Categories.push('Past ' + i * 60 + ' to ' + (i + 1) * 60 + ' days');
        }
        vm.CPData = ko.observableArray();
        if (vm.Statistics.ChannelPayments()) {
            for (var i = 0; i < vm.Statistics.ChannelPayments().length; i++) {
                
                vm.CPData.push([vm.Statistics.ChannelPayments()[i].Key(), vm.Statistics.ChannelPayments()[i].Value()]);
            }
        }
        
        vm.StatisticsPass(true);
    }

    function getIndividualProfileStatistics(vm, i) {
        console.log('starting to retrieve statistics for id ' + i);
        $.ajax({
            url: '/People/Individual/GetProfileStatistics',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({ id: i }),
            error: function(data, textStatus) {
                vm.StatisticsPass(false);
            },
            success: function (data, textStatus, jqXhr) {
                if (data.Data!= null) {
                    data = data.Data;
                    vm.Statistics = ko.mapping.fromJS(data);

                    updateData(vm);
                } else {
                    return;
                }
               
            }
        });
    }

    function getOrganizationProfileStatistics(vm, i) {
        $.ajax({
            url: '/Organization/Profile/GetProfileStatistics',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({ id: i }),
            error: function (data, textStatus) {
                vm.StatisticsPass(false);
            },
            success: function (data, textStatus, jqXhr) {
                if (data.Data!= null) {
                    data = data.Data;
                    vm.Statistics = ko.mapping.fromJS(data);
                    updateData(vm);
                }
               
            }
        });
    }

    function updateCharts(vm) {
        
        vm.AGChart = generateChart(vm.Statistics.AverageGiftLastYear(), vm.Statistics.AverageGiftThisYear(), "AGContainer");
        vm.APChart = generateChart(vm.Statistics.AveragePaymentLastYear(), vm.Statistics.AveragePaymentThisYear(), "APContainer");
        vm.MGChart = generateChart(vm.Statistics.MaxGiftLastYear(), vm.Statistics.MaxGiftThisYear(), "MGContainer");
        vm.MPChart = generateChart(vm.Statistics.MaxPaymentLastYear(), vm.Statistics.MaxPaymentThisYear(), "MPContainer");
        vm.TGChart = generateChart(vm.Statistics.TotalGiftLastYear(), vm.Statistics.TotalGiftThisYear(), "TGContainer");
        vm.TPChart = generateChart(vm.Statistics.TotalPaymentLastYear(), vm.Statistics.TotalPaymentThisYear(), "TPContainer");
       
        vm.CPChart = new Highcharts.Chart({
            chart: {
                renderTo: 'CPContainer',
                spacingTop: 0
                
            },
            title: {
                text: null,
            },
            tooltip: {
                pointFormat: '{series.name}: <b>${point.y}</b>',
                percentageDecimals: 1
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        formatter: function () {
                            var pointName = this.point.name;
                            var percent = Math.round(this.percentage);
                            if (pointName.length > 25) {
                                return '<b>' + pointName.substring(0, 21) + "...</b><span>" + percent + '%M</span>';
                            } else {
                                return '<b>' + pointName + ': </b><span>' + percent + '%</span>';
                            }
                        },
                       
                        style: {
                            width: 50,
                            color: 'black'
                        }
                        
                    }
                }
            },
            series: [{
                type: 'pie',
                name: 'Channel',
                data: vm.CPData(),
                size: '60%',
                innerSize: '20%'
            }]
        });
        
        vm.FChart = new Highcharts.Chart({
            chart: {
                renderTo: 'FContainer',
                type: 'column',
                margin: [50, 50, 100, 80]
            },
            title: {
                text: null
            },
            xAxis: {
                categories: vm.Categories(),
                labels: {
                    rotation: 0,
                    align: 'center',
                    overflow: "justify"
                }
            },
            yAxis: {
                title: {
                    text: 'Frequency'
                }
            },
            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                borderWidth: 0,
                itemHoverStyle: '#000'
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b><br/>' +
                        '' + Highcharts.numberFormat(this.y, 1);
                }
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return Highcharts.numberFormat(this.y, 2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                    }
                }
            },
            series: [{
                name: 'Gifts',
                data: vm.GiftFrequencyData(),
                dataLabels: {
                    enabled: false,
                    rotation: -90,
                    color: '#FFFFFF',
                    align: 'right',
                    x: 4,
                    y: 10,
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif',
                        fontWeight: 'bold'
                    }
                }
            }, {
                name: 'Payments',
                data: vm.PaymentFrequencyData(),
                dataLabels: {
                    enabled: false,
                    rotation: -90,
                    color: '#FFFFFF',
                    align: 'right',
                    x: 4,
                    y: 10,
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif',
                        fontWeight: 'bold'
                    }
                }
            }]
        });

       
    }

module.exports ={
        GetIndividualStatistics: getIndividualProfileStatistics,
        GetOrganizationProfileStatistics: getOrganizationProfileStatistics,
        UpdateCharts : updateCharts

}
