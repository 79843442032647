/* File Created: July 24, 2012 */
/* Copyright 2012 Vision2Systems LLC All rights reserved */
define([],function () {

    
    function notesModel() {
        this.GiftId = ko.observable(0);
        this.GiftNoteId = ko.observable(0);
        this.GiftNote = ko.observable(null);
        this.UserAccountId = ko.observable(0);
        this.RowStatus = ko.observable(1);
    }

    function subscribeGiftPayment(vm) {
        vm.setShowFrom = function(o) {
            vm.showFrom(o);
            if (o == 0) {
                vm.IndividualProfile.EnableSearch();
                vm.setShowConduit(false);
            }
            if (o == 1) {
                vm.OrganizationProfile.EnableSearch();
                vm.setShowConduit(false);
                vm.setAnonymousSource(1);
            }
            if (o == 2) {
                if (vm.NewPaymentFundingMethodType() != 6 && vm.NewPaymentFundingMethodType() != 7 && vm.NewPaymentFundingMethodType() != 9) {
                    //invalid combination of anonymous source and non check or cash payment
                    vm.NewPaymentFundingMethodType(6);
                }
                vm.setShowConduit(false);
            }
            return true;
        };


        vm.AutoApplyPayment = function (paymentHistory) {
                        var toAllocate = ko.unwrap(vm.CheckToACHPayment.PaymentAmount);
                        //we have a check to allocate funds from, no gift designations already selected, the template is set to show and apply commitments and we have some commitments
                        if (toAllocate && vm.GiftDesignations().length == 0 && vm.IsAutoAllocateOpenCommitments() && vm.ShowExistingCommitments() && vm.ExistingCommitments().length > 0) {
                            var i = 0;
                            while (toAllocate > 0 && vm.ExistingCommitments()[i]) {
                                var currentCommitment = vm.ExistingCommitments()[i];
                                if (toAllocate > ko.unwrap(currentCommitment.OutstandingAmount)) {
                                    currentCommitment.AppliedPaymentAmount(ko.unwrap(currentCommitment.OutstandingAmount));
                                } else {
                                    currentCommitment.AppliedPaymentAmount(toAllocate);
                                }
                                toAllocate -= ko.unwrap(currentCommitment.AppliedPaymentAmount);
                                i++;
                            }
                        } else if (toAllocate > 0 && vm.GiftDesignations().length == 0) { //we have money to allocate and no current or templated designation, see if we have a prior exact payment match
                            var matchingPayments = ko.utils.arrayFilter(paymentHistory, function (payment) {
                                return payment.Amount == toAllocate;
                            });
                            if (matchingPayments.length) {
                                var sourcePayment = matchingPayments[0];
                                for (var ap = 0; ap < sourcePayment.AppliedTo.length; ap++){
                                    for (var apd = 0; apd < sourcePayment.AppliedTo[ap].Details.length; apd++) {
                                        var currAP = ko.unwrap(sourcePayment.AppliedTo)[ap];
                                        var currAPD = ko.unwrap(currAP.Details)[apd];
                                        if (currAP && currAPD) {
                                            vm.GiftDesignations.Add();
                                            var gd = vm.GiftDesignations()[vm.GiftDesignations().length - 1];
                                            gd.FundableId(ko.unwrap(currAPD.Designation.FundableId));
                                            gd.FundableName(ko.unwrap(currAPD.Designation.FundableName));


                                            //GetDesignationDetail
                                                                                       
                                                
                                            gd.GiftAmount(ko.unwrap(currAPD.AppliedDetailAmountDouble));
                                            gd.AppliedPaymentAmount(ko.unwrap(currAPD.AppliedDetailAmountDouble));
                                            if (!ko.unwrap(gd.MarketingCommunicationId) && ko.unwrap(currAPD.MarketingCommunicationId))
                                                gd.MarketingCommunicationId(ko.unwrap(currAPD.MarketingCommunicationId));
                                        }
                                    }
                                }
                            }
                        }
                    };

        vm.fundingFrom = ko.computed(function() {
            if (vm.IsConduit()) {
                if (vm.OrganizationProfile.Name() != "" && vm.OrganizationProfile.Name() != null)
                    return "Funding provided by " + vm.OrganizationProfile.Name();
            }
            if (vm.showFrom() == 0 && vm.IndividualProfile.FirstName() != "" && vm.IndividualProfile.FirstName() != null) {
                var temp = "Funding provided by " + vm.IndividualProfile.FirstName();
                if (vm.IndividualProfile.LastName() != "" && vm.IndividualProfile.LastName() != null)
                    temp = temp + " " + vm.IndividualProfile.LastName();
                return temp;
            }
            if (vm.GiftType() == 4 && vm.AnonymousSource() == 2)
                return "Anonymous";
            return "Who is providing funding?";
        });

        vm.setAnonymousSource = function(a) {
            if (a === 0) {
                vm.IndividualProfile.EnableSearch();
            }
            if (a === 1) {
                vm.OrganizationProfile.EnableSearch();
            }
            vm.AnonymousSource(a);
        };

        vm.from = ko.computed(function() {
            if (vm.showFrom() == 0 && vm.IndividualProfile.FirstName() != "" && vm.IndividualProfile.FirstName() != null) {
                var temp = vm.IndividualProfile.FirstName();

                if (vm.IndividualProfile.LastName() != "" && vm.IndividualProfile.LastName() != null)
                    temp = temp + " " + vm.IndividualProfile.LastName();

                if (vm.IndividualProfile.Address1() != null && vm.IndividualProfile.Address1() != "")
                    temp = temp + " " + vm.IndividualProfile.Address1();

                if (vm.IndividualProfile.City() != null && vm.IndividualProfile.City() != "")
                    temp = temp + " " + vm.IndividualProfile.City();

                if (vm.IndividualProfile.Region() != null && vm.IndividualProfile.Region() != "")
                    temp = temp + " " + vm.IndividualProfile.Region();

                if (vm.IndividualProfile.PostalCode() != null && vm.IndividualProfile.PostalCode() != "")
                    temp = temp + " " + vm.IndividualProfile.PostalCode();

                return temp;
            }

            if (vm.showFrom() == 1 && vm.OrganizationProfile.Name() != "" && vm.OrganizationProfile.Name() != null) {
                return vm.OrganizationProfile.Name();
            }

            if (vm.showFrom() == 2 && vm.OrganizationProfile.Name() != "") {
                return "Anonymous";
            }

            return "?";

        });


        vm.setShowPayMethod = function(p) {
            vm.NewPaymentFundingMethodType(p);
            //now we are a pledge, clear out all applied payment amounts
            return true;
        };
        vm.fundingMethodName = ko.computed(function() {
            if (vm.NewPaymentFundingMethodType() === 6)
                return "Add cash funding";
            if (vm.NewPaymentFundingMethodType() === 7)
                return "Add check funding";
            if (vm.NewPaymentFundingMethodType() === 1)
                return "Add credit card funding";
            if (vm.NewPaymentFundingMethodType() === 2)
                return "Add bank account funding";
            if (vm.NewPaymentFundingMethodType() === 3)
                return "Pledging future funding";
            if (vm.NewPaymentFundingMethodType() === 5)
                return "Add stock funding";
            if (vm.NewPaymentFundingMethodType() === 9 || vm.NewPaymentFundingMethodType() === 10)
                return "Electronic deposit funding";
            return "";
        });


        vm.ShowAppliedPaymentAmount = ko.computed(function () {
            if (vm.GiftId() == 0 && vm.NewPaymentFundingMethodType() != 3)
                return true;
            if (vm.GiftId() != 0 && !vm.HasValidPaymentToken())
                return true;
            return false;
        });

        stockServices.SubscribeStockPayment(vm.StockPayment);



        function allocateSingleDesignationPayment(a) {
            var count = vm.GiftDesignations().length;
            if (count > 0) {
                var designatedTotal = 0
                ko.utils.arrayForEach(vm.GiftDesignations(), function (item) {
                    designatedTotal += parseFloat(item.GiftAmount());
                });
                if (designatedTotal == parseFloat(a)) {
                    return;
                }
                var amt = parseFloat(a / count || 0).toFixed(2);
                ko.utils.arrayForEach(vm.GiftDesignations(), function (item) {
                    item.GiftAmount(amt);
                    //item.AppliedPaymentAmount(amt);
                });
            }
        }

        vm.PaymentAmountEditable = ko.observable(true);
        vm.appliedPaymentTotal= ko.computed(function() {
            var amt = parseFloat(vm.GiftDesignations.totalAppliedPayment());
            ko.utils.arrayForEach(vm.ExistingCommitments(),
                function (i) {
                    amt = parseFloat(amt||0) + parseFloat(i.AppliedPaymentAmount()||0);
                });
            return amt.toFixed(2);
        });
        //batch only function
        if (vm.Batch && ko.utils.unwrapObservable(vm.Batch) != null) {
            //console.log('setting batch payment amount');
            vm.PaymentAmountEditable(true);
            //for single designation gifts make the payment amount the gift amount
            vm.CashPayment.PaymentAmount.subscribe(function(nv) {
                allocateSingleDesignationPayment(nv);
            });
            vm.CheckPayment.PaymentAmount.subscribe(function(nv) {
                allocateSingleDesignationPayment(nv);
            });
            vm.CreditCardPayment.PaymentAmount.subscribe(function(nv) {
                allocateSingleDesignationPayment(nv);
            });
            vm.BankAccountPayment.PaymentAmount.subscribe(function(nv) {
                allocateSingleDesignationPayment(nv);
            });
            vm.CheckToACHPayment.PaymentAmount.subscribe(function(nv) {
                allocateSingleDesignationPayment(nv);
            });
        } else {
            //gift entry only function
            
            if (vm.GiftId() === 0) {
                console.log('setting single entry new gift payment amount');
                vm.PaymentAmountEditable(false);


                vm.CashPayment.PaymentAmount = vm.appliedPaymentTotal;
                vm.CheckPayment.PaymentAmount = vm.appliedPaymentTotal;
                vm.CreditCardPayment.PaymentAmount = vm.appliedPaymentTotal;
                vm.BankAccountPayment.PaymentAmount = vm.appliedPaymentTotal;
                vm.StockPayment.PaymentAmount = vm.appliedPaymentTotal;
            }
        }
       
        
        

        vm.CreditCardPayment.CreditCard.Address.countryCodes = enumerations.CountryCodes;
        
        vm.CreditCardPayment.ExistingAddresses = ko.computed(function() {

            if (!vm.IndividualProfile.Address1()) {
                vm.CreditCardPayment.IsPrimaryAddressBillingAddress(false);
                return false;
            }
            return true;


        });
        vm.CreditCardPayment.ExistingCards = ko.observable(false);
        vm.CreditCardPayment.ShowBillingAddress = ko.computed(function() {
            if (!vm.CreditCardPayment.IsPrimaryAddressBillingAddress())
                return true;

            if (!vm.IndividualProfile.Address1())
                return true;

            return false;
        });

        vm.IsScheduledPayment = ko.computed(function() {
            var rar = false;
            ko.utils.arrayForEach(vm.GiftDesignations(), function(designation) {
                //console.log(ko.utils.unwrapObservable(viewModel.NewPaymentFundingMethodType()) == 1);
                //console.log(ko.utils.unwrapObservable(viewModel.NewPaymentFundingMethodType()) == 2);
                //console.log(parseFloat(ko.utils.unwrapObservable(designation.AppliedPaymentAmount)));
                //console.log(ko.utils.unwrapObservable(designation.IsScheduled));
                //console.log(ko.utils.unwrapObservable(designation.Schedule.StartDate));
                //console.log(moment().format("MM/DD/YYYY"));
                if (
                    (ko.utils.unwrapObservable(vm.NewPaymentFundingMethodType()) == 1 /*card*/ || ko.utils.unwrapObservable(vm.NewPaymentFundingMethodType()) == 2 /* bank */) && //Credit Card or Bank
                    (parseFloat(ko.utils.unwrapObservable(designation.AppliedPaymentAmount)) > parseFloat(0)) && // Amount is greater than 0
                    (ko.utils.unwrapObservable(designation.IsScheduled)) && //Is Scheduled
                    (ko.utils.unwrapObservable(designation.Schedule.StartDate) !== moment(new Date()).format("MM/DD/YYYY")) //Start Date is NOT today.
                ) {
                    rar = true;
                }
            });
            return rar;
        });


        vm.IsCheckImage = ko.computed(function() {
            return (vm.NewPaymentFundingMethodType() == 9 || vm.NewPaymentFundingMethodType() == 10);
        });

        vm.CheckToACHPayment.editPayment = ko.observable(false);

        if (vm.CheckToACHPayment && vm.CheckToACHPayment.ScanDetails && vm.CheckToACHPayment.ScanDetails.AccountNumber) {
            vm.CheckToACHPayment.ScanDetails.AccountNumber.subscribe(function(nv) {
                vm.CheckToACHPayment.Account.IsAccountNumberChanged(true);
            });

            vm.CheckToACHPayment.ScanDetails.RoutingNumber.subscribe(function (nv) {
                vm.CheckToACHPayment.Account.IsRoutingNumberChanged(true);
            });
            
        }


    }

    function setDeletedProperties(vm) {
        vm.deletedProperties = ko.computed(function() {
            var toDelete = ["GiftDesignations.Fundables", "PotentialDonorMatches", "PotentialOrganizations", "PotentialBankAccounts", "IndividualProfile.ProfileMatches", "OrganizationProfile.ProfileMatches", "PreviousSearch", "PotentialSites", "OutstandingCommitments", "GiftDesignations.FundableItem", "showFrom", "IsCheckImage", "fundingMethodName", "showCheckImage", "countryCodes", "countryTypeCodes"];
            if (vm.NewPaymentFundingMethodType() != 1)
                toDelete.push("CreditCardPayment");
            if (vm.NewPaymentFundingMethodType() != 2)
                toDelete.push("BankAccountPayment");
            if (vm.NewPaymentFundingMethodType() != 3)
                toDelete.push("PledgeCommitment");
            if (vm.NewPaymentFundingMethodType() != 5)
                toDelete.push("StockPayment");
            if (vm.NewPaymentFundingMethodType() != 7)
                toDelete.push("CheckPayment");
            if (vm.NewPaymentFundingMethodType() != 9 && vm.NewPaymentFundingMethodType() != 10)
                toDelete.push("CheckToACHPayment");
            if (vm.NewPaymentFundingMethodType() != 6 && vm.NewPaymentFundingMethodType() != 9 && vm.NewPaymentFundingMethodType() != 10)
                toDelete.push("CashPayment");
            if (vm.GiftType() == 4) {
                if (vm.AnonymousSource() != 0)
                    toDelete.push("IndividualProfile");
                if (vm.AnonymousSource() != 1)
                    toDelete.push("OrganizationProfile");
            }
            return toDelete;
        });
    };

    function subscribeNotes(vm) {
        vm.Notes.Add = function() {
            vm.Notes.push(new notesModel());
        };
        vm.Notes.Delete = function(row) {
            var _self = this;
            
            notify.confirm('Are you sure you want to delete this note?').then((result) => {
                
                if (result.value) { /* all cases if*/
                    if (row.RowStatus() == 1) {
                        vm.Notes.remove(_self);
                    } else {
                        row.RowStatus(2);
                    } /*end all cases if*/
                }
            });
        };
    }



    function subscribeProfileChanges(vm) {

        vm.IndividualProfile.IndividualProfileId.subscribe(function(nv) {
            if (nv == 0) {
                setTimeout(function() { $('#profileForm #FirstName').focus(); }, 10);
            } else {
                if (vm.NewPaymentFundingMethodType() == 6)
                    setTimeout(function() { $('#cashPaymentAmount').focus().select(); }, 10);
                if (vm.NewPaymentFundingMethodType() == 7)
                    setTimeout(function() { $('#checkPaymentAmount').focus().select(); }, 10);
                if (vm.NewPaymentFundingMethodType() == 1)
                    setTimeout(function() { $('#creditCardPaymentAmount').focus().select(); }, 10);
                if (vm.NewPaymentFundingMethodType() == 2)
                    setTimeout(function() { $('#bankAccountPaymentAmount').focus().select(); }, 10);
                if (vm.NewPaymentFundingMethodType() == 9 && vm.NewPaymentFundingMethodType() == 10)
                    setTimeout(function () { $('#checkToACHPaymentAmount').focus().select(); }, 10);
            }
        });

      
        //set individual profile search to use complex search
        vm.IndividualProfile.UseComplex = ko.observable(true);

        //set entry field to payment amount after search
        vm.OrganizationProfile.Id.subscribe(function(nv) {
            if (nv == 0) {
                setTimeout(function() { $('#organizationProfileForm #Name').focus(); }, 10);
            } else {
                if (vm.NewPaymentFundingMethodType() == 6)
                    setTimeout(function() { $('#cashPaymentAmount').focus().select(); }, 10);
                if (vm.NewPaymentFundingMethodType() == 7)
                    setTimeout(function() { $('#checkPaymentAmount').focus().select(); }, 10);
                if (vm.NewPaymentFundingMethodType() == 1)
                    setTimeout(function() { $('#creditCardPaymentAmount').focus().select(); }, 10);
                if (vm.NewPaymentFundingMethodType() == 2)
                    setTimeout(function() { $('#bankAccountPaymentAmount').focus().select(); }, 10);
                if (vm.NewPaymentFundingMethodType() == 9 && vm.NewPaymentFundingMethodType() == 10)
                    setTimeout(function () { $('#checkToACHPaymentAmount').focus().select(); }, 10);
            }
        });

        if (vm.ShowExistingCommitments()) {
            basicProfileServices.SubscribeGiftProfileChanges(vm.IndividualProfile, vm);
            orgProfileServices.SubscribeGiftProfileChanges(vm.OrganizationProfile, vm);
        } else {
            basicProfileServices.SubscribeProfileChanges(vm.IndividualProfile, vm);
            orgProfileServices.SubscribeProfileChanges(vm.OrganizationProfile, vm);
        }

        if (vm.Id() != 0) {
            console.log('vm in Edit mode');
            if (vm.IndividualProfile.IndividualProfileId() != 0) {
                var id = 
                console.log('vm has individual profile');
                vm.showFrom(0);

            }
            if (vm.OrganizationProfile.Id() != 0) {
                console.log('vm has organization profile');
                vm.showFrom(1);
            }
            if (vm.IndividualProfile.IndividualProfileId() == 0 && vm.OrganizationProfile.Id() == 0) {
                console.log('vm has no individual or organization profiles');
                vm.showFrom(2);
            }
        }

        vm.profileMatchSearchUrl = '/People/Individual/GetFullProfile';
    }
   
    function subscribeGiftType(vm) {
       


        vm.GiftType = ko.computed(function() {
            if (vm.showFrom() == 0) {
                if (vm.IsConduit())
                    return 6;
                else
                    return 1;
            }
            if (vm.showFrom() === 1)
                return 2;
            if (vm.showFrom() === 2)
                return 4;
            return 0;
        });

      
        vm.setShowConduit = function(c) {
            vm.IsConduit(c);
            if (c && vm.OrganizationProfile.Id() != 0)
                vm.OrganizationProfile.Id(0);
            if (c) {
                vm.AnonymousSource(1);
            } else {
                vm.AnonymousSource(0);
            }
        };

        if (!vm.MarketingCommunicationId()) {
            vm.ShowSource(true);
        }
    }

    function subscribeGiftSite(vm) {
        vm.GiftCurrencyType = ko.computed(function() {
            if (vm.SiteCurrencyType && vm.SiteCurrencyType() > 0) {
                return vm.SiteCurrencyType();
            } else {
                return 0;
            }
        });

        
        var defaultSiteIdAssigned = (vm.UseDonorSiteId() == false && vm.GiftSiteId() && vm.GiftSiteId() != 0);

        //primary site default for gift
        vm.IndividualProfile.SiteId.subscribe(function(nv) {
            if (!defaultSiteIdAssigned && vm.showFrom() === 0 && nv!=0 )
                vm.GiftSiteId(nv);
        });

        
        vm.OrganizationProfile.SiteId.subscribe(function (nv) {
            console.log('site id change for org', nv, vm.showFrom(), !defaultSiteIdAssigned, nv != 0);
            if (!defaultSiteIdAssigned && vm.showFrom() === 1 && nv != 0)
                vm.GiftSiteId(nv);
        });


        //primary site default for gift
        

        //default the currency type based on the site selected
        vm.GiftSiteId.subscribe(function(nv) {
            ko.utils.arrayForEach(vm.PotentialSites(), function(item) {
                if (item.Id == nv) {
                    vm.SiteCurrencyType(ko.utils.unwrapObservable(item.CurrencyType));
                    vm.SiteName(ko.utils.unwrapObservable(item.Name));
                    vm.CurrencyType(ko.utils.unwrapObservable(item.CurrencyType));
                    return;
                }
            });
        });

        vm.DepositBankAccountId.subscribe(function(nv) {
            ko.utils.arrayForEach(vm.PotentialBankAccounts(), function(item) {
                if (item.Id == nv) {
                    vm.c = ko.observable(item.Name);
                    return;
                }
            });
        });
    }
   

    function subscribeGiftEntry(vm) {
        var type = vm.GiftType();
        var orgId = vm.OrganizationProfile.Id();

        vm.AskLadderId = ko.observable();
        vm.AskLadderAmounts = ko.observableArray();
        vm.IsImpactOrPremium = ko.observable(false);
        vm.ShowOutstandingAmount = ko.observable(false);
        vm.IsConduit = ko.observable(false);
        vm.showFrom = ko.observable(0);
        EventCalendarExtender.DatePickerEnable(vm, vm.GiftDate);
        //console.log('subscribing gift site');
        subscribeGiftSite(vm);
      
        subscribeGiftType(vm);
        setDeletedProperties(vm);
        subscribeNotes(vm);
        subscribeProfileChanges(vm);
        giftDesignationServices.SubscribeGiftDesignations(vm.GiftDesignations, vm);
        giftDesignationServices.SubscribeGift(vm, vm.SourceCode);
        //moved to be after gift designations
        subscribeGiftPayment(vm);

        if (vm.Id() != 0 || vm.GiftId()!=0) {
            //Check to see if we are loading an existing batch item and set the sources types
            if (type == 4) {
                vm.showFrom(2);
                
            }

            if (type == 1 || type == 6) {
                vm.showFrom(0);
                if (vm.IndividualProfile.IndividualProfileId() == 0)
                    vm.IndividualProfile.SearchForMatches();

            }

            if (type == 2) {
                vm.showFrom(1);
                if (vm.OrganizationProfile.Id() == 0)
                    vm.OrganizationProfile.SearchForMatches();
            }

            if (type == 6) {
                vm.IsConduit(true);
                vm.AnonymousSource(1);
                
            }
        }
        //console.log("org id", orgId);
        //handle create from organization
        if (orgId && orgId!= 0) {
            vm.showFrom(1);
            vm.OrganizationProfile.Id(orgId);
        }

        vm.isFundableADesignation = function (id) {
            
            for (i = 0; i < viewModel.GiftDesignations().length; i++) {
                if (viewModel.GiftDesignations()[i].FundableId() == id) {
                    return true;
                }
            }
            return false;
        };
       
    }

    function subscribeBatchEntry(vm) {
        subscribeGiftEntry(vm);
        if (vm.AreTemplateFieldsEditable() === false) {
            ko.utils.arrayForEach(vm.PotentialBankAccounts(), function (item) {
                if (item.BankAccountId() === vm.DepositBankAccountId()) {
                    vm.DepositAccountName = ko.observable(item.Name());
                    return;
                }
            });
            ko.utils.arrayForEach(viewModel.PotentialSites(), function (item) {
                if (item.SiteId() === vm.GiftSiteId()) {
                    vm.SiteCurrencyType(item.CurrencyType());
                    vm.SiteName(item.Name());
                    vm.CurrencyType(item.CurrencyType());
                    return;
                }
            });
            vm.GiftCurrencyName = ko.observable(currency.Symbols[vm.GiftCurrencyType()]);
        }
    }


    return {
        SubscribeGiftEntry: subscribeGiftEntry,
        SubscribeBatchEntry: subscribeBatchEntry
    };
});