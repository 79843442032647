
var template = require('./archive-status.html');

function viewModel(params) {


    var self = this;
    if (ko.isObservable(params.Data.IsArchived)) {
        self.IsArchived = params.Data.IsArchived;
    } else {
        self.IsArchived = ko.oservable(params.Data.IsArchived);
    }
    
    

    self.archive = function(d) {
        notify.confirm('Are you sure you want to archive this ' + params.Name + ' ?',
            function() {
                var id = ko.utils.unwrapObservable(params.Data.Id);
                v2Form.POST({
                    block: true,
                    element: $("#main_body"),
                    url: params.Url + '/Archive',
                    data: { id: id },
                    success: function(data) {
                        if (data.Data === true) {
                            if(ko.isObservable(params.Data.IsArchived))
                                params.Data.IsArchived(true);
                            else
                                params.Data.IsArchived =true;
                        }
                    }
                });
            });
    };

    self.unarchive = function(d) {
        notify.confirm('Are you sure you want to unarchive this ' + params.Name + ' ?',
            function() {
                var id = ko.utils.unwrapObservable(params.Data.Id);
                v2Form.POST({
                    block: true,
                    element: $("#main_body"),
                    url: params.Url + '/Unarchive',
                    data: { id: id },
                    success: function(data) {
                        if (data.Data === true) {
                            if (ko.isObservable(params.Data.IsArchived))
                                params.Data.IsArchived(false);
                            else
                                params.Data.IsArchived = false;
                        }
                    }
                });
            });
    };

    return self;
}



module.exports = {
    viewModel: viewModel,
    template: template

};