var template = require('./fundable-picker.html');





function viewModel(params) {
    var self = this;
    self.Fundables = ko.observableArray();
    self.FundableName = ko.observable();
    self.Description = ko.observable();
    self.AccountSegment = ko.observable();
    
    self.FundableId = params.FundableId;
    self.FundableReferenceType = ko.observable();
    self.Label = ko.observable("Designation");
    self.IsCharitable = ko.observable();
    console.log('params', params);
    if (params.Type) {
        self.FundableReferenceType(ko.utils.unwrapObservable(params.Type));
    
    }

    if (params.IsCharitable != null || params.IsCharitable != "undefined") {
        self.IsCharitable(ko.utils.unwrapObservable(params.IsCharitable));
    }

    if (params.Label)
        self.Label(ko.utils.unwrapObservable(params.Label));
    
    self.GetFundables = function (options) {
        if (options.text == "") return;
        var model = { QueryTerm: options.text, PageIndex : options.page, PageSize : 15 };
        if (params.Type) {
            
            model.FundableReferenceType = self.FundableReferenceType();
        }

        if (params.IsCharitable != null || params.IsCharitable != "undefined") {
            model.IsCharitable = self.IsCharitable();
        }
        


        $.ajax({
            url: '/Finance/Designation/Search',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(model),
            success: function (data, textStatus, jqXhr) {
               options.callback(data.Data);
            },
            error: function (data, textStatus, jqXhr) {
                console.log('error');
                console.log(data);
            }
        });
    };


    

    self.FundableItem = ko.observable();

    self.FundableItem.subscribe(function (x) {
        params.FundableId(ko.utils.unwrapObservable(x.FundableId));
        if (params.FundableReferenceType)
            params.FundableReferenceType(ko.utils.unwrapObservable(x.FundableReferenceType));
        self.FundableId(ko.utils.unwrapObservable(x.FundableId));
        self.FundableName(ko.utils.unwrapObservable(x.Name));
        self.FundableId(ko.utils.unwrapObservable(x.FundableId));
        self.Description(ko.utils.unwrapObservable(x.Description));
        self.AccountSegment(ko.utils.unwrapObservable(x.AccountSegment));
        self.FundableReferenceType(ko.utils.unwrapObservable(x.FundableReferenceType));
        
    });

    self.getDetail = function () {
        v2Form.POST({
            url: '/Finance/Designation/GetDisplay',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: { id: self.FundableId() },
            success: function (data, textStatus, jqXhr) {
                self.FundableItem(data.Data);
            },
            error: function (data, textStatus, jqXhr) {
                console.log('error');
                console.log(data);
            }
        });
    };

    if (self.FundableId() != null && self.FundableId() > 0)
        self.getDetail();


    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};

