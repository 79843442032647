var template = require("./givinggoalsearch.html")
var pagination = require("../../v2/paginationServices");
var analytics = require("../../v2/analytics.js");
require("knockout");
require("knockout-mapping");
require("../../v2/knockout.custombindings");
var radio = require("radio");
var moment = require("moment");
var jstz = require("jstz");
var notify = require("../../v2/notify.js");





function viewModel(params) {
    var vm = this;
    vm.Goals = new pagination.PageModel();
    console.log('goal search params', params);
    vm.displayAnalytics = ko.observable(true);
    vm.displaySearch = ko.observable(true);
    vm.UseComplex = ko.observable(false);
    vm.loading = ko.observable(false);
    vm.hideProfile = ko.observable(true);

    vm.resultDownloadModalVisible = ko.observable(false);

    var profileId = ko.utils.unwrapObservable(params.ProfileId) || 0;
    var pageSize = params.PageSize || 30;

    vm.GoalSearchCriteria = new pagination.GivingGoalSearchModel(profileId, 0, pageSize);

    console.log('goal search', ko.toJS(vm.GoalSearchCriteria));

    EventCalendarExtender.DatePickerEnable(vm.GoalSearchCriteria, vm.GoalSearchCriteria.FromDateCreated);
    EventCalendarExtender.DatePickerEnable(vm.GoalSearchCriteria, vm.GoalSearchCriteria.ToDateCreated);
    EventCalendarExtender.DatePickerEnable(vm.GoalSearchCriteria, vm.GoalSearchCriteria.FromEndDate);
    EventCalendarExtender.DatePickerEnable(vm.GoalSearchCriteria, vm.GoalSearchCriteria.ToEndDate);


    vm.Channels = ko.observableArray();
    for (var i = 0; i < enumerations.Channel.length; i++) {
        if (i != 0)
            var y = { Name: enumerations.Channel[i], Index: i.toString() };
        else
            var y = { Name: 'All', Index: i.toString() };
        vm.Channels.push(y);
    }

    if (params.hideProfile)
        vm.hideProfile(ko.utils.unwrapObservable(params.hideProfile));

    if (params.SiteId)
        vm.GoalSearchCriteria.SiteId(ko.utils.unwrapObservable(params.SiteId));

    if (params.FundableId)
        vm.GoalSearchCriteria.FundableId(ko.utils.unwrapObservable(params.FundableId));

    if (params.HouseholdId)
        vm.GoalSearchCriteria.HouseholdId(ko.utils.unwrapObservable(params.HouseholdId));

    if (params.IsGivingPermissionBased)
        this.GoalSearchCriteria.IsGivingPermissionBased(ko.utils.unwrapObservable(params.IsGivingPermissionBased));


    if (params.OrganizationIndividualProfileId)
        vm.GoalSearchCriteria.OrganizationIndividualProfileId(ko.utils.unwrapObservable(params.OrganizationIndividualProfileId));

    if (params.OrganizationProfileId)
        vm.GoalSearchCriteria.OrganizationProfileId(ko.utils.unwrapObservable(params.OrganizationProfileId));

    if (params.HasSchedule)
        vm.GoalSearchCriteria.HasSchedule(ko.utils.unwrapObservable(params.HasSchedule));

    if (params.IsOpen)
        vm.GoalSearchCriteria.IsOpen(ko.utils.unwrapObservable(params.IsOpen));

    if (params.MarketingCommunicationId)
        vm.GoalSearchCriteria.MarketingCommunicationId(ko.utils.unwrapObservable(params.MarketingCommunicationId));

    if (params.displayAnalytics)
        vm.displayAnalytics(ko.utils.unwrapObservable(params.displayAnalytics));

    if (params.displaySearch)
        vm.displaySearch(ko.utils.unwrapObservable(params.displaySearch));

    if (params.UseComplex)
        vm.GoalSearchCriteria.UseComplex(ko.utils.unwrapObservable(params.UseComplex));

   
    vm.GoalSearchCriteria.GoalSearchType(params.Type || 0);



    pagination.SubscribeSearchPagination(vm, vm.Goals, '/Giving/Goal/Search', vm.GoalSearchCriteria);


    vm.navigateToIndividual = function (d) {

        window.open('/People/Individual/Details/' + d.Individual.Id());
    };

    vm.navigateToOrganization = function (d) {

        window.open('/Organization/Profile/Details/' + d.Organization.Id());
    };

    vm.navigateToDetails = function (d) {
        //if we are single page capable
        if (params.parent && params.parent.detailView) {
            params.parent.Id(d.Id());
            params.parent.indexView(false);
            params.parent.detailView(true);
           
        }
        else {
            window.open('/Giving/Goal/Details/' + d.Id());
        }
        
    };

    vm.navigateToEdit = function (d) {
        if (params.parent && params.parent.editView) {
            params.parent.Id(d.Id());
            params.parent.indexView(false);
            params.parent.editView(true);
           
        }
        else {
            window.open('/Giving/Goal/Details/' + d.Id());
        }
    };

    vm.navigateToCampaign = function (d) {

        window.open('/Marketing/Campaign/Details/' + d.Designation.FundableId());
    };

    vm.navigateToDelete = function (d) {
        //console.log('navigate to delete clicked');
        vm.deleteGoal(d);
    };

    vm.Channels = ko.observableArray();
    for (var i = 0; i < enumerations.Channel.length; i++) {
        if (i != 0)
            var y = { Name: enumerations.Channel[i], Index: i.toString() };
        else
            var y = { Name: 'All', Index: i.toString() };
        vm.Channels.push(y);
    }


    vm.GetSpreadSheet = function () {
        var model = ko.toJS(vm.GoalSearchCriteria);
        //console.log('get spreadsheet', model);
        v2Form.POST({
            url: '/Giving/Goal/GoalAnalyticsSpreadsheet',
            beforeSend: function () { vm.loading(true); },
            data: model,
            error: function () {
                v2Form.GET({
                    url: '/Giving/Goal/ClearArgumentsFromSession'
                });
            },
            complete: function () {
                vm.loading(false);
                $('body').unblock(); 
            }
        });

    };


    vm.deleteGoal = function (d) {
        //console.log('delete clicked', d);
        notify.confirm("Are you sure you would like to delete this goal?", function (b) {
            if (eval(b)) {
                v2Form.DELETE({
                    beforeSend: function () {
                        vm.loading(true);
                    },
                    url: '/Giving/Goal/Delete/' + d.Id(),
                    success: function (data) {
                        if (data.Data) {
                            vm.Goals.PageData.remove(d);
                        }
                        vm.loading(false);
                    },
                    complete: function () {
                        vm.loading(false);
                    }
                });
            }
        });
    };

    vm.GoalSearchCriteria.TimeZone(jstz.determine().name());
    vm.Goals.firstPage();

   


   //console.log('goal view model', vm);
}


module.exports = {
    viewModel: viewModel,
    template: template
}