
    const packageData = {
        PackageId: 0,
        PhotoId: 0,
        DisplayName: "Premium or Mission Trip Participant",
        Description: "Description of package",
        QuidProQuoValue: "$10.00",
        Items: [
            {
                Name: "Our DVD",
                Description: "Our dv description",
                PhotoId: 0,
                Quantity: 1
            }
        ]

    };


const individualGiftAcknowledgementData = {
        GiftId: 0,
        IsPledge: false,
        IsPledgePayment: false,
        OrganizationName: "your organization name",
        OrganizationContactName: "organization contact name",
        OrganizationContactEmail: "someone@yourorganization.com",
        OrganizationContactPhone: "organization phone",
        FirstName: "Lou",
        LastName: "Sullivan",
        ToName: "Lou Sullivan",
        FromEmailAddress: "support@vision2systems.com",
        FromName: "Kevin",
        EventCalendarId: 0,
        WebsiteId: 0,
        HasSchedule: false,
        Designation: {
            Name: "organizationname",
            Amount: "$100.23",
            IsPledge: false,
            IsPledgePayment: false,
            PledgeAmount: "$100.23",
            Schedule:
            {
                Id: 0,
                IsScheduled: false,
                IsComplete: false,
                Frequency: "Monthly",
                StartDate: "\/Date(1398860760400)\/",
                EndDateDisplay: "",
                WeekDay: "Sunday",
                NumberOfPayments: 0,
                DayOfMonth: 0,
                Display: " monthly starting on Wednesday, April 30, 2022 until Wednesday, April 30, 2023"
            },
            IsPackage: false,
            IsPremium: false,
            IsMissionTrip: false,
            Package: packageData

        },
        TotalAmount: "$1200.00",
        RemainingOutstandingAmount: "$50.00",
        SupportedChannels: ["Email", "Web"],
        AnnualTotal: "$1200.00",
        GiftDetailUrl: "http://yourorganization.com/giftdetail.html#0",
        GivingHistoryUrl: "http://yourorganization.com/profile.html",
        GiftDate: "April 30th 2021"
    };



const recurringPaymentAcknowledgementData = {
        GiftId: 0,
        PaymentId: 0,
        PaymentMethod: "VISA ending in 2525",
        IsPledge: false,
        IsPledgePayment: false,
        OrganizationName: "your organization name",
        OrganizationContactName: "organization contact name",
        OrganizationContactEmail: "someone@yourorganization.com",
        OrganizationContactPhone: "organization phone",
        FirstName: "Lou",
        LastName: "Sullivan",
        ToName: "Lou Sullivan",
        FromEmailAddress: "support@vision2systems.com",
        FromName: "Kevin",
        EventCalendarId: 0,
        WebsiteId: 0,
        HasSchedule: false,
        Designation: {
            Name: "YourOrganization",
            Amount: "$100.23",
            IsPledge: false,
            IsPledgePayment: false,
            PledgeAmount: "$100.23",
            Schedule:
            {
                Id: 0,
                IsScheduled: false,
                IsComplete: false,
                Frequency: "Monthly",
                StartDate: "\/Date(1398860760400)\/",
                EndDateDisplay: "",
                WeekDay: "Sunday",
                NumberOfPayments: 0,
                DayOfMonth: 0,
                Display: " monthly starting on Wednesday, April 30, 2015 until Wednesday, April 30, 2016"
            },
            IsPackage: false,
            IsPremium: false,
            IsMissionTrip: false,
            Package: packageData

        },
        TotalAmount: "$1200.00",
        RemainingOutstandingAmount: "$50.00",
        SupportedChannels: ["Email", "Web"],
        AnnualTotal: "$1200.00",
        GiftDetailUrl: "http://yourorganization.com/giftdetail.html#0",
        GivingHistoryUrl: "http://yourorganization.com/profile.html",
        GiftDate: "April 30th 2015"
    };

const firstGiftNotificationData = {
        ToName: "Mike Roberts",
        FromEmailAddress: "from@yourorganization.com",
        FundableDetailUrl: "http://yourorganization.com/details?v2did=1",
        Amount: "$100.00",
        DateDisplay: "April 30th 2021",
        Name: "your designation",
        ShortDescription: "your designation short description",
        LongDescription: "your designation long description",
        DefaultGivingLinkUrl: "http://yourorganization.com/give?v2glid=1",
        FirstName: "Mike",
        LastName: "Roberts",
        OrganizationName: "your organization name",
        OrganizationContactName: "organization contact name",
        OrganizationContactEmail: "someone@yourorganization.com",
        OrganizationContactPhone: "organization phone"
    };

const lastGiftNotificationData = {
        ToName: "Mike Roberts",
        FromEmailAddress: "from@yourorganization.com",
        LastFundableDetailUrl: "http://yourorganization.com/details?v2did=1",
        LastAmount: "$100.00",
        LastDateDisplay: "April 30th 2021",
        Name: "your designation",
        ShortDescription: "your designation short description",
        LongDescription: "your designation long description",
        DefaultGivingLinkUrl: "http://yourorganization.com/give?v2glid=1",
        FirstName: "Mike",
        LastName: "Roberts",
        OrganizationName: "your organization name",
        OrganizationContactName: "organization contact name",
        OrganizationContactEmail: "someone@yourorganization.com",
        OrganizationContactPhone: "organization phone"
    };

const multiDesignationGiftAcknowledgementData =
    {
        GiftId: 0,
        IsPledge: false,
        FirstName: "Lou",
        LastName: "Sullivan",
        ToName: "Lou Sullivan",
        FromEmailAddress: "support@vision2systems.com",
        FromName: "Kevin",
        EventCalendarId: 0,
        WebsiteId: 0,
        HasSchedule: false,
        ReceivedByChannelName : "Mail",
        Designations: [
            {
                Name: "YourOrganization",
                Amount: "$100.25",
                IsPledge: false,
                IsPledgePayment: false,
                PledgeAmount: "$100.25",
                RemainingOutstandingAmount: "$0.00",
                Schedule: {
                    Id: 0,
                    IsScheduled: true,
                    IsComplete: false,
                    Frequency: "Monthly",
                    StartDate: "4/30/2015",
                    EndDateDisplay: "",
                    WeekDay: "Sunday",
                    NumberOfPayments: 12,
                    DayOfMonth: 0,
                    Display: " monthly starting on Wednesday, April 30, 2015 until Wednesday, April 30, 2016"
                },
                IsPackage: false,
                IsPremium: false,
                IsMissionTrip: false,
                Package: packageData
            },
            {
                Name: "YourOrganizationCampaign",
                Amount: "$50.32"
            }
        ],
        TotalAmount: "$103.25",
        GiftDetailUrl: "yourorganization.com/giftdetail.html#0",
        GivingHistoryUrl: "yourorganization.com/profile.html",
        GiftDate: "April 30th 2020"
    };


const churchServiceGiftAcknowledgementData =
    {
        GiftId: 0,
        IsPledge: false,
        FirstName: "Chris",
        LastName: "Thompson",
        ToName: "Chris Thompson",
        FromEmailAddress: "support@vision2systems.com",
        FromName: "Kevin",
        EventCalendarId: 0,
        WebsiteId: 0,
        HasSchedule: false,
        ChurchServiceName: "Sunday 9AM Service",
        CampusName: "Downtown",
        Designations: [
            {
                Name: "Your organization",
                Amount: "$103.25",
                IsPledge: false,
                IsPledgePayment: false,
                PledgeAmount: "$103.25",
                RemainingOutstandingAmount: "$0.00",
                Schedule: {
                    Id: 0,
                    IsScheduled: true,
                    IsComplete: false,
                    Frequency: "Monthly",
                    StartDate: "4/30/2022",
                    EndDateDisplay: "",
                    WeekDay: "Sunday",
                    NumberOfPayments: 12,
                    DayOfMonth: 0,
                    Display: " monthly starting on Wednesday, April 30, 2022 until Wednesday, April 30, 2023"
                },
                IsPackage: false,
                IsPremium: false,
                IsMissionTrip: false,
                Package: packageData
            },
            {
                Name: "Your Designation",
                Amount: "$50.32"
            }
        ],
        TotalAmount: "$103.25",
        GiftDetailUrl: "yourorganization.com/giftdetail.html#0",
        GivingHistoryUrl: "yourorganization.com/profile.html",
        GiftDate: "April 30th 2020"
    };

const connectionAcknowledgement = {
        OrganizationName: "YourOrganization",
        FirstName: "Marc",
        LastName: "Knight",
        ToName: "Marc Knight",
        ToEmailAddress: "interestedperson@gmail.com",
        FromEmailAddress: "support@vision2systems.com",
        FromName: "Kevin"

    };

const selfRegister = {
        ToEmailAddress: "interestedperson@gmail.com",
        FromEmailAddress: "support@vision2systems.com",
        FirstName: "Marc",
        LastName: "Knight",
        ToName: "Marc Knight",
        OrganizationName: "YourOrganization",
        Token: 0

    };

const recurringGiftExpirationNotificationData = {
        ShortDescription: "Your short designation description",
        LongDescription: "Your long designation description",
        EditGiftUrl: "yourorganization.com/editgift.html",
        IsExpired: false,
        EndDateDisplay: "April 30th 2020",
        GiftId: 0,
        IsPledge: false,
        FirstName: "Lou",
        LastName: "Sullivan",
        ToName: "Lou Sullivan",
        FromEmailAddress: "support@vision2systems.com",
        FromName: "Kevin",
        EventCalendarId: 0,
        WebsiteId: 0,
        HasSchedule: false,
        Designation: {
            Name: "YourOrganization",
            Amount: "$100.23",
            Schedule: {
                Id: 0,
                IsScheduled: true,
                IsComplete: false,
                Frequency: "Monthly",
                StartDate: "\/Date(1398860760515)\/",
                EndDate: "\/Date(1430396760515)\/",
                EndDateDisplay: "Thursday, April 30, 2015",
                WeekDay: "Sunday",
                NumberOfPayments: 0,
                DayOfMonth: 0,
                Display: " monthly starting on Wednesday, April 30, 2014 until Wednesday, April 30, 2014"
            }
        },

        AnnualTotal: "$1200.00",
        GiftDetailUrl: "yourorganization.com/giftdetail.html#0",
        GivingHistoryUrl: "yourorganization.com/profile.html"
    };

const scheduledPaymentReminderNotificationData =
    {
        ShortDescription: "Your short designation description",
        LongDescription: "Your long designation description",
        EditGiftUrl: "yourorganization.com/editgift.html",
        PaymentDateDisplay: "June 1, 2015",
        GiftId: 0,
        IsPledge: false,
        FirstName: "Lou",
        LastName: "Sullivan",
        ToName: "Lou Sullivan",
        FromEmailAddress: "support@vision2systems.com",
        FromName: "Kevin",
        EventCalendarId: 0,
        WebsiteId: 0,
        HasSchedule: false,
        Designation:
        {
            Name: "YourOrganization",
            Amount: "$100.23",
            Schedule:
            {
                Id: 0,
                IsScheduled: false,
                IsComplete: false,
                Frequency: "Monthly",
                StartDate: "\/Date(1398860760519)\/",
                EndDateDisplay: "",
                WeekDay: "Sunday",
                NumberOfPayments: 0,
                DayOfMonth: 0,
                Display: " monthly starting on Wednesday, April 30, 2014 until Wednesday, April 30, 2014"
            }
        },
        AnnualTotal: "$1200.00",
        GiftDetailUrl: "http://yourorganization.com/giftdetail.html#0",
        GivingHistoryUrl: "http://yourorganization.com/profile.html"
    };

const recurringGiftPaymentFailureNotificaitonData = {
        ShortDescription: "Your short designation description",
        LongDescription: "Your long designation description",
        EditGiftUrl: "yourorganization.com/editgift.html",
        GiftId: 0,
        IsPledge: false,
        FirstName: "Lou",
        LastName: "Sullivan",
        ToName: "Lou Sullivan",
        FromEmailAddress: "support@vision2systems.com",
        FromName: "Kevin",
        EventCalendarId: 0,
        WebsiteId: 0,
        HasSchedule: false,
        Designation: {
            Name: "YourOrganization",
            Amount: "$100.23",
            Schedule: {
                Id: 0,
                IsScheduled: false,
                IsComplete: false,
                Frequency: "Monthly",
                StartDate: "\/Date(1398860760523)\/",
                EndDateDisplay: "",
                WeekDay: "Sunday",
                NumberOfPayments: 0,
                DayOfMonth: 0,
                Display: " monthly starting on Wednesday, April 30, 2014 until Wednesday, April 30, 2014"
            }
        },
        AnnualTotal: "$1200.00",
        GiftDetailUrl: "http://yourorganization.com/giftdetail.html#0",
        GivingHistoryUrl: "http://yourorganization.com/profile.html"
    };

const multiDesignationPaymentFailureNotification = {
        FirstName: "Lou",
        LastName: "Sullivan",
        ToName: "Lou Sullivan",
        FromEmailAddress: "support@vision2systems.com",
        FromName: "Kevin",
        WebsiteId: 0,
        LineItems: [
            {
                Amount: "$100.25",
                Name: "YourOrganization"
            },
            {
                Amount: "$50.32",
                Name: "YourOrganizationCampaign"
            }
        ],
        GiftDetailUrls: ["yourorganization.com/giftdetail.html?v2gId=1", "yourorganization.com/giftdetail.html?v2gId=2"],
        GivingHistoryUrl: "yourorganization.com/profile.html",
        EditPaymentMethodUrl: "yourorganization.com/editpaymentmethod.html"
    };

const expiredCreditCardNotificaitonData = {
        Name: "Visa ending in 0123",
        CreditCardTypeName: "VISA",
        ExpirationDate: "December 31, 2019",
        IsExpired: false,
        PaymentMethodEditUrl: "yourorganization.com/editpaymentmethod.html",
        ProfileEditUrl: "yourorganization.com/profile.html",
        FirstName: "Lou",
        LastName: "Sullivan",
        FromEmailAddress: "support@vision2systems.com",
        FromName: "Kevin",
        ToName: "Lou Sullivan"

    };

const individualFundraisingAppealNotificationData = {
        PackageName: "My Fundraiser",
        DesignationName: "Help Our Organization",
        FirstName: "Lou",
        LastName: "Sullivan",
        FromEmailAddress: "support@vision2systems.com",
        FromName: "Kevin",
        ToName: "Lou Sullivan",
        DesignationUrl: "http://myorg.org/designation",
        PackageUrl: "http://myorg.org/support",
        Goal: "$1000",
        GoalDate: "March 12, 2017",
        TotalToDate: "$500",
        Statement: "This is important to me because"

    };


const volunteerFundraiserNotificationData = {
        PackageName: "My Fundraiser",
        DesignationName: "Help Our Organization",
        FirstName: "Lou",
        LastName: "Sullivan",
        FromEmailAddress: "support@vision2systems.com",
        FromName: "Kevin",
        ToName: "Lou Sullivan",
        DesignationUrl: "http://myorg.org/designation",
        PackageUrl: "http://myorg.org/support",
        VolunteerFundraiserAdminUrl: "http://myorg.org/fundraiser",
        Goal: "$1000",
        GoalDate: "March 12, 2017",
        TotalToDate: "$500",
        Statement: "This is important to me because"

    };

const individualStatementNotificationData = {
        FirstName: "John",
        LastName: "Smith",
        ChurchName: "Your Church Name",
        StatementDateRange: "1/1/20XX - 12/31/20XX"
    };

const basicAuthenticationCreditialsVerificationNotificationData = {
        ToEmailAddres: "your.name@gmail.com",
        FromEmailAdress: "fromus@organization.org",
        FromName: "Sue Thompson",
        FirstName: "Mary",
        LastName: "Smith",
        ToName: "Mary Smith",
        Token: "123456",
        ActionUrl: "https://myorg.org/VerifyAccount"
    };

const passwordResetNotificationData = {
        ToEmailAddres: "your.name@gmail.com",
        FromEmailAdress: "fromus@organization.org",
        FromName: "Sue Thompson",
        FirstName: "Mary",
        LastName: "Smith",
        ToName: "Mary Smith",
        Token: "123456",
        ActionUrl: "https://myorg.org/ResetPassword"
    };

const campaignGivingGoalAcknowledgementNotificationData = {
        GoalId: 0,
        IsPledge: false,
        IsPledgePayment: false,
        OrganizationName: "your organization name",
        OrganizationContactName: "organization contact name",
        OrganizationContactEmail: "someone@yourorganization.com",
        OrganizationContactPhone: "organization phone",
        FirstName: "Lou",
        LastName: "Sullivan",
        ToName: "Lou Sullivan",
        FromEmailAddress: "support@vision2systems.com",
        FromName: "Kevin",
        GoalAmount: "$1000",
        WebsiteId: 0,
        CampaignName: "Your Campaign",
        CampaignDescription: "Description goes here",
        PaymentHistoryUrl: "http://yourorganization.com/giftdetail.html#0",
        GoalEditUrl: "http://yourorganization.com/profile.html"
    };

const individualGiftAppealNotificationData = {

        FirstName: "Lou",
        LastName: "Sullivan",
        FromEmailAddress: "giving@myorg.org",
        FromName: "Kevin",
        ToName: "Lou Sullivan",
        GiftUrl: "http://myorg.org/give?v2amt=100&v2firstname=Lou&v2lastName=Sullivan",
        Amount: "$20",
        ScheduleString: "$20 Monthly starting March 1st, 2021"

    };


const contextFromMarketingTemplateType = [
        '',
        'ConnectLinkRequestAcknowledgementNotificationData',
        'IndividualGiftAcknowledgementNotificationData',
        'MultiDesignationGiftAcknowledgementNotificationData',
        'IndividualGiftAcknowledgementNotificationData',
        'IndividualFundraisingAppealNotificationData',
        '', //6
        'MultiDesignationPaymentFailureNotification',
        'RecurringGiftPaymanetFailureNotificationData',
        'ExpiredCreditCardNotificationData', //9
        'RecurringGiftExpirationNotificationData',
        'CommitmentPaymentReminderNotificationData',
        'LastIndividualGiftNotificationData',
        'FirstGiftNotificationData',
        'VolunteerFundraiserNotificationData',
        'CampaignGivingGoalAcknowledgementNotificationData',
        '', //16
        'RecurringPaymentNotificationData',
        'IndividualStatementNotificationData', //18
        'IndividualFundraisingAppealNotificationData', //Recurring Gift Migration
        'IndividualGiftAppealNotificationData',//Individual Gift Appeal
        'EmailSearchResultsNotificationData', //Email Search Results
        'EmailDuplicateResultsNotificationData', //Email Duplicate Results
        'EmailNightlyTransactionsNotificationData', //Email Nightly Transaction Results
        'ChurchServiceGiftAcknowledgementNotificationData', //church service gift acknowledgement
        'MultiDesignationGiftAcknowledgementNotificationData' //general source gift acknowledgement

        
    ];

module.exports = {
    IndividualGiftAcknowledgementNotificationData: individualGiftAcknowledgementData,
    FirstGiftNotificationData: firstGiftNotificationData,
    LastIndividualGiftNotificationData: lastGiftNotificationData,
    MultiDesignationGiftAcknowledgementNotificationData: multiDesignationGiftAcknowledgementData,
    RecurringGiftExpirationNotificationData: recurringGiftExpirationNotificationData,
    CommitmentPaymentReminderNotificationData: scheduledPaymentReminderNotificationData,
    RecurringGiftPaymanetFailureNotificationData: recurringGiftPaymentFailureNotificaitonData,
    MultiDesignationPaymentFailureNotification: multiDesignationPaymentFailureNotification,
    ExpiredCreditCardNotificationData: expiredCreditCardNotificaitonData,
    ConnectLinkRequestAcknowledgementNotificationData: connectionAcknowledgement,
    AccountSelfRegistrationVerificationNotificationData: selfRegister,
    IndividualFundraisingAppealNotificationData: individualFundraisingAppealNotificationData,
    VolunteerFundraiserNotificationData: volunteerFundraiserNotificationData,
    BasicAuthenticationCreditialsVerificationNotificationData: basicAuthenticationCreditialsVerificationNotificationData,
    PasswordResetNotificationData: passwordResetNotificationData,
    CampaignGivingGoalAcknowledgementNotificationData: campaignGivingGoalAcknowledgementNotificationData,
    ContextFromType: contextFromMarketingTemplateType,
    RecurringPaymentNotificationData: recurringPaymentAcknowledgementData,
    IndividualStatementNotificationData: individualStatementNotificationData,
    IndividualGiftAppealNotificationData: individualGiftAppealNotificationData,
    ChurchServiceGiftAcknowledgementNotificationData: churchServiceGiftAcknowledgementData
}
