
/* Copyright@2012 Vision2Systems */
/* All rights reserved */

var regex = /(\b)(on\S+)(\s*)=|javascript|(<\s*)(\/*)script/ig;

function growl(message, title, opts) {
    if (!opts)
        opts = {};
    
    var cleaned = message.replace(regex);
     

   return import("sweetalert2").then(mod=> {  
        var swal = mod.default;
       return new Promise(function (resolve, reject) {
           swal.fire({
               title: title,
               target: '#contentbody',
               type: opts.mode || 'info',
               toast: true,
               timer: opts.timeOut || 10000,
               text: cleaned,
               cancelButtonClass: 'btn btn-info',
               buttonsStyling: false,
               position: 'top-right'
           }).then(resolve).catch(reject);
       });
    });
}

    

function topBar(message, time,type,title) {
    var cleaned = message.replace(regex);

   return import("sweetalert2").then(mod => {
        swal = mod.default;
        return new Promise(function (resolve, reject) {
            swal.fire({
                title: title,
                html: cleaned,
                timer: time,
                type: type,
                position: top,

                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                customClass: 'notifyalert',
                buttonsStyling: false
            }).then(resolve).catch(reject);
        });
    });
}

function alert(message, title, type, showCancelButton) {
    var cleaned = message.replace(regex);
    return import("sweetalert2").then(mod => {
        var swal = mod.default;
        return new Promise(function (resolve, reject) {
            swal.fire({
                title: title || "Um...",
                html: cleaned,
                type: type || "warning",
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                buttonsStyling: false,
                customClass: 'notifyalert',
                showCancelButton: showCancelButton
            }).then(resolve).catch(reject);
        });

    });
}

   

function confirm(messageOrOpts, okCallback, allowClose, closeCallback) {
    return import("sweetalert2").then(mod => {
        var swal = mod.default;
        //var bootswal = Swal.mixin({
        //    confirmButtonClass: 'btn btn-success',
        //    cancelButtonClass: 'btn btn-danger',
        //    buttonsStyling: false,
        //});

        var opts = (typeof messageOrOpts === 'string') ? { type: 'question', html: messageOrOpts } : mesageOrOpts;
        opts.confirmButtonClass = 'btn btn-success';
        opts.cancelButtonClass = 'btn btn-danger';
        opts.showCancelButton = true;
        opts.buttonsStyling = false;
        opts.customClass = 'notifyalert';
        //console.log('opts', opts);

        //old method signature
        if(okCallback  || closeCallback )
        {
            console.warn("calling deprecated notify.confirm dialogue, confirm returns a promise now", swal);
            return swal.fire(opts).then((result)=>{
                if (result.value) {
                    if (okCallback && typeof okCallback === "function") {
                        console.warn("in depricated okCallback");
                        okCallback(result.value);
                    }
                }
                else{
                    if (closeCallback && typeof closeCallback === "function") {
                        console.warn("in depricated closeCallback");
                        closeCallback();
                    }
                }
            });
        }
        else {
            console.warn("calling new notify promise");
            return new Promise(function (resolve, reject) {
                console.warn("in new slallow notify promise");

                swal.fire(opts).then(resolve).catch(reject);
            });
            
            //var x = swal.fire(opts);
            //console.log('x', x);
            //return x;
            //return swal.fire(opts);
        }

    });

       
}

   


module.exports = {
        alert: alert,
        confirm: confirm,
        growl: growl,
        top: topBar
       
} 