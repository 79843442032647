var template = require('./catalogitem-search.html');




function viewModel(params) {

    var self = params.Items;

    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};

