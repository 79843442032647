

function getSession(key) {
    var value = sessionStorage.getItem('permissions.' + key);
    return value;
}

function saveSession(key, value) {
    sessionStorage.setItem('permissions.' + key, value);
}

async function  hasPermission(permissionName) {

    var permission = getSession(permissionName);
    
    if (permission) {
        return permission;
    }

    permission = await new Promise((resolve, reject) => {
                
                import("../v2/formUtilities.js").then((v2forms) => {

                    v2forms.GET({
                        url: '/User/Permissions/Has?name=' + permissionName,
                        before: function () { },
                        success: function (data, textStatus, jqXhr) {
                            if (data.Data == null)
                                return resolve(false);

                            if (data.Data == true)
                                return resolve(true);
                            else
                                return resolve(false);
                        },
                        error: function (error, textStatus, jqXhr) {
                            console.error("user permisisons received an error requesting permisison " + permissionName, error);
                            return resolve(false);
                        },
                        complete: function () { }

                    });
                });
        });

    saveSession(permissionName, permission);

    return permission;

    
}


module.exports = {
    HasPermission: hasPermission
  
}