/* File Created: July 28, 2012 */

/* File Created: July 24, 2012 */
/* Copyright 2012 Vision2Systems LLC All rights reserved */
(function () {
    //var SymbolSearchResults = ko.observableArray();
    //var SymbolName = ko.observable();
    //var SymbolSymbol = ko.observable();
    //var SymbolId = ko.observable();

    //SymbolSearchResults.search = function (options) {
    //    if (options.text == "") return;

    //    $.ajax({
    //        url: '/Giving/Gift/SymbolSearch',
    //        type: 'POST',
    //        contentType: 'application/json',
    //        dataType: 'json',
    //        data: JSON.stringify({ searchTerm: options.text }),
    //        success: function (data, textStatus, jqXhr) {
    //            var results = new Array();
    //            for (var i = 0, l = data.length; i < l; i++) {
    //                var temp = new selectableSymbol(data[i]);
    //                results[i] = temp;
    //            }
    //            SymbolSearchResults(results);
    //            options.callback({ data: data });
    //        },
    //        error: function (data, textStatus, jqXhr) {
    //            console.log(data);
    //        }
    //    });
    //};

    //var SymbolItem = ko.observable(function () {
    //    var assignTo = SymbolSearchResults().filter(function (item) {
    //        console.log(item)
    //        return (item.Id() == SymbolId());
    //    });
    //    assignTo = assignTo ? assignTo[0] : null;
    //    console.log(assignTo)
    //    return assignTo;
    //}());

    //SymbolItem.subscribe(function (item) {
    //    console.log(item)
    //    item = item || SymbolSearchResults().filter(function (_item) { return (_item.Id() == item.Id()); })[0];
    //    SymbolId(item.Id);
    //});

    //SymbolId.subscribe(function (id) {
    //    console.log(id)
    //    if (id) {
    //        var assignTo = SymbolSearchResults().filter(function (item) {
    //            console.log(item)
    //            return (item.Id() == id);
    //        })[0];
    //        console.log('this is assign to');
    //        console.log(ko.toJS(assignTo));
    //        SymbolName(ko.toJS(assignTo.Name));
    //        SymbolSymbol(ko.toJS(assignTo.Name));

    //        //viewModel.BatchTemplateDescription(ko.toJS(assignTo.Description));
    //    }
    //});

    function StockModel(f) {
        var self = this;
        if (f) {
            this.Id = ko.observable(f.Id);
            this.RowStatus = ko.observable(0);
            this.StockSymbol = ko.observable(f.StockSymbol);
            this.PricePerShare = ko.observable(f.PricePerShare);
            this.Shares = ko.observable(f.Shares);
            this.AssetTransferSource = ko.observable(f.AssetTransferSource);
        } else {
            this.Id = ko.observable(0);
            this.RowStatus = ko.observable(0);
            this.StockSymbol = ko.observable();
            this.PricePerShare = ko.observable();
            this.Shares = ko.observable(0);
            this.AssetTransferSource = ko.observable();
        }

        this.TotalValue = ko.computed(function() { return self.Shares() * self.PricePerShare(); });
        this.PotentialSymbols = ko.observableArray();
        this.StockName = ko.computed(function() {
            var name = "";
            ko.utils.arrayForEach(self.PotentialSymbols(), function(item) {
                if (item.Symbol() == self.StockSymbol()) {
                    name = item.Name();
                    return;
                }
            });
            return name;
        });

        return this;
    }

    //stock 
    function selectableSymbol(s) {
        this.IsSelected = ko.observable(false);
        this.Symbol = ko.observable(s.Symbol);
        this.Name = ko.observable(s.Name);
        this.Exchange = ko.observable(s.ExchDisp);
        this.Type = ko.observable(s.TypeDisp);
        this.Display = ko.observable(s.Name + " Exchg:" + s.ExchDisp + " Type:" + s.TypeDisp);
    }

    function subscribeStockPayment(stockPayment) {
        stockPayment.StockName = ko.observable();
        stockPayment.StockSymbol = ko.observable();
        stockPayment.StockExchange = ko.observable();
        stockPayment.StockType = ko.observable();

        //var array = ko.observableArray();
        //ko.utils.arrayForEach(stockPayment.StockDetails, function(item) {
        //    var temp = ko.unwrapObservable(item);
        //    array.push(new StockModel(temp));
        //});

        stockPayment.GetSymbols = function (options) {
            if (options.text == "") return;
            console.log('stock symbol get data');
            $.ajax({
                url: '/Giving/Gift/SymbolSearch',
                type: 'POST',
                contentType: 'application/json',
                dataType: 'json',
                data: JSON.stringify({ searchTerm: options.text }),
                success: function (data, textStatus, jqXhr) {
                    var results = [];
                    console.log('received',data.Data.PageData)
                    for (var x in data.Data.PageData) {
                        var temp = new selectableSymbol(data.Data.PageData[x]);
                        results.push(temp);
                    }
                    data.Data.PageData = results;
                    options.callback(data.Data);
                },
                error: function (data, textStatus, jqXhr) {
                    console.log(data);
                }
            });
        };
        stockPayment.SymbolItem = ko.observable();
        stockPayment.SymbolItem.subscribe(function (item) {
            stockPayment.StockName(item.Name());
            stockPayment.StockSymbol(item.Symbol());
            stockPayment.StockExchange(item.Exchange());
            stockPayment.StockType(item.Type());
        });
        stockPayment.StockDetails.Add = function() { stockPayment.StockDetails.push(new StockModel()); };
        stockPayment.StockDetails.Delete = function() {
            if (this.RowStatus() == 0) {
                this.RowStatus(2);
            } else {
                stockPayment.StockDetails.remove(this);
            }
        };
        
        stockPayment.calculatedAmount = ko.computed(function() {
            var total = 0;
            ko.utils.arrayForEach(stockPayment.StockDetails(), function(item) {
                console.log(item);
                if (item.RowStatus() != 2 && item.PricePerShare() && item.Shares()) {
                    total = total + (item.PricePerShare() * item.Shares());
                }

            });
            return total;
        });
    }

    //function getSymbols(searchTerm, sourceArray) {
    //    $.ajax({
    //        url: '/Giving/Gift/SymbolSearch',
    //        type: 'POST',
    //        contentType: 'application/json',
    //        dataType: 'json',
    //        data: JSON.stringify({ searchTerm: searchTerm }),
    //        success: function (data, textStatus, jqXhr) {
    //            var results = new Array();
    //            for (var x in data) {
    //                var temp = new selectableSymbol(data[x]);
    //                results[x] = temp;
    //            }
    //            sourceArray(results);
    //        }
    //    });
    //}

    window.stockServices = {
        SubscribeStockPayment: subscribeStockPayment
    };
})();