/* File Created: July 26, 2012 */


(function () {
    
    var currencies = [];
    


    var US = {
        name: 'United States',
        pre: '$',
        display: function (value) {

            toks = parseFloat(value || 0).toFixed(2).replace('-', '').split('.');
            var display = '$' + $.map(toks[0].split('').reverse(), function (elm, i) {
                return [(i % 3 == 0 && i > 0 ? ',' : ''), elm];
            }).reverse().join('') + '.' + toks[1];

            return value < 0 ? '(' + display + ')' : display;
        },
        value: function (value) {
            return parseFloat(value.replace(/[^0-9.-]/, ''));
        },
        symbol: 'USD'
    };
    currencies.push(US);

    var UK = {
        name: 'United Kingdom',
        pre: '£',
        display: function (value) {

            toks = parseFloat(value || 0).toFixed(2).replace('-', '').split('.');
            var display = '£' + $.map(toks[0].split('').reverse(), function (elm, i) {
                return [(i % 3 == 0 && i > 0 ? ',' : ''), elm];
            }).reverse().join('') + '.' + toks[1];

            return value < 0 ? '(' + display + ')' : display;
        },
        value: function (value) {
            return parseFloat(value.replace(/[^0-9.-]/, ''));
        },
        symbol: 'GBP'
    };
    currencies.push(UK);

    var Mex = {
        name: 'Mexico',
        pre: '$',
        display: function (value) {

            toks = parseFloat(value || 0).toFixed(2).replace('-', '').split('.');
            var display = '$' + $.map(toks[0].split('').reverse(), function (elm, i) {
                return [(i % 3 == 0 && i > 0 ? ',' : ''), elm];
            }).reverse().join('') + '.' + toks[1];

            return value < 0 ? '(' + display + ')' : display;
        },
        value: function (value) {
            return parseFloat(value.replace(/[^0-9.-]/, ''));
        },
        symbol: 'MXN'
    };
    currencies.push(Mex);

    var Euro = {
        name: 'Euro',
        post: '\u20AC',
        display: function (value) {

            toks = parseFloat(value || 0).toFixed(2).replace('-', '').split('.');
            var display = $.map(toks[0].split('').reverse(), function (elm, i) {
                return [(i % 3 == 0 && i > 0 ? ',' : ''), elm];
            }).reverse().join('') + '.' + toks[1] + ' \u20AC';

            return value < 0 ? '(' + display + ')' : display;
        },
        value: function (value) {
            return parseFloat(value.replace(/[^0-9.-]/, ''));
        },
        symbol: 'EUR'
    };
    currencies.push(Euro);

    function getExchangeRate(sourceSymbol, targetSymbol, exchangeRate) {
        $.ajax({
            url: 'http://www.webservicex.net/CurrencyConvertor.asmx/ConversionRate?FromCurrency=' + sourceSymbol + '&ToCurrency' + targetSymbol,
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({
                ProfileSearchDetail: ko.toJS(profile),
                PageIndex: profile.PageIndex(),
                PageSize: profile.PageSize()
            }),
            success: function (data, textStatus, jqXhr) {
                console.log(data);
                var xmlDoc = $xml($.parseXML(data));
                exchangeRate($xml.find("double"));
            }
        });
    }


    var formatters = [];
    formatters.push(US);
    formatters.push(UK);
    formatters.push(Mex);
    formatters.push(Euro);

    var symbols = [];
    symbols.push(US.symbol);
    symbols.push(UK.symbol);
    symbols.push(Mex.symbol);
    symbols.push(Euro.symbol);

    window.currency = {
        ExchangeRate: getExchangeRate,
        Format: formatters,
        US: US,
        UK: UK,
        MEX: Mex,
        Euro: Euro,
        Symbols: symbols,
        Currencies: currencies
    };

})();