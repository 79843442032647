var template = require('./catalogitem-edit.html');



function newItem() {
    var self = this;
    self.Id = ko.observable();
    self.Name = ko.observable();
    self.CatalogSourceType = ko.oservable();
    self.ItemType = ko.observable();
    self.SourceType = ko.observable();
    self.IsFrequencyAdjustable = ko.observable(false);
    self.Frequency = ko.observable();
    self.CatalogIdentifier = ko.observable();
    self.AvailableStartDate = ko.observable(moment());
    self.AvailableUntilDate = ko.observable();
    self.IsInventoryDefinedByOption = ko.observable(false);
    self.IsSubscriptionRequired = ko.observable(false);
    self.IsInventoryReturnedIfScheduleStops = ko.observable(false);
    self.TargetAmount = ko.observable();
    self.MinimumAmount = ko.observable();
    self.MaximumAmount = ko.observable();
    self.Description = ko.observable();
    self.IsCharitable = ko.observable();
    self.FundableId = ko.observable();
    self.FundableReferenceType = ko.observable();
    self.IsQuidProQuo = ko.observable();
    self.IsFrequencyAdjustable = ko.observable();
    self.QuidProQuoValue = ko.observable();
    self.IsAmountFixed = ko.observable();
    self.MakeAvailableAsAPackage = ko.observable(true);
    self.ItemTypeName = ko.computed(function () {
        return enumerations.CatalogItemType[self.ItemType()];
    });
    self.Photos = ko.observableArray();
    self.IsOptionsRequired = ko.observable(false);
    self.FirstOptionCategory = ko.observable();
    self.FirstOptionCategoryId = ko.observable();
    self.EnableSecondOptionCategory = ko.observable();
    self.SecondOptionCategory = ko.observable();
    self.SecondOptionCategoryId = ko.observable();
    self.FirstOptions = ko.observableArray();
    self.SecondOoptions = ko.obsrvableArray();
}

function itemPhoto() {
    var self = this;
    self.Id = ko.observable();
    self.RowStatus = ko.observable(1);
    self.PhotoId = ko.observable();
    self.IsDefault = ko.observable(false);
}

function catalogOption() {
    var self = this;
    self.Id = ko.observable();
    self.RowStatus = ko.observable(1);
    self.PhotoId = ko.observable();
    self.Sequence = ko.observable();
    self.PhotoType = ko.observable(10);
    self.Name = ko.observable();
    self.Description = ko.observable();
    self.Identifier = ko.observable();
}


function viewModel(params) {

    var self = this;
    if (params.Item != null) {
        self = params.Item;
        console.log(ko.toJS(params.Item), ko.toJS(self));
    } else {
        self = new newItem();
    }

    self.ItemTypeName = ko.computed(function () {
        return enumerations.CatalogItemType[self.ItemType()];
    });

    if (!self.Photos)
        self.Photos = ko.observableArray();

    if (self.FirstOptions == null || self.FirstOptions.push == null) {
        self.IsOptionsRequired(false);
        self.EnableSecondOptionCategory(false);
        self.FirstOptions = ko.observableArray();
    }
        

    if (self.SecondOptions == null || self.FirstOptions.push == null)
        self.SecondOptions = ko.observableArray();

    //if is options required = false then disable secondary option
    //category
    self.IsOptionsRequired.subscribe(function(nv) {
        if (!nv) {
            self.EnableSecondOptionCategory(false);
        }
    });


    self.frequencyTypeCodes = [//todo: figure out how to serialize the enumeration FrequencyTypeCodes.cs
            //{display: 'None', key: 0 },
            { display: 'Yearly', key: 1 },
            { display: 'Monthly', key: 2 },
            { display: 'Weekly', key: 3 },
            { display: 'Biweekly', key: 7 }
    ];

    self.addFirstOption = function (){
        var o = new catalogOption();
        self.FirstOptions.push(o);
        
    }

    
    self.addSecondOption = function () {
        var o = new catalogOption();
        self.SecondOptions.push(o);
    }


    self.removeFirstOption = function(d) {
        if (d.RowStatus() === 1)
            self.FirstOptions.remove(d);
        else
            d.RowStatus(2);
    }

    self.removeSecondOption = function (d) {
        if (d.RowStatus() === 1)
            self.SecondOptions.remove(d);
        else
            d.RowStatus(2);
    }


    self.save = function () {
        var model = ko.toJS(self);
        v2Form.POST({
            url: '/Catalog/Item/Edit',
            type: 'POST',
            contentType: false,
            dataType: 'json',
            data: model,
            success: function (data, textStatus, jqXhr) {
                console.log('save catalog', data);
                if (data.Data != null) {

                 
                }
                

            },
            error: function (d) {

            }
        });

    };
    self.dummy = ko.observable();
    self.DefaultPhotoId = ko.computed({
        read: function () {
            var y = self.dummy();
            var id = 0;
            ko.utils.arrayForEach(self.Photos(), function (i) {
                if (i.IsDefault())
                    id = i.Id();
            });
            return id.toString();
        },
        write: function (value) {
            var y = self.dummy();
            var sel = parseInt(value, 10);
            ko.utils.arrayForEach(self.Photos(), function (i) {
                if (i.Id() == sel) {
                    i.IsDefault(true);
                }
                else
                    i.IsDefault(false);
            });
        },
        owner: self
    });

    if (self.Photos().length == 0)
        self.Photos = ko.observableArray();

    self.addPhoto = function() {
        var photo = new itemPhoto();
        photo.Id(self.Photos().length + 1);
        if (self.Photos().length === 0)
            photo.IsDefault(true);
        self.dummy.notifySubscribers();
        self.Photos.push(photo);
        
    };

  

    self.removePhoto = function(d) {
        if (d.RowStatus() == 1)
            self.Photos.remove(d);
        else
            d.RowStatus(2);
    };

    self.PhotoType = ko.observable(9);
    
    EventCalendarExtender.DatePickerEnable(self, self.AvailableStartDate);
    EventCalendarExtender.DatePickerEnable(self, self.AvailableUntilDate);


    //console.log(self);

    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};

