var template = require('./premium-askladder-result.html');


function viewModel(params) {

    var self = this;
   // console.log('ask ladder premium', params.LineItem, params.Package);
    self.Amounts = params.LineItem.Amounts;
    self.PaymentAmount = params.LineItem.AppliedPaymentAmount;
    self.PackageId = params.LineItem.PackageId;
    self.Amount = ko.observable();
    self.IsPremium = params.LineItem.IsPremium;
    self.OverridePremium = params.LineItem.OverridePremium;
    self.Package = params.LineItem.Package;

    self.QualifiedAmount = ko.computed(function() {
        var amount = null;
        ko.utils.arrayForEach(self.Amounts(), function(i) {
            if (self.PaymentAmount() >= i.Amount()) {
                if (amount == null)
                    amount = i;
                else if (amount.Amount() < i.Amount())
                    amount = i;
            }
        });
        //console.log('new amount', ko.toJS(amount));
        if (self.OverridePremium() == false) {
            if (amount != null) {
                self.IsPremium(amount.IsPremium());
                self.PackageId(amount.PackageId());
                self.Amount(amount);
                if (amount.PackageId() > 0)
                    self.Package(amount.Package);
                else
                    self.Package(null);
            } else {
                self.IsPremium(false);
                self.PackageId(0);
                self.Amount(null);
                self.Package(null);
            }
        }
       
            
        return amount;
    });

    




    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};

