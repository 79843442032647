
var theme = {
    colors: ["#00a4da", "#6fb419", "#b7a4e2", "#fcb72f", "#90e9e9", "#ec6863", "#fff593", "#778ad2", "#f1b0ce", "#c5f281", "#33cccc"],
    lang: {
        thousandsSep: ','
    },
    chart: {
        backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 0 },
            stops: [
                [0, 'rgb(255, 255, 255)'],
                [1, 'rgb(255, 255, 255)']
            ]
        },
        borderWidth: 0,
        borderRadius: 0,
        plotBackgroundColor: null, /* background for bar chart */
        plotShadow: false,
        plotBorderWidth: 0 /* border that surrounds entire bar chart */
    },
    credits: {
        enabled: false
    },
    title: {
        style: {
            color: '#000',
            font: '16px  Verdana, Arial, Helvetica, sans-serif',
            fontWeight: 'bold'
        }
    },
    subtitle: {
        style: {
            color: '#ababab',
            font: '12px Verdana, Arial, Helvetica, sans-serif'
        }
    },
    xAxis: {
        gridLineWidth: 0,
        lineColor: '#999',
        tickColor: '#999',
        labels: {
            rotation: -45,
            align: 'right',
            overflow: 'justify',
            style: {
                color: '#999',
                fontWeight: 'bold',
                fontSize: '13px',
                fontFamily: 'Verdana, sans-serif'
            }
        },
        title: {
            style: {
                color: '#AAA',
                font: 'bold 12px Verdana, Arial, Helvetica, sans-serif'
            }
        }
    },
    yAxis: {
        min: 0,
        alternateGridColor: null,
        minorTickInterval: null,
        gridLineColor: 'rgba(0, 0, 0, .1)',
        lineWidth: 1,
        tickWidth: 0,
        labels: {
            style: {
                color: '#999',
                fontWeight: 'bold'
            }
        },
        title: {
            style: {
                color: '#AAA',
                font: 'bold 12px Verdana, Arial, Helvetica, sans-serif'
            }
        }
    },
    legend: {
        itemStyle: {
            color: '#CCC'
        },
        itemHoverStyle: {
            color: '#FFF'
        },
        itemHiddenStyle: {
            color: '#333'
        }
    },
    labels: {
        style: {
            color: '#CCC'
        }
    },
    tooltip: {
        backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
                [0, 'rgba(255, 255, 255, .95)']
            ]
        },
        borderWidth: 0,
        style: {
            color: '#000'
        }
    },


    plotOptions: {
        column: {
            dataLabels: {
                enabled: true,
                color: '#fff',
                style: {
                    fontSize: '13px',
                    fontFamily: 'Verdana, sans-serif',
                    fontWeight: 'bold',
                    textShadow: '-1px -2px 2px #999, 1px 2px 2px #999,-1px 2px 2px #999, 1px -2px 2px #999;'
                }
            }
        },
        line: {
            dataLabels: {
                color: '#CCC'
            },
            marker: {
                lineColor: '#333'
            }
        },
        spline: {
            marker: {
                lineColor: '#333'
            }
        },
        scatter: {
            marker: {
                lineColor: '#333'
            }
        },
        candlestick: {
            lineColor: 'white'
        },
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            enabled: true,
            size: '50%',
            dataLabels: {
                crop: false,
                align: 'center',
                distance: 35,
                y: -10,
                style: {
                    width: '90px'
                }
            }
        }
    },


    toolbar: {
        itemStyle: {
            color: '#CCC'
        }
    },

    navigation: {
        buttonOptions: {
            backgroundColor: {
                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                    [0.4, '#606060'],
                    [0.6, '#333333']
                ]
            },
            borderColor: '#000000',
            symbolStroke: '#C0C0C0',
            hoverSymbolStroke: '#FFFFFF'
        }
    },

    exporting: {
        buttons: {
            exportButton: {
                symbolFill: '#55BE3B'
            },
            printButton: {
                symbolFill: '#7797BE'
            }
        }
    },

    // scroll charts
    rangeSelector: {
        buttonTheme: {
            fill: {
                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                    [0.4, '#888'],
                    [0.6, '#555']
                ]
            },
            stroke: '#000000',
            style: {
                color: '#CCC',
                fontWeight: 'bold'
            },
            states: {
                hover: {
                    fill: {
                        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                        stops: [
                            [0.4, '#BBB'],
                            [0.6, '#888']
                        ]
                    },
                    stroke: '#000000',
                    style: {
                        color: 'white'
                    }
                },
                select: {
                    fill: {
                        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                        stops: [
                            [0.1, '#000'],
                            [0.3, '#333']
                        ]
                    },
                    stroke: '#000000',
                    style: {
                        color: 'yellow'
                    }
                }
            }
        },
        inputStyle: {
            backgroundColor: '#333',
            color: 'silver'
        },
        labelStyle: {
            color: 'silver'
        }
    },

    navigator: {
        handles: {
            backgroundColor: '#666',
            borderColor: '#AAA'
        },
        outlineColor: '#CCC',
        maskFill: 'rgba(16, 16, 16, 0.5)',
        series: {
            color: '#7798BF',
            lineColor: '#A6C7ED'
        }
    },

    scrollbar: {
        barBackgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
                [0.4, '#888'],
                [0.6, '#555']
            ]
        },
        barBorderColor: '#CCC',
        buttonArrowColor: '#CCC',
        buttonBackgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
                [0.4, '#888'],
                [0.6, '#555']
            ]
        },
        buttonBorderColor: '#CCC',
        rifleColor: '#FFF',
        trackBackgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
                [0, '#000'],
                [1, '#333']
            ]
        },
        trackBorderColor: '#666'
    },

    // special colors for some of the demo examples
    legendBackgroundColor: 'rgba(48, 48, 48, 0.8)',
    legendBackgroundColorSolid: 'rgb(70, 70, 70)',
    dataLabelsColor: '#444',
    textColor: '#E0E0E0',
    maskColor: 'rgba(255,255,255,0.3)'
};

export default theme;