define([],function() {

    
    
   


    function clearModel(self) {

        self.Individual = new basicProfileServices.BasicProfileModel();
        basicProfileServices.SubscribeProfileChanges(self.Individual);
    }



    function subscribeVolunteer(e,v) {
        

        v.updateStatus = function (model) {
            var data = ko.mapping.toJS(model);
            //prep data to match volunteer participant detail proejction
            data.Individual = null;
            data.Opportunity = {
                Id: data.VolunteerOpportunityId

            };
            v2Form['POST']({
                panel: '#MarketingTemplatePanel',
                block: true,
                url: '/Volunteer/Participant/SaveStatusChanges',
                type: 'POST',
                data: data,
                element: $(e),
                success: statusChangeSuccess,
                error: statusChangeError,
                clientModel: data
            });

        };

        
    }

    function statusChangeSuccess(data, textStatus, jqXhr, opts) {
        var retData = data.Data,
            message = data.Message,
            error = data.Error,
            redirect = data.Redirect,
            modelState = data.ModelStateDictionary;

        var panel = {};
        if (opts.panel)
            panel = $(opts.panel);
        else
            panel = $('#FormNotificationPanel');

        if (modelState) {
            var messages = "Issues: <br />";
            for (key in modelState)
                if (modelState[key].Errors)
                    for (error in modelState[key].Errors)
                        messages += modelState[key].Errors[error].ErrorMessage + '<br />';

            panel.html(messages).slideDown('fast');
            notify.alert(messages);
        }
    }

    function statusChangeError(data, textStatus, jqXhr, opts) {
        var panel = {};

        if (opts.panel)
            panel = $(opts.panel);
        else
            panel = $('#FormNotificationPanel');

        var temp = jqXhr;
        var error = errorThrown;
        if (jqXhr.status == 500) {
            errorThrown = 'Oops, we messed up, tell Kevin about it.';
        }

        if (jqXhr.status == 404) {
            errorThrown = 'Not found, Kevin may have moved tell Kevin about it';
        }

        if (jqXhr.status == 403) {
            notify.top("You are trying to access things you aren't supposed to be, you are going on the Vision2 naughty list");
        }
        //todo: deal with validators
        notify.growl(errorThrown, "Error", { mode: 'error', timeout: 10000 });
        panel.html(errorThrown).slideDown('fast');
    }

    return {
        SubscribeVolunteer: subscribeVolunteer
      
    };
});