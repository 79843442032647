var template = require('./picklist-display.html');



function viewModel(params) {
    //console.log('picklist params', ko.toJS(params));
    var self = {
        Id: ko.observable(ko.utils.unwrapObservable(params.Id)),
        PickList: {
            Name: ko.observable()
        },
        Display: ko.observable(false)

};

    if (self.Id() && self.Id() > 0) {
        v2Form.POST({
            url: '/Catalog/Picklist/GetDisplay',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: { id: self.Id() },
            success: function (data, textStatus, jqXhr) {
                console.log(data.Data);
                if (data.Data) {
                    self.PickList.Name(data.Data.Name);
                }
                self.Display(true);
            },
            error: function (data, textStatus, jqXhr) {
                console.log('error',data);
                
            }
        });
    }
    
    return self;

}


module.exports = {
    viewModel: viewModel,
    template: template

};
