var template = require('./individualprofile-display.html');


function profile() {
    this.Id = ko.observable(0);
    this.PrimaryName = new name();
    this.Names = ko.observableArray();
    this.Gender = ko.observable();
    this.IsDeceased = ko.observable(false);
    this.BirthDate = ko.observable();
    this.Addresses = ko.observableArray();
    this.PrimaryPhone = new phone();
    this.PhoneNumbers = ko.observableArray();
    this.PrimaryEmail = new email();
    this.EmailAddresses = ko.observable();
    this.SiteId = ko.observable();
    this.Envelope = ko.observable();
    //TODO Reincorporate after geolocation fix
    //this.GeoLocation = new geoPin();
    this.UserAccountId = ko.observable();
    this.Display = new display();
    
   
}

function display() {
    this.Name = ko.observable();
    this.Phone = ko.observable();
    this.EmailAddress = ko.observable();
    this.Address = ko.observable();
}

function name() {
    this.FirstName = ko.observable();
    this.LastName = ko.observable();
    this.Suffix = ko.observable();
    this.IsPrimary = ko.observable(false);
    
}

function phone() {
    this.PhoneNumber = ko.observable();
    this.PhoneType = ko.observable();
    this.IsSMSCapable = ko.observable();
    this.IsPrimary = ko.observable(false);
}

function email() {
    this.EmailAddress = ko.observable();
    this.IsPrimary = ko.observable(false);
    
}

function address() {
    this.Address1 = ko.observable();
    this.Address2 = ko.observable();
    this.City = ko.observable();
    this.Region = ko.observable();
    this.PostalCode = ko.observable();
    this.Country = ko.observable();
    this.CountryType = ko.observable();
    //TODO Reincorporate after geolocation fix
    //this.GeoLocation = new geoPin();
}

//TODO Reincorporate after geolocation fix
//function geoPin() {
//    this.Lat = ko.observable();
//    this.Long = ko.observable();
//}




function viewModel(params) {
    var self = this;
        
        if (params.Individual) {
            self.Individual = params.Individual;
            self.loaded = ko.observable(true);
        }
        else {
            self.Individual = new profile();
            self.loaded = ko.observable(false);
            getDisplay(params);
        }

    

        function getDisplay(p) {
            var data = {
                id: ko.utils.unwrapObservable(p.Id),
                userAccountId: ko.utils.unwrapObservable(p.UserAccountId)
            };

            if (!data.id && (!data.userAccountId || data.userAccountId == 0))
                return;

            v2Form.POST({
                url: '/People/Individual/GetProfileForDisplay',
                type: 'POST',
                contentType: 'application/json',
                dataType: 'json',
                data: data,
                success: function (data, textStatus, jqXhr) {
                    //console.log(data.Data);
                    ko.mapping.fromJS(data.Data, {}, self.Individual);
                    self.loaded(true);
                //    console.log(ko.toJS(self),params);
                }

            });
        }

    return self;


}


module.exports = {
    viewModel: viewModel,
    template: template

};