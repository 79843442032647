var template = require('./pagination.html');

function viewModel(params) {

    var self = this;
    //console.log(params);
    if (params.vm) {

        self = params.vm;

    }
    else {

        if (!params || !params.PageData)
            console.error('paginationcomponent: page data not passed to pagination, check params', ko.toJS(params));

        if (!params.PageData.PageIndex)
            console.error('paginationcomponent: page data does not have a page index, check params', ko.toJS(params.PageData));

        self = params.PageData;
    }

    
    return self;
}




module.exports = {
    viewModel: viewModel,
    template: template

};
