/* File Created: December 7, 2012 */
/* Copyright 2012 Vision2Systems LLC All rights reserved */


    var throttle = 500;

function findProfileMatches(profile) {
    if (profile.searchPending)
        return;

    profile.searchPending = setTimeout(function () {
        doFindProfileMatches(profile);
        clearTimeout(profile.searchPending);
        profile.searchPending = null;
    }, 400);
}




function doFindProfileMatches(profile) {
        
        if (profile.Id() == 0) {
            var model = JSON.stringify({
                ProfileSearchDetail: ko.toJS(profile),
                PageIndex: profile.PageIndex(),
                PageSize: profile.PageSize()
            });
            
            $.ajax({
                url: '/Organization/Profile/SearchProfile',
                type: 'POST',
                contentType: 'application/json',
                dataType: 'json',
                data: model,
                success: function (data, textStatus, jqXhr) {
                    //console.log('profile match results', data.Data);
                    
                    profile.ProfileMatches.removeAll();
                    ko.utils.arrayForEach(data.Data.PageData, function (i) {
                        profile.ProfileMatches.push(i);
                    });

                    $('[data-spy="affix"]').each(function () {
                        $(this).affix('refresh');
                    });
                    
                }
            });
        }
    }

    function findDesignationMatches(profile, vm, resultsArray) {
        var model = ko.toJS(profile);
        var GiftSearchArguments = new PaginationServices.GiftSearchModel(profile.Id(), 0, 5);
        GiftSearchArguments.GiftSearchType(4); //organization profile
        GiftSearchArguments.Analytics(3); //designated
        var fundableIds = [];

        if (profile.Id() > 0) {
            $.ajax({
                url: '/Giving/Gift/SearchCommonFundablesForProfile',
                type: 'POST',
                contentType: 'application/json',
                dataType: 'json',
                data: ko.toJSON(GiftSearchArguments),
                success: function (data, textStatus, jqXhr) {
                    if (data.Data) { data = data.Data; }
                    profile.DesignationMatches.removeAll();
                    profile.DesignationMatches(data.PageData);
                    profile.PageIndex(data.PageIndex);
                    profile.PageSize(data.PageSize);
                    $('[data-spy="affix"]').each(function () {
                        $(this).affix('refresh');
                    });
                    findRecentPayments(profile, vm);
                }
            });
        }
    }

    function findRecentPayments(profile, resultsArray) {
        if (profile.Id() > 0) {
            $.ajax({
                url: '/Giving/BatchItems/SearchRecentPaymentsForOrganizationProfile/' + profile.Id(),
                type: 'POST',
                success: function (data, textStatus, jqXhr) {
                    if (data.Data) { data = data.Data; }
                    profile.RecentPayments.removeAll();
                    profile.RecentPayments(data.PageData);
                }
            });
        }
    }

    function findCharitableProfileMatches(profile, resultsArray) {
        if (profile.Id() == 0) {
            $.ajax({
                url: '/Organization/Profile/SearchCharitableProfile',
                type: 'POST',
                contentType: 'application/json',
                dataType: 'json',
                data: JSON.stringify({
                    ProfileSearchDetail: ko.toJS(profile),
                    PageIndex: profile.PageIndex(),
                    PageSize: profile.PageSize()
                }),
                success: function (data, textStatus, jqXhr) {
                    if (data.Redirect) {
                        window.location = data.Redirect + "?returnUrl=" + encodeURIComponent(window.location.pathname);
                    }
                    profile.ProfileMatches(data.Data.PageData);
                    $('[data-spy="affix"]').each(function () {
                        $(this).affix('refresh');
                    });
                }
            });
        }
    }


    function findNonCharitableProfileMatches(profile, resultsArray) {
        if (profile.Id() == 0) {
            $.ajax({
                url: '/Organization/Profile/SearchNonCharitableProfile',
                type: 'POST',
                contentType: 'application/json',
                dataType: 'json',
                data: JSON.stringify({
                    ProfileSearchDetail: ko.toJS(profile),
                    PageIndex: profile.PageIndex(),
                    PageSize: profile.PageSize()
                }),
                success: function (data, textStatus, jqXhr) {
                    if (data.Redirect) {
                        window.location = data.Redirect + "?returnUrl=" + encodeURIComponent(window.location.pathname);
                    }

                    ko.utils.arrayForEach(data.Data.PageData, function (i) {
                        profile.ProfileMatches.push(i);
                    });

                    //profile.ProfileMatches(data.Data.PageData);
                    $('[data-spy="affix"]').each(function () {
                        $(this).affix('refresh');
                    });
                 
                }
            });
        }
    }

    function findIrsMatches(profile, resultsArray) {
        if (profile.Id() == 0) {
            $.ajax({
                url: '/Organization/Agency/SearchIrs',
                type: 'POST',
                contentType: 'application/json',
                dataType: 'json',
                data: JSON.stringify(ko.toJS(profile)),
                success: function (data, textStatus, jqXhr) {
                   // console.log(data.Data);
                    profile.IrsMatches(data.Data.PageData);
                    $('[data-spy="affix"]').each(function () {
                        $(this).affix('refresh');
                    });
                }
            });
        }
    }
    
    function getSites (options) {
        if (options.text == "") return;

        $.ajax({
            url: '/Organization/Site/Search',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({ searchTerm: options.text, PageIndex: options.page, PageSize: 15 }),
            success: function (data, textStatus, jqXhr) {
               options.callback(data.Data);
            },
            error: function (data, textStatus, jqXhr) {
              //  console.log('error');
             //   console.log(data);
            }
        });
    };

    function getSiteDetail(profile,id) {
        if (options.text == "") return;

        $.ajax({
            url: '/Organization/Site/SiteDetails',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({ searchTerm: options.text, PageIndex: 0, PageSize: 15 }),
            success: function (data, textStatus, jqXhr) {
               options.callback(data.Data);
            },
            error: function (data, textStatus, jqXhr) {
               // console.log('error');
                //console.log(data);
            }
        });
    };

    function subscribeSiteChanges(profile) {
        profile.Site = ko.observable();
        if(!profile.SiteName)
            profile.SiteName = ko.observable();
        if (!profile.SelectedSite)
            profile.SelectedSite = {};
        profile.GetSites = getSites;
        profile.SelectedSite.Address1 = ko.observable();
        profile.SelectedSite.Address2 = ko.observable();
        profile.SelectedSite.City = ko.observable();
        profile.SelectedSite.Region = ko.observable();
        profile.SelectedSite.PostalCode = ko.observable();
        profile.Site.subscribe(function (x) {
            //profile.SelectedSite.SiteName(ko.utils.unwrapObservable(x.SiteName));
            profile.SiteId(ko.utils.unwrapObservable(x.Id));
            profile.SiteName(ko.utils.unwrapObservable(x.SiteName));
            profile.SelectedSite.Address1(ko.utils.unwrapObservable(x.Address1));
            profile.SelectedSite.Address2(ko.utils.unwrapObservable(x.Address2));
            profile.SelectedSite.City(ko.utils.unwrapObservable(x.City));
            profile.SelectedSite.Region(ko.utils.unwrapObservable(x.Region));
            profile.SelectedSite.PostalCode(ko.utils.unwrapObservable(x.PostalCode));
        });
    }

    
    function subscribeSimpleSearch(vm) {
       
        
        vm.SearchCriteria.PageIndex(0);
        vm.SearchCriteria.PageSize(15);
        vm.SearchCriteria.PreviousSearchTerm = ko.observable();
        vm.Id = ko.observable();
        vm.Address = ko.observable();
        vm.Description = ko.observable();
        vm.Name = ko.observable();
        console.log('model', vm);
        vm.EnableSearch = function () {
            vm.SearchCriteria.Id(0);
            vm.SearchCriteria.SearchTerm(vm.SearchCriteira.PreviousSearchTerm);
        };
        vm.ProfileSelected = function (d, e) {
            vm.Name(d.Name);
            vm.Description(d.Description);
            vm.Address(d.Address1 + ' ' + d.City + ', ' + d.Region);
            vm.SearchCriteria.PreviousSearchTerm(vm.SearchCriteria.SearchTerm());
            vm.Id(d.Id);
        };
        vm.doSearch = function () {
            var model = {
                SearchTerm: ko.utils.unwrapObservable(vm.SearchCriteria.SearchTerm),
                OrganizationProfileType: 0,
                PageIndex: ko.utils.unwrapObservable(vm.SearchCriteria.PageIndex),
                PageSize: ko.utils.unwrapObservable(vm.SearchCriteria.PageSize)
            };

                $.ajax({
                url: '/Organization/Profile/Search',
                type: 'POST',
                contentType: 'application/json',
                dataType: 'json',
                data: JSON.stringify(model),
                success: function (data, textStatus, jqXhr) {
                    vm.PageData(data.PageData);
                    vm.SearchCriteria.PageIndex(data.PageIndex);
                    vm.SearchCriteria.PageSize(data.PageSize);
                    $('[data-spy="affix"]').each(function () {
                        $(this).affix('refresh');
                    });
                }
            });
        };

    }

function subscribeProfileChanges(profile, vm) {
        if (!profile.ProfileMatches)
            profile.ProfileMatches = ko.observableArray();
        profile.DesignationMatches = ko.observableArray();
        findDesignationMatches(profile, vm);
        profile.RecentPayments = ko.observableArray();
        profile.PageIndex = ko.observable(0);
        profile.PageSize = ko.observable(15);
        profile.PreviousSearch = ko.toJS(profile);
        profile.Selected = ko.observable(false);
        profile.EnableSearch = function () {
            profile.Name(profile.PreviousSearch.Name);
            profile.Description(profile.PreviousSearch.Description);
            profile.Address1(profile.PreviousSearch.Address1);
            profile.Address2(profile.PreviousSearch.Address2);
            profile.City(profile.PreviousSearch.City);
            profile.Region(profile.PreviousSearch.Region);
            profile.PostalCode(profile.PreviousSearch.PostalCode);
            profile.Country(profile.PreviousSearch.Country);
            profile.Id(0);
            profile.Selected(false);

        };
        profile.ProfileSelected = function (d, e) {
            profile.Id(d.Id);
            findDesignationMatches(profile, vm);
        };
        setupSearch(profile, findProfileMatches, profileIdChange, vm);
        subscribeSiteChanges(profile);
    }

function subscribeNonCharitableProfileChanges(profile, vm) {
        if (!profile.ProfileMatches)
            profile.ProfileMatches = ko.observableArray();
        profile.PageIndex = ko.observable(0);
        profile.PageSize = ko.observable(15);
        profile.PreviousSearch = ko.toJS(profile);
        profile.Selected = ko.observable(false);
        profile.EnableSearch = function () {
            profile.Name(profile.PreviousSearch.Name);
            profile.Description(profile.PreviousSearch.Description);
            profile.Address1(profile.PreviousSearch.Address1);
            profile.Address2(profile.PreviousSearch.Address2);
            profile.City(profile.PreviousSearch.City);
            profile.Region(profile.PreviousSearch.Region);
            profile.PostalCode(profile.PreviousSearch.PostalCode);
            profile.Country(profile.PreviousSearch.Country);
            profile.Id(0);
            profile.Selected(false);

        };
        profile.ProfileSelected = function (d, e) {
            profile.Id(d.Id);
        };
        setupSearch(profile, findNonCharitableProfileMatches, profileIdChange, vm);
        subscribeSiteChanges(profile);

    }

    function subscribeCharitableProfileChanges(profile, vm) {
        if (!profile.ProfileMatches)
            profile.ProfileMatches = ko.observableArray();
        profile.PageIndex = ko.observable(0);
        profile.PageSize = ko.observable(15);
        profile.Selected = ko.observable(false);
        profile.PreviousSearch = ko.toJS(profile);
        profile.EnableSearch = function () {
            profile.Name(profile.PreviousSearch.Name);
            profile.Description(profile.PreviousSearch.Description);
            profile.Address1(profile.PreviousSearch.Address1);
            profile.Address2(profile.PreviousSearch.Address2);
            profile.City(profile.PreviousSearch.City);
            profile.Region(profile.PreviousSearch.Region);
            profile.PostalCode(profile.PreviousSearch.PostalCode);
            profile.Country(profile.PreviousSearch.Country);
            profile.Id(0);
            profile.Selected(false);

        };
        profile.ProfileSelected = function (d, e) {
            profile.Id(d.Id);
        };
        setupSearch(profile, findCharitableProfileMatches, profileIdChange, vm);
        subscribeSiteChanges(profile);

    }

    function subscribeIrsEntityChanges(profile, vm) {
        profile.IrsMatches = ko.observableArray();
        profile.PageIndex = ko.observable(0);
        profile.PageSize = ko.observable(15);
        profile.PreviousSearch = ko.toJS(profile);
        profile.EnableSearch = function () {
            profile.Name(profile.PreviousSearch.Name);
            profile.EIN(profile.PreviousSearch.EIN);
            profile.Address1(profile.PreviousSearch.Address1);
            profile.City(profile.PreviousSearch.City);
            profile.Region(profile.PreviousSearch.Region);
            profile.PostalCode(profile.PreviousSearch.PostalCode);
            profile.Country(profile.PreviousSearch.Country);
            profile.Id(0);

        };
        profile.IrsEntitySelected = function (d, e) {
            profile.Id(d.Id);
            profile.Name(d.Name);
            profile.Address1(d.Address1);
            profile.EIN(d.EIN);
            profile.City(d.City);
            profile.Region(d.Region);
            profile.PostalCode(d.PostalCode);
            profile.Country(d.Country);
            profile.SubsectionType(d.SubsectionType);
            profile.Classifications.removeAll();
            profile.Classifications.push(d.Classifications);
            profile.nteeCode(d.nteeCode);

        };
        var f = findIrsMatches;
        profile.Name.subscribe(function (nv) { properCase(profile.Name); f(profile); });
        profile.EIN.subscribe(function (nv) { f(profile); });
        profile.Address1.subscribe(function (nv) { f(profile); });
        profile.City.subscribe(function (nv) { f(profile); });
        profile.Region.subscribe(function (nv) { f(profile); });
        profile.PostalCode.subscribe(function (nv) { f(profile); });
        profile.SubsectionType.subscribe(function (nv) { f(profile); });
        profile.SearchForMatches = function () {
            f(profile);
        };
       
        
    }
    

    function irsEntity() {
        var self = this;
        self.Id = ko.observable(0);
        self.Name = ko.observable();
        self.EIN = ko.observable();
        self.Address1 = ko.observable();
        self.City = ko.observable();
        self.Region = ko.observable();
        self.PostalCode = ko.observable();
        self.Country = ko.observable();
        self.SubsectionType = ko.observable(3);
        self.Classifications = ko.observableArray();
        self.nteeCode = ko.observableArray();
        self.nteeCategory = ko.observable();
        self.nteeDescription = ko.observable();
        self.nteeParentCode = ko.observable();
        self.OrganizationType = ko.observable(1);
        self.DeductabilityType = ko.observable(1);
        self.ExemptionType = ko.observable(1);
        self.PageIndex = ko.observable(0);
        self.PageSize = ko.observable(15);
    }

function setupSearch(profile, f, c, vm) {
    if (profile.setupSearchComplete) {
        console.log('org profile setup search called twice on same profile');
        return;
    }
        profile.setupSearchComplete = true;

        profile.Id.extend({ rateLimit: { timeout: throttle, method: "notifyWhenChangesStop" } });
        profile.Name.extend({ rateLimit: { timeout: throttle, method: "notifyWhenChangesStop" } });
        profile.Description.extend({ rateLimit: { timeout: throttle, method: "notifyWhenChangesStop" } });
        profile.Address1.extend({ rateLimit: { timeout: throttle, method: "notifyWhenChangesStop" } });
        profile.Address2.extend({ rateLimit: { timeout: throttle, method: "notifyWhenChangesStop" } });
        profile.City.extend({ rateLimit: { timeout: throttle, method: "notifyWhenChangesStop" } });
        profile.Region.extend({ rateLimit: { timeout: throttle, method: "notifyWhenChangesStop" } });
        profile.PostalCode.extend({ rateLimit: { timeout: throttle, method: "notifyWhenChangesStop" } });
        



        profile.Id.subscribe(function (nv) { c(nv, profile, vm); });
        profile.Name.subscribe(function (nv) { console.log('name change'); properCase(profile.Name); f(profile); });
        profile.Description.subscribe(function (nv) { properCase(profile.Description); f(profile); });
        profile.Address1.subscribe(function (nv) { f(profile); });
        profile.Address2.subscribe(function (nv) { f(profile); });
        profile.City.subscribe(function (nv) { f(profile); });
        profile.Region.subscribe(function (nv) { f(profile); });
        profile.PostalCode.subscribe(function (nv) { f(profile); });
        profile.SearchForMatches = function () {
            f(profile);
        };
    }
    
function subscribeGiftProfileChanges(profile, vm) {
        if (!profile.ProfileMatches)
            profile.ProfileMatches = ko.observableArray();
        profile.DesignationMatches = ko.observableArray();
        findDesignationMatches(profile, vm);
        profile.RecentPayments = ko.observableArray();
        profile.OutstandingCommitments = ko.observableArray();
        profile.PageIndex = ko.observable(0);
        profile.PageSize = ko.observable(15);
        profile.PreviousSearch = ko.toJS(profile);
        profile.Selected = ko.observable(false);
        profile.EnableSearch = function () {
            profile.Name(profile.PreviousSearch.Name);
            profile.Description(profile.PreviousSearch.Description);
            profile.OrganizationProfileType(profile.PreviousSearch.organizationProfileType);
            profile.Address1(profile.PreviousSearch.Address1);
            profile.Address2(profile.PreviousSearch.Address2);
            profile.City(profile.PreviousSearch.City);
            profile.Region(profile.PreviousSearch.Region);
            profile.PostalCode(profile.PreviousSearch.PostalCode);
            profile.Country(profile.PreviousSearch.Country);
            profile.Id(0);
            profile.Selected(false);

        };
        profile.ProfileSelected = function (d, e) {
            profile.Id(d.Id);
            findDesignationMatches(profile, vm);
        };
        setupSearch(profile, findProfileMatches, profileIdChangeWithCommitments, vm);
        subscribeSiteChanges(profile);
    }

    function profileIdChange(id, profile) {
        if (id != 0) {
            profile.PreviousSearch = ko.toJS(profile);
            var matches = $.ajax({
                url: '/Organization/Profile/GetProfile',
                type: 'POST',
                contentType: 'application/json',
                dataType: 'json',
                data: JSON.stringify({ Id: id }),
                success: function (data, textStatus, jqXhr) {
                    var result = data.Data;
                    profile.Name(result.Name);
                    profile.Description(result.Description);
                    profile.OrganizationProfileType(result.OrganizationProfileType);
                    profile.Address1(result.Address1);
                    profile.Address2(result.Address2);
                    profile.City(result.City);
                    profile.Region(result.Region);
                    profile.PostalCode(result.PostalCode);
                    profile.Country(result.Country);
                    profile.SiteId(result.SiteId);
                    profile.Selected(true);
                    profile.Website(result.Website);
                    profile.EIN(result.EIN);
                    profile.MasterProfileId(result.MasterProfileId);
                    profile.Vision2OrganizationId(result.Vision2OrganizationId);
                }
                

            });
            
        
        }
    }
    
    function profileIdChangeWithCommitments(id, profile,vm) {
        if (id != 0) {
            profile.PreviousSearch = ko.toJS(profile);
            var matches = $.ajax({
                url: '/Organization/Profile/GetProfileWithCommitments',
                type: 'POST',
                contentType: 'application/json',
                dataType: 'json',
                data: JSON.stringify({ Id: id }),
                success: function (data, textStatus, jqXhr) {
                    var result = data.Data;
                    profile.Name(result.Name);
                    profile.Description(result.Description);
                    profile.OrganizationProfileType(result.OrganizationProfileType);
                    profile.Address1(result.Address1);
                    profile.Address2(result.Address2);
                    profile.City(result.City);
                    profile.Region(result.Region);
                    profile.PostalCode(result.PostalCode);
                    profile.SiteId(result.SiteId);
                    profile.Country(result.Country);
                    profile.MasterProfileId(result.MasterProfileId);
                    profile.Selected(true);
                    if (vm.GiftType()!=6) {
                        for (var i = 0; i < result.OutstandingCommitments.length; i++) {
                            vm.ExistingCommitments.push(new outstandingCommitmentDetail(result.OutstandingCommitments[i]));
                        }
                    }
                }


            });


        }
    }

    function properCase(o) {
        var temp = ko.utils.unwrapObservable(o);
        //console.log(temp);
        if (temp != null && temp === temp.toLowerCase()) {
            temp = temp.substring(0, 1).toUpperCase() + temp.substring(1);
            o(temp);
        }
    }
    
    function outstandingCommitmentDetail(m) {
        var self = this;
        self.CommitmentId = ko.observable(m.CommitmentId);
        self.GiftId = ko.observable(m.GiftId);
        self.GiftDesignationId = ko.observable(m.GiftDesignationId);
        self.FundableId = ko.observable(m.FundableId);
        self.FundableName = ko.observable(m.FundableName);
        self.GiftDate = ko.observable(moment.utc(m.GiftDate).local().format("MM/DD/YYYY"));
        self.OutstandingAmount = ko.observable(m.OutstandingAmount);
        self.HasSchedule = ko.observable(m.HasSchedule);
        self.Void = ko.observable(m.Void);
        self.AppliedPaymentAmount = ko.observable(m.AppliedPaymentAmount);
        self.MakeFinal = ko.observable(m.MakeFinal);
    }
    
    function searchProfileModel() {
        this.Id = ko.observable(0);
        this.SearchTerm = ko.observable();
        this.Name = ko.observable();
        this.Address = ko.observable();
        this.Description = ko.observable();
        this.SearchTitle = ko.observable();
        this.SearchAddButton = ko.observable();
    }

    function organizationProfileModel() {
        this.Id = ko.observable();
        this.Name = ko.observable();
        this.Description = ko.observable();
        this.OrganizationProfileType = ko.observable();
        this.Description = ko.observable();
        this.SiteId = ko.observable();
        this.AddressId = ko.observable().withPausing();
        this.Address1 = ko.observable().withPausing();
        this.Address2 = ko.observable().withPausing();
        this.City = ko.observable().withPausing();
        this.Region = ko.observable().withPausing();
        this.PostalCode = ko.observable().withPausing();
        this.CountryType = ko.observable().withPausing();
        this.Website = ko.observable();
        this.EIN = ko.observable();
        this.ProfileMatches = ko.observableArray();
    }

   
    var temp = {
        SubscribeSimpleSearch: subscribeSimpleSearch,
        SubscribeProfileChanges: subscribeProfileChanges,
        SubscribeGiftProfileChanges: subscribeGiftProfileChanges,
        SubscribeCharitableProfileChanges: subscribeCharitableProfileChanges,
        SubscribeIrsEntityChanges: subscribeIrsEntityChanges,
        SubscribeNonCharitableProfileChanges: subscribeNonCharitableProfileChanges,
        IrsEntity: irsEntity,
        OrganizationProfileModel: organizationProfileModel
    };


    

window.orgProfileServices = temp;

if (typeof window === 'undefined') {
    module.exports = temp;
}
