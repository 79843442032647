/// <reference path="../../Areas/Giving/Views/Shared/giftCommitmentSearchTemplate.cshtml" />
/* File Created: December 7, 2012 */
/* Copyright 2012 Vision2Systems LLC All rights reserved */


var facethandler = require("./facetHandler.js");


//highcharts.setOptions({
//        lang: {
//            thousandsSep: ','
//        }
//    });


function assignZeroStats(m) {
        
    m.Count(0);
    m.Mean(currency.US.display(0));
    m.Min(currency.US.display(0));
    m.Max(currency.US.display(0));
    m.Total(currency.US.display(0));
}


function updateViewModel(d) {
//get facet data for all facets
//console.log('aggregations', ko.toJS(d.Aggregations()));
    for (var i = 0; i < d.Aggregations().length; i++) {
            
        var facet = d.Aggregations()[i];
        //console.log('aggregation ' + i, ko.toJS(facet));
        if (facet && facet.Name) {
            var facetName = ko.utils.unwrapObservable(facet.Name);

            if (!facethandler.handler[facetName]) {
                var h = facethandler.handler[facetName];
                if (h instanceof Function) {
                    facethandler.handler[facetName](d, ko.toJS(facet));
                }
                else {
                    console.log('facethandler missing?', h, facetName);
                }

            }
                //console.log(facetName + ' is not defined in facetHandler.js');
                
                
        }
    }
}

    var gifts = {};
    gifts.InitializeViewModel = function(v,a,s,se) {
        v.GivingSummary = {
            Count: ko.observable(),
            Mean: ko.observable(),
            Min: ko.observable(),
            Max: ko.observable(),
            Total: ko.observable()
        };

       
        v.GivingYTD = {
            Categories: ['YTD', 'Trailing 12 Months', 'Previous Year'],
            Data: []
        };

        v.GenderGiving = {
            Data: []
        };

        v.AgeGiving = {
            Categories: ['70+', '60-69', '50-59', '40-49', '30-39', '20-29', '<20'],
            TotalData: [],
            AverageData: []
        };

        v.Top10PostalCodeGiving = {
            Categories: [],
            TotalData: [],
            AverageData:[]
        };
        

        v.ChannelGiving = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };
        
        v.SourceCodeGiving = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };

        v.FundableGiving = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };
        
        v.FundableTypeGiving = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };

        v.LastYearGivingInMonths = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };

      

        v.ClearAll = function () {
            assignZeroStats(v.GivingSummary);
        };

        //analytics property - when it changes run firstPage on the page model
        a.subscribe(function (nv) {
            v.firstPage();
        });

        v.GetSpreadSheet = function() {
            v.GiftSearchCriteria.LocalUrl = window.location.pathname;
           
            v2Form.POST({
                url: s,
                data: ko.toJS(v.GiftSearchCriteria),
                error: function() {
                    v2Form.GET({
                        url: se
                    });
                },
                complete: function () { console.log('analytics unblock'); $('body').unblock(); }
            });
        };


    };

    var payments = {};
    payments.InitializeViewModel = function (v, a, s, se) {
        

        v.PaymentSummary = {
            Count: ko.observable(),
            Mean: ko.observable(),
            Min: ko.observable(),
            Max: ko.observable(),
            Total: ko.observable()
        };
        
        v.SettledBankPayments = {
            Count: ko.observable(),
            Mean: ko.observable(),
            Min: ko.observable(),
            Max: ko.observable(),
            Total: ko.observable()
        };
        
        v.SettledCardPayments = {
            Count: ko.observable(),
            Mean: ko.observable(),
            Min: ko.observable(),
            Max: ko.observable(),
            Total: ko.observable()
        };
        
        v.SettledBankPaymentsGraph = {
            Count: ko.observable(),
            Mean: ko.observable(),
            Min: ko.observable(),
            Max: ko.observable(),
            Total: ko.observable()
        };

        v.SettledCardPaymentsGraph = {
            Count: ko.observable(),
            Mean: ko.observable(),
            Min: ko.observable(),
            Max: ko.observable(),
            Total: ko.observable()
        };

        v.PaymentYOY = {
            Stats: ko.observableArray(),
            Data: [],
            Categories: ['YTD', 'YOY', 'PY Total', '2YP Total'],
            
        };
        
        v.GenderPayments = {
            Data: []
        };

        v.AgePayments = {
            Categories: ['70+', '60-69', '50-59', '40-49', '30-39', '20-29', '<20'],
            TotalData: [],
            AverageData: []
        };

        v.Top10PostalCodePayments = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };


        v.ChannelPayments = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };
        
        v.PaymentMethodSummary = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };

        v.SourceCodePayments = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };

        v.FundablePayments = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };

        v.FundableTypePayments = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };
        
        v.PaymentsByYear = {
            Stats: ko.observableArray(),
            Data: [],
            Categories: ['This Month', 'YTD', 'PYTD', 'PY Total', '2YP Total'],

        };
        
        v.IndividualPaymentRange = {
            Categories: ['500','500-1000','1000-2500','2500-5000','5000-10000','10000-20000','>20000'],
            TotalData: []
        };
        
        v.OrganizationPaymentRange = {
            Categories: ['500', '500-1000', '1000-2500', '2500-5000', '5000-10000', '10000-20000', '>20000'],
            TotalData: []
        };
        
        v.IndividualDistancePayments = {
            Categories: ['5','5-10','10-20','20-30','30-50','>50'],
            TotalData: [],
            AverageData: []
        };
        
        v.OrganizationDistancePayments = {
            Categories: ['5', '5-10', '10-20', '20-30', '30-50', '>50'],
            TotalData: [],
            AverageData: []
            
        };

        v.LastYearGivingInMonths = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };

        v.ClearAll = function () {
            assignZeroStats(v.PaymentSummary);
        };

        //analytics property
        a.subscribe(function (nv) {
           v.firstPage();
        });

        v.GetSpreadSheet = function () {
            v.PaymentSearchCriteria.LocalUrl = window.location.pathname;

            v2Form.POST({
                url: s,
                data: ko.toJS(v.PaymentSearchCriteria),
                error: function () {
                    v2Form.GET({
                        url: se
                    });
                },
                complete: function () { $('body').unblock(); }
            });
        };
    };

    var commitments = {};
    commitments.InitializeViewModel = function (pageModel, searchAnalyticsProperty, searchSpreadsheetUrl, clearArgumentsFromSessionUrl) {//PaginationServices.PageModel,
        pageModel.CommitmentSummary = {
            Count: ko.observable(),
            Mean: ko.observable(),
            Min: ko.observable(),
            Max: ko.observable(),
            Total: ko.observable()
        };


        pageModel.CommitmentYTD = {
            Categories: ['YTD', 'Trailing 12 Months', 'Previous Year'],
            Data: []
        };

        pageModel.GenderCommitments = {
            Data: []
        };

        pageModel.AgeCommitments = {
            Categories: ['70+', '60-69', '50-59', '40-49', '30-39', '20-29', '<20'],
            TotalData: [],
            AverageData: []
        };

        pageModel.Top10PostalCodeCommitments = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };


        pageModel.ChannelCommitments = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };

        pageModel.SourceCodeCommitments = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };

        pageModel.FundableCommitments = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };

        pageModel.FundableTypeCommitments = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };
        
        pageModel.ClearAll = function () {
            assignZeroStats(pageModel.CommitmentSummary);
        };

        searchAnalyticsProperty.subscribe(function (nv) {
            pageModel.firstPage();
        });

        pageModel.GetSpreadSheet = function () {
            pageModel.CommitmentSearchCriteria.LocalUrl = window.location.pathname;

            v2Form.POST({
                url: searchSpreadsheetUrl,
                data: ko.toJS(pageModel.CommitmentSearchCriteria),
                error: function () {
                    v2Form.GET({
                        url: clearArgumentsFromSessionUrl
                    });
                },
                complete: function () { $('body').unblock(); }
            });
        };
    };

    var recurringGifts = {};
    recurringGifts.InitializeViewModel = function (v, a, s, se) {
        v.CommitmentSummary = {
            Count: ko.observable(),
            Mean: ko.observable(),
            Min: ko.observable(),
            Max: ko.observable(),
            Total: ko.observable()
        };


        v.RecurringGiftYTD = {
            Categories: ['YTD', 'Trailing 12 Months', 'Previous Year'],
            Data: []
        };

        v.GenderRecurringGifts = {
            Data: []
        };

        v.AgeRecurringGifts = {
            Categories: ['70+', '60-69', '50-59', '40-49', '30-39', '20-29', '<20'],
            TotalData: [],
            AverageData: []
        };

        v.Top10PostalCodeRecurringGifts = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };


        v.ChannelRecurringGifts = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };

        v.SourceCodeRecurringGifts = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };

        v.FundableRecurringGifts = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };

        v.FundableTypeRecurringGifts = {
            Categories: [],
            TotalData: [],
            AverageData: []
        };

        v.ClearAll = function () {
            assignZeroStats(v.CommitmentSummary);
        };


        a.subscribe(function (nv) {
            v.firstPage();
        });



        v.GetSpreadSheet = function () {
            v.CommitmentSearchCriteria.LocalUrl = window.location.pathname;

            v2Form.POST({
                url: s,
                data: ko.toJS(v.CommitmentSearchCriteria),
                error: function () {
                    v2Form.GET({
                        url: se
                    });
                },
                complete: function () { $('body').unblock(); }
            });
        };
    };

    var ledgers = {};
    ledgers.InitializeViewModel = function (v, a, s, se) {

        v.GetSpreadSheet = function () {

            v2Form.POST({
                url: s,
                data: ko.toJS(v.GLSearchCriteria),
                error: function () {
                    v2Form.GET({
                        url: se
                    });
                },
                complete: function () { $('body').unblock(); }
            });
        };
    };

    var volunteerParticipation = {};

   
    
    volunteerParticipation.InitializeViewModel = function(v, a, s, se) {
        v.ParticipationStatus = {};
        v.ParticipationStatus.Pending = ko.observable(0);
        v.ParticipationStatus.Accepted = ko.observable(0);
        v.ParticipationStatus.Approved = ko.observable(0);
        v.ParticipationStatus.Removed = ko.observable(0);
        v.ParticipationStatus.Declined = ko.observable(0);
        

        v.ClearAll = function() {
      
        };
    };

    var statements = {};
    statements.InitializeViewModel = function (v, a, s, se) {


        v.PaymentSummary = {
            Count: ko.observable(),
            Mean: ko.observable(),
            Min: ko.observable(),
            Max: ko.observable(),
            Total: ko.observable()
        };


        v.PaymentsByYear = {
            Stats: ko.observableArray(),
            Data: [],
            Categories: ['This Month', 'YTD', 'PYTD', 'PY Total', '2YP Total'],

        };


        v.ClearAll = function () {
            assignZeroStats(v.PaymentSummary);
        };

        //analytics property
        a.subscribe(function (nv) {
            v.firstPage();
        });

        v.GetSpreadSheet = function () {
            v.PaymentSearchCriteria.LocalUrl = window.location.pathname;
            v2Form.POST({
                url: s,
                data: ko.toJS(v.PaymentSearchCriteria),
                error: function () {
                    v2Form.GET({
                        url: se
                    });
                }
            });
        };
    };

module.exports = {
        UpdateViewModel: updateViewModel,
        Gifts: gifts,
        Payments: payments,
        Commitments: commitments,
        RecurringGifts: recurringGifts,
        Ledger: ledgers,
        Statements: statements,
        VolunteerParticipation: volunteerParticipation
    };
