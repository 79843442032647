/* File Created: July 8, 2012 */

//sumit support form
$(function () {
    $('form.supportForm').on('submit', function (e) {
        e.preventDefault();
        if ($(this).valid()) {
            var $this = $(this),
                        action = $this.attr('action'),
                        method = $this.attr('method').toUpperCase() || 'POST',
                        panel = $('#FormNotificationPanel');

            $.ajax({
                url: action,
                type: method,
                contentType: 'application/json',
                data: cleanViewModel(supportViewModel),
                statusCode: {
                    404: function () { panel.html("page not found").slideDown('fast'); }
                },
                beforeSend: function () {
                    /*todo: Check Validation then BlockUI*/
                    $this.block();
                },
                success: function (data, textStatus, jqXhr) {

                    data = data.Data;
                    //data = JSON.parse(data); wtf is this choking on?
                    var message = data.ReturnValue,
                                error = data.Error,
                                modelState = data.ModelStateDictionary,
                                apiResponse = data.ConfirmationMessage;

                    if (modelState) {
                        //console.log(modelState);
                        var messages = "Model Errors: <br />";
                        for (key in modelState)
                            if (modelState[key].Errors)
                                for (error in modelState[key].Errors)
                                    messages += modelState[key].Errors[error].ErrorMessage + '<br />';
                        panel.html(messages).slideDown('fast');
                        notify.alert(messages);
                    }
                    else if (apiResponse) {
                        $("#submitATicket").modal("hide");
                        notify.alert(apiResponse);
                    }
                    else if (message) panel.html(message).slideDown('fast');
                    else if (error) panel.html(error).slideDown('fast');
                },

                error: function (jqXhr, textStatus, errorThrown) {
                    //todo: deal with validators
                    panel.html(errorThrown).slideDown('fast');
                },
                complete: function () { $this.unblock(); }
            });
        }
    });
});