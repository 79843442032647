var json2html = require('../json2html.js');

var TemplateContexts = require('./templateContexts.js')



    


    function bindTemplate(template) {

    }

    function bindMarkup(section) {
        
        //console.log('bind markup called', section.Markup(), section.Json());
        var output = section.getOutput(section.Markup());
        section.Output(output);
        section.SupportedTemplateTypes = null;
    }

    function templateSection(t) {
        var self = this;
        
        self.TemplateSectionId = ko.observable(0);
        self.NotificationTemplateId = ko.observable(0);
        self.TemplateSectionType = ko.observable(t);
        self.Markup = ko.observable("");
        self.NotificationTemplateId = ko.observable(0);
        self.Output = ko.observable();
        self.TemplateContextName = ko.observable();
        self.TemplateContextName(TemplateContexts.ContextFromType[t]);
        self.Json = ko.computed(function() {
            return TemplateContexts[self.TemplateContextName()];
        });
        self.getOutput = function (nv) {
            var retStr = 'There was an error with the template output, please check the markup.'; 
            try {
                return Mustache.render(nv, self.Json());
            } catch (e) {
                retStr = retStr + ': ' + e;
                console.error(retStr);
            }
            return retStr;
        }
        self.Markup.subscribe(function (nv) {
            var output = self.getOutput(nv);
            self.Output(output);
        });
        return self;
    }

    function acknowledgementTemplate(s, i, c) {
        var self = this;
        self.NotificationTemplateType = ko.observable(viewModel.Channel());
        self.TemplateName = ko.observable();
        self.TemplateDescription = ko.observable();
        self.TemplateContextId = ko.observable(i);
        self.TemplateContextName = ko.observable();
        self.TemplateContext = ko.observable(c());
        switch (viewModel.AcknowledgementType()) {
            case 1:
                //gift
                self.MarketingTemplateType = ko.observable(2);
                break;
            case 2:
                //connect
                self.MarketingTemplateType = ko.observable(1);
                break;
            case 3:
                //commitment
                self.MarketingTemplateType = ko.observable(4);
                break;
            case 4:
                //multi
                self.MarketingTemplateType = ko.observable(3);
                break;
            case 8:
                //general source 
                self.MarketingTemplateType = ko.observable(8);
                break;
            case 7:
                //church service 
                self.MarketingTemplateType = ko.observable(7);
                break;
            default:
                break;
        }
        self.Sections = ko.observableArray();

        if (s) //subject
        {
            self.Sections.push(new templateSection(2, c()));
        }
        self.Sections.push(new templateSection(1, c()));
        return self;
    }

    function templateModel() {
        var self = this;
        
        self.ModelRetrieved = ko.observable();
        self.Name = ko.observable();
        self.Description = ko.observable();
        self.NotificationTemplateType = ko.observable(),
        self.Channel = ko.observable();
        self.TemplateContextName = ko.observable();
        self.TemplateContextId = ko.observable();
        self.Sections = ko.observableArray();
        self.MarketingTemplateType = ko.observable();
        self.ApplicationId = ko.observable();
        self.ApplicationScopeId = ko.observable();
        self.NotificationChannelType = ko.observable();
        self.FromEmail = ko.observable();


    }

    function clearModel() {
        self.Id(0);
        self.ModelRetrieved(false);
        self.Name(null);
        self.Description(null);
        self.NotificationTemplateType(null);
        self.Channel(null);
        self.TemplateContextName(null);
        self.TemplateContextId(null);
        self.Sections.removeAll();
        self.MarketingTemplateType(null);
        self.ApplicationId(null);
        self.ApplicationScopeId(null);
        self.NotificationChannelType(null);
    }

    function assignTemplateViewModel(model,x) {
        var d = ko.toJS(x);
        console.log('assigning template', ko.toJS(model), d);

        //if(d.Id)
        //    model.TemplateId(d.Id);
        //if (d.TemplateId)
        //    model.TemplateId(d.TemplateId);

        model.ApplicationId(d.ApplicationId);
        model.ApplicationScopeId(d.ApplicationScopeId);
        model.TemplateContextId(d.TemplateContextId);
        model.Name(d.Name);
        model.Description(d.Description);
        model.MarketingTemplateType(d.MarketingTemplateType);
        model.NotificationChannelType(d.NotificationChannelType);
        model.Channel(d.NotificationChannelType);
        model.NotificationTemplateType(d.NotificationChannelType);
        model.TemplateContextName(d.TemplateContextName);
        model.FromEmail(d.FromEmail);
        model.Sections.removeAll();
        for (var i = 0; i < d.Sections.length; i++) {
            var x = d.Sections[i];
            var sec = new templateSection(d.Sections[i].TemplateSectionType);
            sec.NotificationTemplateId(d.TemplateId);
            sec.TemplateSectionId(d.Sections[i].TemplateSectionId);
            sec.TemplateContextName(model.TemplateContextName());
            sec.Markup(d.Sections[i].Markup);
            model.Sections.push(sec);
            bindMarkup(sec);
            if (sec.TemplateSectionType() == 1)
                model.Body = sec;
            if (sec.TemplateSectionType() == 2)
                model.Subject = sec;
        }

        console.log('model assigned', ko.toJS(model));
    }


    function getTemplateModel(id, model) {
        //console.log('getting template model')
        $.ajax({
            url: '/Marketing/CommTemp/GetTemplateDetail',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({
                Id: id
            }),
            success: function(data, textStatus, jqXhr) {
                var d = data.Data;
                assignTemplateViewModel(model, d);

            }
        });

    }

   

    function subscribeTemplate(p,preview,editor,e,s) {
        var element = $(e);

        var model = {
            ShowPreviewDialog: ko.observable(false),
            TemplateId: ko.observable(),
            ModelRetrieved: ko.observable(false),
            TemplateViewModel: new templateModel(),
            Step: ko.observable(1),
            
        };

        
        
        model.loadTemplate = function (d) {
            //retrieve the model
          
            if (d != 0 && model.TemplateId() != d) {
                getTemplateModel(d, model.TemplateViewModel);
            }
            model.TemplateId(d);
            console.log('load template model', ko.toJS(model));

        };

        model.MultiSection = ko.computed(function() {
            return (model.TemplateViewModel.NotificationChannelType() == 3);
        });

        

        model.incrementStep = function() {
            if (model.MultiSection() && model.Step() == 1) {
                model.Step(2);
                return;
            }
                

            if (!model.MultiSection() && model.Step() == 1) {
                model.Step(3);
                return;
            }
                

            if (model.Step() == 4)
                return;

            model.Step(model.Step() + 1);
        };

        model.decrementStep = function() {
            if (!model.MultiSection() && model.Step() == 3) {
                model.Step(1);
                return;
            }
                

            if (model.Step() == 1)
                return;

            model.Step(model.Step() - 1);
        };

        model.showPreview = function (d, n, c, t) {
            model.idToUpdate = d;
            model.nameToUpdate = n;
            model.channelToUpdate = c;
            model.loadTemplate(d());
            $(preview).modal({
                show: true,
                backdrop: 'static'
            });
        };

        model.hidePreview = function () {
        
            $(preview).modal('hide');
        };

        model.showEditor = function (d, n, c, t, newTemplate) {
            model.idToUpdate = d;
            model.nameToUpdate = n;
            model.channelToUpdate = c;
            if (d() != 0 && !newTemplate) {
                //console.log('loading template', d());
                model.loadTemplate(d());
            }
            
          
            model.Step(1);
            $(s).modal('hide');
            $(preview).modal('hide');
            $(editor).modal({
                show: true,
                backdrop: 'static'
            });
            
        };

        model.hideEditor = function () {
          
            $(editor).modal('hide');
        };

        model.showSearch = function (d,n,c,t) {
            model.idToUpdate = d;
            model.nameToUpdate = n;
            model.channelToUpdate = c;
            model.Templates.PageData.removeAll();
            model.Templates.TemplateSearchCriteria.QueryString('*');
            model.Templates.TemplateSearchCriteria.MarketingTemplateType(t);
            model.Templates.firstPage();
            $(s).modal('show');
        };

        model.hideSearch = function (d) {
            $(s).modal('hide');
        };

        model.searchSelect = function (d) {
            //console.log('search select');
            assignTemplateViewModel(model.TemplateViewModel, d);
            model.TemplateId(d.Id());
            model.hideSearch();
            model.idToUpdate(d.Id());
            model.nameToUpdate(d.Name());
            model.channelToUpdate(d.NotificationChannelType());
            
        };

        model.isSaving = false;

        model.saveChanges = function () {
            if (model.isSaving) {
                return;
            }
            model.isSaving = true;
            var data = ko.mapping.toJS(model.TemplateViewModel);
            data.TemplateId = model.TemplateId();

            v2Form['POST']({
                panel: '#MarketingTemplatePanel',
                block: true,
                url: '/Marketing/CommTemp/SaveChanges',
                type: 'POST',
                data: data,
                element: element,
                success: generalSuccess,
                error: generalFail,
                clientModel : model
            });
        };
        
        model.Templates = new PaginationServices.PageModel();
        model.Templates.TemplateSearchCriteria = PaginationServices.MarketingTemplateSearchModel(0, 10);
        PaginationServices.SubscribeSearchPagination(p, model.Templates, '/Marketing/CommTemp/SearchMarketingTemplates', model.Templates.TemplateSearchCriteria);

       
        p.TemplateModel = model;
    };

    function generalSuccess(data, textStatus, jqXhr, opts) {

        var panel = {};

        if (opts.panel)
            panel = $(opts.panel);
        else
            panel = $('#FormNotificationPanel');
        //console.log('opts client model', ko.toJS(opts.clientModel));
        var retData = data.Data,
            message = data.Message,
            error = data.Error,
            redirect = data.Redirect,
            modelState = data.ModelStateDictionary;
            
      
        if (modelState) {
            var messages = "Issues: <br />";
            for (key in modelState)
                if (modelState[key].Errors)
                    for (error in modelState[key].Errors)
                        messages += modelState[key].Errors[error].ErrorMessage + '<br />';

            panel.html(messages).slideDown('fast');
            notify.alert(messages);
            opts.clientModel.isSaving = false;
        } else {
            opts.clientModel.isSaving = false;
            opts.clientModel.TemplateId(data.Data.TemplateId);
            opts.clientModel.TemplateId(data.Data.TemplateId);
            opts.clientModel.idToUpdate(data.Data.TemplateId);
            opts.clientModel.nameToUpdate(data.Data.Name);
            opts.clientModel.channelToUpdate(data.Data.NotificationChannelType);
            for (s in data.Data.Sections) {
                ko.utils.arrayForEach(opts.clientModel.TemplateViewModel.Sections(), function (item) {
                    if (item.TemplateSectionType() == s.TemplateSectionType)
                        item.TemplateSectionId(s.TemplateSectionId);
                });
            }
            opts.clientModel.hideEditor();
           
        }
       

    }

    function generalFail(jqXhr, textStatus, errorThrown, opts) {

        var panel = {};

        if (opts.panel)
            panel = $(opts.panel);
        else
            panel = $('#FormNotificationPanel');

        var temp = jqXhr;
        var error = errorThrown;
        if (jqXhr.status == 500) {
            errorThrown = 'Oops, we messed up, tell Kevin about it.';
        }

        if (jqXhr.status == 404) {
            errorThrown = 'Not found, Kevin may have moved tell Kevin about it';
        }

        if (jqXhr.status == 403) {
            notify.top("You are trying to access things you aren't supposed to be, you are going on the Vision2 naughty list");
        }
        //todo: deal with validators
        notify.growl(errorThrown, "Error", { mode: 'error', timeout: 10000 });
        panel.html(errorThrown).slideDown('fast');
        opts.clientModel.isSaving = false;

    };

    function createTemplate(model, c, m, f) {
        console.log('model', ko.toJS(model));
        
        model.TemplateId(0);
        model.TemplateViewModel.Name(null);
        model.TemplateViewModel.Description(null);
        model.TemplateViewModel.MarketingTemplateType(m);
        model.TemplateViewModel.NotificationTemplateType(2);
        model.TemplateViewModel.TemplateContextName(TemplateContexts.ContextFromType[m]);
        model.TemplateViewModel.Channel(c);
        model.TemplateViewModel.NotificationChannelType(c);
        model.TemplateViewModel.FromEmail(f);
        model.TemplateViewModel.Sections.removeAll();
        if (c == 3) {
            var s = new templateSection(m);
            s.TemplateSectionType(2);
            bindMarkup(s);
            model.TemplateViewModel.Subject = s;
            model.TemplateViewModel.Sections.push(s);

        }
        var b = new templateSection(m);
        b.TemplateSectionType(1);
        bindMarkup(b);
        model.TemplateViewModel.Body = b;
        model.TemplateViewModel.Sections.push(b);
        
        
    }

    function duplicateTemplate(model,id,f) {
        var model = new templateModel();
        getTemplateModel(id, model);
        model.Id(0);
        model.TemplateId(0);
        model.FromEmail(f);
        ko.utils.arrayForEach(model.Sections(), function(i) {
            i.TemplateSectionId(0);
        });

    }

    var jsonTransformHelper = {
        getValue: function(obj) {
            var type = $.type(obj);

            //Determine if this object has children
            switch (type) {
                case 'array':
                case 'object':
                    return (undefined);
                    break;

                case 'function':
                    //none
                    return ('function');
                    break;

                case 'string':
                    return ("'" + obj + "'");
                    break;

                default:
                    return (obj);
                    break;
            }
        },
        children : function(obj) {
            var type = $.type(obj);

            //Determine if this object has children
            switch (type) {
                case 'array':
                case 'object':
                    return (json2html.transform(obj, transforms.object));
                    break;

                default:
                    //This must be a litteral
                    break;
            }
        },
        convert : function(name, obj, show, parent) {

            var type = $.type(obj);

            if (show === undefined) show = 'open';

            var children = [];

            var newParent = '';
            if (parent != null) {
                newParent = parent + '.' + name;

            } else {
                newParent = name;
            }
            var mustache = '{{' + newParent + '}}';

            //Determine the type of this object
            switch (type) {
                case 'array':
                    //Transform array
                    //Itterrate through the array and add it to the elements array
                    var len = obj.length;
                    for (var j = 0; j < len; ++j) {
                        //Concat the return elements from this objects tranformation
                        children[j] = jsonTransformHelper.convert(j, obj[j], show, newParent);
                    }
                    break;
                case 'object':
                    //Transform Object
                    var j = 0;
                    for (var prop in obj) {
                        children[j] = jsonTransformHelper.convert(prop, obj[prop], newParent);
                        j++;
                    }
                    break;
                default:
                    //This must be a litteral (or function)
                    children = obj;
                    break;
            }

            return ({ 'name': newParent, 'value': children, 'type': type, 'show': show, 'mustache': mustache });

        }
    };
    

    //Transform the children
    

    


    var transforms = {
        'object': {
            'tag': 'div',
            'class': 'package ${show} ${type}',
            'children': [
                {
                    'tag': 'div',
                    'class': 'header',
                    'children': [
                        {
                            'tag': 'div',
                            'class': function (obj) {
                                if (jsonTransformHelper.getValue(obj.value) !== undefined) return ('arrow hide');
                                else return ('arrow');
                            }
                        },
                        { 'tag': 'span', 'class': 'name', 'html': '${name}' },
                        {
                            'tag': 'span',
                            'class': 'value',
                            'html': function (obj) {
                                var value = jsonTransformHelper.getValue(obj.value);
                                if (value !== undefined) return (" : " + value);
                                else return ('');
                            }
                        },
                        {
                            'tag': 'span', 'class': 'name', 'html': function (obj) {
                                var value = jsonTransformHelper.getValue(obj.value);
                                if (value !== undefined) return (obj.mustache);
                                else return ('');
                            }
                        },
                        { 'tag': 'span', 'class': 'type', 'html': '${type}' }
                    ]
                },
                { 'tag': 'div', 'class': 'children', 'children': function (obj) { return (jsonTransformHelper.children(obj.value)); } }
            ]
        }
    };


    function transformContext(name, value) {
        
        var temp = json2html.transform(jsonTransformHelper.convert(name, value, 'open'), transforms.object);
        return temp;
    }
   

    //this is synched with trigger source type and marketing template type enumerations
    var templateTypeFromSourceType = [0, 9, 8, 10, 7, 11, 13, 12];

    var templateTypeFromAcknowledgementType = [0,2, 1, 4, 3,24,25,4,4];


    var templateTypeFromAppealType = [0, 5, 14];

module.exports ={
        BindMarkup: bindMarkup,
        TemplateSectionModel: templateSection,
        AcknowledgementTemplateModel: acknowledgementTemplate,
        SubscribeTemplate : subscribeTemplate,
        TemplateModel: templateModel,
        CreateTemplate: createTemplate,
        DuplicateTemplate: duplicateTemplate,
        TemplateTypeFromSourceType: templateTypeFromSourceType,
        TemplateTypeFromAcknowledgementType : templateTypeFromAcknowledgementType,
        TemplateContextTransform: transformContext,
        TemplateTypeFromAppealType : templateTypeFromAppealType
    };



