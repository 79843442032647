$(document).ready(function () {
    $('.projectLongDescriptionTruncate').expander({
        slicePoint: 260,
        userCollapseText: 'read less',
        expandPrefix: '...',
        expandEffect: 'fadeIn',
        expandSpeed: 250,
        collapseEffect: 'fadeOut',
        collapseSpeed: 200,
        expandText: 'read more'
    });
});