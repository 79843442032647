
var template = require('./individual.html');
var throttle = 500;


function getAddresses(profileId, sourceArray) {
    $.ajax({
        url: '/People/Individual/Addresses/' + profileId,
        type: 'POST',
        contentType: 'application/json',
        dataType: 'json',
        success: function (data, textStatus, jqXhr) {
            sourceArray(data);
        }
    });
}

function properCase(o) {
    var temp = ko.utils.unwrapObservable(o);
    if (temp != null && temp === temp.toLowerCase()) {
        temp = temp.substring(0, 1).toUpperCase() + temp.substring(1);
        o(temp);
    }
}



function profileIdChange(id, profile) {
    if (id && id != 0) {
        profile.PreviousSearch = ko.toJS(profile);
        var matches = $.ajax({
            url: '/People/Individual/GetProfile',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({ Id: id }),
            success: function (data, textStatus, jqXhr) {
                var result = data.Data;
                profile.IndividualProfileId(id);
                profile.FirstName(result.FirstName);
                profile.LastName(result.LastName);
                profile.Address1(result.Address1);
                profile.Address2(result.Address2);
                profile.City(result.City);
                profile.Region(result.Region);
                profile.PostalCode(result.PostalCode);
                profile.CountryType(result.CountryType);
                profile.PhoneNumber(result.PhoneNumber);
                profile.EmailAddress(result.EmailAddress);
                profile.SiteId(result.SiteId);
                profile.Envelope(result.Envelope);
                profile.AddressId(result.AddressId);
                profile.EmailAddressId(result.EmailAddressId);
                profile.PhoneId(result.PhoneNumberId);
            }
        });
    }
}


function findProfileMatches(profile, resultsArray) {
    var model = ko.toJS(profile);

    if (model.PageSize == 0)
        model.PageSize = 15;

    if (profile.IndividualProfileId() == null) {

        $.ajax({
            url: '/People/Individual/SearchProfile',
            type: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({
                ProfileSearchDetail: {
                    FirstName: model.FirstName,
                    MiddleName: model.MiddleName,
                    LastName: model.LastName,
                    Address1: model.Address1,
                    Address2: model.Address2,
                    City: model.City,
                    Region: model.Region,
                    CountryType: model.CountryType,
                    PostalCode: model.PostalCode,
                    PhoneNumber: model.PhoneNumber,
                    EmailAddress: model.EmailAddress,
                    Envelope: model.Envelope,
                    UseComplex: true,
                    PageIndex: model.PageIndex,
                    PageSize: model.PageSize,
                    IsDeceased: false
                }

            }),
            success: function (data, textStatus, jqXhr) {
                if (data.Data) {
                    data = data.Data;
                }
                profile.ProfileMatches.removeAll();
                profile.ProfileMatches(data.PageData);
                profile.PageIndex(data.PageIndex);
                profile.PageSize(data.PageSize);
                $('[data-spy="affix"]')
                    .each(function () {
                        $(this).affix('refresh');
                    });
            }
        });

    }
}



function subscribeProfileChanges(profile) {
    profile.ProfileMatches = ko.observableArray();
    profile.DesignationMatches = ko.observableArray();
   // findDesignationMatches(profile);
    profile.RecentPayments = ko.observableArray();
    profile.PageIndex = ko.observable(0);
    profile.PageSize = ko.observable(15);
    profile.PreviousSearch = ko.toJS(profile);
    profile.UseComplex = ko.observable(true);
    profile.EnableSearch = function () {
        profile.PhoneOrEmailAddress(profile.PreviousSearch.PhoneOrEmailAddress);
        profile.FirstName(profile.PreviousSearch.FirstName);
        profile.LastName(profile.PreviousSearch.LastName);
        profile.Address1(profile.PreviousSearch.Address1);
        profile.Address2(profile.PreviousSearch.Address2);
        profile.City(profile.PreviousSearch.City);
        profile.Region(profile.PreviousSearch.Region);
        profile.PostalCode(profile.PreviousSearch.PostalCode);
        profile.CountryType(profile.PreviousSearch.CountryType);
        profile.PhoneNumber(profile.PreviousSearch.PhoneNumber);
        profile.EmailAddress(profile.PreviousSearch.EmailAddress);
        profile.SiteId(profile.PreviousSearch.SiteId);
        profile.Envelope(profile.PreviousSearch.Envelope);
        profile.UseComplex(true);
        profile.IndividualProfileId(0);

    };
    profile.ProfileSelected = function (d, e) {
        console.log('selected id ' + d);
        profile.IndividualProfileId(d.Id);
        //findDesignationMatches(profile);
    };
    var lastfieldChanged = null;
   
    profile.IndividualProfileId.subscribe(function (nv) { profileIdChange(nv, profile); });
    //profile.PhoneOrEmailAddress.subscribe(function (nv) { profileIdChange(nv, profile); });
    profile.FirstName.subscribe(function (nv) { properCase(profile.FirstName); findProfileMatches(profile); });
    profile.MiddleName.subscribe(function (nv) { properCase(profile.MiddleName); });
    profile.LastName.subscribe(function (nv) { properCase(profile.LastName); findProfileMatches(profile); });
    profile.Address1.subscribe(function (nv) { findProfileMatches(profile); });
    profile.Address2.subscribe(function (nv) { findProfileMatches(profile); });
    profile.City.subscribe(function (nv) { findProfileMatches(profile); });
    profile.Region.subscribe(function (nv) { findProfileMatches(profile); });
    profile.PostalCode.subscribe(function (nv) { findProfileMatches(profile); });
    profile.EmailAddress.subscribe(function (nv) { findProfileMatches(profile); });
    profile.PhoneNumber.subscribe(function (nv) { findProfileMatches(profile); });
    profile.Envelope.subscribe(function (nv) { findProfileMatches(profile); });
    profile.SearchForMatches = function () {
        findProfileMatches(profile);
    };


    profile.FirstName.extend({ rateLimit: { timeout: throttle, method: "notifyWhenChangesStop" } });
    //profile.PhoneOrEmailAddress.extend({ rateLimit: { timeout: throttle, method: "notifyWhenChangesStop" } });
    profile.MiddleName.extend({ rateLimit: { timeout: throttle, method: "notifyWhenChangesStop" } });
    profile.LastName.extend({ rateLimit: { timeout: throttle, method: "notifyWhenChangesStop" } });
    profile.Address1.extend({ rateLimit: { timeout: throttle, method: "notifyWhenChangesStop" } });
    profile.Address2.extend({ rateLimit: { timeout: throttle, method: "notifyWhenChangesStop" } });
    profile.City.extend({ rateLimit: { timeout: throttle, method: "notifyWhenChangesStop" } });
    profile.Region.extend({ rateLimit: { timeout: throttle, method: "notifyWhenChangesStop" } });
    profile.PostalCode.extend({ rateLimit: { timeout: throttle, method: "notifyWhenChangesStop" } });
    profile.EmailAddress.extend({ rateLimit: { timeout: throttle, method: "notifyWhenChangesStop" } });
    profile.PhoneNumber.extend({ rateLimit: { timeout: throttle, method: "notifyWhenChangesStop" } });
    profile.Envelope.extend({ rateLimit: { timeout: throttle, method: "notifyWhenChangesStop" } });


}

function basicProfileModel() {
    var self = this;
    self.IndividualProfileId = ko.observable(0).withPausing();
    self.FirstName = ko.observable().withPausing();
    self.MiddleName = ko.observable().withPausing();
    self.Suffix = ko.observable().withPausing();
    self.LastName = ko.observable().withPausing();
    self.AddressId = ko.observable().withPausing();
    self.Address1 = ko.observable().withPausing();
    self.Address2 = ko.observable().withPausing();
    self.City = ko.observable().withPausing();
    self.Region = ko.observable().withPausing();
    self.PostalCode = ko.observable().withPausing();
    self.CountryType = ko.observable().withPausing();
    self.PhoneId = ko.observable().withPausing();
    self.PhoneNumber = ko.observable().withPausing();
    self.EmailAddress = ko.observable().withPausing();
    self.EmailAddressId = ko.observable().withPausing();
    self.SiteId = ko.observable().withPausing();
    self.SearchTerm = ko.observable().withPausing();
    self.IsDeceased = ko.observable(false).withPausing();
    self.Envelope = ko.observable().withPausing();
    self.clear = function () {
        self.FirstName(null);
        self.MiddleName(null);
        self.Suffix(null);
        self.LastName(null);
        self.AddressId(null);
        self.Address1(null);
        self.Address2(null);
        self.City(null);
        self.Region(null);
        self.PostalCode(null);
        self.PhoneId(null);
        self.PhoneNumber(null);
        self.EmailAddress(null);
        self.EmailAddressId(null);
        self.SiteId(null)
        self.SearchTerm(null);
        self.Envelope(null);
        self.IsDeceased(null);
        self.IndividualProfileId(0);

    }

    return this;
}


function viewModel(params) {
    //var vm = params.Id;
    var vm =  new basicProfileModel();
    
    vm.IndividualProfileId = params.Id;
    console.log('vm individual', ko.toJS(vm));
    subscribeProfileChanges(vm);

    return vm;

    
}

module.exports = {
    viewModel: viewModel,
    template:template

}